import { invalidate } from '@react-three/fiber';
import { Vector2,ShadowMaterial } from 'three';

export const takeScreenshotSize = (width=1000,height=1000, asBlob=false) => {
	console.log("Taking screenshot with size", width,height);
	const size = new Vector2()
	window.gl.getSize(size)

	resizeGl(width,height)
	invalidate()
	window.post?.current ? window.post.current.render() : window.gl.render(window.scene,window.camera)
	const url = window.gl.domElement.toDataURL('image/jpeg', 0.9);
	resizeGl(size.x, size.y);
	invalidate()
	return  asBlob==true ? dataURLtoBlob(url) : url
}

//will this work with progressive rendering?  
export const takeScreenshot = (sizeIncrease=3,quality=0.9, asBlob=false, mimeType='image/jpeg') => {
	console.log("Taking a screenshot!");
	const size = new Vector2()
	window.gl.getSize(size)
	if (sizeIncrease > 1) sizeIncrease = Math.min(2000 / size.x, 2000/size.y);
	if (sizeIncrease != 1) resizeGl(size.x * sizeIncrease, size.y*sizeIncrease);
	window.post?.current ? window.post.current.render() : window.gl.render(window.scene,window.camera)

	const url = window.gl.domElement.toDataURL(mimeType, quality);
	if (sizeIncrease != 1) resizeGl(size.x, size.y);
	invalidate()

	return  asBlob==true ? dataURLtoBlob(url) : url
}

export const takeScreenshotTransparent = (sizeIncrease=3,quality=0.9,asBlob=false) => {
	const floor = window.scene.children.filter(f=>f.name=='floor')[0]
	let oldMaterial =floor.material;
	let oldBackground = window.scene.background;
	let oldClearColor = window.gl.clearColor()

	const restoreVals = () => {
		window.gl.alpha = false;
		window.scene.background = oldBackground;
		window.gl.setClearColor( oldClearColor );
		floor.material = oldMaterial;
	}
	try {
		window.gl.alpha = true;
		window.scene.background = null;
		window.gl.setClearColor( 0x000000, 0 );
		var material = new ShadowMaterial();
		material.opacity = 0.5;
		material.transparent = true;
		floor.material = material;
		const image =  takeScreenshot(sizeIncrease,quality,asBlob,'image/png')
		restoreVals()
		return image;
	}
	finally {
		restoreVals()
		invalidate()
	}
}


export const addWatermark = (url, watermarkSrc='/images/watermark.png') => {
	return new Promise((resolve, reject) => {
		const waterMark = new Image();
		const logoSize=0.75; const bottomRightOffset = 90;
		const adj = 2.0
		const canvas = document.createElement('canvas'); canvas.ctx = canvas.getContext('2d');
		const img = new Image()
		img.onload = () => {
			canvas.width=img.width; canvas.height=img.height;
			canvas.ctx.drawImage(img,0,0)
			waterMark.onload=() => {
				canvas.ctx.globalAlpha = 0.4;
				const dx = (img.width * 0.97) - (waterMark.width*adj)
				const dy = (img.height * 0.97) - (waterMark.height*adj)
				canvas.ctx.drawImage(waterMark,dx,dy, waterMark.width*adj, waterMark.height*adj)
				resolve(canvas.toDataURL('image/jpeg',0.9))
			}
			waterMark.src=watermarkSrc
		}
		img.src = url;
	})
}

const resizeGl = (width,height) => {
	window.camera.aspect = width / height;
	window.camera.updateProjectionMatrix();
	window.gl.setSize(  width, height );
	if (window.post?.current)  { window.post.current.setSize(width,height)}
}


export function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
}