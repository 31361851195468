import React, { useEffect, useState } from "react"
import { Avatar, Typography} from '@mui/material';
import { profilesDB, projectsDB, getUrlFromRef} from "../libs/firebase"
import { query, where,getDocs } from "firebase/firestore";
import { styled } from '@mui/system';

const MyAvatar = styled(Avatar)({ color:'#ffffff', backgroundColor:'#000000', height:'44px',width:'44px', border:'1px solid white'})
const avatarDiv ={top: '49px', position: 'absolute', right: '30px', zIndex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',color:'white'}
const UserTypography = styled(Typography)({ fontWeight:'600' })

export const UserAvatar = (props) => {
	const projectId = props.projectId
	const [userId,setuserId]=useState(null)
	const [creatorProfile,setcreatorProfile]=useState({})
	const [userName,setuserName]=useState("")

	useEffect(() => {
		const q = query(projectsDB, where("id", "==", projectId));
		getDocs(q).then ( querySnapshot => {
			querySnapshot.forEach((doc) => {setuserId(doc.data().user) })
		})
		.catch((error) => { console.log("Error getting project data: ", error); });
	},[projectId])
	
	useEffect(() => {
		if (userId) {
			const q = query(profilesDB, where("uid", "==", userId))
			getDocs(q).then ( doc => {
				if (doc.docs.length > 0) { 
					setcreatorProfile(doc.docs[0].data());
	               let userNameTemp =  creatorProfile?.first_name ? creatorProfile?.first_name + ' ' + creatorProfile?.last_name : '';
				   setuserName(userNameTemp)
				}
				else { setcreatorProfile({})}
			})
		.catch((error) => { console.log("Error getting profile data: ", error); });
		}
	},[userId])

	useEffect(() => {
		let userNameTemp =  creatorProfile?.first_name ? creatorProfile?.first_name + ' ' + creatorProfile?.last_name : '';
		setuserName(userNameTemp)
	},[creatorProfile])

	const getAvatar = () => {
		if (creatorProfile?.files?.pic) return (<MyAvatar alt={userName} src={getUrlFromRef(creatorProfile.files.pic)}/>);		
		else return ( <MyAvatar >M</MyAvatar> );
	}
	return (
        <React.Fragment>
		{userId &&
				<div>
					<div style ={avatarDiv}> 
						{getAvatar()}
						<UserTypography sx={{ mb: 1.0, fontSize:'10px', letterSpacing: '0.5px', marginTop: '5px' }} >{userName}</UserTypography>
						<UserTypography sx={{ mb: 1.0, fontSize:'11px', textTransform: 'uppercase', width: '100%', textAlign: 'right' }} >{creatorProfile?.category}</UserTypography>	
					</div>	
				</div>
		}
		</React.Fragment>
    );
}

