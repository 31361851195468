import React from 'react'
import { Grid, Typography } from '@mui/material';
import {usePhysicalObjects} from '../MattoState'


export const ArrowKeys = () => {
        return (
            <Grid container id= 'ArrowKeys'  item xs={12} style={{maxWidth:'90%',paddingLeft:'23px', paddingTop:'20px'}} >
                <Grid item xs={3} style={{paddingLeft:'5px',paddingTop:'10px'}}>
                    <img src='/images/arrowkeys.png' width={50} />
                </Grid>
                <Grid item xs={9} style={{paddingLeft:'10px'}}>
                    <Typography  variant="caption" >Use Arrow Keys for fine movement (U=Up/D=Down)</Typography>
                </Grid>
            </Grid>
        )
}