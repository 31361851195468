import React, { useMemo,useRef, useState,useEffect,useCallback } from 'react'
import { usePhysicalObjects } from '../MattoState'
import { Align, CanvasObject, PhysicalObjectInterface } from '../ts/app_interfaces';
import { Button, ButtonGroup, Checkbox, Grid, MenuItem, Select, Stack, TextField, TextareaAutosize, Typography } from '@mui/material';
import { produce } from 'immer';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import {useClickOutside} from '../libs/useClickOutside'
import { ScaleRotationSelector } from './ScaleRotationSelector';
import { ArrowKeys } from './ArrowKeys';
import { commonTypefaces, lineEndTypes } from '../libs/util';
import { styled } from '@mui/material/styles';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';

const MyButton = styled(Button)(({ theme }) => ({
    color: 'black',
    height: '32px',
    fontSize: '0.75rem',
    padding: '0px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0',
    letterSpacing: '0.46px',
    minWidth: '64px',
    [theme.breakpoints.down('lg')]: {
        padding: '0px', // Adjust the padding for small screens
        minWidth: '48px',
        fontSize: '0.70rem'
    },
    [theme.breakpoints.down('md')]: {
        padding: '0px', // Adjust the padding for small screens
        minWidth: '40px',
        fontSize: '0.60rem'
    }
}));

const MySelect = styled(Select)(({ theme }) => ({
    height: '32px', 
    fontSize: '0.75rem',
    [theme.breakpoints.down('lg')]: {
        padding: '0px', // Adjust the padding for small screens
        minWidth: '48px',
        fontSize: '0.70rem',
        // reduce padding of child div
        '& .MuiSelect-select': {
            padding: '0px 6px'
        },
    },
    [theme.breakpoints.down('md')]: {
        padding: '0px', // Adjust the padding for small screens
        minWidth: '24px',
        fontSize: '0.60rem',
        '& .MuiSelect-select': {
            padding: '0px 3px',
            paddingRight: '26px !important'
        },
        '& .MuiSelect-icon': {
            right: '5px'
        }
    }
}));


export const CanvasObjectInspector = () => {
    const selectedPhysicalObject:PhysicalObjectInterface | any = usePhysicalObjects(state=>state.selectedPhysicalObject)
    const setSelectedPhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)
    if (!selectedPhysicalObject?.canvasObject) return null;
    const canvasObject:CanvasObject = selectedPhysicalObject.canvasObject


    const updateText = (e:any) => {
        if (!selectedPhysicalObject) return;
        const newObject:PhysicalObjectInterface = produce(selectedPhysicalObject as PhysicalObjectInterface, draft => {
            if (draft.canvasObject) draft.canvasObject.text = e.target.value
        })
        setSelectedPhysicalObject(newObject)
    }
    const handleColorChange = (color:string) => {
        const newObject:PhysicalObjectInterface = produce(selectedPhysicalObject as PhysicalObjectInterface, draft => {
            if (draft.canvasObject) draft.canvasObject.color = color
        })
        setSelectedPhysicalObject(newObject)
    }
    const handleBackgroundColorChange = (color:string) => {
        const newObject:PhysicalObjectInterface = produce(selectedPhysicalObject as PhysicalObjectInterface, draft => {
            if (draft.canvasObject) {
                draft.canvasObject.backgroundColor = color
                draft.canvasObject.transparent = false
            }
        })
        setSelectedPhysicalObject(newObject)
    }
    const handleTransparentChange = (isTransparent:boolean) => {
        const newObject:PhysicalObjectInterface = produce(selectedPhysicalObject as PhysicalObjectInterface, draft => {
            if (draft.canvasObject) draft.canvasObject.transparent = isTransparent;
        })
        setSelectedPhysicalObject(newObject)

    }
    const handleFontSizeChange = (fontSize:any) => {
        const newObject:PhysicalObjectInterface = produce(selectedPhysicalObject as PhysicalObjectInterface, draft => {
            if (draft.canvasObject) draft.canvasObject.fontSize = fontSize
        })
        setSelectedPhysicalObject(newObject)
    }       
    const toggleStyle = (style='bold') => {
        const newObject:PhysicalObjectInterface = produce(selectedPhysicalObject as PhysicalObjectInterface, draft => {
            if (draft.canvasObject) {                                
                if (!draft.canvasObject.style) draft.canvasObject.style=[]
                if (draft.canvasObject.style.includes(style)) {
                    draft.canvasObject.style = draft.canvasObject.style.filter((s) => s != style)
                }
                else if (draft.canvasObject.style) draft.canvasObject.style.push(style);
            }
        })
        setSelectedPhysicalObject(newObject)
    }
    const toggleAlignment = (alignment: Align) => {
        const newObject:PhysicalObjectInterface = produce(selectedPhysicalObject as PhysicalObjectInterface, draft => {
            if (draft.canvasObject) {    
                draft.canvasObject.alignment = alignment;
            }
        })
        setSelectedPhysicalObject(newObject)
    }
    const handleTypefaceChange = (typeface:string) => {
        const newObject:PhysicalObjectInterface = produce(selectedPhysicalObject as PhysicalObjectInterface, draft => {
            if (draft.canvasObject) draft.canvasObject.fontFamily = typeface
        })
        setSelectedPhysicalObject(newObject)
    }
    const handleLineEndTypeChange = (lineEndType:string) => {
        const newObject:PhysicalObjectInterface = produce(selectedPhysicalObject as PhysicalObjectInterface, draft => {
            if (draft.canvasObject) {    
                draft.canvasObject.lineEndType = lineEndType;
            }
        })
        setSelectedPhysicalObject(newObject)
    }

    if (selectedPhysicalObject?.canvasObject?.type !== 'text') {
        return (
            <Grid container item xs={12} sx={{ maxHeight:'79vh', overflowY:'auto',overflowX:'hidden'}} >
                <Stack spacing={1}  justifyContent='center'  alignItems='center' style={{paddingTop:'20px' }}>
                    <GeneralColorSelector onColorChange={handleColorChange} startingColor={canvasObject.color || '#000'} name='Color' />
                    <Stack sx={{width: '88%', display: 'flex'}}>
                        <LineEndTypeSelector startingLineEndType={canvasObject.lineEndType} onLineEndTypeChange={handleLineEndTypeChange} />
                    </Stack>
                    <ScaleRotationSelector />
                    <ArrowKeys />
                </Stack>
            </Grid>
        )
    }

    return (
        <Grid container item xs={12} sx={{ maxHeight:'79vh', overflowY:'auto',overflowX:'hidden'}} >
            <Stack spacing={1}  justifyContent='center'  alignItems='stretch' >
                <Grid sx={{paddingLeft: '4px'}}>
                    <Stack direction="row" spacing={1} style={{paddingTop:'4px', justifyContent: 'left', maxWidth: '220px'}}>
                        <MyButton variant="outlined" onClick={ () => { toggleAlignment('left') } }>
                            <FormatAlignLeftIcon fontSize="small" />
                        </MyButton>
                        <MyButton variant="outlined"  onClick={ () => { toggleAlignment('center') } }>
                            <FormatAlignCenterIcon fontSize="small" />
                        </MyButton>
                        <MyButton variant="outlined"  onClick={ () => { toggleAlignment('right') } }>
                            <FormatAlignRightIcon fontSize="small" />
                        </MyButton>
                    </Stack>

                    <Stack direction="row" spacing={1} style={{paddingTop:'10px', justifyContent: 'left', maxWidth: '220px'}}>
                        <MyButton variant="outlined" onClick={ () => { toggleStyle('bold') } }>Bold</MyButton>
                        <MyButton variant="outlined"  onClick={ () => { toggleStyle('italic') } }>Italic</MyButton>
                        <FontSizeSelector startingSize={canvasObject.fontSize} onChange={handleFontSizeChange} />            
                    </Stack>
                    <Stack>
                        <TextareaAutosize style={{background:'#ebebeb', width:'84%', height:'50px', maxWidth: '210px', marginTop: '8px', marginBottom: '8px', borderRadius: '4px', padding: '4px'}} onChange={updateText} value={canvasObject.text} aria-label="empty textarea" placeholder="Empty" />
                    </Stack>
                    <Stack>
                        <TypefaceSelector startingTypeface={canvasObject.fontFamily} onTypefaceChange={handleTypefaceChange} />
                    </Stack>

                    <Typography sx={{marginTop:'12px'}}>Color</Typography>
                    {/* <TextField onChange={updateText} value={canvasObject.text}  /> */}
                    <GeneralColorSelector onColorChange={handleColorChange} startingColor={canvasObject.color || '#000'} name='Color' />
                    {/* <GeneralColorSelector onColorChange={handleBackgroundColorChange} startingColor={canvasObject.backgroundColor || '#fff'} name='Background' />
                    <CheckboxSelector startingState={canvasObject.transparent? true : false} onChange={handleTransparentChange} /> */}
                </Grid>

                <ScaleRotationSelector />
                <ArrowKeys />
            </Stack>
        </Grid>
    )
}

const TypefaceSelector = ({startingTypeface, onTypefaceChange}) => {
    const [selectedTypeFace, setSelectedTypeFace] = useState( startingTypeface || Object.keys(commonTypefaces).slice(-1)[0])

    const handleChange= (e)  => {
        setSelectedTypeFace(e.target.value)
        onTypefaceChange?.(e.target.value)
    }
    return (
        <Select style={{width:'88%', height: '32px', maxWidth: '220px', fontSize: '0.75rem'}} value={selectedTypeFace} onChange={(e) => { handleChange(e) }}  >            
            {Object.keys(commonTypefaces).map((typeFace) => <MenuItem  key={typeFace} value={typeFace}>{typeFace}</MenuItem>)}
        </Select>

    )
}



const fontSizes=[40,60,80,100,120,140,160,180,200]
const FontSizeSelector = ({startingSize, onChange}) => {
    const [fontSize,setFontSize] = useState(startingSize)
    useEffect(() => { setFontSize(startingSize) },[startingSize])
    const handleChange = (e:any) => {  setFontSize(e.target.value); onChange?.(e.target.value) }

    return (
            <MySelect value={fontSize} onChange={handleChange}  >            
                {fontSizes.map((size) => <MenuItem key={size} value={size}>{size / 10 }</MenuItem>)}
            </MySelect>

    )
}


const padding= '10px';
const text2={paddingTop:'0px',textAlign:'center',textTransform:'capitalize',overflow:'hidden', textOverflow:'ellipsis'} as any

const GeneralColorSelector = ({name,startingColor='#000', onColorChange}) => {
    const [isOpen, setOpen] = useState(false);
    const [color,setColor]=useState('#000')
    const popover:any = useRef();
    useClickOutside(popover, () => { setOpen(false) })
    useEffect(() => { setColor(startingColor) },[startingColor])

    const handleColorChange = (color:string) => {        
        setColor(color)    
        onColorChange?.(color)
    }
    const handleMouseUp = () => { }

    return (
        <Grid container item xs={12} style={{maxWidth:'90%',paddingTop:'6px'}} sx={{paddingLeft: {xs:'0px',sm: padding, md:padding,xl:padding}}} >
        <Grid item xs={3} ref={popover}>
            <div className="colorPicker">
                <div className="colorSwatch" style={{ backgroundColor: color }} onClick={() => setOpen(true)} />
                {isOpen && 
                    <div className="colorPopover" >
                        <HexColorPicker color={color} onChange={handleColorChange} onMouseUp={handleMouseUp}/> 
                        <div style={{marginTop:'10px', display: 'flex'}}>
                            <Typography style={{paddingRight:'20px'}} variant="caption"> HEX</Typography> 
                            <HexColorInput style={{textAlign: 'center', width: '100%'}} color={color} onChange={handleColorChange} />
                        </div>
                    </div> 
                }
            </div>
        </Grid>            
        <Grid item xs={9} style={{paddingTop:'5px',paddingLeft:'4px'}}>
        <Typography style={text2} variant="caption" >{name}</Typography>        
        </Grid>
        </Grid>
    )
}

const CheckboxSelector = ({startingState, onChange}) => {
    const [isTransparent, setIsTransparent] = useState(startingState);

    useEffect(() => { 
        if(startingState!=isTransparent) setIsTransparent(startingState) 
    },[startingState]);

    const handleChange = (event:any) => {
        setIsTransparent(event.target.checked);
        onChange?.(event.target.checked)
    };

    return (
        <Grid container item xs={12} style={{maxWidth:'90%', paddingTop:'6px', display: 'flex', flexDirection: 'row', alignItems: 'center'}} sx={{paddingLeft: {xs:'0px',sm: padding, md:padding,xl:padding}}} >
        <Grid item xs={3} >
            <Checkbox
                sx={{marginLeft:'-5px'}}
                size='medium'
                checked={isTransparent}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </Grid>            
        <Grid item xs={9} style={{paddingTop:'5px',paddingLeft:'4px'}}>
            <Typography style={text2} variant="caption" >Transparent</Typography>        
        </Grid>
        </Grid>
    )

};

const LineEndTypeSelector = ({startingLineEndType, onLineEndTypeChange}) => {

    const [selectedLineEndType, setSelectedLineEndType] = useState( startingLineEndType || Object.keys(lineEndTypes)[0])


    useEffect(() => { 
        if(startingLineEndType!=selectedLineEndType) setSelectedLineEndType(startingLineEndType) 
    },[startingLineEndType])

    const handleChange= (e)  => {
        setSelectedLineEndType(e.target.value)
        onLineEndTypeChange?.(e.target.value)
    }
    return (
        <Select style={{width:'88%', height: '32px', maxWidth: '220px', fontSize: '0.75rem', marginTop: '8px'}} value={selectedLineEndType} onChange={(e) => { handleChange(e) }}  >            
            {Object.keys(lineEndTypes).map((lineEndType) => <MenuItem key={lineEndType} value={lineEndType}>{lineEndType}</MenuItem>)}
        </Select>

    )
}