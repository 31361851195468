import React, { useState} from 'react'
import { Button, DialogContent, Dialog, DialogTitle, Card, CardContent, CardActions,Typography, IconButton, Checkbox, Tooltip, Snackbar } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close"
import { useProjectData, usePhysicalObjects, useMattoState } from '../MattoState'
import { styled } from '@mui/system';
import {BarIconButton} from './ButtonStyles';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';

const DialogCard = styled(Card)({backgroundColor:'#cccccc'}) 
export function ShareButton(props) {
	const setSelectedPhysicalObjectKey = usePhysicalObjects( (state) => state.setSelectedPhysicalObjectKey)
	const projectData = useProjectData()
	const saveProjectNow = useMattoState((state) => state.saveProjectNow);
	const [previewOpen, setPreviewOpen]=useState(false)
	const handleClick = (event) => {
		if(!projectData.projectId)
		alert('Please save your project before sharing')
		else {
			setSelectedPhysicalObjectKey(null)
			setPreviewOpen(true)
		}
	}
	return (
        <React.Fragment>
			<Tooltip title="Share" arrow>
				<BarIconButton
					aria-label='Share'
					onClick={handleClick}
					edge='start'
					size="large"
					disabled={saveProjectNow ? true : false}>
					<ShareRoundedIcon style={saveProjectNow===true ? {color:'grey'}: {color:'black'}} />
				</BarIconButton>	
			</Tooltip>
			{previewOpen && <PreviewDialog open={previewOpen} onClose={ () => { setPreviewOpen(false) } }  /> }
		</React.Fragment>
    );
}
const PreviewDialog = (props) => {
	const { open,onClose } = props;
	const [openSnack, setOpenSnack]=useState(false)
	const [canEdit, setCanEdit]=useState(false)

	const handleClose = () => {  onClose() };
	const projectData = useProjectData()
	let shareUrl = window.location.href.replace('project','show');

	const handleOpen = () => {}
	const copyLink = () => {
		setOpenSnack(true) 
        if (window.gtag)  window.gtag('event','shared_project')
		const url = new URL(shareUrl)
		url.searchParams.append('ee', canEdit)
		shareUrl = url.toString()		
		navigator.clipboard.writeText(url.toString())
	}
	const openInNewTab = () => {
		const url = new URL(shareUrl)
		url.searchParams.append('ee', canEdit)
		window.open(url.toString(), '_blank').focus();
	}

	return (
        <React.Fragment>
		<Dialog
            onClose={handleClose}
            disableEscapeKeyDown={true}
            aria-labelledby="simple-dialog-title"
            open={open}
            TransitionProps={{
                onEnter: handleOpen
            }}
			style={{textAlign:'center',padding:'20px'}}>
		<DialogTitle id="simple-dialog-title" style={{fontWeight:'bold',paddingTop:'22px',paddingBottom:'24px'}}>{projectData.title}
		<IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 0.5,
            top: 0.5,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
		</DialogTitle>
		<DialogContent>
			<DialogCard>
				<CardContent>
					<Typography variant="h7" component="div" style={{fontWeight:'bold'}}>
						Share 3D scene     
					</Typography>

    			</CardContent>
    			<CardActions style={{display:'flex',justifyContent:'space-around',paddingTop:'0px',paddingBottom:'10px'}}>
					<Button  onClick={copyLink} style={{backgroundColor:'white',padding:'3px 35px 3px 35px'}}>Copy Link</Button>
					<Button onClick={openInNewTab} style={{backgroundColor:'#F4D5B8',padding:'3px 11px 3px 11px'}}>Open in New Tab</Button>
				</CardActions>

				<CardActions style={{display:'flex'}}>
					<Checkbox checked={canEdit} onClick={  () => { setCanEdit(!canEdit)}}	/> Prevent Users From Editing Scene
				</CardActions>

			</DialogCard>
		</DialogContent>
		</Dialog>
		<Snackbar
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left',}}
			open={openSnack}
			onClose={ () => { setOpenSnack(false)}}
			autoHideDuration={4000}
			message={'Project Link Copied'}
		/>	   
		</React.Fragment>
    );
}
