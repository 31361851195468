// @ts-nocheck
import { Modal, Box } from "@mui/material";
import { ModalContainer } from "./styles";
import { Container } from "@mui/system";

const StyledModal = ({ children, ...rest }) => {
  return (
    <Modal {...rest}>
      <ModalContainer>
        {/* <img src={'/images/left-bg.png'} alt="" className="bg-img" /> */}

        <Box className="inner-root">
          {children}
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export default StyledModal;
