import React, { useMemo,useRef, useState,useEffect } from 'react'
import { Grid} from '@mui/material'
import { TypesenseSearch } from '../instasearch/TypesenseSearch';
import { MaterialHits } from '../instasearch/MaterialHits';
import { useMattoState, usePhysicalObjects } from '../MattoState';
import { ProductType } from '../../../../packages/ts-interfaces';
import { SideBarMode } from '../ts/app_interfaces';


export const MaterialSearch = (props) => {  
    const selectedPhysicalObjectKey:any = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const getCurrentSelectedPhysicalObject = usePhysicalObjects( (state) => state.getCurrentSelectedPhysicalObject)
    const sideBarMode = useMattoState((state) => state.sideBarMode)
    const [showPaintSearch, setShowPaintSearch] = useState(false)

    const productType:ProductType | null = useMemo(() => {
        if (props?.hide==true) return null;
        else if (getCurrentSelectedPhysicalObject()?.type=='paint') return ProductType.PAINT
        else if (sideBarMode==SideBarMode.Paints && selectedPhysicalObjectKey==null ) {
            return ProductType.PAINT
        }
        else return ProductType.MATERIAL
    },[selectedPhysicalObjectKey,sideBarMode,props.hide])

    if (productType==ProductType.PAINT && showPaintSearch==false) setShowPaintSearch(true)
    
    return (
        <>       
       {showPaintSearch==true && 
        <Grid  container spacing={0} style={{paddingTop:'5px', display:productType==null || productType!=ProductType.PAINT ? 'none' : 'flex' }}> 
            <TypesenseSearch 
                indexName='productsV2' 
                productType={ProductType.PAINT} 
                hitsHandler={MaterialHits} />
        </Grid>
        }
        <Grid  container spacing={0} style={{paddingTop:'5px', display: productType==null || productType== ProductType.PAINT ? 'none' : 'flex' }}> 
                <TypesenseSearch 
                    indexName='productsV2' 
                    productType={ProductType.MATERIAL} 
                    hitsHandler={MaterialHits} />
         </Grid>
         </>
    )
}

