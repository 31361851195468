import React, { useMemo,useRef, useState,useEffect, useContext } from 'react'
import { useMattoState, usePhysicalObjects, useProjectData } from '../MattoState'
import { Button, Grid, Hidden, IconButton, Slider, Tooltip, Typography,Snackbar } from '@mui/material'
import { MaterialData, ProductInterface } from '../../../../packages/ts-interfaces';
import { PhysicalObjectInterface } from '../ts/app_interfaces';
import { deepClone,dimensionsToString,materialDefaults,getDimensions, calculateRepeatModifier,getMaterialThumbnail, getMaterialThumbnailFromMaterialData, } from '../libs/util';
import { Stack } from '@mui/system';
import FormatPaintRoundedIcon from '@mui/icons-material/FormatPaintRounded';
import { Fluorescent as Transmission, Flare as FlareIcon, TrendingFlat as DisplaceIcon ,Repeat as RepeatIcon, Texture as TextureIcon,Brightness7 as BrightnessIcon,Backspace,ColorLens,Save} from '@mui/icons-material';
import { produce } from 'immer'
import { getUrlFromRef, updateRecord, userProductsDB } from '../libs/firebase';
import { Color } from 'three';
import { PropertySlider } from './PropertySlider';
import { AuthContext } from '../auth/Auth';
import { and, getDocs, query, updateDoc, where } from 'firebase/firestore';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { ProductType } from 'ts-interfaces';
import { ProductDownloadButton } from '../buttons/ProductDownloadButton';
/*
    Modify properties of the selected object. 
*/
const text={paddingTop:'10px', paddingBottom:'15px',textAlign:'center',textTransform:'capitalize',overflow:'hidden', textOverflow:'ellipsis'} as any

const propertySliders = [
    {name:'Texture (Normal Map)',materialKey:'normalScale',max:6,min:-6},
    {name:'Repeat',materialKey:'textureRepeat',max:7,min:0},
    {name:'Glossiness',materialKey:'roughness',max:1,min:0},
    {name:'Metalness',materialKey:'metalness',max:1,min:0},
    {name:'Clearcoat',materialKey:'clearcoat',max:1,min:0},
    {name:'Clearcoat Roughness',materialKey:'clearcoatRoughness',max:1,min:0},
    {name:'Transparency',materialKey:'transmission',max:1,min:0},
    {name:'Exposure',materialKey:'envMapIntensity',max:4,min:0},
]

export const PropertyModifier2= () => {
    const [showMore,setShowMore] = useState(false)
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)
    const updateCount = usePhysicalObjects(state=>state.updateCount)

    const sliders = useMemo( () => {
        if (selectedPhysicalObjectKey==null) return propertySliders;
        const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)        
        const hasMap = selectedPhysicalObject?.mode=='material' || selectedPhysicalObject?.materialData?.files?.color_original ? true : false
        return hasMap ? propertySliders :  propertySliders.slice(2)
    },[selectedPhysicalObjectKey,updateCount])

    return (
        <Stack id='propertyModifier2' sx={{width:'90%'}}>
            <MaterialInfo   />
            <Stack>
            {sliders.map( (sliderProps,index) => {  
                return showMore==false && index>=2 ? null : (
                    <PropertySlider key={index} {...sliderProps} />
                )
            })}
            <Button id='showMore' onClick={ () => { setShowMore(!showMore)}} > {showMore ? 'Less..' : 'More..' } </Button>
            </Stack>
        </Stack>
    )
}

const imgCss = { borderRadius:'13px', width:'7vw',height:'7vw', cursor:'pointer' }
const MaterialInfo = () => {
    const currentUser = useContext(AuthContext);
    const selectedPhysicalObject:PhysicalObjectInterface = usePhysicalObjects.getState().getCurrentSelectedPhysicalObject()
    const idx = selectedPhysicalObject?.type=='paint' ? usePhysicalObjects.getState().selectedMaterialndex : 0 
    const materialData = selectedPhysicalObject?.type=='paint' ? selectedPhysicalObject?.paintMaterialsData?.[idx] : selectedPhysicalObject?.materialData
    const projectData:any = useProjectData()
    const thumbnail = selectedPhysicalObject?.name ? getSelectedMaterialThumbnail(selectedPhysicalObject): projectData.backgroundTexture ? getMaterialThumbnail(projectData.backgroundTexture):false

    const getMaterialName = () => {
        
        if (!selectedPhysicalObject) return projectData.backgroundTexture?.name ?? 'Background'
        else if (selectedPhysicalObject?.type=='dynamic') return selectedPhysicalObject?.dynamicMaterialProps?.metadata?.name || 'Dynamic Material'
        else if (selectedPhysicalObject?.name) return materialData?.name ?? 'No Material Selected'
        else return 'No Material Selected'
    }

    return (
        <Stack   alignItems="center" >
            {/* {thumbnail &&  */}
            {<MaterialImage imgSrc={thumbnail} currentUser={currentUser?.currentUser} /> }
            <Typography style={text} variant="caption" >{getMaterialName()}</Typography>
        </Stack>
    )
}
const MaterialImage = ({imgSrc, currentUser}) => {
    const setProjectData = useProjectData(state=>state.set)	
    const isSelectedObjectStatic = usePhysicalObjects(state=>state.isSelectedObjectStatic)    
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)
	const setSelectedPhysicalObjectKey = usePhysicalObjects( (state) => state.setSelectedPhysicalObjectKey)
    const setCopiedMaterialData = useMattoState((state)=>state.setCopiedMaterialData)
    const backgroundTexture:ProductInterface | any = useProjectData(state=>state.backgroundTexture)
	const lockedObjects = usePhysicalObjects( (state) => state.lockedObjects)
	const isObjectLocked = usePhysicalObjects( (state) => state.isObjectLocked)

    const [saving,setSaving] = useState(false)
    const physicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)    
    const showSave =  physicalObject?.materialData?.uid && currentUser?.uid ? physicalObject.materialData.uid === currentUser?.uid : false

    useEffect(()=> {
    },[lockedObjects])

    const clearMaterial = () => {
        if (selectedPhysicalObjectKey==null) {
            setProjectData(state=> { state.backgroundTexture = null })
        }
        else {
            const materialProps = deepClone(materialDefaults)      
            if (isSelectedObjectStatic()===false) {
                materialProps.color = new Color(0xC5C5C5)
                materialProps.color =  materialProps.color.toJSON();
            }
            const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)        
            const newObj = produce(selectedPhysicalObject, (draft:any) => { 
                draft.materialData = {materialProps:materialProps}
            })	
            			
            updatePhysicalObject(newObj)
            setSelectedPhysicalObjectKey(null)
        }
    }
    const copyFormat = () => {
        const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)   
        document.getElementById("root")?.classList.add("dropper-cursor")
        const toCopyMaterialData = selectedPhysicalObject?.name ? selectedPhysicalObject : backgroundTexture ;
        setCopiedMaterialData(toCopyMaterialData)
    }; 

    const handleSave = () => {
        const materialData = physicalObject.materialData        
        if (currentUser?.uid != materialData?.uid) return;             
        if (materialData?.id && materialData?.uid && materialData.materialProps) {  
            const q = query(userProductsDB as any, and(where("uid", "==", currentUser.uid), where("id", "==", materialData.id)))
            getDocs(q).then ( querySnapshot => {
                setSaving(true)
                if (querySnapshot.size == 0) return;
                const doc = querySnapshot.docs[0]                
                const newRecord:ProductInterface | any= produce(doc.data(), (draft:any) => {
                    draft.materialData.materialProps = materialData.materialProps
                }) as ProductInterface
                updateDoc(doc.ref, newRecord)
                .then( () => {
                    document.dispatchEvent(new CustomEvent("userMaterialUpdate", { detail: { material: newRecord } }));
                    console.log("all updated"); 
                })
            })
        }
    }

    const isObjectDownloadable = () => {
        const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)   
        if (selectedPhysicalObjectKey==null) return backgroundTexture?.productType == ProductType.MATERIAL;
        else if (selectedPhysicalObject?.type == 'not_static' && selectedPhysicalObject?.materialData?.files) return true;
        else return false;
    }

    return (
        <>
        <Stack direction="column"   spacing={1} alignItems="center" >
            <Stack direction="row"  spacing={1} >
            {showSave==true && <TooltipIcon id={"saveMaterialButton"} title='Save Your Material' handleClick={handleSave} ><Save /></TooltipIcon> }
            {/* {selectedPhysicalObjectKey && <TooltipIcon id={"objectLockUnlockButton"} title={isObjectLocked(selectedPhysicalObjectKey) ? 'Unlock Object' :'Lock Object'} handleClick={() => {isObjectLocked(selectedPhysicalObjectKey) ? usePhysicalObjects.getState().unLockSelectedObject() : usePhysicalObjects.getState().lockSelectedObject()}} >{isObjectLocked(selectedPhysicalObjectKey) ? <LockOutlinedIcon/> : <LockOpenOutlinedIcon/>}</TooltipIcon>} */}
            { isObjectDownloadable() && <ProductDownloadButton />}

            {imgSrc && <>
            <TooltipIcon style={{paddingTop: '0px'}}  id={'ColorPicker'} title={'CopyFormat'} handleClick={copyFormat} ><FormatPaintRoundedIcon /></TooltipIcon>
            <TooltipIcon id={"clearMaterialButton"} title='Clear Material' handleClick={clearMaterial} ><Backspace /></TooltipIcon>
            </>}
            </Stack>
            {imgSrc && <img style={imgCss} src={imgSrc} />}
        </Stack>
        <Snackbar autoHideDuration={1000}  onClose={ ()=> { setSaving(false)}} open={saving} message={'Updating Material'} />
        </>
    )
}
const TooltipIcon = (props) => {
    return (        
        <Tooltip style={props.style} title={props.title} arrow>
		<IconButton onClick={props.handleClick} edge='start' size='small'>
		{props.children}
		</IconButton>
		</Tooltip>
    )
}
const getSelectedMaterialThumbnail = (physicalObject) =>{
    if(physicalObject.materialData?.files || physicalObject.materialData?.renderedImage )  return getMaterialThumbnailFromMaterialData(physicalObject.materialData) 
    else if (physicalObject?.dynamicMaterialProps?.files) {  return getUrlFromRef(physicalObject.dynamicMaterialProps.files)  }
    else return false
}