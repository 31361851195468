import React, { useState } from 'react';
import { TextField, Box, IconButton, InputAdornment,    CircularProgress, Typography } from '@mui/material';
import { fetchData } from '../libs/util.ts';
import { checkPromoCodeAPIUrl } from './config.js';
import CheckIcon from '@mui/icons-material/Check';

export const PromoCodeComponent = ({ promoCode, setPromoCode, onApplyPromoCode, onResetPromoCode, selectedPlan }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [promoCodeValid, setPromoCodeValid] = useState(false);

  const handleApplyPromoCode = async () => {
    setLoading(true);
    setError(null);
    setPromoCodeValid(false);

    try {
      const requestPromoCheck = { promoCode: promoCode, selectedPlan: selectedPlan };
      const promoCodeData = await fetchData(requestPromoCheck, checkPromoCodeAPIUrl);  
      console.log(promoCodeData);
      if(!promoCodeData?.valid) setError(promoCodeData?.message);
      else {
        setPromoCodeValid(true);
        onApplyPromoCode(promoCodeData.promo_id, promoCodeData.discount_percentage);
      }

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
    setError(null);
    setPromoCodeValid(false);
    onResetPromoCode();
  }

  return (
    <Box component="form" noValidate autoComplete="off" sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
        <TextField
            label="Promo Code"
            variant="outlined"
            fullWidth
            value={promoCode}
            onChange={(e) => handlePromoCodeChange(e)}
            disabled={loading}
            error={!!error}
            helperText={error}
            InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                <IconButton
                    color="primary"
                    onClick={handleApplyPromoCode}
                    disabled={loading || !promoCode || promoCodeValid}
                    edge="end"
                    sx={{borderRadius: '15%'}}
                >
                    {loading ? <CircularProgress size={24} /> : 
                      promoCodeValid ? <CheckIcon sx={{verticalAlign: 'middle', color: 'green', marginRight: '4px'}} /> : <Typography>Apply</Typography>}
                </IconButton>
                </InputAdornment>
            ),
            }}
            sx={{ mb: 2 }}
        />
    </Box>
  );
};