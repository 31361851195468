

export const DrawLine = ({parentCanvas, hasDot=false, callback,positionIn3D, camera, gl, raycaster,copyPhysicalObject}) => {

    let top = parentCanvas?.offsetParent.offsetTop;
    let left = parentCanvas?.offsetParent.offsetLeft;
    let width = parentCanvas?.offsetParent.offsetWidth;
    let height = parentCanvas?.offsetParent.offsetHeight;

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.style.position = 'absolute';
    canvas.style.cursor = 'crosshair';

    // absolute position canvas with top 0 and left 100px
    canvas.style.top = top + 'px';
    canvas.style.left = left + 'px';

    // add background color to canvas with opacity 0.2
    canvas.style.backgroundColor = 'rgba(0, 0, 0, 0.2)';

    document.body.appendChild(canvas);
    let isDrawing = false;
    let startX = 0;
    let startY = 0;
    let endX = 0;
    let endY = 0;
    let startPosition = {x:0,y:0,z:0};
    let endPosition = {x:0,y:0,z:0};

    const startDrawing = (event) => {        
        event.stopPropagation()
        if (canvas !==   event.target) { 
            finishDrawing();
            return;
        }

		startPosition = positionIn3D(gl, raycaster, camera, event);
        startX = event.clientX - left;
        startY = event.clientY - top;
        isDrawing = true;
    };
    
    const draw = (event) => {
        event.stopPropagation()
        if (canvas !==   event.target) return;

        const ctx = canvas.getContext('2d');
        if (!isDrawing) return;
        const x = event.clientX - left;
        const y = event.clientY - top;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();

        if(hasDot) {
            ctx.fillStyle = "black";
            ctx.arc(startX, startY, 2, 0, Math.PI * 2, true);
            ctx.fill();
        }
        ctx.strokeStyle = 'black';
        ctx.moveTo(startX, startY);
        ctx.lineTo(x, y);

        ctx.stroke();
        endX = x;
        endY = y;
        endPosition = positionIn3D(gl, raycaster, camera, event);
    };

    const finishDrawing = () => {
        try {
            canvas.style.cursor = 'default';        
            callback(endPosition,startPosition,copyPhysicalObject)
        }
        finally {  isDrawing = false;  cleanupDrawing() }
    };

    const abortDrawing = () => {
        callback(null,null)
        cleanupDrawing()
    }

    const cleanupDrawing = () => {
        if (canvas?.style?.cursor) canvas.style.cursor = 'default';
        canvas.remove();
        document.body.removeEventListener('mousedown', startDrawing);
        document.body.removeEventListener('mousemove', draw);
        document.body.removeEventListener('mouseup', finishDrawing);
        document.body.removeEventListener('keydown', abortDrawing);
        window.removeEventListener('resize', abortDrawing);         
    }
    document.body.addEventListener('mousedown', startDrawing);
    document.body.addEventListener('mousemove', draw);
    document.body.addEventListener('mouseup', finishDrawing);
    document.body.addEventListener('keydown', abortDrawing);
    window.addEventListener('resize', abortDrawing);
   
};
