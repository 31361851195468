
const cloudFlareAPI = 'https://api.mattoboard.uk?'
import { getUrlFromRef } from "../libs/firebase";

export const  unflattenObject = (data) => {
    const result = {}
    for (var i in data) {
      var keys = i.split('.')
      keys.reduce(function(r, e, j) {
        return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 == j ? data[i] : {}) : [])
      }, result)
    }
    return result
  }

 
export const cloudflareCDN = (url:any,format:any) => { 
    if (url?.startsWith('http://localhost')) return url 
    if (url?.startsWith('meshes') || url?.startsWith('gltfs')) return getUrlFromRef(url)
    const s = format.split(',')    
    let i = 'image=' + encodeURI(url);
    s.forEach((x: string)=>{ i += '&' + x?.trim() })
    const u = cloudFlareAPI + i    
    return u
}
export const generateID = () =>  { return Array.from(Array(10), () => Math.floor(Math.random() * 36).toString(36)).join(''); }



export async function getUserLocationFromIP2(){
      const requestOptions = { method: 'GET',};
      if (AbortSignal?.timeout) {  requestOptions['signal'] =  AbortSignal.timeout(500)}
      const response = await fetch("http://www.geoplugin.net/json.gp", requestOptions)
      const result = await response.json()
      return result?.geoplugin_countryName
}

export async function getUserLocationFromIP(timeout=500){
    return new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'GET',
        };
        if (AbortSignal?.timeout) {  requestOptions['signal'] =  AbortSignal.timeout(timeout)}
        let country = null;
        fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=94fc430c15c343cc93b732537277a0cc", requestOptions)
            .then(response => response.json())
            .then(result => {  
              if (window.sessionStorage && result?.country?.currency) {
                window.sessionStorage.setItem('currency', result?.country?.currency)
              }
              country = result?.country?.names?.en; resolve(country)
            
            })
            .catch(error =>  { console.log('error', error); reject(error);   }  );
    })
}

export async function getUserLocationFromIPdata(timeout=500) {
  return new Promise((resolve, reject) => {
    if (window.sessionStorage && window.sessionStorage['currency']) { resolve(window.sessionStorage['currency']) }
    const requestOptions = { method: 'GET',};
    if (AbortSignal?.timeout) { requestOptions['signal'] = AbortSignal.timeout(timeout) }
    fetch('https://api.ipdata.co?api-key=d5a756a764e248854368e06ff31e506948471e7c40bffa73c0dde8c8', requestOptions)
      .then(response => response.json())
      .then(result => {        
        resolve(result)
      })
      .catch(error => { console.log('user location err', error); reject(error); });
  })
}




export const getResizedImage = (product, size = 400) => {

  const format = "format=auto,width=" + size + ",height=" + size;
  if (product?.mesh?.rendered_image) {
    return cloudflareCDN(product?.mesh?.rendered_image, format);
  } else if (product?.materialData?.files?.color_original) {
    const u = getUrlFromRef(product?.materialData?.files?.color_original);
    if (u.endsWith('.png') || u.endsWith('.jpg') || u.endsWith('.jpeg') || u.endsWith('.webp')) {
      return cloudflareCDN(u, format);
    }
    else if (u?.endsWith('color_original')) {
      return cloudflareCDN(getUrlFromRef(product?.materialData?.files?.small_color), format);
    }
    else return u;      
  } else {
    let renderedImage =
      product?.materialData?.renderedImage ?? product?.materialData?.files?.color_original;

    if (renderedImage && renderedImage.endsWith('color_original')) {
      renderedImage = product?.materialData?.files?.small_color;
    }

    if (renderedImage) {
      const u = getUrlFromRef(renderedImage, true);
      if (u.endsWith('.png') || u.endsWith('.jpg') || u.endsWith('.jpeg') || u.endsWith('.webp')) {
        return cloudflareCDN(u, format);
      }
      else return u;    
    }
    else return null;
  }
};
  export const getErrorMessage = (message) => {
    let errMessage = message;
    if(message.includes('popup-closed-by-user'))
    errMessage = 'Authentication popup closed too early';
    else if(message.includes('email-already-in-use'))
    errMessage = 'Email address is already registered with us.Please Sign in to continue.';
    else if(message.includes('auth/missing-email'))
    errMessage = 'Please enter email address';
    else if (message.includes("user-not-found"))
    errMessage = "Email address is not in our records";
    else if (message.includes("wrong-password"))
    errMessage = "Entered password does not match";
    else if (message.includes("weak-password"))
    errMessage = "Password should be at least 6 characters";
    return errMessage;
    
  }