import React, { useState, useEffect, useMemo } from "react";
import { TextField, Box, Typography, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useLoginState } from "./LoginState";
import { resetPassword, sendResetPassword } from "../../libs/firebase";
import { PrimaryButton } from "../Button";
import { SigninContainer } from "./styles";
import { Visibility as VisibilityIcon,VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";

export const Reset = () => {
  const [showFlash, setShowFlash] = useState(false);
  const [flashMessage, setFlashMessage] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [oobCode, setOobCode]=useState("")

  const setShowLogin = useLoginState((state) => state.setShowLogin);

  useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.oobCode) {
            setOobCode(params.oobCode)
        }
  },[])

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleTogglePasswordVisibility2 = () => { setShowPassword2((prevShowPassword) => !prevShowPassword); };
  const onChangePassword = (e) => { setPassword(e.target.value); };
  const onChangePassword2 = (e) => { setPassword2(e.target.value); };

  const validateSubmit = () => {
    if(!password || !password2){
      setFlashMessage("Please enter all the required details.");
      setShowFlash(true);
      return false;
    }
    else if (password != password2) {
      setFlashMessage("Passwords do not match!");
      setShowFlash(true);
      return false;
    }
  }

  const handleSubmit = (evt: any) => {
    evt.preventDefault();
    if(validateSubmit() == false) return;
    else {
        resetPassword(oobCode, password).then( (result) => {
            setFlashMessage('Password reset - Please sign in again.')
            setTimeout(() => {
              const rootLink = window.location.protocol + '//' + window.location.host;
              window.location.href = rootLink;
            }, 3000);
            setShowFlash(true)
        }).catch( (error) => {
            setFlashMessage("Unable to reset password!")
            console.log(error);
            setShowFlash(true)
        })
    }
  };

  return (
    <SigninContainer>
      <Box className="form-root">
        <img src={'/images/mattoboard-logo.jpg'} alt="MattoBoard" className="logo-img" />
        <Typography component="h1" variant="h1" className="login-title">
          Change Password
        </Typography>
        {showFlash && 
        <Typography component="h6" color='error' align='center' variant="h5">
          {flashMessage}
        </Typography>
        }
        <div className="login-form">

        <TextField
          size='small' variant="outlined"
          margin="normal" required fullWidth
          name="password" label="Password" id="password"
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleTogglePasswordVisibility}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            ),
          }}
          value={password}
          onChange={onChangePassword}
        />

        <TextField
          size='small'
          variant="outlined" margin="normal"
          required fullWidth
          name="password2" label="Confirm Password"
          id="password2"
          type={showPassword2 ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleTogglePasswordVisibility2}>
                {showPassword2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            ),
          }}
          value={password2}
          onChange={onChangePassword2}
        />

          <PrimaryButton
            fullWidth
            style={{ marginTop: "38px" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Reset Password
          </PrimaryButton>
        </div>
      </Box>
      <IconButton onClick={() => setShowLogin(null)} className="close-btn">
        <CloseIcon />
      </IconButton>
    </SigninContainer>
  );
};
