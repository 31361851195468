import React, { useEffect, useRef } from 'react'
import {useMattoState, usePhysicalObjects} from '../MattoState'
import {ImageItem} from '../libs/ImageItem'
import {Grid} from '@mui/material'
import {dynamicObjects} from '../threejs/objects/DynamicObject'
import { produce } from 'immer'
import { PhysicalObjectInterface } from '../ts/app_interfaces'
import { BackgroundRemovalButton } from '../buttons/BackgroundRemovalButton'


export const DynamicObjectSelector = (props) => {
	const addPhysicalObject = usePhysicalObjects((state) => state.addPhysicalObject)
	const selectedPhysicalObject:PhysicalObjectInterface | null = usePhysicalObjects(state=>state.selectedPhysicalObject)
	const setTransformMode = useMattoState((state) => state.setTransformMode);

	const handleMeshChange = (dynamicObject) => {
		if (!selectedPhysicalObject) return;
		const newObject:any = produce(selectedPhysicalObject, draft=> {
			draft.name = dynamicObject.name;
			draft.mode = dynamicObject.mode;
			draft.dimensionData = [];
			draft.isStaticObject=true;
			draft.meshName = dynamicObject.meshName;
			draft.flipY = dynamicObject.flipY;
			draft.scale = [dynamicObject.scale,dynamicObject.scale,dynamicObject.scale];
			if (dynamicObject.aspect) draft.aspect = dynamicObject.aspect;
			draft.rotation = dynamicObject.rotation;
			if (dynamicObject.centered) draft.centered = dynamicObject.centered;
			draft.envMapIntensity = dynamicObject.envMapIntensity;
			if (draft.dynamicMaterialProps) draft.dynamicMaterialProps.repeat = {x:null, y:null};
			if (draft.dynamicMaterialProps) draft.dynamicMaterialProps.offset = {x:null, y:null};
			if (dynamicObject.meshTextureRepeat) draft.meshTextureRepeat = dynamicObject.meshTextureRepeat;
			if (dynamicObject.dynamicScale) draft.dynamicScale = dynamicObject.dynamicScale;

			if (dynamicObject.files) draft.files = dynamicObject.files; 
		})
		usePhysicalObjects.getState().deleteSelectedObject();
		addPhysicalObject(newObject, true);
		setTransformMode(null);
	}
	return (
		<Grid container spacing={0} style={{paddingTop:'5px' }}>
		<Grid container item xs={12} justifyContent="center" style={{padding: '8px'}}>
			<BackgroundRemovalButton />
		</Grid>
		<Grid container item xs={12} id="scrollableDiv" justifyContent="space-evenly" style={{maxHeight:'78.5vh', overflow:'auto', paddingTop:'10px', paddingLeft:'7px'}}>
			{dynamicObjects.map( (object,index) => {
				return (
					<Grid key={index} item xs={6}>
					<ImageItem onClick={ () => { handleMeshChange(object) }} 
						name={object.name} src={object.files.thumbnail} draggable={false} type="shapeshift" />
					</Grid>
				)
			})}
		</Grid>
		</Grid>
	)
}


