import { generateID } from "ui";
import { updateRecord, useStaging,deepRenderDB, MAX_FREE_RENDERS } from "../libs/firebase";
import { limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { create } from "zustand";
import { useLoginState } from "../studioxr/auth/LoginState";
import { ProfileInterface, UserProfileRole } from "ts-interfaces";
import { createHash } from "../libs/util";
import { useMattoState } from "../MattoState";

export interface DeepRenderInterface {
    id: string,
    uid: string,
    maxWidth: number,
    maxHeight: number,
    samples: number,
    project_id: string,
    project_name?:string,
    createdAt: number,
    updatedAt: number,
    format: 'jpg' | 'png',
    rendered_url?: string,
    render_status: 'done' | 'queued' | 'rendering' | 'failed',
    scene_graph_hash: string,
    viewed: boolean,
}
export interface DeepRenderSceneInterface {
    id: string,
    uid: string,
    deepRenderID: string,
    scene_graph: string,
    createdAt: number
}

//get 1 hour ago in millis
const oneHourAgo = Date.now() - 1 * 60 * 60 * 1000

export const useRenderStore = create((set,get:any) => ({
    results: [],
    unsubscribe: null,
    uid: null,
    unviewed:0, //how many renders have not been clicked?

    canUserRender:  async () => {        
        if (!get().unsubscribe) {
            console.log("Need to fetch the current renders");
            await get().fetch(get().uid)
        }
        const canRender = get().results.filter( (f) => f.render_status=='queued' 
            && f.createdAt > Date.now() - 1 * 60 * 10 * 1000  )        
        return canRender.length==0
    },
    setViewed: async (id) => {
        await updateRecord(id,'deepRender',{viewed:true})
        set({ unviewed: get().unviewed==0 ? 0 : get().unviewed - 1 })        
    },
    fetch: async (uid) => {
        if (uid==get().uid) return;        
        await get().unsubscribe?.()
        set({ uid: uid, results: [],unviewed:0, unsubscribe: null})        
        if (uid==null) return;
        const q = query(deepRenderDB as any, 
            where("uid", "==", uid), 
            orderBy("createdAt", "desc"), 
            limit(50))
        if (window.debug) window.debug.deepRenderResultsCalls = (window.debug.deepRenderResultsCalls || 0)  + 1;
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data:any = querySnapshot.docs.map(doc => doc.data())
            set({ 
                unviewed: data.filter(d => d.viewed==false && d.render_status=='done').length,
                results: data 
            })
        })
        set({ unsubscribe: unsubscribe })
    },
  }))

//depenend on useLoginState... Not ideal but it works
export async function addToQueue(currentUser, project_id, json) {
    return new Promise(async (resolve, reject) => {
        if (currentUser?.uid==null || project_id==null || json==null) reject('invalid parameters');
        else {
            try {
                const profile:ProfileInterface | any = useLoginState.getState().profile
                if (!profile?.uid)  { reject('invalid profile'); return }
                if (profile.renders > MAX_FREE_RENDERS && profile.userRole!=UserProfileRole.PRO) {
                    //reject('You need to be Pro to for more renders')
                    reject('Requires Pro')
                    return;
                }                    
                const test:any = await (useRenderStore.getState() as any).canUserRender() 
                if (test==false) {
                    reject('You can only render one scene at a time.  Please wait a few minutes and try again.')
                    return;
                }                
                const token = await currentUser.getIdToken()
                const [row, rowScene] = createDeepRenderRequest(currentUser.uid, project_id, json)
                const look = await updateRecord(row.id,'deepRender',row);
                const look2= await updateRecord(rowScene.id,'deepRenderScene',rowScene);

                const searchParams = new URLSearchParams({
                    id: row.id, dev: useStaging ? 'true' : 'false',token: token
                })                      
                await fetch('https://us-central1-mattoboard-b8284.cloudfunctions.net/deep_render?' + searchParams.toString(), 
                    { mode: 'no-cors'})
                await useLoginState.getState().setIncrementRenderCount()
                resolve(true)
            }
            catch (err) {
                console.log('nooo ', err);
                reject(err)
            }
        }
    })
}


function createDeepRenderRequest(uid,project_id,scene_graph,maxHeight=2000,maxWidth=2000,samples=120):[DeepRenderInterface,DeepRenderSceneInterface] {    
    const parsed_scene_graph = JSON.parse(scene_graph)
    const d:DeepRenderInterface = {
        id: generateID(),
        uid: uid,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        samples: samples,
        format: parsed_scene_graph['format']=='PNG' ? 'png' : 'jpg',
        project_name: parsed_scene_graph['title']  || 'Untitled',
        project_id: project_id,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        render_status: 'queued',
        scene_graph_hash: createHash(scene_graph),
        viewed: false,
    }
    const d2:DeepRenderSceneInterface = {
        id: generateID(),
        uid: uid,
        deepRenderID: d.id,
        scene_graph: scene_graph,
        createdAt: Date.now()
    }
    return [d,d2]
}


