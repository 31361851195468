import React, { useState, useContext, useEffect, useMemo } from "react";
import { Grid,IconButton, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { TrialContainer } from "./styles";
import { PrimaryButton } from "../studioxr/Button";
import { setProfileFirstAction } from "../libs/util.ts";
import { useLoginState } from "../studioxr/auth/LoginState.ts";
import { ProfileFirstActions, ProfileInterface, UserProfileRole } from "ts-interfaces";
import { cloudflareCDN, firebaseHelper } from "ui";
import { useMattoState } from "../MattoState.ts";
import { getGoogleAnalyticsProductInfo } from "./config.js";
import { getPublicURL } from "../libs/firebase.js";
import { LookupKey, Plans, UserTypes, useStripeStore,ProfilePrices } from "./useStripeStore.tsx";
import { ChoosePlan } from "./ChoosePlan.tsx";
import { GetPro } from "./GetPro.tsx";
import { FinalizePayment } from "./FinalizePayment.tsx";
import { stripePitchImage } from "./config.js";

export const StartSubscription = () => {
  const profile:ProfileInterface | any = useLoginState((state) => state.profile);
  const [activeStep, setActiveStep] = React.useState(0); 
  const setShowSubscription = useMattoState((state) => state.setShowSubscription);
  const profile_prices = useStripeStore(state => state.profile_prices) 
  const setProfile = useLoginState((state) => state.setProfile);

  const [clientSecret, setClientSecret] = useState();
  const [selectedPlan, setSelectedPlan] = useState<ProfilePrices | any>(null);
  const isStudent = profile?.occupation=='Student' || profile?.occupation=='Hobbyist'

  useEffect(() => {  useStripeStore.getState().setProductAndPrices()  } ,[])

  const plan={}
  plan['monthly'] = isStudent ? profile_prices[LookupKey.student_monthly] : profile_prices[LookupKey.pro_monthly]
  plan['yearly'] = isStudent ? profile_prices[LookupKey.student_yearly] : profile_prices[LookupKey.pro_yearly]

  const handleModalClose = () => void  setShowSubscription(null);

  const handleSuccessfulPayment = (stripeCustomerId, subscriptionId) => {
    if(stripeCustomerId && subscriptionId){
      const dataToUpdate = {
        stripeCustomerId: stripeCustomerId,
        stripeSubscriptionId: subscriptionId,
        userRole: UserProfileRole.PRO
        // trialends: getDateDaysFromNow(30,true)  
      }
      firebaseHelper.updateFieldsInRecord(profile.id, 'profiles', dataToUpdate).then(res => {
          const updatedProfile = {...profile, ...dataToUpdate}
          setProfile(updatedProfile);
          setActiveStep(3);
      }).catch((err) => {
        console.log(err);
        setShowSubscription(null);
      })
    }
  }
  
  if (!profile || Object.keys(profile_prices).length < 1 ) return null;
  return(
    <TrialContainer>
    <IconButton sx={{zIndex:'100',margin:'-15px'}} onClick={handleModalClose} className="close-btn"> 
      <CloseIcon sx={{width: "18px", height: "18px"}} />
    </IconButton> 
    <div className="form-root trial">
          {activeStep === 0 && (
              <GetPro 
                profilePrices={plan['monthly']} 
                onSuccess={() => { setActiveStep(1); }}/>
          )}
          {activeStep === 1 && (
              <ChoosePlan profilePrices={ plan } 
                setSelectedPlan={setSelectedPlan} 
                onSuccess={() => setActiveStep(2)} 
                onBack={() => setActiveStep(0)} 
                setClientSecret={setClientSecret} />
          )}
          {activeStep === 2 && clientSecret && selectedPlan && (
              <FinalizePayment 
                clientSecret={clientSecret} 
                selectedPlan={selectedPlan} 
                onSuccess={handleSuccessfulPayment} 
                onBack={() =>  setActiveStep(1)} />
          )}
          {activeStep === 3 && clientSecret && selectedPlan && (
              <PaymentSuccessView  
                selectedPlan={selectedPlan} 
                onSuccess={() => setShowSubscription(null)} />
          )}
    </div>
    </TrialContainer>
  )
}

const PaymentSuccessView = ({ onSuccess,selectedPlan }) => {
  useEffect(() => {      
    setProfileFirstAction(ProfileFirstActions.PAYMENT_SUCCESS_MODAL).finally(()=>{});
    if (window.gtag) window.gtag("event", "purchase",  getGoogleAnalyticsProductInfo(selectedPlan)  )
  },[])
const complete = () => { onSuccess && onSuccess();  };

return (
      <>
        <Grid  sx={{height: '100%',display: 'flex', flexDirection: 'row'}}>
          <Grid sx = {{padding:"15px", justifyContent: "left",overflowY: 'auto',height:'100%', width: '400px', flexGrow: '0'}}>
            <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'left', flex: '1'}}>
              <Typography className="main-title" sx={{paddingBottom:'10px'}} >Mattoboard Pro is Successfully Activated!</Typography> 
              <PrimaryButton fullWidth variant="contained" onClick={complete}>
                Start Designing!
              </PrimaryButton>
            </Grid>
          </Grid>
          <Grid sx={{display: "flex",width: 'calc(100% - 400px)',height:'100%', overflow: "hidden", borderRadius: "0px 15px 15px 0", flexGrow: '1'}}>
            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={cloudflareCDN(getPublicURL(stripePitchImage), 'width=600,format=auto')} loading="lazy" alt=""  />     
          </Grid>
        </Grid> 
      </>
  );
};

