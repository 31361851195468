import { Link,Box,AppBar,Toolbar,IconButton,Drawer,Button, MenuItem} from "@mui/material";
import { styled } from "@mui/material/styles";

const drawerWidth = 240;

export const HeaderContainer = styled(Box)(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  background: "white",
  zIndex: "999",
  display: "flex",
  padding: "14px 0",

  "&.scrolled": {
    boxShadow: "rgba(0, 0, 0, 0.2) 3px 8px 20px",
  },
  ".MuiIconButton-root": {
    padding: 0,
  },
}));

export const StyledAppBar = styled(AppBar)(() => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  position: "static",
}));

export const LogoContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  justifyContent: "space-between",
  [theme.breakpoints.up("sm")]: {
    padding: 0,
  },
}));

export const MenuButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "8px",
  "@media (min-width: 901px)": {
    display: "none",
  },
}));

export const NavContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: drawerWidth,
  },
}));

export const StyledLogo = styled("img")(() => ({
  marginRight: "40px",
  cursor: "pointer",
  "@media (max-width: 965px)": {
    marginRight: "20px",
  },
}));

export const NavLink = styled(Link)(({ theme }) => ({
  fontFamily: "Roundo-Regular",
  fontSize: "15px",
  cursor: "pointer",
  //color: theme.palette.customColor.main,
  textDecoration: "none",
  textTransform: "uppercase",
  "&:hover": {
    textDecoration: "underline",
    fontWeight: theme.typography.fontWeightMedium,
  },
  margin: "0 10px",
  marginTop: "4px !important",
  "@media (min-width: 1331px)": {
    margin: "0 20px",
  },
  "@media (max-width: 965px)": {
    margin: "0 5px",
  },
  "@media (max-width: 900px)": {
    display: "none",
  },
}));
export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: "Roundo-Regular",
  
}));
export const PersonButton = styled("div")(({ theme }) => ({
  marginLeft: 0,
  width: '24px',
  height: '24px',
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
   // display: "none",
  },
}));

export const BoardButton = styled(Button)(({ theme }) => ({
  fontFamily: "Roundo-Regular",
  fontSize: "15px",
  color:'white',
  background:'black',
  //color: theme.palette.customColor.main,
  textTransform: "uppercase",
  marginLeft: "20px",
  "@media (min-width: 1331px)": {
    marginLeft: "20px",
  },
    "&:hover": {
      color:'black'
  },
  border: "1px solid #9D989B",
  borderRadius: "0px",
  paddingLeft: "22px",
  paddingRight: "22px",
  "> span": {
    marginTop: "4px",
  },
  [theme.breakpoints.down("sm")]: {
    //fontSize: "7px",
    display: "none",
  },
}));

export const SearchWrapper = styled("div")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("lg")]: {
    display: "block",
  },
}));

export const MobileSearchButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
