import React,{ useEffect, useState } from "react";
import  {Elements}  from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutForm } from "./CheckoutForm.tsx";
import { getStripeSetupIntentAPIUrl, publishableKey } from "./config.js";
import { fetchData } from "../libs/util.ts";

export const PaymentComponent = ({clientSecret, onSuccess, selectedPlan, promoId}) => {
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [activeClientSecret, setActiveClientSecret] = useState<any>(clientSecret);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  async function getNewClientSecret() {
    const requestConfiguration = {};
    const { clientSecret } = await fetchData(requestConfiguration, getStripeSetupIntentAPIUrl);
    
    setActiveClientSecret(clientSecret)
  }

  async function getStripeConfiguration() { setStripePromise(loadStripe(publishableKey)) }

  useEffect(() => {
      if(!stripePromise) getStripeConfiguration()
  }, [clientSecret]);

  useEffect(() => {
    setActiveClientSecret(clientSecret);
  }, [clientSecret]);
  
  return (
    <>
      {activeClientSecret && stripePromise && (
        <Elements key={activeClientSecret} stripe={stripePromise} options={{ clientSecret: activeClientSecret }}>
          <CheckoutForm onSuccess={onSuccess} selectedPlan={selectedPlan} promoId={promoId} getNewClientSecret={getNewClientSecret} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
        </Elements>
      )}
    </>
  );
}
