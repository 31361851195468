import React from 'react'
import { Grid } from '@mui/material'
import { ImageItemCover } from '../libs/ImageItemCover';
import { gtagCuratedImagesDragEvent } from '../libs/util'



export function CollectionUserImages(props) {
	let currentUserUid = props?.currentUserUid

	const onDrag = (e,upload) => { 
		if(props?.collectionType == 'curatedImages') gtagCuratedImagesDragEvent(props.collectionID, props.collectionName)
		let src = upload.files.file;
		let dynamicMaterialProps;

		if(upload.files.bg_removed_file) {
			const bg_removed_file = upload.files.bg_removed_file;
			dynamicMaterialProps = {
				files: bg_removed_file, 
				backgroundRemoved: true,
				backgroundRemovedFile: bg_removed_file,
				originalFile: src,
				repeat:{x:null, y:null}, offset:{x:null, y:null},
				metadata:{name:upload?.name || 'Upload', uid:currentUserUid}
			};
		} else {
			dynamicMaterialProps = {files:src, 
				repeat:{x:null, y:null}, offset:{x:null, y:null},
				metadata:{name:upload?.name || 'Upload', uid:currentUserUid}};
		}
		e.dataTransfer.setData("upload",JSON.stringify(dynamicMaterialProps))
	}
	const getImageFiles = (upload) => {
		return upload?.files 
	}

		return (
			<>
			{props.userImages.map((item,index) => (
				<Grid key={index}  item xs={6} >               
					<ImageItemCover 
						id={item.id} 
						name={item.name} 
						src={item.src}
						draggable={true} 
						onDragStart={(e) => onDrag(e,item)}
						currentUserUid={currentUserUid}
						getImageFiles ={()=> getImageFiles(item)} 
						type='collectionUserImages'
					/>	
				</Grid>
			))}
			</>
	    );
}