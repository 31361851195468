import React, { useState } from "react";
import { FormControl,FormControlLabel,Grid,IconButton, Radio, RadioGroup, Stack, Typography, styled } from "@mui/material";
import { PrimaryButton } from "../studioxr/Button";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { fetchData, formatPrice } from "../libs/util.ts";
import { useLoginState } from "../studioxr/auth/LoginState.ts";
import { ProfileInterface, UserProfileRole } from "ts-interfaces";
import { cloudflareCDN } from "ui";
import { getStripeSetupIntentAPIUrl } from "./config.js";
import { getPublicURL } from "../libs/firebase.js";
import { stripePitchImage as imageUrl } from "./config.js";
import { Plans } from "./useStripeStore.tsx";


export const ChoosePlan = ({ onSuccess, onBack, profilePrices, setSelectedPlan, setClientSecret }) => {
    const [planValue, setPlanValue] = useState<Plans>(Plans.monthly);
    const profile:ProfileInterface | any = useLoginState((state) => state.profile);
    const [nextButtonDetails, setNextButtonDetails] = useState({label:"Next", disable:false});
  
    const CustomizedFormControlLabel = styled(FormControlLabel)`font-weight: 700 !important;`;
  
    async function getStripePaymentIntent() {
      const requestConfiguration = { amount: profilePrices[planValue].local_price, currency: profilePrices[planValue].currency  };
      const { clientSecret } = await fetchData(requestConfiguration, getStripeSetupIntentAPIUrl);
      setClientSecret(clientSecret)
    }
    const handleNextBtnClick = async() => {  
        if(!profile?.id) { window.alert('Something went wrong! you cannot proceed with the process. Please contact studio@mattoboard.com.') ; return;}
        setNextButtonDetails({label:'Processing...Please Wait',disable:true});
        setSelectedPlan(profilePrices[planValue]);
        await getStripePaymentIntent();
        onSuccess && onSuccess(); 
    };
    const calculateYearlySavings = () => {
        const yearlyPrice = profilePrices.yearly.local_price;
        const monthlyPrice = profilePrices.monthly.local_price;
        return (monthlyPrice * 12) - yearlyPrice;
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlanValue((event.target as any).value);
    };

    return (
        <>
          <Grid sx={{height: '100%', display: 'flex', flexDirection: 'row'}}>
            <Grid sx = {{padding:"15px", justifyContent: "left", overflowY: 'auto', height:'100%', width: '400px', flexGrow: '0'}}>
              <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
                <Grid sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Grid>
                    <IconButton onClick={onBack}> 
                      <ChevronLeftIcon />
                    </IconButton>
                  </Grid>
                  <Typography className="main-title" sx={{paddingBottom:'10px'}} >Choose your plan</Typography> 
                </Grid>
                
                {/* <Grid sx={{display:'flex', flexDirection:'row', marginLeft: '10px'}}>
                  <CheckIcon sx={{verticalAlign: 'middle', color: 'green', marginRight: '4px'}} />
                  <Typography variant="body1">
                    Free 30 day trial, cancel any time
                  </Typography>
                </Grid>
                <Grid sx={{display:'flex', flexDirection:'row', marginLeft: '10px', marginTop: '10px'}}>
                  <CheckIcon sx={{verticalAlign: 'middle', color: 'green', marginRight: '4px'}} />
                  <Typography variant="body1">
                    We'll remind you before your trial ends
                  </Typography>
                </Grid> */}
  
                <FormControl sx = {{padding:"15px", justifyContent: "center", marginTop: '12px'}}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="student_monthly"
                    name="radio-buttons-group"
                    onChange={handleChange}
                    value={planValue}>
                    <Grid sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <CustomizedFormControlLabel value={Plans.yearly} control={<Radio />} label="Yearly" />
                      <Typography sx={{fontSize:'11px', marginLeft: '-10px', background: 'red', padding: '4px', borderRadius: '8px', color: 'white', fontWeight: '600'}}>
                        Best value - Save { formatPrice(calculateYearlySavings(),profilePrices.monthly.currency,false) } </Typography>
                    </Grid>
                     <Typography id='price_yearly' className="price-text">{formatPrice(profilePrices.yearly.local_price,profilePrices.yearly.currency,true)}   </Typography>
                     <CustomizedFormControlLabel value={Plans.monthly} control={<Radio />} label="Monthly" />
                     <Typography  id='price_monthly' className="price-text">{formatPrice(profilePrices.monthly.local_price,profilePrices.monthly.currency,true)} </Typography>
                  </RadioGroup>
                </FormControl>
                <PrimaryButton aria-label="ChoosePlan" fullWidth variant="contained" onClick={handleNextBtnClick} disabled={nextButtonDetails.disable}>
                  {nextButtonDetails.label}
                </PrimaryButton>
              </Grid>
            </Grid>
            <Grid sx={{display: "flex", width: 'calc(100% - 400px)', height: '100%', overflow: "hidden", borderRadius: "0px 15px 15px 0", flexGrow: '1'}}>
              <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={cloudflareCDN(getPublicURL(imageUrl), 'width=600,format=auto')} loading="lazy" alt=""  />     
            </Grid>
          </Grid>  
           
        </>
      );
    };