import React, { useRef,useEffect,useContext } from 'react'

export const Welcome = () => {
	return (
		<React.Fragment>
		<iframe src="https://mailchi.mp/ec00f05e8f3f/qejd182jl9" style={{position:'fixed', top:'0px',
		border:'0px', left:'0px',height:'100%',width:'100%'}}>
		    Your browser doesn't support iframes
		</iframe>
		{/* <Bar homePage={true} /> */}
		</React.Fragment>
	)	
}
