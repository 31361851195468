import React, { useEffect,useState,useContext } from "react"
import {Bar} from '../auth/Bar'
import { Grid, Container, Snackbar } from '@mui/material'
import {SideBar} from "../sidebar/SideBar"
import {ActionBar} from "./ActionBar"
import {Board} from '../threejs/Board'
import { LoadScene } from "../threejs/LoadScene";
import { LoadingMessage } from "../threejs/LoadingMessage";
import { Inspector2 } from "../inspector/Inspector2";
import { DialogBox } from "../threejs/DialogBox";
import { SideBarCategory } from "../sidebar/SideBarCategory"
import { useParams, useLocation } from 'react-router-dom'
import { HelpCenter, Onboarding, WelcomeSplash } from "../components/Onboarding"
import { useMattoState } from "../MattoState"
import { CollectionModal } from "../studioxr/components/CollectionPopover/CollectionModal"


export const Project = () => {
	let params = useParams();
	let location = useLocation();
	let props = {...params,location}
	const [isOnline, setIsOnline] = useState(navigator.onLine);
    const OfflineUserSnackMessage = "You are experiencing network problems at the moment"

  useEffect(() => {
    const handleOnline = () => { 
		setIsOnline(true) 
		if (window.gtag)  window.gtag('event','offline detected')
	};
    const handleOffline = () => {  setIsOnline(false)  };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
	return (
	  <React.Fragment>
	  <LoadScene {...props} />
	  <Grid item >
	    <Bar />
	  </Grid>
	  <Container maxWidth={false} style={{paddingLeft:'0px', paddingRight:'10px'}} >
		<Grid spacing={1} container direction="row" justifyContent="center" style={{overflow:'hidden',maxHeight:'92vh'}}>		
			<Grid item sm={1} md={0.7} xl={0.7}  sx={{display: { iphone:'none', sm:'block', md:'block'}, backgroundColor: theme => theme.palette.primary.monochrome.dark }}   >
				<SideBar />
			</Grid>
			<Grid item sm={2.5} md={2.4} xl={2} sx={{display: { iphone:'none', sm:'block',  md:'block', paddingLeft: '10px' }}} >
				<SideBarCategory />
			</Grid>
			<Grid item iphone={12} sm={6}  md={6.5} xl={7.3} id='action_bar' style={{marginTop:'5px', minHeight:'500px'}} >
				<ActionBar />
				{/* <ActionBar isOnline={isOnline}/> */}
				<Grid item xs={12} style={{height:'84vh'}} >
				<Board  style={{minHeight:'500px'}} {...props} />
				</Grid>
			</Grid>
			<Grid item sm={2.5}  md={2.4} xl={2} sx={{display: {  iphone:'none',  sm:'block',  md: 'block' }}}  >
				<Inspector2 />
			</Grid>
		</Grid>
	  </Container>
	  <LoadingMessage />
	  <Snackbar id='offlineMessage' autoHideDuration={null}  anchorOrigin={{vertical: 'bottom',horizontal: 'center'}}  open={!isOnline} message={OfflineUserSnackMessage} />
	  <DialogBox/>
	  <Onboarding />
	  <CollectionModal />
	  <GlobalSnackbar />
	  {/* <HelpCenter /> */}
	  {/* <WelcomeSplash /> */}
	  </React.Fragment>  
	)
}
export const GlobalSnackbar = () => {
	const snackbarMessage = useMattoState((state) => state.snackbarMessage);
	const snackbarAutoHide = useMattoState((state) => state.snackbarAutoHide);
	const setSnackbarMessage = useMattoState((state) => state.setSnackbarMessage);
	return (
		<Snackbar 
			open={snackbarMessage?.length > 2} 
			onClose={ () => { setSnackbarMessage(null) } } 
			ClickAwayListenerProps={{ onClickAway: () => null }}
			// autoHideDuration={3500}
			autoHideDuration={snackbarAutoHide ? 3500 : null}
			message={snackbarMessage} />
	)
}

