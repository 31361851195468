import React, { useContext, useEffect, useState } from "react";
import { Modal, Box, Grid, IconButton, Typography, Button } from "@mui/material";
import { ModalContainer } from "./styles";
import { StartSubscription } from "./StartSubscription";
import { CancelSubscription } from "./CancelSubscription";
import { useMattoState } from "../MattoState";
import { getStripeInvoicesAPIUrl, getSubscriptionAPIUrl  } from "./config.js";
import { fetchData, formatPrice, getContactForm } from "../libs/util.js";
import { useLoginState } from "../studioxr/auth/LoginState.js";
import { ProfileInterface, UserProfileRole } from "ts-interfaces";
import AuthModal from "../studioxr/auth/AuthModal";
import { Close as CloseIcon } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { styled } from '@mui/system';
import { ActionButton } from "./../buttons/ButtonStyles";
// import { AuthContext } from "../auth/Auth.jsx";
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { revertStripeSubscriptionAPIUrl } from "./config.js";


const CancelBtn = styled(Button)(({ theme }:any) => ({ background: '#C68CD6', color:'white', border: '1px solid #C68CD6', marginRight:'20px', padding: '8px 16px', fontWeight: 'bold', fontSize: '12px',
                                                  maxHeight:theme.subBarButtons.maxHeight,'&:hover':{'color':'#C68CD6','backgroundColor':'white'}}));

const ChangeBillingBtn = styled(Button)(({ theme }:any) => ({ background: '#C68CD6', color:'white', border: '1px solid #C68CD6', marginRight:'20px', padding: '8px 16px', fontWeight: 'bold', fontSize: '12px',
                                                    maxHeight:theme.subBarButtons.maxHeight,'&:hover':{'color':'#C68CD6','backgroundColor':'white'}}));
  
const StyledModal = ({ children, ...rest }) => {
  return (
    <Modal {...rest}>
      <ModalContainer>
        <Box className="inner-root">
          {children}
        </Box>
      </ModalContainer>
    </Modal>
  );
};

const UserSubscriptionModal = () => {
  const profile:ProfileInterface | any = useLoginState((state) => state.profile);
  const showSubscription = useMattoState((state) => state.showSubscription);
  const setShowSubscription = useMattoState((state) => state.setShowSubscription);

  useEffect(() => {
    if ( !profile?.stripeCustomerId) { return; }
    const urlParams = new URLSearchParams(window.location.search);    
    if (urlParams.get('accounts') == 'true') { setShowSubscription('accounts'); }
  },[profile])


  const handleClose = (event, reason) => {  setShowSubscription(null); }

  const sub = Array.isArray(showSubscription) ? showSubscription[0] : showSubscription;  
  if (sub == "startTrial") {
      return (
        <StyledModal open={true} onClose={handleClose}>
          <StartSubscription />
        </StyledModal>
      );      
    } 
    else if (sub == "accounts") {
      return <AccountsModal />
    }
    else if (sub == "cancel") {
      return (
        <StyledModal open={true} onClose={handleClose}>
          <CancelSubscription/>
        </StyledModal>
      );
    } else return null;
};

export default UserSubscriptionModal;

const isEmpty = (obj) => Object.keys(obj).length === 0;

const AccountsModal = () => {
  const profile:ProfileInterface | any = useLoginState((state) => state.profile);
  const setShowSubscription = useMattoState((state) => state.setShowSubscription);
  const [customerInvoices, setCustomerInvoices] = useState([]);
  const [customerUpcomingInvoice, setCustomerUpcomingInvoice] = useState<any>(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState<any>(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState('');
  const [subscriptionAmount, setSubscriptionAmount] = useState(0);

  const handleClose = (event, reason) => { setShowSubscription(null); }

  useEffect(()=> { fetchCustomerInvoices(); },[profile?.stripeCustomerId])

  async function revertStripeSubscription() {
    const requestRevert = { customerId: profile?.stripeCustomerId };
    const revertStripeSubscriptionData = await fetchData(requestRevert, revertStripeSubscriptionAPIUrl);
    return revertStripeSubscriptionData;
  }

  const revertSubscription = async () => { 
    try {
      const revertedStripeSubscriptionPlan  = await revertStripeSubscription();
      setSubscriptionPlan(revertedStripeSubscriptionPlan);
      fetchCustomerInvoices();
    }
    catch (error) {
      alert("There is some error in reverting your subscription. Please contact studio@mattoboard.com for assistance.")
    }
  }

  const subcriptionPlan = () => {

    if(!profile?.stripeCustomerId) {
      return <Typography>Regular Plan</Typography>
    }
    else if (subscriptionPlan?.subscription?.cancel_at_period_end==true) {
      return ( 
        <Stack direction="column" spacing={1}>
          <Typography variant="h5" component="h5">Your Subscription <Typography component="span" variant="h5" sx={{color: '#cc010c', fontWeight: 'bold'}}>will be canceled</Typography></Typography>
          <Typography sx={{paddingTop: '20px', fontWeight: 'bold'}}>Cancel Date: {subscriptionEndDate}</Typography>
          <Typography sx={{paddingBottom: '10px'}}>You will be able to use Mattoboard Pro until your cancel date.  If you change your mind and want to resume Mattoboard Pro click below.  Come back and cancel anytime</Typography>
          <ActionButton sx={{fontWeight: 'bold', width: '80%', marginLeft: '10%!important'}} onClick={revertSubscription}>KEEP MY SUBSCRIPTION</ActionButton>
        </Stack>
      );
    }
    else if(subscriptionPlan?.subscription?.status == 'active' || subscriptionPlan?.subscription?.status == 'trialing' || subscriptionPlan?.subscription?.status == 'past_due' || subscriptionPlan?.subscription?.status == 'incomplete') {
      return ( 
        <Grid sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '20px', alignItems: 'center'}}>
          <Typography >Mattoboard PRO Tier.</Typography>
          <CancelBtn onClick={() => setShowSubscription('cancel')}>Cancel Subscription</CancelBtn>
        </Grid>
      )
    } else if(subscriptionPlan && isEmpty(subscriptionPlan)) {
      return <Typography>Regular Plan</Typography>
    } else {
      return <Typography>Loading Plan...</Typography>
    }
  }

  const needMoreTime = () => {
    const today = new Date().getTime()
    const eightDays = (8 * 24 * 60 * 60 * 1000)

    if (subscriptionPlan?.subscription?.status=='active' 
    && typeof(subscriptionPlan?.subscription?.trial_end)=='number'
    && (subscriptionPlan?.subscription?.trial_end*1000 + eightDays) > today) {  
        return <Typography>Did you need a longer trial?  <a target='_' href={getContactForm(profile)}>Contact Us For a Full Refund.</a> </Typography>
    }
  }  

const calculateInvoiceDetails = (customerInvoices) => {
    if(customerInvoices?.length > 0) {
            const latestInvoice: any = customerInvoices[0];
            const perioEndDate = new Date(latestInvoice?.lines?.data[0]?.period?.end * 1000); 
            const dueDate = perioEndDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
            const subscriptionAmountComputed = (latestInvoice?.lines?.data[0]?.plan?.amount / 100)    
            setSubscriptionEndDate(dueDate)   
            setSubscriptionAmount(subscriptionAmountComputed)
    }
  }

  const nextBill = () => {
    if (subscriptionPlan?.subscription?.cancel_at_period_end==true) {
      const endingDate = subscriptionPlan?.subscription.cancel_at ? new Date(subscriptionPlan?.subscription.cancel_at*1000).toLocaleDateString() :''
      return (
        <>
        <Typography>Your subscription will be canceled at the end of the billing period ({endingDate}). </Typography>        
        {needMoreTime()}
        </>
      )      
    }

    if((subscriptionPlan?.subscription?.status == 'active' || subscriptionPlan?.subscription?.status == 'trialing') && customerUpcomingInvoice?.total) {
      // const latestInvoice = customerInvoices[0];
          const options:any = { month: 'long', day: 'numeric', year: 'numeric' };

          // const perioEndDate = new Date(latestInvoice?.lines?.data[0]?.period?.end * 1000); 
          const perioEndDate = new Date(customerUpcomingInvoice?.period_end * 1000); 
          const dueDate = perioEndDate.toLocaleDateString('en-US', options);

          //const subscriptionAmount = (latestInvoice?.lines?.data[0]?.plan?.amount / 100)          
          // const subscriptionAmount = formatPrice(customerInvoices[0]?.total, customerInvoices[0]?.currency.toUpperCase())
          const subscriptionAmount = formatPrice(customerUpcomingInvoice?.total, customerUpcomingInvoice?.currency.toUpperCase())



          return (
            <>
              <Typography>Your next payment will be a total of {subscriptionAmount} on {dueDate}. </Typography>
              {needMoreTime()}
            </>

          )
    } else {
      return ''
    }
  }
  const invoicesList = () => {
    const validCustomerInvoices = customerInvoices?.filter((invoice: any) => invoice?.total > 0 && invoice?.number!= null);
    if( validCustomerInvoices?.length > 0 ){
      return (validCustomerInvoices.map((invoice:any) => (<Typography key={invoice.id}><a  target='_' href={invoice?.receiptUrl || invoice?.invoice_pdf}>{invoice?.number}</a></Typography>)) )
    } else {
      return <Typography >You have no receipts.</Typography>
     }
  }
  
  async function getStripeInvoice() {
    //    const requestInvoice = { email: profile.email };
    const requestInvoice = { customerId: profile.stripeCustomerId };
    const invoiceData = await fetchData(requestInvoice, getStripeInvoicesAPIUrl); 
    return invoiceData;
  }
  async function getStripSubscription() {
    const requestSubscription = { customerId: profile.stripeCustomerId };    
    const subscriptionData = await fetchData(requestSubscription, getSubscriptionAPIUrl);
    return subscriptionData;
  }
  const fetchCustomerInvoices  = async() => {
    if(profile?.stripeCustomerId) {
      const customerInvoices  = await getStripeInvoice();
      const subscriptionPlan = await getStripSubscription();
      setSubscriptionPlan(subscriptionPlan);
      setCustomerInvoices(customerInvoices?.invoice?.data)
      calculateInvoiceDetails(customerInvoices?.invoice?.data)
      setCustomerUpcomingInvoice(customerInvoices?.upcomingInvoice)
    }
  }
  return (
    <AuthModal open={true} onClose={handleClose}>
      <Stack>
        <Stack  sx={{paddingBottom:'30px'}}  justifyContent="space-between"   alignItems="center"  direction="row" spacing={2}>
        <img style={{height:'100%', width:'150px'}} src='/images/mattoboard-logo.jpg' />

        <IconButton sx={{width:'50px'}} component="button" onClick={(event) => handleClose(event, null)} className="close-btn">
            <CloseIcon />
        </IconButton>   
        </Stack>

        <Stack sx={{paddingBottom:'30px'}} direction="column" spacing={1}>
          <Typography sx={{fontWeight:'700'}}>Subscription Plan</Typography>
          {subcriptionPlan()}
        </Stack>

        <Stack sx={{paddingBottom:'30px'}} direction="column" spacing={1}>
         <Typography sx={{fontWeight:'700'}}>Account and Billing</Typography>
          {nextBill()}
          {profile?.stripeSubscriptionId &&
            <Grid sx={{display: 'flex', width: '100%', justifyContent: 'right' }}> 
              <ChangeBillingBtn onClick={() => window.open('https://billing.stripe.com/p/login/eVa7vVdLSdLc7gk3cc')}>Advanced Account Options</ChangeBillingBtn>
            </Grid>
          }
        </Stack>
        
        <Stack sx={{paddingBottom:'30px'}} direction="column" spacing={1}>
          <Typography sx={{fontWeight:'700'}}>Receipts</Typography>
          {invoicesList()}
        </Stack>

        <Stack direction="column" spacing={1}>
            <Typography sx={{fontWeight:'700'}}>Need help? Have a question about a bill?  </Typography>
            <Typography><a  target='_' href={getContactForm(profile)}>Contact Us</a> for additional support.</Typography>
        </Stack>

      </Stack>

    </AuthModal>
  )
}

// export const SubscriptionStatus = () => {
  
// 	const {currentUser} = useContext(AuthContext);
//   const profile:ProfileInterface | any = useLoginState((state) => state.profile);
//   const [subscriptionPlan, setSubscriptionPlan] = useState<any>(null);
// 	const setShowSubscription = useMattoState((state) => state.setShowSubscription);
  
//   const MyButton = styled(Button)(({ theme }) => ({ 
//     [theme.breakpoints.down('md')]: {
//       display:'none'
//     },
//     color:'white', background:'darkgrey',marginRight:'7px', maxHeight:theme.barButtons.maxHeight }));


//   useEffect(()=> { fetchCustomerSubcriptionPlan(); },[profile])

//   const handleTryMattoboardPro = () => {
// 		setShowSubscription('startTrial')
// 	}
  
//   async function getStripSubscription() {
// 		const requestSubscription = { customerId: profile.stripeCustomerId };
// 		const subscriptionData = await fetchData(requestSubscription, getSubscriptionAPIUrl);
// 		return subscriptionData;
//   }
  
//   const fetchCustomerSubcriptionPlan  = async() => {
// 		if(profile?.stripeCustomerId) {
// 		  const subscriptionPlan = await getStripSubscription();
// 		  setSubscriptionPlan(subscriptionPlan);
// 		}
//   }

//   console.log('subscriptionPlan', subscriptionPlan);

//   return (
//     <>
//     {currentUser && profile && profile.userRole !== UserProfileRole.PRO && 
//       <MyButton  sx={{ xs:'none',sm: 'none', md:'inline' }} id="mattoboardProBtn" onClick={handleTryMattoboardPro} startIcon={<CheckCircleOutlineIcon style={{ color: 'purple' }} />}>Upgrade to Pro</MyButton>
//     }
//     {currentUser && profile && profile.userRole === UserProfileRole.PRO && subscriptionPlan?.subscription?.cancel_at_period_end == false &&
    
//       <Box component="div" sx={{ display:{xs:'none',sm: 'inline'} }} >
//       <a href='https://blog.mattoboard.com/pro' target='_'><img src='/images/icons/proFinalIcon.png' style={{ height:'25px',paddingRight:'10px', marginLeft: '24px'}} /></a>
//       </Box>
//     }
//     </>
//   )
// }

