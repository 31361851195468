import React, { useMemo,useRef, useState,useEffect } from 'react'
import { SearchBox } from '../components/SearchBox';
import { gtagSearchEvent } from '../libs/util';
import { useSearchBox } from 'react-instantsearch';

export const CustomSearchBox = ({placeholderText}) => {
  const {query,refine,clear} = useSearchBox();
  return <SearchBox  onChange={value => refine(value)} onBlur={value => { if(value.length > 0) gtagSearchEvent(value)}} placeholder={placeholderText} />
}

