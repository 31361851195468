import React, { useEffect,useState,useContext } from "react"
import { Grid,Container, Typography, Button, Snackbar, Alert } from '@mui/material'
import {Board} from '../threejs/Board'
import { LoadScene } from "../threejs/LoadScene";
import { LoadingMessage } from "../threejs/LoadingMessage";
import { ProjectTitleBar } from "./ProjectTitleBar";
import { MoveLightButton } from  "../buttons/MoveLightButton";
import { CreateBoardButton } from "../buttons/CreateBoardButton"
import { UserAvatar } from "../auth/UserAvatar"
import {  mainContainerCssShare, mainContainerGridCssShare, canvasCssShare, boardGridCssShare, absoluteButtonsCssShare} from "./ProjectStyles"
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';

export const Show = () => {
	let params = useParams();
	let location = useLocation();
	let props = {...params,location}
	const history = useNavigate();
	const handleHomePageClick = () => {  history('/') }
	
	const mobileWarning = window.location.search.indexOf('mobileWarning=true') > -1
	const mobileMessage = 'Note: To edit this project please use a desktop or tablet device.'
	const [isCreatorNote, setIsCreatorNote] = useState(false)


	useEffect(() => {
		const mobileWarning = window.location.search.indexOf('mobileWarning=true') > -1
		if (mobileWarning) { setIsCreatorNote(true) }
	},[])

	return (
	  <React.Fragment>
	  <LoadScene {...props} />
	  <Grid item sx={{display: { xs:'flex',sm: 'flex', md: 'flex'}}}>		
		<Button onClick={handleHomePageClick} sx={{height:'30px',display:{xs:'block', sm:'none'}, backgroundColor:'#cccccc'}}>
			<HomeIcon style={{marginTop:'-3px'}} /> </Button>
	  	<ProjectTitleBar />
	  </Grid>
	  <Grid item sx={{display: { xs:'flex',sm: 'flex', md: 'flex'}}} style={absoluteButtonsCssShare}>
		<CreateBoardButton />
	  	<MoveLightButton/>
	  </Grid>
	  <Grid item sx={{display: {xs:'none',sm: 'none', md: 'block'}}}>
	  <UserAvatar projectId={props.id}/>
	  </Grid>
	  <Container maxWidth={false} sx={mainContainerCssShare} >
	  <Grid spacing={1} container direction="row" justifyContent="center" sx={mainContainerGridCssShare}>
	  <Grid item sm={12} md={12} xl={12}  sx={canvasCssShare}>
	    <Grid item xs={12} sx={boardGridCssShare} >
	     <Board  style={{minHeight:'500px'}} {...props} />
	    </Grid>
	  </Grid>
	  </Grid>
	  </Container>
	  <LoadingMessage />
	  {mobileWarning==true &&
	  <Snackbar autoHideDuration={5000} onClick={ () => { setIsCreatorNote(false) }} onClose={ () => { setIsCreatorNote(false) }} anchorOrigin={{vertical: 'bottom',horizontal: 'center'}}  open={isCreatorNote} message={mobileMessage} />
		}

	  </React.Fragment>  
	)
}

