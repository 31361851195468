import React, { useState,useEffect, Suspense,lazy } from 'react'
import { MeshTypes } from 'ts-interfaces';
const MetaSKU = lazy(() => import('./MetaSKU.tsx').then(module => ({ default: module.MetaSKU })));
const PaintBuilder = lazy(() => import('./PaintBuilder.tsx').then(module => ({ default: module.PaintBuilder })));
const MaterialBuilder = lazy(() => import('./MaterialBuilder.tsx').then(module => ({ default: module.MaterialBuilder })));
const GLTFBuilder = lazy(() => import('./GLTFBuilder.tsx').then(module => ({ default: module.GLTFBuilder })));
const MaterialImageRenderer = lazy(() => import('./MaterialImageRender.tsx').then(module => ({ default: module.MaterialImageRenderer }))); 
const CreateMaterial2 = lazy(() => import('./CreateMaterial2.tsx').then(module => ({ default: module.CreateMaterial2 })));
const MetaSKUConverter = lazy(() => import('./MetaSKUConverter.tsx').then(module => ({ default: module.MetaSKUConverter })));
const MetaSKUUpscale = lazy(() => import('./MetaSKUUpscale.tsx').then(module => ({ default: module.MetaSKUUpscale })));
const MetaSKUSeamless = lazy(() => import('./MetaSKUSeamless.tsx').then(module => ({ default: module.MetaSKUSeamless })));
const MetaSKUShadowRemoval = lazy(() => import('./MetaSKUShadowRemoval.tsx').then(module => ({ default: module.MetaSKUShadowRemoval })));

const Loading = () => {
    return <div>Loading...</div>
}
export const MetaSKULazy = () => {
    return (
        <Suspense fallback={<Loading />}>
          <MetaSKU />
        </Suspense>
    )
}
export const PaintBuilderLazy = () => {
    return (
        <Suspense fallback={<Loading />}>
          <PaintBuilder />
        </Suspense>
    )
}
export const MaterialBuilderLazy = () => {
    return (
        <Suspense fallback={<Loading />}>
          <MaterialBuilder />
        </Suspense>
    )
}
export const GLTFBuilderLazy = ({meshType=MeshTypes.GLTF, updateRecord=false}) => {
    return (
        <Suspense fallback={<Loading />}>
          <GLTFBuilder meshType={meshType} updateRecord={updateRecord} />
        </Suspense>
    )
}
export const MaterialImageRenderLazy = () => {
    return (
        <Suspense fallback={<Loading />}>
          <MaterialImageRenderer />
        </Suspense>
    )
}
export const CreateMaterial2Lazy = () => {
    return (
        <Suspense fallback={<Loading />}>
          <CreateMaterial2 />
        </Suspense>
    )
}
export const MetaSKUConverterLazy = () => {
    return (
        <Suspense fallback={<Loading />}>
          <MetaSKUConverter />
        </Suspense>
    )
}
export const MetaSKUUpscaleLazy = () => {
    return (
        <Suspense fallback={<Loading />}>
            <MetaSKUUpscale />
        </Suspense>
    )
}
export const MetaSKUSeamlessLazy = () => {
    return (
        <Suspense fallback={<Loading />}>
            <MetaSKUSeamless />
        </Suspense>
    )
}
export const MetaSKUShadowRemovalLazy = () => { 
    return (
        <Suspense fallback={<Loading />}>
            <MetaSKUShadowRemoval />
        </Suspense>
    )
}