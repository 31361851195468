import React, { useState } from "react";
import { ProfileInterface } from "ts-interfaces";
import { useStripe, useElements,PaymentElement } from "@stripe/react-stripe-js";
import { Button } from "@mui/material";
import { useLoginState } from "../studioxr/auth/LoginState.ts";
import { fetchData } from "../libs/util.ts";
import { createStripeSubscriptionAPIUrlV4, cancelStripeSubscriptionAPIUrl } from "./config.js";
import * as Sentry from "@sentry/react";
import { ProfilePrices } from "./useStripeStore.tsx";

interface CheckoutFormProps {
  onSuccess: (customerId: string, subscriptionId: string) => void;
  selectedPlan: ProfilePrices;
  promoId: string;
  getNewClientSecret: () => void;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
}
export const CheckoutForm = ({onSuccess, selectedPlan, promoId, getNewClientSecret, errorMessage, setErrorMessage}:CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<any>(errorMessage);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);

  async function handlePaymentAction(clientSecret, subscriptionData) {
    if (!stripe || !elements) { return; }

    setIsProcessing(true);
    const result = await stripe.confirmCardPayment(clientSecret);
    
    if (result.error) {
      console.error(result.error.message);
      setMessage("Error processing payment. Please try again " + result.error.message);
      setPaymentFailed(true);
      const requestCancellation = { customerId: subscriptionData.customerId };
      const cancelStripeSubscriptionData = await fetchData(requestCancellation, cancelStripeSubscriptionAPIUrl);
      // console.log('cancelStripeSubscriptionData', cancelStripeSubscriptionData);
      getNewClientSecret();
    } else if (result.paymentIntent.status === 'succeeded') {
      onSuccess(subscriptionData.customerId, subscriptionData.subscriptionId);
    }
    setIsProcessing(false);
    return;
  }  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const profile:ProfileInterface | any = useLoginState.getState().profile
    if (!stripe || !elements) { return; }
    if (isProcessing===true) { return; }
    if (!profile?.id) { 
      setMessage( "Profile does not exist. Please login.");
      Sentry.captureException("Profile does not exist. Please login."); 
      return; 
    }
    try {
      setIsProcessing(true);
      setMessage('');
      setErrorMessage('');
      const  paymentConfirmation:any = await stripe.confirmSetup({
        elements,
        redirect:'if_required',
        confirmParams: {
          return_url:`${window.location.origin}`,
        },
      });
      if(paymentConfirmation?.setupIntent?.status == "succeeded"){
          const paymentMethod = paymentConfirmation.setupIntent.payment_method;
          
          const requestSubscription = {
            email: profile?.email,        
            currency: selectedPlan.currency,    
            priceId: selectedPlan.priceID,
            paymentMethod: paymentMethod,
            promoId: promoId
          };
          try {
            const subscriptionData = await fetchData(requestSubscription, createStripeSubscriptionAPIUrlV4);
            if (subscriptionData.requiresAction) {
              await handlePaymentAction(subscriptionData.paymentIntentClientSecret, subscriptionData);
            } else {
              if(!subscriptionData.customerId || !subscriptionData.subscriptionId) {
                throw new Error(subscriptionData.error || "Error processing payment"); 
              }
              onSuccess(subscriptionData.customerId, subscriptionData.subscriptionId);
            }
          }
          catch (error) {
            console.log(error);
            Sentry.captureException("error processing payment", error)
            setMessage(error?.message);
            setErrorMessage(error?.message);
            setPaymentFailed(true);
            getNewClientSecret();
          }
      }
      else if(paymentConfirmation?.error) {      
        Sentry.captureException("error processing payment", paymentConfirmation?.error);
        setMessage(paymentConfirmation?.error?.message);
        setPaymentFailed(true);
        getNewClientSecret();
        setErrorMessage(paymentConfirmation?.error?.message);
      }
    }
    finally { setIsProcessing(false); }
  };

  return (
    <form id="payment-form" >
      <PaymentElement id="payment-element" />
      <p />
      <Button aria-label='GetYourFreeTrial' onClick={handleSubmit} sx={{fontWeight:'700',background:'blue', width:'100%'}} variant='contained' disabled={isProcessing || !stripe || !elements || paymentFailed} id="submit">
      {isProcessing ? "Processing ... " : "Start Mattoboard Pro Now"}
      </Button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}