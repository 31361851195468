import React, {useState,useMemo,useEffect } from 'react'
import { GLTFObject } from './GLTFObject';
import { produce } from 'immer'
import { getUrlFromRef } from '../../libs/firebase';
import { ProductType } from 'ts-interfaces';

/*
    Accepts a dynamic physical object, converts it into a GLTFObject. 
*/
export const DynamicPhysicalObject = ({physicalObject, onLoad, dynamicImage}) => {
    const [normalTexture, setNormalTexture] = useState<any>(null);
    const idsArray = physicalObject?.dynamicMaterialProps?.files?.split('/');
    const uid = idsArray?.[1];
    const imageId = idsArray?.[2];
    
    const resolvedPhysicalObject = useMemo(() => {
        const newPhysicalObject = {type:ProductType.NOT_STATIC,
        rotation:physicalObject.rotation, scale:physicalObject.scale, isStaticObject:false,
        url:physicalObject.url, originalScale:physicalObject.originalScale ||0.1,
        position:physicalObject.position,key:physicalObject.key, 
        meshTextureRepeat:physicalObject.meshTextureRepeat || 1, 
        materialData:{}  };
        const s =  getUrlFromRef(physicalObject.dynamicMaterialProps.files)
        const files={color_original:s}
        if (normalTexture) files['normal_original']=normalTexture;
        const newMaterialData = produce(physicalObject.materialData, draft => {  draft.files = files; })
        newPhysicalObject.materialData = newMaterialData;

        return newPhysicalObject;   
    }, [physicalObject,normalTexture, dynamicImage]);

    useEffect(() => {
        // (async () => {
        //     const data = await computeMaps(dynamicImage, imageId, uid);
        //     setNormalTexture(data.normalMap);
        // })();
    }, [dynamicImage]);     
    if (!resolvedPhysicalObject) return (null);
    return  <GLTFObject physicalObject={resolvedPhysicalObject}  onLoad={onLoad} size={'small'} />;
}
