import React, { useRef,useState,useEffect } from 'react'
import { useFrame,useThree,extend } from '@react-three/fiber'
import {defaultCameraPosition, useMattoState, useProjectData} from '../MattoState'
import { MapControls } from '../threejs/stdlib/MapControls'
import { isMobile } from 'react-device-detect';
import { useProgress } from '@react-three/drei';
extend({ MapControls })

export const CameraControls = (props) => {
  const ref = useRef();
  const count = useRef(0)

  const { camera, gl, invalidate } = useThree()
  const setSelectedObjectIsChanging = useMattoState((state) => state.setSelectedObjectIsChanging)
  const setControls = useMattoState((state) => state.setControls)
  const projectData = useProjectData()

  useEffect(() =>{ updateCamera() }, [])
  useEffect(() =>{ updateCamera() }, [projectData?.cameraMatrix])


  const updateCamera = () => {
    const controlsTarget=projectData?.controlsTarget
    if (controlsTarget) {
      const cameraMatrix=projectData?.cameraMatrix || defaultCameraPosition
      camera.matrix.fromArray(cameraMatrix)
      ref.current.target.set(controlsTarget[0], controlsTarget[1], controlsTarget[2]);
    }
    else { camera.matrix.fromArray(defaultCameraPosition) }

    camera.matrix.decompose(camera.position, camera.quaternion, camera.scale);
    camera.updateProjectionMatrix();
    ref.current.update()
    gl.shadowMap.autoUpdate=true
    gl.shadowMap.needsUpdate=true
    invalidate()
    count.current = count.current + 1
  }


  const controlParams = {
    enablePan: true,
    minZoomDistanceFromThePivot: 5.0,
    maxZoomDistanceFromThePivot: isMobile ? 130 :100,
    maxVerticalOrbitAngle: Math.PI/4.125
  };
  let stopTimeout = 0

  useEffect(()=>{ setControls(ref) },[ref])

  // useEffect( () => {
  //   camera.matrix.fromArray(defaultCameraPosition)
  //   camera.matrix.decompose(camera.position, camera.quaternion, camera.scale);
  //   camera.updateProjectionMatrix();
  //   ref.current.update()
  //   //ref.current.saveState()
  //   invalidate()
  // },[])

  useFrame(() => {  
    if (count > 0 )  ref.current.update(); 
  })

  const cameraUpdate = () => {
    gl.shadowMap.autoUpdate=false
    invalidate()
  }

  useEffect(() => {
    ref.current.addEventListener('change', cameraUpdate) 
    ref.current.addEventListener('start' , () => { 
      clearTimeout(stopTimeout);
      setSelectedObjectIsChanging(true) 
    })
    ref.current.addEventListener('end', () => {
      gl.shadowMap.autoUpdate=true
      gl.shadowMap.needsUpdate=true
      invalidate()
      clearTimeout(stopTimeout)
      stopTimeout = setTimeout( () => { setSelectedObjectIsChanging(false) },200)
    })

    return () => {
      // console.log("meeee ");
      // ref.current.removeEventListener('change', cameraUpdate)
      // ref.current.removeEventListener('start', () => { setSelectedObjectIsChanging(true) })
    }

  }, [])


  return <mapControls ref={ref}  args={[camera, gl.domElement]} enablePan={controlParams.enablePan} minDistance={controlParams.minZoomDistanceFromThePivot}
                      maxDistance={controlParams.maxZoomDistanceFromThePivot} maxPolarAngle={controlParams.maxVerticalOrbitAngle} />
}
