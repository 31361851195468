import React from "react";
import { Typography, AppBar, Toolbar, Button } from "@mui/material";
import { styled } from '@mui/system';
import { useProjectData } from '../MattoState';

const desktopDisplayOnly = {display: {xs:'none',sm: 'none', md: 'flex'}}

const TitleAppBar = styled(AppBar)(({ theme }) => ({
	boxShadow: "none", flexGrow:1,height:"35px", backgroundColor:theme.bar.backgroundColor,
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
}));
const TitleToolbar  = styled(Toolbar)({
	paddingLeft:'0px', paddingRight:'0px'
});
const TitleTypography = styled(Typography)({ color:'#000000', textAlign:'center', fontSize: '14px', fontWeight:'600', letterSpacing:'0.5px', display:'inline', flexGrow:1,whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis" })
const HoverDiv = styled('div')({ lineHeight:'10px', '&:hover':{'cursor':'pointer' },paddingLeft:'inherit'})
const BetaTypography = styled(Typography)({ color: '#737373', paddingLeft:'4px', fontStyle:'italic', fontSize:'10px', paddingTop: '6px'})
const TextTypography = styled(Typography)({ color:'#000000', textAlign:'center', fontWeight:'600', letterSpacing:'0.5px',paddingLeft:'10px', fontSize:'10px', paddingTop: '6px' })
const DesignYourOwnBtn = styled(Button)({backgroundColor:'orangered', color:'white', borderRadius:'50px',minWidth:'auto', maxHeight:'28px', marginRight: '10px', fontSize: '10px', padding: '6px 10px',fontWeight:'bold',
'&:hover':{'backgroundColor':'red'},'&:active':{'backgroundColor':'dimgray'}});


export const ProjectTitleBar = () => {
	const title = useProjectData(state=>state.title)
	const onClick = () => {
        const x = window.location.origin + '/project/new';
        window.location.replace(x)
    }
	const handleHomne = ()=> {
		window.location.href='/'
	}

	return (
		<>
			<TitleAppBar position="static" sx={{height:{xs:'30px',md:'35px'}}} >
				<TitleToolbar  sx={{minHeight:{xs:'30px',md:'35px'},height:{xs:'30px',md:'35px'}}}>
					<HoverDiv sx={desktopDisplayOnly}>
						<img  onClick={handleHomne} src='/images/mattoboard-logo.png' style={{maxWidth: '120px'}} />
					</HoverDiv>
					<BetaTypography sx={desktopDisplayOnly} >Beta</BetaTypography>	 
					<TextTypography sx={desktopDisplayOnly} >Design 3D Material Boards Online</TextTypography>
					<TitleTypography sx={{textAlign:{xs:'center',md:'left'},paddingLeft:{md:'20px'}}}>{title}</TitleTypography>
					<DesignYourOwnBtn sx={desktopDisplayOnly} onClick={onClick}>Design your own</DesignYourOwnBtn>
				</TitleToolbar>
			</TitleAppBar>
		</>
	)
}

