import React, { useEffect } from 'react'
import ContentEditable from 'react-contenteditable'
import {useMattoState,useProjectData} from '../MattoState'

function TitleEditor(props) {
  const title = useProjectData(state=>state.title)
  const setProjectData = useProjectData(state=>state.set)
  return (
    <TitleEditorC projectData={ {title:title}} setProjectData={setProjectData} />
  )
}
class TitleEditorC extends React.Component {

  static getDerivedStateFromProps(props, state) {
    if (state.html != props.projectData.title) {
      state.html = props.projectData.title
    }
    return state
  }
  constructor(props) {
    super(props)
    this.contentEditable = React.createRef();
    this.state = {html: props.projectData.title, isHovering: false };
    this.setProjectData = props.setProjectData
  };
  updateTitle = newTitle => {
    this.setState(newTitle)
    this.setProjectData(state=> { state.title = newTitle.html })

//    this.setProjectData(prevState => ({...prevState, title:newTitle.html}))
    useMattoState.setState({ isStateDirty: true })          
  }

  handleChange = evt => {       
        const v = evt.currentTarget.innerText.replace('>','')
        if (v.length > 35) { this.updateTitle(this.state) }
        else { this.updateTitle({html: v}); }
  };
  handleBlur = evt => {
        if (evt.target.innerText.length < 1) this.updateTitle( {html:"Untitled"});
        else { 
          this.updateTitle(this.state)
          document.activeElement.blur()
        }
  }
  keyDown = evt => {
        if (evt.code=='Enter') evt.target.blur();       
  }

  handleMouseEnter = () => {
    this.setState({ isHovering: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovering: false });
  };

  render = () => {
    return <ContentEditable 
              style={{
                display: this.state.isHovering ? '' : 'inline',
                cursor: this.state.isHovering ? 'pointer' : 'auto',
                border: this.state.isHovering ? '1px solid #000' : 'none'
              }}
              innerRef={this.contentEditable}
              html={this.state.html} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onChange={this.handleChange} // handle innerHTML change
              onBlur={this.handleBlur}
              onKeyDown={this.keyDown}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}      
            />
  };
};
export {TitleEditor}