import React, { useRef,useState,useEffect,useContext } from 'react'
import { AuthContext } from "../auth/Auth";
import {LightingButton} from '../buttons/LightingButton'
import {GridButton} from '../buttons/GridButton'
import {DownloadButton} from '../buttons/DownloadButton'
import {AddTextButton} from '../buttons/AddTextButton'
import {TitleEditor} from '../buttons/TitleEditor'
import {TemplateButton} from '../buttons/TemplateButton'
import {SaveButton} from '../buttons/SaveButton'
import {UndoRedoButton} from '../buttons/UndoRedoButton'
import {EnvironmentButton} from '../buttons/EnvironmentButton'
import { ResetCamera } from '../buttons/ResetCamera'
import { ShareButton } from '../buttons/ShareButton'
import {AppBar,Tooltip,Divider,Toolbar,Fade,Paper,Popover,Slider,Typography,IconButton,Button,Grid, Snackbar, Stack} from '@mui/material';
import {useMattoState} from '../MattoState'
import { styled } from '@mui/system';
import { RecordButton } from '../buttons/RecordButton';
import { MaterialSheetButton } from '../buttons/MaterialSheetButton';


export const ActionBar = () => {
	const [resetCamera, showResetCamera] = useState(false);
	const [oldSafari, setOldSafari] = useState(false);
	useEffect(() => {
		try {
			const userAgent = navigator.userAgent;
			const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
			const safariVersion = userAgent.match(/version\/(\d+)/i);
			const safariVersionNumber = safariVersion ? parseInt(safariVersion[1], 10) : null;
			if (isSafari && safariVersionNumber && safariVersionNumber < 16) {
				setOldSafari(true);
				if (window.gtag) {gtag('event', 'old_safari');}
			  	console.log('Safari version is less than 16.');
			} else {
				showResetCamera(true);
			}
		}
		catch (e) { console.log(e); }
	  }, []);

	return (
		<Stack   justifyContent="space-between"	   alignItems="center"	 style={{marginTop:'-4px',maxHeight:'40px'}}	direction={'row'} spacing={1}>
			<Stack direction="row" spacing={0} style={{maxHeight:'48px'}} sx={{display: { xs:'block',sm: 'block', md: 'block' }}}>
				<SaveButton />
				<UndoRedoButton />
				<GridButton />
				<LightingButton />
				{resetCamera && <ResetCamera /> }
			</Stack>

			<Stack direction={'row'} style={{maxHeight:'48px'}} sx={{ paddingTop:'6px', display: {xs:'none', sm:'none', md:'block'},  overflow:'hidden', maxHeight:'20px'}} >
				<TitleEditor style={{color:'black', fontWeight:'bold'}} />
			</Stack>
			<Stack direction={'row'} spacing={0} style={{maxHeight:'48px'}}  sx={{display:{xs:'none', sm:'none', md:'flex'} }}    >
				{oldSafari===false && <RecordButton/> }
				<ShareButton/>
				<DownloadButton />
				<MaterialSheetButton />
			</Stack>
			<Snackbar sx={{width:'400px'}} onClick={ ()=> { setOldSafari(false )} }  open={oldSafari} autoHideDuration={12000} message="You are using an older version of Sarai. Please upgrade to Safari 17+ or Chrome for the best exerpience." />
		</Stack>
	)

}


