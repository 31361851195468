import React, { useState } from 'react'
import useEventListener from '../libs/useEventListener'
import { Modal, Typography, Container} from '@mui/material';
import { browserName,fullBrowserVersion, osVersion, osName, isDesktop, isMobile } from 'react-device-detect';
import { useProjectData } from '../MattoState'
import { getGPUTier } from 'detect-gpu';
import { REVISION } from 'three';
import { styled } from '@mui/system';

const BoldTypography = styled(Typography)({fontWeight:600 })
const ItalicTypography = styled(Typography)({fontWeight:400, textTransform: 'capitalize' })

const PaperDiv = styled('div')(({ theme }) => ({ 
	marginTop: theme.spacing(20),display: 'flex',flexDirection: 'column',alignItems: 'left',paddingBottom:'20px', paddingTop:'20px',
}));
const InfoDiv = styled('div')(({ theme }) => ({ 
	display: 'flex',flexDirection: 'row',alignItems: 'left',
}));

export const DebuggingModalBox = (props) => {

    const [openModal, setOpenModal]=useState(false)
	const handleClose = () => setOpenModal(false);
	const projectData = useProjectData()
    const [gpuTier, setGpuTier] = useState({});
 
	const handleKeyDown = (e) =>  {
		if (e.target!=document.body) return;
		else if (e.key=='D' && e.shiftKey==true && e.ctrlKey==true){
		     openDebuggingModal(e);e.preventDefault();
             updateGPU()
	    }
	}

    const openDebuggingModal = (event) => {
        setOpenModal(true)
    }

    const updateGPU = async() => {
        let gpuTierTemp = await getGPUTier();
        setGpuTier(gpuTierTemp)
    }

	useEventListener('keydown', handleKeyDown);
	return (
		<Modal open={openModal} onClose={handleClose}>  
         <>
            <Container component="main" maxWidth="xs"  sx={{borderRadius: 2, backgroundColor: 'background.default' }}>
                <PaperDiv>
                {/* <Typography component="h5" variant="h5" style ={{paddingBottom:'10px'}}>Debugging Info</Typography> */}
                
                  <InfoDiv> <BoldTypography>Device:&nbsp;</BoldTypography>
                   <ItalicTypography>{isDesktop ? 'Desktop' : isMobile ? 'Mobile' : 'Tablet'}</ItalicTypography></InfoDiv>
                   
                  <InfoDiv> <BoldTypography>Browser:&nbsp;</BoldTypography>
                   <ItalicTypography>{browserName} {fullBrowserVersion}</ItalicTypography></InfoDiv>
                   
                  <InfoDiv> <BoldTypography>OS:&nbsp;</BoldTypography>
                   <ItalicTypography>{osName} {osVersion}</ItalicTypography></InfoDiv>
                   
                  <InfoDiv> <BoldTypography>Project ID:&nbsp;</BoldTypography>
                   <ItalicTypography>{projectData.projectId ? projectData.projectId : 'N/A'}</ItalicTypography></InfoDiv>
                     
                  <InfoDiv> <BoldTypography>threeJS Version:&nbsp;</BoldTypography>
                   <ItalicTypography>{REVISION}</ItalicTypography></InfoDiv>
                   
                  <InfoDiv> <BoldTypography>GPU Info:&nbsp;</BoldTypography>
                   <ItalicTypography>{gpuTier.gpu} {gpuTier.fps} FPS</ItalicTypography></InfoDiv>

                </PaperDiv>          
            </Container> 
            </>
		</Modal>
      
	)
}
