import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getFirestore,getDoc,updateDoc,doc,setDoc,deleteDoc,collection, getDocs, query, where} from 'firebase/firestore';
import { initializeApp } from "firebase/app";
import { getAuth,GoogleAuthProvider,signInWithPopup,sendPasswordResetEmail,confirmPasswordReset } from "firebase/auth";
import { cloudFlareImageResize, generateID, getUserLocationFromIP } from "./utils";
import { MeasuringCategory, ProfileInterface, ProfileRole } from "ts-interfaces";

const product_cache:any={}


class FirebaseHelper {
    firebaseApp: any;
    storage: any;
    db: any;
    profilesDB: any;
    productsDB: any;
    suppliersDB: any;
    fulfillersDB: any;
    pendingInvitationsDB: any;
    projectsDB: any;
    notificationsDB: any;
    notificationsViewedDB: any;
    userProductsDB: any;
    static instance: any;
    
    constructor() {
        if (!FirebaseHelper.instance) {
            FirebaseHelper.instance = this
          }
          // Initialize object
          return FirebaseHelper.instance
    }

    initializeApp = (firebaseConfig) => {
        if (this.firebaseApp) {
            console.log("Firebaseapp already initialized!"); return;
        }        
        this.firebaseApp=initializeApp(firebaseConfig);
        this.storage = getStorage(this.firebaseApp);
        this.db = getFirestore(this.firebaseApp);
        this.profilesDB = collection(this.db, 'profiles');
        this.suppliersDB = collection(this.db, 'suppliers');
        this.fulfillersDB = collection(this.db, 'fulfillers');
        this.productsDB = collection(this.db, 'productsV2');
        this.pendingInvitationsDB = collection(this.db, 'pending_invitations');
        this.projectsDB = collection(this.db, 'projects');
        this.notificationsDB = collection(this.db, 'notifications');
        this.notificationsViewedDB = collection(this.db, 'notifications_viewed');
        this.userProductsDB = collection(this.db, 'userProducts');
    }

    googleSignup = () => {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: "select_account"
        });
        provider.addScope('profile');
        provider.addScope('email');
        return signInWithPopup(getAuth(), provider)
    }

    getDocByID = (collection,id) => { return getDoc(doc(this.db, collection, id));}
     
    uploadAndSave2 =(collectionRef, recordId:any=null, userId:any=null, data:any={}, files={},fileHeaders={cacheControl:'public,max-age=31536000'}) =>{        
        userId = userId==null ? '' : '/' + userId
        return new Promise((resolve, reject) => {
            if (!collectionRef) reject("No collectionRef")
                const promises=[]               
                //promises.push(this.deleteRecordAndFiles(recordId,collection,true)); //just deleting any files.
                for (const key in files) {
                        if (files[key] instanceof Blob == false && files[key] instanceof File ==false) 
                                return reject("All files must be of type Blob or File!");                               
                        if (data.files==null ) data.files={};
                        const ext = files[key].extension || ''
                        const filename=`${collectionRef.parent.path}${userId}/${recordId}/${Date.now()}_${key}${ext}`
                        data.files[key] =  filename                        
                        console.log("putting ", filename, 'and', fileHeaders, ' and ', files[key]);                     
                        const fileRef = ref(this.storage, filename)
                        promises.push( uploadBytes(fileRef, files[key], fileHeaders) )
                        //promises.push(firebase.storage().ref().child(filename).put(files[key],fileHeaders))                   
                }
   
                Promise.all(promises).then(d=> {
                        setDoc(collectionRef, data, {merge:true})
                        .then(values=>resolve(data))
                        .catch(error=> { console.log("HI ", error);reject(error) })
                }).catch(error=>reject(error))
        })
    }

    uploadAndSave =(recordId:any=null, collection, userId:any=null, data:any={}, files={},fileHeaders={cacheControl:'public,max-age=31536000'}) =>{        
        userId = userId==null ? '' : '/' + userId
        const collectionRef = doc(this.db, collection, recordId)
        return new Promise((resolve, reject) => {
                const promises=[]               
                promises.push(this.deleteRecordAndFiles(recordId,collection,true)); //just deleting any files.
                for (const key in files) {
                        if (files[key] instanceof Blob == false && files[key] instanceof File ==false) 
                                return reject("All files must be of type Blob or File!");                               
                        if (data.files==null ) data.files={};
                        const ext = files[key].extension || ''
                        const filename=`${collection}${userId}/${recordId}/${Date.now()}_${key}${ext}`
                        data.files[key] =  filename
                        console.log("putting ", filename, 'and', fileHeaders, ' and ', files[key]);                     
                        const fileRef = ref(this.storage, filename)
                        promises.push( uploadBytes(fileRef, files[key], fileHeaders) )
                        //promises.push(firebase.storage().ref().child(filename).put(files[key],fileHeaders))                   
                }
                Promise.all(promises).then(d=> {
                        setDoc(collectionRef, data, {merge:true})
                        .then(values=>resolve(data))
                        .catch(error=> { console.log("HI ", error, userId, recordId);reject(error) })
                }).catch(error=>reject(error))
        })
    }
    deleteRecordAndFiles = (recordId,collection,skipDeletingRecord=false) => {
        const collectionRef = doc(this.db, collection, recordId)
        return new Promise((resolve, reject) => {
                const promises=[]               
                getDoc(collectionRef).then(doc => {
                        if (!doc.exists()) return resolve("no record to delete.");                                      
                        //for (var key in doc.data().files || {}) promises.push( deleteObject(ref(storage, doc.data().files[key])) )
                        if (skipDeletingRecord==false) promises.push(deleteDoc(collectionRef))
                        Promise.all(promises).then(values=> resolve(values)).catch(error=>reject(error))
                })
		        .catch((error) => { console.log("Error getting data: ", error); });          
        })
    }

  uploadFileToGCloud= (collection,id,file,fileHeaders={cacheControl:'public,max-age=31536000'}): Promise<string> => {
        return new Promise( (resolve,reject) => {
            if (!collection || !id) reject("No collection or id")
            if (file instanceof Blob == false && file instanceof File ==false) reject("All files must be of type blob or file!")		
            const extension = file?.name?.split('.')?.pop() ?? 'jpg'
            const filename =`${collection}/${id}.${extension}`
            const fileRef = ref(this.storage, filename)
             uploadBytes(fileRef, file)
                 .then(file => {  resolve(this.publicURL(file.ref.fullPath)) })
                .catch(error=>reject(error))
        })
    }

    updateRecord = (recordId, collection,data) => {
        const collectionRef = doc(this.db, collection, recordId)
        return setDoc(collectionRef, data, {merge:true})
    }

    updateFieldsInRecord = (recordId, collection, data) => {
        const collectionRef = doc(this.db, collection, recordId)
        return updateDoc(collectionRef, data);
    }

    sendResetPassword = (email) => {return sendPasswordResetEmail(getAuth(),email); } 
    resetPassword = (oobCode,password) => { return confirmPasswordReset(getAuth(),oobCode,password);}  

    getProductCache = (id,userCreated) => {
        return new Promise((resolve, reject) => {
            if (!id) reject("No id")
            if (product_cache[id]) resolve(product_cache[id])
            else {
                const collectionName = userCreated ? 'userProducts':'productsV2';
                const docRef = doc(this.db, collectionName, id)
                getDoc(docRef).then(doc => {
                    if (doc.exists())  { 
                        product_cache[id] = doc.data()
                        resolve(doc.data())                    
                    }
                    else reject("No product found! "+ id)
                })        
            }
        })
    }

    URLFromRef = (file,useCDN=false) => {
        if (!file) return;
        if (file.startsWith('/material_test')) return file;
        if (file.startsWith('http') || file.startsWith('blob') ) return file;
        if (file.startsWith("data:") || file.tagName=='IMG') return file;
        if (file.startsWith('gs://')) { file = file.replace('gs://mattoboard-b8284.appspot.com/','') }
        file.replaceAll("/", "%2F")
        if (useCDN && (file.endsWith('.jpg') || file.endsWith('.webp') || file.endsWith('.jpeg'))  ) 
                return cloudFlareImageResize(this.publicURL(file))
        else return this.publicURL(file)

    }

    publicURL(filePath) {
        return 'https://storage.googleapis.com/' + this.firebaseApp.options.storageBucket + '/'+filePath;
        //console.log(filePath);
        //console.log(this.firebaseApp.options, filePath);
        console.log("We have ",this?.firebaseApp);
        let x= null;
        try {
            x = 'https://storage.googleapis.com/' + this.firebaseApp.options.storageBucket + '/'+filePath;
        }
        catch {
            debugger
            1
        }
        return x;
        
        //return `https://firebasestorage.googleapis.com/${this.storage._bucket.bucket}/${filePath}`
    } 
}
export const firebaseHelper = new FirebaseHelper()
