import React from 'react'
import {IconButton} from '@mui/material';
import { styled } from '@mui/system';

export const ProButton = styled(IconButton)(() => ({ 
   position:'absolute',
   width: '14px',
   height: '14px',
   right:'6px',
   top: '6px',
}));


export const ProIconButton =  (props) => {
	let productId = props.id;

	return (
		<React.Fragment>
			<ProButton id={"Pro_"+ productId}>
				<div style={{position: 'absolute', zIndex:10, width: '1.7vw', height: '1.7vw', top:'1px', right: '1px', background: 'url(/images/icons/proFinalIcon.png)', backgroundSize:'contain',backgroundRepeat: 'no-repeat' }} />
			</ProButton>
		</React.Fragment>
	);	
}