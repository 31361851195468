import React, { useRef,useEffect } from 'react'
import {TransformControls } from "@react-three/drei"
import { useThree } from '@react-three/fiber'
import {useMattoState,usePhysicalObjects} from '../MattoState'

export const TransformController = () => {
	const { camera,gl,raycaster,scene,events,invalidate } = useThree();
	const transform = useRef();
	const transformMode = useMattoState((state) => state.transformMode);
	//const selectedPhysicalObject = usePhysicalObjects((state) => state.selectedPhysicalObject);
	const orbitControls = useMattoState((state) => state.controls)
	const updateObjectState = usePhysicalObjects((state)=> state.updateObjectState)
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)


	useEffect(() => {
		if (!transform?.current) return;
		const controls = transform.current
		let objectChangeCallback, mouseUpCallback,mouseDownCallback;
		controls.addEventListener("mouseDown", mouseDownCallback = (event) => {
			   orbitControls.current.enabled = false;
		});
		controls.addEventListener("mouseUp", mouseUpCallback = (event) => {
			// const threeJSObject = usePhysicalObjects.getState().getCurrentThreeJSObject()
			// if (threeJSObject==null || selectedPhysicalObject==null) {
			// 	console.log("ut oh ",threeJSObject,selectedPhysicalObject);
			// 	return
			// }
			if (selectedPhysicalObjectKey==null) {
				console.log("Trying to transform non selected object!", selectedPhysicalObjectKey);
				return
			}
			const selectedPhysicalObject = getSelectedPhysicalObject(selectedPhysicalObjectKey)
			updateObjectState(selectedPhysicalObject)
			orbitControls.current.enabled = true;

			// const newPhysicalObject = produce(selectedPhysicalObject,draftState => {
			// 	draftState.position = [threeJSObject.position.x,threeJSObject.position.y,threeJSObject.position.z]
			// 	draftState.scale = [threeJSObject.scale.x,threeJSObject.scale.y,threeJSObject.scale.z]
			// 	draftState.rotation = [threeJSObject.rotation.x,threeJSObject.rotation.y,threeJSObject.rotation.z]
			// })
			// updatePhysicalObject(newPhysicalObject)
	  	});
  	    return () => {
  	    	controls.detach()
  			controls.removeEventListener("objectChange", objectChangeCallback);
  			controls.removeEventListener("mouseUp", mouseUpCallback);
  			controls.removeEventListener("mouseDown", mouseDownCallback);
			if (orbitControls?.current?.enabled==true) orbitControls.current.enabled = true;
  	    }
	},[])
	useEffect(() => {
		if (selectedPhysicalObjectKey==null) {  transform.controls?.detach();return; }
		else {
			const threeJSObject = usePhysicalObjects.getState().getCurrentThreeJSObject()
			if (threeJSObject==null) { return }
			transform.current.attach(threeJSObject)
		}
		invalidate()
	},[selectedPhysicalObjectKey])
	useEffect(() => {
		if (transform.current) {
			transform.current.setMode(transformMode);
		}
	},[transformMode])
	return ( <TransformControls ref={transform} /> )
}
