import React, { useRef,useState,useEffect } from 'react'
import useEventListener from '../libs/useEventListener';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import {Tooltip,IconButton} from '@mui/material';
import {useMattoState, usePhysicalObjects, useProjectData} from '../MattoState'
import { invalidate }  from '@react-three/fiber'
import { produce } from 'immer'
import {BarIconButton,BarImage} from './ButtonStyles'

export const UndoRedoButton = (props) => {
	const [changes,setChanges]=useState([])
	const [redoChanges,setRedoChanges]=useState([])
	const [timerId,setTimerId]=useState(0)
	const [undoing,setUndoing]=useState(false)
	const [redoing,setRedoing]=useState(false)
	const physicalObjects = usePhysicalObjects( (state) => state.physicalObjects)
	const setPhysicalObjects = usePhysicalObjects( (state) => state.setPhysicalObjects)
	const setSelectedPhysicalObjectKey = usePhysicalObjects( (state) => state.setSelectedPhysicalObjectKey)
	const projectData = useProjectData()
	const setProjectData = useProjectData(state=>state.set)

	useEffect(() => {
		// clearTimeout(timerId)
		if (undoing==true || redoing==true) { setUndoing(false); setRedoing(false); return; }
		if ((physicalObjects.length==0 || 
			     JSON.stringify(physicalObjects) == JSON.stringify(changes[changes.length-1]?.physicalObjects))
		&& ( projectData.backgroundTexture == null ||
			JSON.stringify(projectData.backgroundTexture) == JSON.stringify(changes[changes.length-1]?.projectData?.backgroundTexture))
		 && ( projectData.envmap == null ||
			JSON.stringify(projectData.envmap) == JSON.stringify(changes[changes.length-1]?.projectData?.envmap))
		 ) return;  

			const newChangedStatePhysical = produce(physicalObjects, draft=> {}) 
			const newChangedStateProject = produce(projectData, draft=> {})

		// setTimerId(
			setTimeout(() => { 
				if (physicalObjects?.length==0 && changes.length==0) return;
				const newChanges = produce(changes, draft=> {
						if (draft.length > 10) draft.shift();                            
						draft.push({physicalObjects:newChangedStatePhysical,projectData:newChangedStateProject})
				})
				setChanges(newChanges)
			},200)
		// )
		

	},[physicalObjects,projectData.backgroundTexture,projectData.envmap])

	const handleClickUndo = (event) => {
			// clearTimeout(timerId)
			//Store the last undo change for the next redo.				
			if (Array.isArray(changes)===false) { setChanges([]); return; }	
			if (changes.length < 2) return;
			//const lastChange = changes.at(-1);
			const lastChange = changes[changes.length-1];
			const newRedoChanges = lastChange ? produce(redoChanges, draft => {
				if (draft.length > 10) draft.shift();
				draft.push(lastChange);
			}) : redoChanges;

			const newChanges = produce(changes, draft=> { draft.pop() })
			const newChangedState = changes.length <= 1 ? [] : produce(changes, draft=> { draft.splice(0,draft.length-2) })[0]

			if(props.handleTextureImageUndo) notifyUndoRedoToTextureImage(newChangedState,'undo')
			
			setChanges(newChanges)
			setRedoChanges(newRedoChanges);
			setUndoing(true)
			setSelectedPhysicalObjectKey(null)
			updateChangedState(newChangedState)
			invalidate()
	}

	const handleClickRedo = (event) => {		
		// clearTimeout(timerId)
		if(redoChanges.length==0) return;

		//Store the last redo change to the change stack.
		//const lastRedoChange = redoChanges.at(-1);
		const lastRedoChange = redoChanges[redoChanges.length-1];
		const newChanges = produce(changes, draft => {
			if (draft.length > 10) draft.shift();
			draft.push(lastRedoChange);
		});
		const newRedoChanges = produce(redoChanges, draft=> { draft.pop() })
		const newChangedState = lastRedoChange;

		if(props.handleTextureImageRedo) notifyUndoRedoToTextureImage(newChangedState,'redo')

		setChanges(newChanges)
		setRedoChanges(newRedoChanges); 
		setRedoing(true)
		setSelectedPhysicalObjectKey(null)
		updateChangedState(newChangedState)
		invalidate()
	}

	const notifyUndoRedoToTextureImage = (newChangedState,actionType) =>{
		if (physicalObjects.length != 0 && newChangedState && newChangedState?.physicalObjects?.length > 0 &&
			JSON.stringify(physicalObjects) != JSON.stringify(newChangedState?.physicalObjects) &&
			JSON.stringify(physicalObjects[0].materialData?.files) != JSON.stringify(newChangedState.physicalObjects[0].materialData?.files)
			){ 
				if(actionType == 'undo')
				props.handleTextureImageUndo()
				else props.handleTextureImageRedo()

			}
	}

	const handleKeyDown = (e) =>  {
		if (e.key=='z' && (e.metaKey==true || e.ctrlKey==true)) {  e.preventDefault(); handleClickUndo() }
		else if (e.key=='y' && (e.metaKey==true || e.ctrlKey==true)) {  e.preventDefault(); handleClickRedo() }
	}

	function updateChangedState(newChangedState){
		if(JSON.stringify(physicalObjects) != JSON.stringify(newChangedState?.physicalObjects)){	
			let physicalObjects = newChangedState?.physicalObjects ? newChangedState?.physicalObjects : []
			setPhysicalObjects(physicalObjects)	
		}
		if(JSON.stringify(projectData?.backgroundTexture) != JSON.stringify(newChangedState?.projectData?.backgroundTexture)){
			let background = newChangedState?.projectData?.backgroundTexture ? newChangedState?.projectData?.backgroundTexture : null
			setProjectData(state=> { state.backgroundTexture = background })
		}
		if(JSON.stringify(projectData?.envmap) != JSON.stringify(newChangedState?.projectData?.envmap)){
			let envMap = newChangedState?.projectData?.envmap ? newChangedState?.projectData?.envmap : null
			setProjectData(state=> { state.envmap = envMap })
		}
	}

	useEventListener('keydown', handleKeyDown);
	return (
		<>
			<Tooltip title="Undo" arrow>
			<BarIconButton
				onClick={handleClickUndo}
				aria-label="undo"
				edge='start'
				size="large">
				<UndoIcon style={{color:'black'}} />
			</BarIconButton>
			</Tooltip>

			<Tooltip title="Redo" arrow>
				<BarIconButton
					onClick={handleClickRedo}
					aria-label="redo"
					edge='start'
					size="large">
					<RedoIcon style={{color:'black'}} />
				</BarIconButton>
			</Tooltip>
		</>
    );
}
