import React, { useEffect, useState} from "react"
import {Box,Grid,Container } from '@mui/material'
import {projectsDB,getUrlFromRef} from '../../libs/firebase'
import { useNavigate } from "react-router-dom";
import { query, where,getDocs, orderBy } from "firebase/firestore";
import { cloudflareCDN } from "../../libs/util";
import { ProjectsGrid } from "./styles";


export const BrandProjectList = (props) => {
	const [data,setData]=useState([])
	const projectEditMap = props.projectEditMap;

	const loadProjects = () => {
		const a = [projectsDB,where("id", "in", props.projectIdArray), orderBy('updatedAt',"desc")]
		const q = query.apply( null, a)
		getDocs(q).then(querySnapshot => { 
			const tempProjects = []
			querySnapshot.forEach((doc) => {tempProjects.push(doc.data()) })
			setData(tempProjects)
		})	
		.catch((error) => { console.log("Error getting documents: ", error); });
	}
	useEffect(() => { 
		loadProjects() 
	  },[])

	return (
		<section>
			<Container sx={{marginLeft: "auto", marginRight: "auto", padding: '0px !important'}} >
				<Box className="projects-wrapper">
					<ProjectsGrid>						
					<Grid container>
						<Grid item xs={12}>
							<h1 className="heading-2">Showrooms</h1>
						</Grid>
						{data.map((project, index) => (
							<Grid key={index} item md={4} sm={6} xs={12} sx={{padding: "10px"}}>
								<Box className="project-item">
									<a href={window.location.origin + "/show/"+ project.id + '?ee=' + projectEditMap[project.id]}  className="project-link w-inline-block">
										<Box className="project-item-image-wrapper">
											<img src={cloudflareCDN(getUrlFromRef(project.files.screenshot),'height=240,format=auto')} loading="lazy" alt="" className="project-item-image" />
										</Box>
										<Box className="project-item-content">
											<h3>{project.projectData.title || "Untitled"}</h3>
											{/* <Box className="spacer _16"></Box> */}
											{/* <p className="paragraph-4">Designed by</p> */}
										</Box>
									</a>
									<a href={window.location.origin + "/show/"+ project.id + '?ee=' + projectEditMap[project.id] }  className="button-5 button3d w-button">View Showroom</a>
								</Box>
							</Grid>
						))}
					</Grid>
					</ProjectsGrid>
				</Box>
			</Container>
		</section>
	)

}


