import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import { TextField,Box,Typography,IconButton,Stepper,Step,StepLabel,FormControl,Select,MenuItem,InputLabel,
  Snackbar, Button, FormGroup, FormControlLabel,Checkbox} from "@mui/material";
import { Visibility as VisibilityIcon,VisibilityOff as VisibilityOffIcon,Google as GoogleIcon,Close as CloseIcon, CheckBox, Check,} from "@mui/icons-material";
import { googleSignup } from "../../libs/firebase";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useLoginState } from "./LoginState";
import { getErrorMessage } from "../helper";
import { PrimaryButton, OutlineButton } from "../Button";
import { AuthContext } from "../../auth/Auth";
import { SigninContainer } from "./styles";``
import { Stack } from "@mui/system";
import { ProfileUpload } from "../../components/ProfileUpload";
import { produce } from "immer";
import { ProfileInterface } from "ts-interfaces";


const SignupForm = ({ onSuccess }) => {
  const setShowLogin = useLoginState((state) => state.setShowLogin);
  const setOrCreateProfile = useLoginState((state) => state.setOrCreateProfile);
  const [showFlash, setShowFlash] = useState(false);
  const [flashMessage, setFlashMessage] = useState("Flash Message");
  const [emailValue, setEmailValue] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [mailingList,setMailingList] = useState(true)
  const [openSnack, setOpenSnack] = useState(false);

  const handleTogglePasswordVisibility = () => { setShowPassword((prevShowPassword) => !prevShowPassword); };
  const handleTogglePasswordVisibility2 = () => { setShowPassword2((prevShowPassword) => !prevShowPassword); };

  const onChangeEmail = (e) => { setEmailValue(e.target.value.replace(/\s/g, "")); };
  const onChangePassword = (e) => { setPassword(e.target.value); };
  const onChangePassword2 = (e) => { setPassword2(e.target.value); };
  const handleMailingList = (e) => { setMailingList(e.target.checked); };
  const [signUpButtonDetails, setSignUpButtonDetails] = useState({label:"Create Account",disable:false});
  const [signUpWithGoogleButtonDetails, setSignUpWithGoogleButtonDetails] = useState({label:"Sign Up With Google",disable:false});


    const validateSubmit = () => {
      if(!emailValue || !password || !password2){
        setFlashMessage("Please enter all the required details.");
        setShowFlash(true);
        return false;
      }
      else if (password != password2) {
        setFlashMessage("Passwords do not match!");
        setShowFlash(true);
        return false;
      }
    }
    const handleSignup = async (evt, isGoogle=false) => {
      evt?.preventDefault();
      if (isGoogle==false && validateSubmit()==false) return;
      else {
        setSignUpWithGoogleButtonDetails({label:'Signing Up...Please Wait',disable:true});
        setSignUpButtonDetails({label:'Signing Up...Please Wait',disable:true})
        setShowFlash(false);
        try {
          let data:any=null;
          if (isGoogle==true) data = await googleSignup()
          else  { 
            setOpenSnack(true);
            const auth = getAuth();
            data= await createUserWithEmailAndPassword(auth, emailValue, password) 
          }
          console.log('setOrCreateProfile called after user signup');
          const result:any = await setOrCreateProfile(data,mailingList)
          if (result?.updated==false) {
            setShowLogin(null);
            console.log("Profile already exists!");
            if (window.gtag) window.gtag("event", "signin");
          }
          else {
            if (window.gtag) window.gtag("event", "signup");
          }
          onSuccess?.()
        }
        catch (error) {
          setSignUpButtonDetails({label :"Sign Up",disable:false});
          setSignUpWithGoogleButtonDetails({label:"Sign Up With Google",disable:false});
          let errMsg = getErrorMessage(error.message)
          if (error?.code=='auth/invalid-email') errMsg ='Error: Enter a valid email address'
          errMsg = errMsg + ' - Please contact support@mattoboard.com if you need help.'
          setShowFlash(true);
          setFlashMessage(errMsg);
        }
        finally {
          setOpenSnack(false);
        }
    }
  }
  const handleSubmit = async evt => { return handleSignup(evt, false) }
  const handleGoogleSignup = async evt => { return handleSignup(evt, true) }

  return (
    <>
      {/* <Typography component="h4" variant="h5" className="login-title">
        Sign up To Get Started
      </Typography> */}
      {showFlash && 
        <Typography sx={{fontSize:'1rem'}} component="h1" color='error' align='center' variant="h5">
          {flashMessage}
        </Typography>
        }
      <div className="login-form" >
        <OutlineButton
          onClick={() => { handleGoogleSignup(null); }}
          fullWidth
          variant="outlined"
          className="google-btn"
          style={{ marginTop:'5px',marginBottom: "15px" }}
          disabled={signUpWithGoogleButtonDetails.disable}
        >
          <GoogleIcon sx={{ color: "#453B41" }} />
          <span>{signUpWithGoogleButtonDetails.label}</span>
        </OutlineButton>
        <Typography sx={{textAlign:'center'}}>or</Typography>


        <TextField
          size='small'
          variant="outlined" margin="normal" required fullWidth
          id="email" type="email" label="Email Address" name="email"
          autoComplete="email"
          value={emailValue}
          onChange={onChangeEmail}
        />
        <TextField
          size='small' variant="outlined"
          margin="normal" required fullWidth
          name="password" label="Password" id="password"
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleTogglePasswordVisibility}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            ),
          }}
          value={password}
          onChange={onChangePassword}
        />
        <TextField
          size='small'
          variant="outlined" margin="normal"
          required fullWidth
          name="password2" label="Confirm Password"
          id="password2"
          type={showPassword2 ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleTogglePasswordVisibility2}>
                {showPassword2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            ),
          }}
          value={password2}
          onChange={onChangePassword2}
        />
        <FormGroup>
          <FormControlLabel control={<Checkbox  onChange={handleMailingList} name='mailingList' defaultChecked />} label="I agree to join Mattoboard's mailing List" />
        </FormGroup>


        <PrimaryButton
          style={{ marginTop: "15px" }}
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          disabled={signUpButtonDetails.disable}
        >
          {signUpButtonDetails.label}
        </PrimaryButton>

        <Box className="signup-link" sx={{paddingTop:'15px'}}>
        <Typography className="signup-text">
          Already have an account?
        </Typography>        
        <button 
          style={{color:'blue',fontWeight:'400'}}
          className="signup-btn"
          onClick={() => setShowLogin("signin")}
        >
          Sign in
        </button>

      </Box>
      <Box>
      <Typography variant="caption" className="signup-text">
          By signing up you agree to the <a href='https://blog.mattoboard.com/terms-of-use' target='_'>Terms of Service and Privacy Policy</a>
      </Typography>
      </Box>


      </div>
      <Snackbar
		    anchorOrigin={{ vertical: 'bottom', horizontal: 'left',}}
		    open={openSnack}
		    onClose={ () => { setOpenSnack(false)}}
		    autoHideDuration={6000}
		    message="Signing Up.."
		  />
    </>
  );
};

export const ProfileForm = ({ initialProfile, onSuccess }) => {
  const { currentUser }  = useContext(AuthContext);
  const [pic, setPic] = useState({});
  const [showFlash, setShowFlash] = useState(false);
  const [submitButtonDetails, setSubmitButtonDetails] = useState({label:initialProfile ? "Save changes" : "Next",disable:false});
  const [flashMessage, setFlashMessage] = useState("");
  const profile:any = useLoginState((state) => state.profile);
  const updateAndSaveProfile = useLoginState((state) => state.updateAndSaveProfile);

  const formik = useFormik({
    //validationSchema: formSchema,
    initialValues: {
      firstName: initialProfile?.first_name ||  profile?.first_name ||  "",
      lastName: initialProfile?.last_name ||  profile?.last_name || "",
      companyName: initialProfile?.company_name || "",
      companySize: initialProfile?.company_size || "",
      industry: initialProfile?.industry || "",
      measuringCategory: initialProfile?.measuring_category || initialProfile?.measuringCategory || profile?.measuringCategory || "Imperial",      
      specialization: initialProfile?.specialization || "",
      occupation: initialProfile?.occupation || "",
    },
    onSubmit: values => {  },
    enableReinitialize: true,
  });

  const {
    errors, handleChange,handleBlur,isValid,
    setFieldValue,touched,values,
  } = formik;

  const isFormTouched = Object.values(touched).some(
    (fieldTouched) => fieldTouched
  );

  const handleProfilePicChange = (blob) => {
    if (blob) setPic({pic:blob})
  };

  const handleSubmit = () => {
    if(!values.industry){
      setFlashMessage("Please enter industry");
      setShowFlash(true);
      return;
    }
    if (!values.specialization) {
      setFlashMessage("Please enter specialization");
      setShowFlash(true);
      return;
    }
    if ( (!values.companySize)  && values.companySize.length > 0 ) {
      setFlashMessage("Please enter company size");
      setShowFlash(true);
      return;
    }
    else if (!values.companySize) {
      values.companySize = "1-5";
    }
    if ( (!profile?.id) || (!profile?.uid)) {
      throw new Error("Profile not found");
    }
    if (profile.uid != currentUser.uid) { 
      throw new Error("Profile id does not match current user id");
    }
    setFlashMessage("");
    setShowFlash(false);
    setSubmitButtonDetails({label:'Saving...Please Wait',disable:true})

    const updatedProfile:ProfileInterface = produce(profile as ProfileInterface, (draft) => {
      draft.first_name = values.firstName;
      draft.last_name = values.lastName;
      draft.company_name = values.companyName;
      draft.company_size = values.companySize;
      draft.industry = values.industry;
      draft.specialization = values.specialization;
      draft.occupation = values.occupation;
      draft.measuring_category = values.measuringCategory;
    }) 
    console.log("now i go here?", updatedProfile);
    
    updateAndSaveProfile(updatedProfile,pic).then((resp) => {
      onSuccess?.(resp);
    }).catch((error) => {
      console.log("noooo ", error);
      setSubmitButtonDetails({label:initialProfile ? "Save changes" : "Next",disable:false})
    })
  };

  if (!profile?.id) return (null)
  return (
    <>
      <Typography component="h1" variant="h5" className="login-title">
        {initialProfile ? 'Edit your profile' : 'Enter additional info'}
      </Typography>
      <div className="login-form" >
      {showFlash && 
        <Typography component="h6" color='error' align='center' variant="h5">
          {flashMessage}
        </Typography>
        }

        <Stack alignItems="stretch">
        <Stack direction='row'>

        <TextField
          variant="outlined" margin="normal" sx={{paddingRight:'10px'}}
          size='small' id="firstName" type="text"
          label="First name" name="firstName"
          value={values.firstName} onChange={handleChange}
        />
        <TextField
          variant="outlined" margin="normal"
          size='small' id="lastName" type="text"
          label="Last name" name="lastName"
          value={values.lastName} onChange={handleChange}
        />
        </Stack>
        <TextField
          variant="outlined"  margin="normal" fullWidth
          size='small'  id="companyName" type="text"
          label="Company name" name="companyName"
          value={values.companyName}
          onChange={(e) => setFieldValue("companyName", e.target.value)}
        />

        <Stack direction='row'>
        <FormControl required fullWidth  sx={{paddingRight:'10px'}} className="custom-select">
          <InputLabel id="company-size-label" sx={{marginTop:'-5px'}}>
            Size of company
          </InputLabel>
          <Select
            size='small' labelId="company-size-label"
            id="company-size" label="Size of company"
            value={values.companySize}
            onChange={(e) => setFieldValue("companySize", e.target.value)}
          >
            <MenuItem value={"1-5"}>1-5</MenuItem>
            <MenuItem value={"6-20"}>6-20</MenuItem>
            <MenuItem value={"20+"}>20+</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth required className="custom-select">
          <InputLabel id="industry-label"  sx={{marginTop:'-5px'}} >
            Industry
          </InputLabel>
          <Select
            size='small' labelId="industry-label" id="industry"
            label="Industry" value={values.industry}
            onChange={(e) => setFieldValue("industry", e.target.value)}
          >
            <MenuItem value={"Architecture"}>Architecture</MenuItem>
            <MenuItem value={"Interior Design"}>Interior Design</MenuItem>
            <MenuItem value={"Graphics"}>Graphics</MenuItem>
            <MenuItem value={"Fashion"}>Fashion</MenuItem>
            <MenuItem value={"Real Estate"}>Real Estate</MenuItem>
            <MenuItem value={"Construction"}>Construction</MenuItem>
            <MenuItem value={"Manufacturer/Distributor"}>Manufacturer/Distributor</MenuItem>
            <MenuItem value={"Set/Stage/Theatre Design"}>Set/Stage/Theatre Design</MenuItem>
            <MenuItem value={"Product Design"}>Product Design</MenuItem>
            <MenuItem value={"Design Hobbyist"}>Design Hobbyist</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Select>
        </FormControl>
        </Stack>

        <Stack direction='row'>
        <FormControl fullWidth required sx={{paddingRight:'10px'}} className="custom-select">
          <InputLabel id="company-specialization-label" sx={{marginTop:'-5px'}}>
            Specialization
          </InputLabel>
          <Select
            size='small' labelId="company-specialization-label"
            id="company-specialization" label="Specialization"
            value={values.specialization}
            onChange={(e) => setFieldValue("specialization", e.target.value)}
          >
            <MenuItem value={"Residential Architecture"}>Residential Architecture</MenuItem>
            <MenuItem value={"Residential Architecture & Interior Design"}>Residential Architecture & Interior Design</MenuItem>
            <MenuItem value={"Commercial Architecture"}>Commercial Architecture</MenuItem>
            <MenuItem value={"Commercial Interior Design"}>Commercial Interior Design</MenuItem>
            <MenuItem value={"Commercial & Residential Architecture"}>Commercial & Residential Architecture</MenuItem>
            <MenuItem value={"Commercial & Residential Interior Design"}>Commercial & Residential Interior Design</MenuItem>
            <MenuItem value={"Commercial & Residential Architecture & Interior Design"}>Commercial & Residential Architecture & Interior Design</MenuItem>
            <MenuItem value={"Hospitality/Retail Architecture"}>Hospitality/Retail Architecture</MenuItem>
            <MenuItem value={"Hospitality/Retail Interior Design"}>Hospitality/Retail Interior Design</MenuItem>
            <MenuItem value={"Institution/Education"}>Institution/Education</MenuItem>
            <MenuItem value={"Transportation"}>Transportation</MenuItem>
            <MenuItem value={"Technology"}>Technology</MenuItem>
            <MenuItem value={"Workplace"}>Workplace</MenuItem>
            <MenuItem value={"Stage/Set/Theatre"}>Stage/Set/Theatre</MenuItem>
            <MenuItem value={"Multi Discipline/Specialization"}>Multi Discipline/Specialization</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth  className="custom-select">
          <InputLabel id="occupation-label"  sx={{marginTop:'-5px'}} >
            Occupation
          </InputLabel>
          <Select
            size='small' labelId="occupation-label" id="occupation"
            label="Occupation" value={values.occupation}
            onChange={(e) => setFieldValue("occupation", e.target.value)}
          >
            <MenuItem value={"Business Owner"}>Business Owner</MenuItem>
            <MenuItem value={"Executive Employee"}>Executive Employee</MenuItem>
            <MenuItem value={"Design/Admin Employee"}>Design/Admin Employee</MenuItem>
            <MenuItem value={"Sales Employee"}>Sales Employee</MenuItem>
            <MenuItem value={"Student"}>Student</MenuItem>
            <MenuItem value={"Hobbyist"}>Hobbyist</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Select>
        </FormControl>
        </Stack>

        <FormControl fullWidth required className="custom-select">
          <InputLabel id="measuringCategory-label" >
            Units
          </InputLabel>
          <Select
            size='small' labelId="measuringCategory-label" id="measuringCategory"
            label="measuringCategory" value={values.measuringCategory}
            onChange={(e) => setFieldValue("measuringCategory", e.target.value)} >
            <MenuItem value={"Metric"}>Metric (meters)</MenuItem>
            <MenuItem value={"Imperial"}>Imperial (inches)</MenuItem>
          </Select>
        </FormControl>

        <ProfileUpload onFileChange={handleProfilePicChange} img={initialProfile?.files?.pic} />
      
        <PrimaryButton
          style={{ marginTop:'10px', marginBottom: "38px" }}
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          disabled={submitButtonDetails.disable}
          aria-label="submit-btn"
        >
          {submitButtonDetails.label}
        </PrimaryButton>

        </Stack>
      </div>
    </>
  );
};

const SuccessView = ({ onSuccess }) => {
  const redirectTo = sessionStorage.getItem("redirectTo");
  const complete = () => {
    if(redirectTo) { window.location.href = redirectTo; return; }
    onSuccess && onSuccess(); 
  };

  return (
    <>
      <Typography component="h1" variant="h5" className="login-title" sx={{paddingBottom:'10px'}}>
        Registration was successful!
      </Typography>
      <div className="login-form">
        <div className="success-second-text">
          Now you are ready to create. So don't wait, let's get started!
        </div>

        <PrimaryButton fullWidth variant="contained" onClick={complete}>
          Let’s go
        </PrimaryButton>
      </div>
    </>
  );
};

export const SignUp = (props) => {
  const setShowLogin = useLoginState((state) => state.setShowLogin);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["First step", "Second step", "Welcome!"];
  const profile:ProfileInterface | any = useLoginState((state) => state.profile);

  return (
    <SigninContainer>
      <IconButton sx={{zIndex:'100',margin:'-15px'}} onClick={() => setShowLogin(null)} className="close-btn"> 
        {activeStep!= 1 && <CloseIcon /> }
      </IconButton> 
      <Box className="form-root signup" style={activeStep === 3 ? { display: "flex" } : {}} >
        <Box className="stepper-root">
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        <div>
          {/* <img src={'/images/mattoboard-logo.jpg'} alt="MattoBoard" className="logo-img" /> */}
          {activeStep === 0 && (
            <SignupForm onSuccess={() => { setActiveStep(1); ; sessionStorage.setItem("redirectTo", '/project/new_from?id=sbsdwnpss9&welcome=true'); }  } />
          )}
          {activeStep === 1 && profile?.uid &&  (
            <ProfileForm initialProfile={profile} onSuccess={() => setActiveStep(3)} />
          )}
          {activeStep === 3 && (
            <SuccessView
              onSuccess={() => {
                setShowLogin(null);
                // window.location.reload();
              }}
            />
          )}
        </div>
      </Box>

    </SigninContainer>
  );
};

//q46misn7rv