import React, { useEffect, useState } from "react"
import { query, where, getDocs, orderBy, limit } from "firebase/firestore";
import { NotificationsNone } from "@mui/icons-material";
import { notificationsDB, notificationsViewedDB, updateRecord} from "../libs/firebase"
import { useLoginState } from "../studioxr/auth/LoginState.js";
import { generateID } from "../libs/util.js";
import Badge from '@mui/material/Badge';
import { styled } from "@mui/system";
import { NotificationsInterface, NotificationsViewedInterface, ProfileInterface } from "ts-interfaces";
import { BarIconButton } from '../buttons/ButtonStyles';
import { Divider, List, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

const StyledBadge = styled(Badge)(() => ({
	'& .MuiBadge-badge': {
	  right: 2,
	  top: 2,
	  width: '14px',
	  height: '14px',
	  minWidth: '12px',
	  fontSize: '10px',
	  backgroundColor: '#EB0014'
	}
}));
const StyledMenu = styled(Menu)(() => ({
	'& .MuiMenu-list': {
	  backgroundColor: '#e8e8e8'
	}
}));

export default function Notifications() {
	const profile:ProfileInterface | any = useLoginState((state) => state.profile)
	const [notifications, setNotifications] = useState<NotificationsInterface[]>([]);
	const [notificationsViewed, setNotificationsViewed] = useState<NotificationsViewedInterface[]>([]);

    useEffect(() => {
        fetchNotifications();
	},[]);

    const fetchNotifications = async () => {
		if(notifications.length > 0 || profile==null) return;
		
		// get notifications from firebase collection called notifications		
		const q = query(notificationsDB as any, orderBy('updatedAt', 'desc'), limit(3));
		const notificationDoc = await getDocs(q);

		const q2 = query(notificationsViewedDB as any, where('user_id', '==', profile.uid), orderBy('updatedAt', 'desc'));
		const notificationViewedDoc = await getDocs(q2);

		if (notificationDoc.docs.length > 0) { 
			const notifications_list = notificationDoc.docs.map((doc) => doc.data() as NotificationsInterface);
			setNotifications(notifications_list);
		}
		if (notificationViewedDoc.docs.length > 0) { 
			const notifications_viewed = notificationViewedDoc.docs.map((doc) => doc.data() as NotificationsViewedInterface);
			setNotificationsViewed(notifications_viewed);
		}
		
	  };
	  const handleNotificationsClick = () => {
		// set all notifications as viewed
		const notifications_list = notifications.filter((notification: NotificationsInterface) => {
			return !notificationsViewed.find((viewed: NotificationsViewedInterface) => viewed.notification_id === notification.id);
		});
		// set notifications viewed in firebase
		notifications_list.forEach((notification: NotificationsInterface) => {
			const data =	{
				id: generateID(),
				notification_id: notification.id,
				user_id: profile.uid,
				updatedAt: Date.now()
			};
			updateRecord(data.id, 'notifications_viewed', data).then(() => {
				// push data to notifications viewed
				setNotificationsViewed([...notificationsViewed, data]);

			}).catch((error) => {
				console.error("Error updating notifications viewed: ", error);	
			});
		});
    };
	return (
		<ShowNotifications 
			notificationsAll={notifications}
			notificationsViewed={notificationsViewed}
			handleNotificationsClick={handleNotificationsClick} />
	);

}

export function ShowNotifications({ notificationsAll, notificationsViewed, handleNotificationsClick }) {

	const [badgeCount, setBadgeCount] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null)
	const showNotifications = Boolean(anchorEl);

	useEffect(() => {
		const notifications_list = notificationsAll.filter((notification: NotificationsInterface) => {
			return !notificationsViewed.find((viewed: NotificationsViewedInterface) => viewed.notification_id === notification.id);
		});
		const notificationsToShow = notifications_list.slice(0, 3);
		setBadgeCount(notificationsToShow.length);

	}, [notificationsAll]);
	const handleClick = (link: string) => {
		window.open(link, '_blank'); // Open the link in a new tab
		handleClose();
	};
	const handleClose = () => { setAnchorEl(null); };
	const handleIconClick = (event: any) => {
		setAnchorEl(event.currentTarget); 
		setBadgeCount(0);
		handleNotificationsClick();
	}
	if (notificationsAll.length === 0) return null;
	return (
		<>
			<BarIconButton sx={{ display:{xs:'none',sm: 'inline'} }} id="notificationsIconBtn" onClick={handleIconClick}>
				<StyledBadge id="notificationsBadgeCount" badgeContent={badgeCount} color="primary" style={{cursor: 'pointer'}}>
					<NotificationsNone style={{color:'black', fontSize:'28px'}}/>
				</StyledBadge>
			</BarIconButton>

			{showNotifications && (
				<StyledMenu
					id="notificationsMenu"
					anchorEl={anchorEl}
					open={showNotifications}
					onClose={handleClose}
				>
				{notificationsAll.map((notification, index) => (
					<MenuItem key={notification.id} sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', width: '350px', maxWidth: '100%', padding: '0px'}}
						onClick={(e) => handleClick(notification.link)}>
						<List sx={{display: 'flex', flexDirection: 'row', paddingLeft: '20px', paddingRight: '20px'}}>
							<ListItemIcon sx={{marginTop: '4px', color: 'black'}}>
								<AddCircleIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText sx={{marginLeft: '-4px'}}>
								<Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '16px', fontWeight: '400', overflowWrap: 'break-word', whiteSpace: 'normal'}}>
									{notification.title}
								</Typography>
								<Typography sx={{ fontSize: '12px', color: '#3B69CF', cursor: 'pointer', textAlign: 'left', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
									{notification.text}
								</Typography>
							</ListItemText>
						</List>
						{index < notificationsAll.length - 1 && <Divider sx={{marginTop: '2px !important', marginBottom: '2px !important', color: 'white', width: '100%'}} />}
					</MenuItem>
				))}
				</StyledMenu>
			)}    
		</>
	);
	
}