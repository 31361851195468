import React, {useContext, useState} from 'react'
import {createSvgIcon, Grid} from '@mui/material'
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import ViewCompactOutlinedIcon from '@mui/icons-material/ViewCompactOutlined';
import LocalFloristOutlinedIcon from '@mui/icons-material/LocalFloristOutlined';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import {SubheaderTypography,SidebarItemBox} from './TabStyles'
import {useMattoState} from '../MattoState'
import { SideBarMode } from '../ts/app_interfaces';
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import { AuthContext } from "../auth/Auth";
import { useLoginState } from "../studioxr/auth/LoginState";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import TextFormatIcon from '@mui/icons-material/TextFormat';

const MyMaterialsIcon = createSvgIcon(
	<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
		width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
		preserveAspectRatio="xMidYMid meet">

		<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
		fill="currentColor" stroke="none">
		<path d="M539 4667 c-24 -13 -57 -43 -74 -66 l-30 -43 -3 -1982 c-2 -2199 -8
		-2020 65 -2090 20 -20 52 -41 72 -47 24 -7 671 -9 2012 -7 l1976 3 39 27 c21
		15 50 44 64 65 l25 37 0 1997 0 1997 -30 43 c-16 23 -50 53 -74 66 l-44 23
		-1977 0 -1977 0 -44 -23z m1318 -1079 c398 -398 723 -727 723 -733 0 -5 -91
		-101 -202 -212 l-203 -203 -677 677 -678 678 0 257 0 258 157 0 158 0 722
		-722z m600 465 l-257 -258 -257 258 -258 257 515 0 515 0 -258 -257z m1053
		-308 l-565 -565 -202 202 -203 203 362 362 363 363 405 0 405 0 -565 -565z
		m83 -1287 c-406 -406 -743 -738 -748 -738 -6 0 -100 90 -210 200 l-200 200
		250 250 c137 138 254 250 260 250 6 0 318 308 695 685 l685 685 3 -397 2 -398
		-737 -737z m-1043 -1036 c0 -4 -90 -97 -200 -207 l-200 -200 -665 665 -665
		665 0 408 0 408 865 -866 c476 -476 865 -869 865 -873z m1780 811 l0 -398
		-517 -517 -518 -518 -405 0 -405 0 915 915 c503 503 918 915 922 915 5 0 8
		-179 8 -397z m-2937 -1431 l-407 -2 -83 82 -83 83 0 410 0 410 490 -490 490
		-490 -407 -3z m2937 228 l0 -230 -237 0 -238 0 230 230 c126 127 233 230 237
		230 5 0 8 -103 8 -230z"/>
		</g>
	</svg>, 'My Materials'
);

const categories = [
					// {name:'Products', mode:SideBarMode.Products},
					{name:'Objects', mode:SideBarMode.Objects},
					// {name:'Products', mode:SideBarMode.Accents},
					{name:'Accessories', mode:SideBarMode.Accents},
					{name:'Paints', mode:SideBarMode.Paints},
					{name:'Collections', mode:SideBarMode.Collections},
					{name:'Templates', mode:SideBarMode.Templates},
					{name:'Labels', mode:SideBarMode.Text},
					// {name:'Environment', mode:SideBarMode.Environment},
					{name:'Images', mode:SideBarMode.Uploads},		
					{name:'My Materials', mode:SideBarMode.YourMaterials}	
					]
//					{name:'Background', mode:SideBarMode.Background},


const getCategoryIcon = (categoryName) => {
	switch (categoryName) {
		case 'Objects':  return <WidgetsOutlinedIcon/>;
		case 'Collections': return <FolderOpenRoundedIcon/>;
		// case 'Environment': return <InsertPhotoOutlinedIcon/>;
		// case 'Products': return <CategoryOutlinedIcon/>;
		case 'Templates': return <ViewCompactOutlinedIcon/>;
		case 'Background': return <WallpaperIcon/>;
		case 'Labels': return <TextFormatIcon/>;
		case 'Accessories': return <LocalFloristOutlinedIcon sx={{ fontSize: 26 }}/>;
		// case 'Products': return <ShoppingBagRoundedIcon />;
		case 'Images': return <ImageOutlinedIcon sx={{fontSize:26}} />;
		case 'My Materials': return <MyMaterialsIcon sx={{fontSize:26}} />;
		case 'Paints': return <ColorLensIcon sx={{fontSize:26}}/>;
		default: return null;
	}
}

export const SideBar = (props) => {
	const sideBarMode = useMattoState((state) => state.sideBarMode)
    const setSideBarMode = useMattoState((state) => state.setSideBarMode);
	const setShowLogin = useLoginState((state) => state.setShowLogin);

	const { currentUser } = useContext(AuthContext);
    const handleClick = (sideBarMode) => { if(sideBarMode == SideBarMode.Collections && !currentUser?.uid) { setShowLogin('signup'); return; } else setSideBarMode(sideBarMode);};

	return (
		<Grid container spacing={1} >
			<Grid item xs={12} style={{paddingLeft:'8px', position:'relative'}}>
				{categories.map((category,index)=>(
					<SidebarItemBox key={index} onClick={() => handleClick(category.mode)} className={sideBarMode == category.mode ? 'selected':''}>
						{getCategoryIcon(category.name)}
						<SubheaderTypography>{category.name}</SubheaderTypography>
					</SidebarItemBox>
				))}
			</Grid>
	  </Grid>
	)
}
