import React, { useRef,useEffect,useState, useContext } from 'react'
import { useFormik } from "formik";
import { ProductInterface, MaterialData, TopSuppliers, validateProduct, ProductType, ObjectStatusType } from 'ts-interfaces';
import { Avatar, Button, Chip, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { generateID } from 'ui';
import AuthModal  from '../studioxr/auth/AuthModal';
import { updateRecord, uploadFileToGCloud } from '../libs/firebase';
import { produce } from 'immer';
import { MaterialCategory } from '../../../../packages/ts-interfaces';
import Autocomplete from '@mui/material/Autocomplete';
import { materialCreator } from './MaterialCreator';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { getPreset,presetMaps } from 'ui';
import * as Sentry from "@sentry/react";
import { AuthContext } from '../auth/Auth';
import { base64ToBlob } from './../libs/util';
import { ActionButton } from '../buttons/ButtonStyles';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const textFieldProps:any = { variant: "outlined", margin: "normal", size: 'small', fullWidth: true, type: "text", sx: { paddingRight: '10px', width:'98%', marginTop: '8px' }}

export const PaintCreator = ({incomingProduct, onSavedProduct}) => {
    const { currentUser } = useContext(AuthContext);
    const modalState = materialCreator(state=>state.modalState)
    const setModalState = materialCreator(state=>state.setModalState)
    const saving = materialCreator(state=>state.saving)
    const setSaving = materialCreator(state=>state.setSaving)
    const [errorMessage,setErrorMessage] = useState(false)
    const [product,setProduct] = useState<ProductInterface | any>({})
    const  showModal = modalState=='paint_metadata'
    const [isHovered, setIsHovered] = useState(false);

    const formik = useFormik({
        initialValues: {name:  'New Paint', url: '', brand: '', color:'#ffffff', preset: Object.keys(presetMaps)[0], category: MaterialCategory.PAINT},
        onSubmit: values => {  },
    });

    useEffect( () => {
        if (saving==true || (!incomingProduct) || incomingProduct?.uid==null) return;             
        else { 
            formik.initialValues.name = incomingProduct.name || 'Material'
            if (incomingProduct.category?.[0]) formik.initialValues.category = incomingProduct.category?.[0]
            formik.initialValues.brand = incomingProduct.metadata?.brand || ''
            formik.initialValues.url = incomingProduct.metadata?.url || ''           
            formik.initialValues.color = incomingProduct.materialData?.materialProps?.color ? `#${incomingProduct.materialData?.materialProps?.color.toString(16)}` : '#fff' 
            formik.initialValues.preset = incomingProduct.materialData?.preset || Object.keys(presetMaps)[0]
            setProduct(incomingProduct) 
        }
    },[incomingProduct])

    async function handleSubmit() {
        if (formik.values.name.length < 3) return alert("Please enter a name for your paint")   
        setSaving(true)
        try {
            if (currentUser?.uid==null) throw new Error("not logged in")

            const isNewProduct = product?.id==null
            const id = product?.id || generateID()
            const colorInt = parseInt(formik.values.color.replace(/^#/, ''), 16); 
            let paintUrl = product?.materialData?.files?.color_original

            if(!paintUrl) {
                //create canvas with color
                const canvas = document.createElement('canvas');
                canvas.width = 100;
                canvas.height = 100;
                const ctx:any = canvas.getContext('2d');
                ctx.fillStyle = formik.values.color;
                ctx.fillRect(0, 0, canvas.width, canvas.height);            
                const dataUrl = canvas.toDataURL('image/jpeg'); // Get base64 string of the image
                const base64Data = dataUrl.split(',')[1]; // Remove the data URL scheme part
                const blob = base64ToBlob(base64Data, 'image/jpeg'); // Convert to Blob

                // save canvas image to firebase storage
                paintUrl = await uploadFileToGCloud(`userImages/${currentUser.uid}/${id}`, id, blob)
            }

            const materialData = {
                files: { color_original: paintUrl},
                id:id, materialProps: { color: colorInt},
                name: formik.values.name, preset: formik.values.preset,
                presets: ['behr-flat', 'behr-matte', 'behr-eggshell-enamel', 'behr-satin-enamel', 'behr-semi-gloss-enamel', 'behr-high-gloss-enamel']
            }
            const newProduct2:ProductInterface = produce(product as ProductInterface, draft => {
                draft.name = formik.values.name;                                
                draft.materialData = materialData
                if (!draft.metadata) draft.metadata={};
                draft.metadata.brand = formik.values.brand;
                draft.metadata.url = formik.values.url;
                draft.productType = ProductType.PAINT;
                draft.objectStatus=ObjectStatusType.USER_CREATED;
                if (!draft.id) draft.id =  id
                if (!draft.uid) draft.uid = currentUser.uid;
                draft.isStaticObject=true;
                draft.updatedAt = Date.now();
                draft.primaryColorInt = colorInt

            })
            console.log(newProduct2);
            validateProduct(newProduct2)
            setProduct(newProduct2)

            const result = await updateRecord(newProduct2.id,'userProducts',newProduct2)            
            if (window.gtag && isNewProduct==true) window.gtag("event", "custom_paint_created");
            console.log("saved! ", result);
            onSavedProduct(newProduct2)
        }
        catch (error) {
            console.log("oops!!",error);
            Sentry.captureException(error);
            setErrorMessage(true)
        }
        finally {
         setSaving(false)
         setModalState(null)
        }
    }
    const handleBrandChange = (_, value) => { formik.setFieldValue('brand', value); };
    const handleClose = () => { setModalState(null) }
    const handlePresetChange = (presetKey) => { formik.setFieldValue('preset', presetKey); }

    if (!showModal ) return (null)
    else {
        return (            
            <AuthModal open={showModal} onClose={handleClose}>
                <Stack sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
                        <DialogTitle sx={{ paddingLeft: '0px', fontWeight: 'bold' }}>Paint Details</DialogTitle>
                        <a 
                            href="https://www.youtube.com/watch?v=PFOoo9dt5Sk" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            style={{ textDecoration:'none' }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <Grid sx={{ 
                                display: 'flex', flexDirection: 'row', 
                                background: (theme) => isHovered ? theme.palette.primary.shades[200] : theme.palette.primary.shades[100],
                                color: 'white', padding: '2px 8px', borderRadius: '4px'
                            }}>
                                <PlayCircleIcon fontSize="small" />
                                <Typography sx={{ fontSize: '14px', paddingLeft: '4px' }}>Tutorial</Typography>
                            </Grid>
                        </a>
                    </Grid>
                    
                    <Grid sx={{ display: 'flex', flexDirection: 'row', padding: '12px', alignItems: 'center', justifyContent: `${incomingProduct == null ? '' : 'center'}`  }}>
                        {incomingProduct==null && 
                            <HexColorPicker style={{paddingRight: '12px'}} color={formik.values.color} onChange={color => formik.setFieldValue('color', color)} />
                        }
                        {formik.values.color && <div style={{ background:formik.values.color, borderRadius:'10px', height:'130px', width:'130px'}} /> }
                    </Grid>

                    <Grid sx={{ display: 'flex', flexDirection: 'row', marginTop: '12px', marginBottom: '12px' }}>
                        <Typography sx={{ fontWeight: 'bold', paddingRight: '10px', marginLeft: '20px' }}> HEX:</Typography>
                        {incomingProduct==null &&
                            <HexColorInput color={formik.values.color} onChange={color => formik.setFieldValue('color', color)} />
                        }
                        {incomingProduct!==null &&
                            <Typography sx={{ fontWeight: 'bold', letterSpacing: '1px' }}> {formik.values.color} </Typography>
                        }
                        </Grid>

                    <Stack direction="row" spacing={1} style={{paddingTop:'20px', paddingBottom: '12px'}} alignItems="center" justifyContent="center">
                        {/* <img width={64} src={getImage()} style={{borderRadius:'13px'}} /> */}
                        <TextField  {...textFieldProps} id="name"  label="Paint Name" name="name" value={formik.values.name} onChange={formik.handleChange} />
                    </Stack>
                <Presets selectedPreset={formik.values.preset } onChange={handlePresetChange} />              

                <Autocomplete
                    id="brand"
                    freeSolo
                    value={formik.values.brand}
                    onChange={handleBrandChange}
                    options={Object.values(TopSuppliers)}
                    renderInput={(params) => <TextField {...params} {...textFieldProps} label="Brand Name" placeholder='Brand Name (Optional)' name="brand" value={formik.values.brand} onChange={formik.handleChange} />}
                />

                <TextField  {...textFieldProps} id="url" label="URL" placeholder="URL (Optional)" name="url" value={formik.values.url} onChange={formik.handleChange} />
                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', padding:'10px', marginTop: '20px', marginBottom: "20px" }}>
                    <ActionButton disabled={saving} style={{ width: '150px' }} variant="contained" onClick={handleSubmit}>
                    {saving ? "Saving..." : "Save Paint"}
                    </ActionButton>
                    <Button 
                        onClick={handleClose} 
                        variant="outlined"
                        sx={{
                            width: '100px',
                            height: '30px',
                            background: 'white',
                            borderColor: (theme) => theme.palette.primary.shades[250],
                            '&:hover': { background: (theme) => theme.palette.primary.shades[100], borderColor: (theme) => theme.palette.primary.shades[250], color: (theme) => theme.palette.primary.light }
                        }}
                    >
                        Close
                    </Button>
                </Grid>
                </Stack>
            </AuthModal>

        )
    }
}
function Presets({selectedPreset,onChange}) {
        return (
        <Grid container sx={{ marginTop: '8px', marginBottom: '8px' }}>
        {Object.keys(presetMaps).map((presetKey) => {
            const variant = selectedPreset == presetKey ? 'outlined' : 'text'
            const preset = getPreset(presetKey)
            return (
                <Grid key={preset.name} item xs={6}>
                    <Button 
                        size='small'
                        startIcon={ <Avatar sx={{width:'25px',height:'20px'}} src={preset.thumbnail} /> }
                        sx={{ justifyContent:'left', width:'100%',
                        background: selectedPreset == presetKey    ? 'white' :'' } }  
                        variant={variant} key={presetKey} 
                        onClick={() => onChange(presetKey)}>
                            {preset.name}
                        </Button>                    
                </Grid>
            )
        })}
        </Grid>
    )
}



