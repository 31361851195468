import React, { useRef,useState,useMemo,Suspense,useEffect } from 'react'
import { useGLTF} from '@react-three/drei'
import {PhysicalMaterial} from '../PhysicalMaterial'
import {getUrlFromRef} from '../../libs/firebase.js'
import { mergeGeometries } from '../stdlib/BufferGeometryUtils';
import { useBVH } from '../../components/useBVH';
import { PhysicalObjectInterface } from '../../ts/app_interfaces';
import { calculateRepeatModifier } from '../../libs/util';
import { MaterialData } from '../../../../../packages/ts-interfaces';

export const GLTFObject = (props) => {
	if (props.physicalObject?.version==2 && props.physicalObject?.isStaticObject==true) {
		return ( <Suspense fallback={null} ><StaticObject2 {...props} /></Suspense>)
	}
	else {
		return ( <Suspense fallback={null} ><AsyncGLTFObject {...props}  /></Suspense> )
	}
}
interface AsyncGLTFProps {
    physicalObject: PhysicalObjectInterface;
    size: string;
	textureRepeatModifier?:number
	onLoad: () => void;
}
export const AsyncGLTFObject = ( {physicalObject, size='small',onLoad}:AsyncGLTFProps) => {
	const gltfFile = physicalObject.mesh?.web_sized_glb || physicalObject.url || physicalObject.files?.[size] || physicalObject.files?.draco_normal
	const gltf:any =  useGLTF(getUrlFromRef(gltfFile))
	const meshes = useMemo(() => {	
		const entries:any = Object.entries(gltf.nodes)
		return entries.filter( i => i[1].type=='Mesh' ).map( m => m[1])
			.slice(physicalObject.meshIndex ?? 0, (physicalObject.meshIndex==null) ? 1000 : physicalObject.meshIndex+1)
			.map(m=>m.clone())
	},[gltf])
	const [loaded,setLoaded]=useState(false)
	const ref:any = useRef()
	useBVH(ref)

	// console.log("Async ", physicalObject);
	const textureRepeatModifier = calculateRepeatModifier(physicalObject)

	let isSingleMesh = physicalObject.mergeGeo==true || (meshes.length==1 && meshes[0].material?.map==null)
	if (physicalObject.isStaticObject==true) isSingleMesh = false

	const geometry = useMemo(() => {
		if (!meshes || isSingleMesh==false) {  setLoaded(true); return null; }
		if (physicalObject.mergeGeo) {
			const geos:any=[]
			gltf.scene.traverse(obj => {if (obj.isMesh) {  geos.push(obj.geometry) }})
			const merged =  mergeGeometries(geos)
			merged.center()
			setLoaded(true); 
			return merged
		}
		else { meshes[0].geometry?.center(); setLoaded(true); return meshes[0].geometry }
	},[meshes])

	useEffect(() =>  {  if (onLoad && loaded==true) { onLoad() }  }, [loaded])
	
	const scaledMesh = !(physicalObject.scale?.[0] == physicalObject.scale?.[1] && physicalObject.scale?.[0] == physicalObject.scale?.[2])
	if (isSingleMesh) {
		return (
			<mesh ref={ref} castShadow receiveShadow  geometry={geometry} >
				<PhysicalMaterial  material={physicalObject.materialData as MaterialData}  scaledMesh={scaledMesh} textureRepeatModifier={textureRepeatModifier} />
			</mesh>
		)
	}
	else if (physicalObject.version==2) {
		return (
			<primitive ref={ref} castShadow receiveShadow  object={gltf.scene.clone()} dispose={null} />
		)
	}
	else {
		return (
			<group ref={ref}>
			  {meshes.map((mesh, meshIndex) => (
				<primitive
				castShadow = {mesh.name.includes('inside') ? false : true} 
				receiveShadow = {mesh.name.includes('inside') ? false : true} 
				object={mesh}  key={meshIndex} dispose={null} />
					))}	
			</group>
		)
	}
	return (null)
}


const StaticObject2 = ( {physicalObject, size='web_sized_glb',onLoad}:AsyncGLTFProps) => {			
	// const gltf:any =  useGLTF(physicalObject.mesh?.web_sized_glb || getUrlFromRef(physicalObject.mesh?.[size] || physicalObject.url))
	const gltf:any =  useGLTF(getUrlFromRef(physicalObject.mesh?.[size] || physicalObject.url || physicalObject.mesh?.full_sized_glb))
	const clone = useMemo(() => gltf.scene.clone(), [gltf])
	const [loaded,setLoaded]=useState(false)
	const ref = useRef()
	useBVH(ref)
	useEffect(() => {
		if (ref.current && loaded==false) {
			setLoaded(true)
			clone.traverse( m => { if (m.isMesh) { m.castShadow=true; m.receiveShadow=true; }} )
			onLoad()
		}
	}, [ref])
	return ( <primitive ref={ref} castShadow receiveShadow  object={clone} dispose={null} /> )
}



