import React, { useEffect, useState,useContext, useMemo } from "react";
import { Typography } from "@mui/material";
import SearchInput from "../../components/SearchInput";
import { OutlineButton } from "../../components/Button";
import { PopoverWrapper } from "./styles";
import CollectionBookmarkGrid from "../../components/CollectionBookmarkGrid";
import { useMattoState } from "../../../MattoState";
import { CollectionInterface, CollectionUserImages, CollectionUserMaterials } from "ts-interfaces";
import { gtagViewItemEvent } from "../../../libs/util";
import useEventListener from "../../../libs/useEventListener";
import { useLoginState } from "../../auth/LoginState";
import { ActionButton } from "../../../buttons/ButtonStyles";
import { SearchBox } from "../../../components/SearchBox";

export const CollectionPopover = ({
  product, userImage, userMaterial, currentUserUid, popoverRef, popoverPosition, toggleCollection,showNewCollectionModal,onClose,
  ...rest
}) => {

  const [searchValue, setSearchValue] = useState("");
  const collections = useLoginState( (state) => state.collections)  
  const toggleProductInCollection = useLoginState( (state) => state.toggleProductInCollection)
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

  const calculatePopupSize = () => {
    if (popoverRef.current) {
      const width = popoverRef.current.offsetWidth;
      const height = popoverRef.current.offsetHeight;
      calculatePopupPosition(width, height);
    }
  }
  useEffect(() => { calculatePopupSize(); }, [])
  useEffect(() => { useLoginState.getState().getMoreCollections() },[])
  useEventListener('keydown', (event) => {  if (event.key === 'Escape') onClose(false) })

  const collectionToggle = async (collectionId, product, userImage, userMaterial) => {
    if (product) gtagViewItemEvent(product)
    if (window?.gtag) window.gtag('event', 'toggle_collection')
    const selectedCollection:CollectionInterface = collections.filter(( (collection:any) => collection.id == collectionId))?.[0];
    await toggleProductInCollection(collectionId, product?.id, product?.src, userImage as CollectionUserImages, userMaterial as CollectionUserMaterials)
    useMattoState.getState().setSnackbarMessage(`${product?.name || userImage?.name || userMaterial?.name} updated in ${selectedCollection.name}`);
    onClose?.() 
  } 

  const calculatePopupPosition = (popupWidth, popupHeight) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    let adjustedX = popoverPosition.x;
    let adjustedY = popoverPosition.y;

    // Check if the popup overflows on the right side of the screen
    if (popoverPosition.x + popupWidth > viewportWidth) {
        adjustedX = popoverPosition.x - popupWidth;
        if(adjustedX < 0) adjustedX = 0;
    }
    // Check if the popup overflows on the bottom of the screen
    if (popoverPosition.y + popupHeight > viewportHeight) {
        adjustedY = popoverPosition.y - popupHeight;
        if(adjustedY < 0) adjustedY = 0;
    }
    setPopupPosition({ x: adjustedX, y: adjustedY });
  };

  useEffect(() => {
    if(popupPosition.x > 0 && popoverRef.current?.style) {
      popoverRef.current.style.visibility = 'visible';
    }
  }, [popupPosition]);

  const createNewCollection = () => {
    onClose(false);
    useMattoState.getState().setModal({name:'collection',id:null, product:product, userImage:userImage, userMaterial:userMaterial});    
  }

  const handleClickOutside = (event) => {
    if (popoverRef?.current && !popoverRef.current.contains(event.target)) {
      onClose(false);
    }
  };
  useEventListener('mousedown', handleClickOutside);
  const handleSearch = (val) => void setSearchValue(val);
  
  const collectionFilter = useMemo(() => {
    if (searchValue?.length < 1) return collections;
    return collections.filter((collection:CollectionInterface) => {
      return collection.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [searchValue, collections]);

  if (!useLoginState.getState().profile) return null;
  return (
    <>
      <PopoverWrapper data-testid="collection-popover" sx={{top:popupPosition.y, left:popupPosition.x, position: 'fixed', visibility: 'hidden' }} ref={popoverRef} {...rest} id='collectionPopover'>
          <Typography style={{margin:'6px 9px', fontFamily: "Roundo-Regular", fontSize: "15px", fontWeight:'800'}} className="title">Add To Collection</Typography>
            <SearchBox onChange={handleSearch} placeholder="Search..." onBlur={() => {}}  />
          <div className="collection-list">
            {collectionFilter.length ? (
              collectionFilter.map((collection:any, index) => {
                return (
                  <CollectionBookmarkGrid key={index} product={product} userImage={userImage} userMaterial={userMaterial} collection={collection} toggleCollection={collectionToggle}  />
                );
              })
            ) : (
              <div className="no-collections">
                <Typography>No Matching Collections</Typography>
              </div>
            )}
          </div>
          <ActionButton variant="contained" onClick={createNewCollection}>
            Create New Collection
          </ActionButton>
      </PopoverWrapper>
      </>
  );
};
export default CollectionPopover;
