import { firebaseHelper } from './firebase';

export const generateID = () =>  { return Array.from(Array(10), () => Math.floor(Math.random() * 36).toString(36)).join(''); }
export const hashCode = (s:string) => {return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0); }

const cloudFlareEndpoint='https://api.mattoboard.uk?'
export const cloudFlareImageResize =  (imageURL:string,format='auto',maxWidth?:number,maxHeight?:number) =>{ 
    if (imageURL?.startsWith('http://localhost')) return imageURL
    let i = 'image=' + encodeURI(imageURL);
    i += '&format=' + format
    if (maxWidth) i += '&width=' + maxWidth
    if (maxHeight) i += '&height=' + maxHeight
    return cloudFlareEndpoint + i
}
export function rgb_to_hex(rgb: { r: number; g: number; b: number; }){
    return "#"
            + ("0" + rgb.r.toString(16)).substr(-2)
            + ("0" + rgb.g.toString(16)).substr(-2)
            + ("0" + rgb.b.toString(16)).substr(-2)
}
//Using Geoapify to determine user location.
export async function getUserLocationFromIP(){
    return new Promise((resolve, reject) => {
        const requestOptions = { method: 'GET',};
        let country = null;
        fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=94fc430c15c343cc93b732537277a0cc", requestOptions)
            .then(response => response.json())
            .then(result => { console.log(result); country = result?.country?.names?.en; resolve(country)})
            .catch(error => console.log('error', error));
    })
}

export const titleCase = str => str == null ? null : `${str[0]?.toUpperCase()}${str.slice(1).toLowerCase()}`

export const cloudflareCDN = (url,format) => { 
    if (url?.startsWith('http://localhost')) return url
    if (url?.startsWith('meshes') || url?.startsWith('gltfs')) return firebaseHelper.URLFromRef(url)
    const s = format.split(',')    
    let i = 'image=' + encodeURI(url);
    s.forEach(x=>{ i += '&' + x?.trim() })
    const u = 'https://api.mattoboard.uk?' + i
    return u
}

export const cloudflareCDN2 = (url,format) => { return 'https://mattoboard.uk/cdn-cgi/image/'+format+'/'+url; }

export const unflattenObject = (data) => {
    if (Object(data) !== data || Array.isArray(data))
        return data;
    const regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
        resultholder = {};
    for (const p in data) {
        let cur = resultholder,
            prop = "",
            m;
        while (m = regex.exec(p)) {
            cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
            prop = m[2] || m[1];
        }
        cur[prop] = data[p];
    }
    return resultholder[""] || resultholder;
};

export function imagetoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);      
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]      
    var ab = new ArrayBuffer(byteString.length);      
    var ia = new Uint8Array(ab);      
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], {type: mimeString});
    return blob;        
}