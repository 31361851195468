import React from 'react'
import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';


const DeleteIconBtn = styled(IconButton)(() => ({ 
	height:'fit-content',
	position:'absolute',
	right:'0px',
	top:'0px',
	zIndex: 1
	}))

export default function DeleteIconButton(props) {
	return (
		<React.Fragment>
			<DeleteIconBtn id={props.id +'delIcon'} onClick={props.onClick} style={{visibility:'hidden'}} >
				<HighlightOffOutlinedIcon  fontSize="small"/>
			</DeleteIconBtn>
		</React.Fragment>
    );
}


