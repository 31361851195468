import React, { useRef,useEffect,useState } from 'react'
import { useFormik } from "formik";
import { TopSuppliers, UserImageInterface } from 'ts-interfaces';
import { create } from 'zustand';
import { Button, DialogTitle, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { uploadImage } from '../libs/util';
import { cloudflareCDN, generateID } from 'ui';
import AuthModal  from '../studioxr/auth/AuthModal';
import { getPublicURL, uploadAndSave } from '../libs/firebase';
import { produce } from 'immer';
import * as Sentry from "@sentry/react";
import Autocomplete from '@mui/material/Autocomplete';
import { ActionButton } from '../buttons/ButtonStyles';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useMattoState } from '../MattoState';

const textFieldProps:any = { variant: "outlined", margin: "normal", size: 'small', fullWidth: true, type: "text", sx: { paddingRight: '10px', width:'98%', marginTop: '8px' }}

export const imageCreator = create( ( set:any,get:any) => ({
    modalState:null,
    saving:false,
    imageData: {name: '', uid: '', url: '', description: '', brand: '', canvas: null, dragged:false},
    setModalState: (modalState)=> set({modalState}),
    setSaving: (saving)=> set({saving}),
    setImageData: (imageData)=> set({imageData})
}))

export const ImageCreatorMetadataModal = ({incomingUserImage, onSavedImage, onCloseModal}) => {
    const modalState = imageCreator(state=>state.modalState)
    const setModalState = imageCreator(state=>state.setModalState)
    const saving = imageCreator(state=>state.saving)
    const setSaving = imageCreator(state=>state.setSaving)
    const imageData: any = imageCreator(state=>state.imageData);
    const [errorMessage,setErrorMessage] = useState(false)
    const [userImage, setUserImage] = useState<UserImageInterface>();
    const [isHovered, setIsHovered] = useState(false);
    const  showModal = modalState=='image_metadata'

    const formik = useFormik({
        initialValues: {name:  imageData.name, url: '', brand: '', description: ''},
        onSubmit: values => {  },
    });

    const handleBrandChange = (_, value) => {
        formik.setFieldValue('brand', value);
      };

    useEffect( () => {
        if (saving==true || (!incomingUserImage) || incomingUserImage?.uid==null) return;             
        else { 
            formik.initialValues.name = incomingUserImage.name || ''
            formik.initialValues.description = incomingUserImage.description || ''
            formik.initialValues.brand = incomingUserImage?.brand || ''
            formik.initialValues.url = incomingUserImage?.url || ''            
            setUserImage(incomingUserImage) 
        }
    },[incomingUserImage])

    async function handleSubmit() {
        setSaving(true)
		useMattoState.setState({ isUploading:true })
        try {
            const isNewUserImage = !(userImage?.files?.file)
            if(isNewUserImage) {
                const userImage2 = createUserImage(imageData.uid, formik.values.name, formik.values.description, formik.values.brand, formik.values.url);
                setUserImage(userImage2);
                uploadImage(userImage2.name, imageData.canvas, "image/png", userImage2.uid, userImage2.description, userImage2.brand, userImage2.url).then( async (result:any) =>{
                    onSavedImage(userImage2, imageData.dragged, result.files.file, imageData.canvas.width,imageData.canvas.height);
                    setSaving(false)
                }).catch(err=>{
                })
            } else {
                // update existing user image
                const userImage2 = produce(userImage, draft => {
                    draft.name = formik.values.name;
                    draft.description = formik.values.description;
                    draft.brand = formik.values.brand;
                    draft.url = formik.values.url;
                });
                const result = await uploadAndSave(userImage2.id, 'userImages', userImage.uid, userImage2);
                setUserImage(userImage2);
                onSavedImage(userImage2, false);
                setSaving(false)
                console.log("saved! ", result);
            }
        }
        catch (error) {
            console.log("oops!!",error);
            Sentry.captureException(error);
            setErrorMessage(true)
            setSaving(false)
        }
    }
    const handleClose = () => { setModalState(null); onCloseModal(); }

    const getImage = () => {  
        if(imageData.url) return imageData.url
        else if(incomingUserImage?.files?.file) {
            const storageUrl = getPublicURL(incomingUserImage.files.file);
    		return cloudflareCDN(storageUrl, 'width=130,format=auto') 
        }
        return null
    }

    if (!showModal) return (null)
    else {
        return (
        <>
        <AuthModal open={showModal} onClose={handleClose}>
            <Stack sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
                    <DialogTitle sx={{ paddingLeft: '0px', fontWeight: 'bold' }}>Image Details</DialogTitle>
                    <a 
                        href="https://www.youtube.com/watch?v=pYOkE0dE-RM&list=PL8nH4c8pjbq6eN-B4NwcAP9pMCdnM--i9&index=11" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ textDecoration:'none' }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <Grid sx={{ 
                            display: 'flex', flexDirection: 'row', 
                            background: (theme) => isHovered ? theme.palette.primary.shades[200] : theme.palette.primary.shades[100],
                            color: 'white', padding: '2px 8px', borderRadius: '4px'
                        }}>
                            <PlayCircleIcon fontSize="small" />
                            <Typography sx={{ fontSize: '14px', paddingLeft: '4px' }}>Tutorial</Typography>
                        </Grid>
                    </a>
                </Grid>

                <Stack direction="column" spacing={1} style={{ paddingTop:'8px' }} alignItems="center" justifyContent="center">
                    <img width={130} src={getImage()} style={{ borderRadius:'10px', marginBottom: '20px' }} />
                </Stack>

                <TextField  {...textFieldProps} id="name"  label="Image Name" name="name" value={formik.values.name} onChange={formik.handleChange} />

                <TextField  {...textFieldProps} id="description"  label="Description" name="description" multiline rows={3} value={formik.values.description} onChange={formik.handleChange} placeholder="Image Description (Optional)" />

                <Autocomplete
                    id="brand"
                    freeSolo
                    value={formik.values.brand}
                    onChange={handleBrandChange}
                    options={Object.values(TopSuppliers)}
                    renderInput={(params) => <TextField {...params} {...textFieldProps} label="Brand Name" name="brand" placeholder="Brand Name (Optional)" value={formik.values.brand} onChange={formik.handleChange} />}
                />

                <TextField  {...textFieldProps} id="url" label="URL" name="url" placeholder="URL (Optional)" value={formik.values.url} onChange={formik.handleChange} />

                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', padding:'10px', marginTop: '20px', marginBottom: "20px" }}>
                    <ActionButton disabled={saving} style={{ width: '150px' }} variant="contained" onClick={handleSubmit}>
                    {saving ? "Saving..." : "Save Image"}
                    </ActionButton>
                    <Button 
                        onClick={handleClose} 
                        variant="outlined"
                        sx={{
                            width: '100px',
                            height: '30px',
                            background: 'white',
                            borderColor: (theme) => theme.palette.primary.shades[250],
                            '&:hover': { background: (theme) => theme.palette.primary.shades[100], borderColor: (theme) => theme.palette.primary.shades[250], color: (theme) => theme.palette.primary.light }
                        }}
                    >
                        Close
                    </Button>
                </Grid>

            </Stack>
        </AuthModal>
        <Snackbar open={saving} message="Uploading and processing image, this can take a minute." />
        <Snackbar autoHideDuration={7000} onClose={() => setErrorMessage(false) }  open={errorMessage} message="Sorry we are having trouble uploading this.  Please let us know at support. " />

        </>
        )        
    }
}
function createUserImage(userID:string, name='', description='', brand='', url='') {
    const userImage:UserImageInterface={
        updatedAt: Date.now(),
        uid: userID,
        id: generateID(),
        name: name, 
		description: description,
		brand: brand,
		url: url
    }
    return userImage;
}
