import React, { useMemo,useRef, useState,useEffect } from 'react'
import { Grid, IconButton, Slider, Tooltip, Typography,Divider,ButtonGroup,Button } from '@mui/material';
import {useMattoState,usePhysicalObjects,useProjectData} from '../MattoState'
import { PhysicalObjectInterface, InspectorMode } from '../ts/app_interfaces';
import { ObjectStatusType, ProductType } from 'ts-interfaces';
import { TabButton } from '../buttons/ButtonStyles';

export const MaterialHeader = () => {
    const selectedPhysicalObject:PhysicalObjectInterface | any = usePhysicalObjects(state=>state.selectedPhysicalObject)
    const headerText = useMemo(() => {
        if ( selectedPhysicalObject?.type==ProductType.CANVAS) return 'Objects'
        return selectedPhysicalObject ? `${selectedPhysicalObject.name}` : 'Floor Material'
    },[selectedPhysicalObject])

    return (
        <>
        <Grid id="MaterialHeader" container style={{overflow:'hidden'}} >
            <Grid item xs={12} sx={{ maxHeight: (theme:any) => theme.bar.height }}
                style={{display:'flex', alignItems:'center',justifyContent:'center', paddingTop:'9px'}}> 
            <Typography style={{paddingRight:'5px', paddingTop:'7px',textOverflow:'ellipsis', textAlign:'center',fontSize:'0.95em'}}>{headerText}</Typography>
            </Grid>
            <InspectorGroup /> 
        </Grid>
        </>
    )
}

export const InspectorGroup = () => {
    const selectedPhysicalObject:PhysicalObjectInterface | any = usePhysicalObjects(state=>state.selectedPhysicalObject)
    const isSelectedObjectStatic = usePhysicalObjects(state=>state.isSelectedObjectStatic)
    const isSelectedObjectDynamic = usePhysicalObjects(state=>state.isSelectedObjectDynamic)
    const isSelectedObjectPaint = usePhysicalObjects(state=>state.isSelectedObjectPaint)
    const showPropertiesOnly = isSelectedObjectStatic()!=false && isSelectedObjectDynamic()!=true && isSelectedObjectPaint()!=true
    const setInspectorMode = useMattoState((state) => state.setInspectorMode);
    const inspectorMode:any = useMattoState((state) => state.inspectorMode);
    const handleClick = (insepctorMode:InspectorMode) => { setInspectorMode(insepctorMode) };
    const showDynamicObjectSelector = !!isSelectedObjectDynamic()

    useEffect(() => { 
        if (selectedPhysicalObject==null) {
            setInspectorMode(InspectorMode.Materials)
        }
        else  {
            let inspectorModeVar = showPropertiesOnly ? InspectorMode.Properties : showDynamicObjectSelector ? 
            ((inspectorMode == InspectorMode.Materials || inspectorMode == InspectorMode.CanvasObject) ? InspectorMode.Objects : null):
            ((inspectorMode == InspectorMode.Objects || inspectorMode == InspectorMode.CanvasObject) ? InspectorMode.Materials : null)
            if (selectedPhysicalObject.type==ProductType.CANVAS) inspectorModeVar = InspectorMode.CanvasObject    
            if (selectedPhysicalObject.type==ProductType.NOT_STATIC && inspectorMode !== InspectorMode.Properties) inspectorModeVar = InspectorMode.Materials
            inspectorModeVar!= null && setInspectorMode(inspectorModeVar)  
        }
    },[selectedPhysicalObject?.key])

    if (selectedPhysicalObject?.type==ProductType.CANVAS) return (
        <div style={{paddingTop:'20px'}}> </div>
    )

    return (
        <Grid container item xs={12} sx={{ justifyContent:'center', maxHeight: (theme:any) => theme.bar.height }} >
            <ButtonGroup fullWidth={true} style={{paddingRight:'5px',paddingTop:'4px'}} variant='text'  aria-label="outlined button group">
                {showPropertiesOnly==false && !showDynamicObjectSelector && <TabButton className={inspectorMode==InspectorMode.Materials ? 'selected' : ''} onClick={ () => handleClick(InspectorMode.Materials)} >{isSelectedObjectPaint() == true ? 'Paints' : 'Materials'}</TabButton> }
                {showPropertiesOnly==false && showDynamicObjectSelector && <TabButton className={inspectorMode==InspectorMode.Objects ? 'selected' : ''} onClick={ () => handleClick(InspectorMode.Objects)} >Shapeshift</TabButton> }
                <TabButton className={inspectorMode==InspectorMode.Properties ? 'selected' : ''} onClick={ () => handleClick(InspectorMode.Properties)} >Properties</TabButton>
            </ButtonGroup>
        </Grid>
    )
    
}