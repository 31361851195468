import React, { useContext,useState,useEffect } from 'react'
import {  Button, Grid, IconButton, Snackbar, Tooltip } from "@mui/material"
import { useMattoState, useProjectData } from '../MattoState';
import { useLoginState } from '../studioxr/auth/LoginState';
import { AuthContext } from '../auth/Auth';
import { ExportCurrentScene } from '../components/ExportCurrentProject';
import { addToQueue } from './DeepRenderData';
import { Vector2 } from 'three';
import { ActionButton } from '../buttons/ButtonStyles';

export const DeepRenderButton = ({onClose,format,backgroundType, cropDefinition}) => {
    const {currentUser} = useContext(AuthContext);    
    const [clicked,setClicked] = useState(false)
	const setShowLogin = useLoginState((state) => state.setShowLogin);

    const handleDeepRender = (jsonStr) => {
        try {
            if (clicked!=true) return;
            if (!currentUser?.uid) { setShowLogin('signup'); return; }
            // onClose("You need to be Pro to for more renders")
            const size:any = new Vector2()
            window.gl.getSize(size)
            const json = JSON.parse(jsonStr)
            json['width'] = parseInt(size.x)
            json['height'] = parseInt(size.y)

            const r = 2000 / size.x 
            json['width'] = 2000
            json['height'] = parseInt((size.y * r).toString()) // Convert the result to a string

            json['samples'] = 100
            json['format'] = format
            json['transparent'] = backgroundType == 'Transparent' ? true : false
            json['crop'] = cropDefinition ? cropDefinition : null
           
            // const blob = new Blob([JSON.stringify(json)],{type: 'text/json' })
            // blob.extension='.json'
            // const link = document.createElement('a');
            // link.style.display = 'none';
            // link.href = URL.createObjectURL( blob );
            // link.download = 'scene.json';
            // link.click();
            // return
                
            const projectData = useProjectData.getState()
                if (!projectData?.projectId) {
                    useMattoState.getState().setSnackbarMessage("Please Save Your Project First! Then try again.")
                    onClose?.("Please Save Your Project First! Then try again.")
                    return;
                }     
                onClose?.("Your render is processing.  You will be notified when it is done.")
                useMattoState.getState().setSnackbarMessage("Starting Render")
                addToQueue(currentUser,projectData.projectId, JSON.stringify(json)).then( () => {
                    if (window.gtag)  window.gtag('event','deep_render_request')         
                    useMattoState.getState().setSnackbarMessage("Your Deep Render is processing.  You will be notified when it is done.")
                })
                .catch( (err) => {
                    if (err=='Requires Pro') {
                        useMattoState.getState().setSnackbarMessage('')
                        useMattoState.getState().setShowSubscription(['startTrial', 'You have used all of your free Deep Renders! Sign up for Pro for Unlimited Deep Renders.']); 
                    }
                    else {
                        console.log(err);
                        useMattoState.getState().setSnackbarMessage("Notice! " + err)
                        onClose?.("Notice:" +  err)
                    }

                })
                .finally( () => { setClicked(false); })
        }
        finally { setClicked(false) }
    }
    return (
        <Grid sx={{width: '300px'}}>
        <Tooltip title="A very high quality ray traced Result. Can take 10-15min for the finished product. Best for professional use." arrow>
            <ActionButton sx={{padding: '6px'}} variant="contained" onClick={ ()=> { setClicked(true)}}>Deep Render Beta (Professional Quality)</ActionButton>
        </Tooltip>
        <ExportCurrentScene doExport={clicked===true} onExport={handleDeepRender}/>
        </Grid>
    )
}


