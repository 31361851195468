import React, { useEffect, useRef,useState } from 'react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {  Grid, Modal, Paper, Step, StepLabel, Stepper } from '@mui/material';
import { Stack } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useMattoState } from '../MattoState';
import { ModalMode } from '../ts/app_interfaces';
import { cloudflareCDN } from '../libs/util';


const videos = [
    {
        title:'How to Get Started',
        desc: [
            "Drag and drop an object or material onto the board",
            "While the object is selected, click on your desired material",
            "While the material is selected, click on your desired object",
        ],
        url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/drag-drop.mp4',
        thumbnail:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/drag-drop.jpg',
    },
    {
        title:'Save, Download & Share Your Project',
        desc: [
            "Name the project, click the 'Save' icon when you are happy with your project",
            "Press 'Download' for a high quality render",
            "Click 'Share' and then 'Copy Link' to share with others in 3D"
        ],
        url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/name-save-share2.mp4',
        thumbnail:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/save-download-compressed.webp',
    },
    {
        title:'Experience Materials with Light and Shadow',
        desc: [
            "Click the 'Lighting' icon to move the light across the board",
            "You can also edit light intensity and adjust color temperature",
        ],
        url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/move-light-compressed.mp4',
        thumbnail:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/move-light-compressed.webp',
    },
    {
        title:'Add Paint to your Moodboard',
        desc: [
            "Drag and drop a paint object onto the board",
            "While the paint object is selected, click on your desired paint shade",
            "Change paint finish in the properties menu"
        ],
        url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/add-paint-compressed.mp4',
        thumbnail:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/add-paint-compressed.webp',
    },
    {
        title:'Filter your Search And Discovery',
        desc: [
            "Use the 'Search' function or 'Filter' menu or the combination to find your desired materials and accessories"
        ],
        url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/search-filter-compressed.mp4',
        thumbnail:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/search-filter-compressed.webp',
    },
    {
        title:'Scale and Rotate Objects',
        desc: [
            "Use the Toolbar or Properties Menu to change dimensions and angles of objects and accessories"
        ],
        url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/scale-rotate-compressed.mp4',
        thumbnail:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/scale-rotate-compressed.webp',
    },
    {
        title:'Change Views of your Moodboard',
        desc: [
            "To move around the board, click the floor and drag",
            "To change the camera angle of your board, hold the SHIFT key (or right click the mouse) and click and move the floor"
        ],
        url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/view-board-compressed.mp4',
        thumbnail:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/videos/view-board-compressed.webp',
    }
]



export const HelpCenter = () => {
    const modal = useMattoState((state) => state.modal);
    const setModal = useMattoState((state) => state.setModal);
    const handleClose = () => { setModal(null); }
    useEffect(() => {
        if (modal==ModalMode.HelpCenter) {
            if (window.gtag) window.gtag('event', 'show_help_center');        
        }
    },[modal])


    if (modal!=ModalMode.HelpCenter) return (null)
    return (
        <Modal open={modal==ModalMode.HelpCenter} onClose={handleClose} >
        <Paper elevation={3} style={{  position:'absolute',maxHeight:'80%', overflow:'scroll', width:'80%', padding:'20px', borderRadius:'20px', top:'10%', left:'10%'}} >
        <Stack style={{paddingTop:'10px'}} justifyContent={'space-between'} direction={'row'}>
            <Typography style={{fontWeight:'900'}}  variant="h4">Help Center</Typography>
            <Button  onClick={handleClose}  color="primary"><CloseIcon /></Button>
        </Stack>
        <Grid style={{padding:'10px'}} container spacing={2}>
        {videos.map((label,index) => {
            return (
            <Grid key={index} item xs={4} spacing={2} >
            <Stack spacing={3} key={index}   justifyContent="center"  alignItems="center">
                <Typography style={{fontWeight:'900'}} variant="h6">{videos[index].title}</Typography>
                <img style={{height:'200px', width:'200px', borderRadius:'20px'}} src={ cloudflareCDN(videos[index].thumbnail,'width=500,format=auto') }  />
                <Typography variant="caption">{videos[index].desc}</Typography>
            </Stack>
            </Grid>
        );
        })}
        </Grid>

        </Paper>
        </Modal>
    )
}


export const WelcomeSplash = () => {

    return (
        <Paper style={{zIndex:'100', position:'absolute', top:'0px',  borderRadius:'20px', width:'100%', height:'100%'}} >
            <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
                <Typography  variant="h5" >Welcome to Mattoboard</Typography>
                <Typography>Loading Creator</Typography>
            </Stack>
        </Paper>
    )
}

const onboardingCSS = {
    display: {sm:'block', xs:'none'},
    position:  'fixed',
    width: '450px',
    padding:'20px',
    borderRadius:'20px',
    top:'15%',
    right:'20%',
    height: '500px'
}

export const Onboarding = () => {
    const [activeStep, setActiveStep] = useState(0);
    const modal = useMattoState((state) => state.modal);
    const setModal = useMattoState((state) => state.setModal);
    const handleClose = () => { setActiveStep(0); setModal(null); }

    const handleNext = () => {
        if (activeStep >= videos.length-1) { setModal(null); return; }
        if (window.gtag) window.gtag('event', 'show_welcome_page_step_'+activeStep);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };    
    const handleBack = () => {
        if (activeStep==0) return;
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        if (modal==ModalMode.Welcome) {
            if (window.gtag) window.gtag('event', 'show_welcome_page');        
        }
    },[modal])


    if (modal!=ModalMode.Welcome) return (null)
    return (
        <Paper elevation={3} sx={onboardingCSS} >
            <Stack spacing={1} display={'flex'} flexDirection={'column'} height={'100%'}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography style={{fontWeight:'900', minHeight:'40px', fontSize: '18px'}} variant="h6">{videos[activeStep].title}</Typography>
                    <Button  onClick={handleClose} color="primary" sx={{position: 'absolute', right: '10px', minWidth: '24px'}}><CloseIcon /></Button>
                </Stack>

                {videos[activeStep].desc.map((item, descIndex) => (
                    <Grid key={'grid' + descIndex} sx={{ display: 'flex', flexDirection: 'row', marginTop: '0px !important'}}>
                        <Typography sx={{fontSize: '14px', marginTop: '2px !important', paddingRight: '4px'}} key={'body'+descIndex} variant="body1">{'\u2022'}</Typography>
                        <Typography sx={{fontSize: '14px', marginTop: '2px !important'}} key={'item'+descIndex} variant="body1">{item}</Typography>
                    </Grid>
                ))}

                {/* <Typography style={{minHeight:'75px'}} variant = "body1">{videos[activeStep].desc[0]}</Typography> */}
                <Grid sx={{ marginTop: '20px', marginBottom: '10px', flexGrow: '1', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                    <video key={videos[activeStep].url} height={'300px'} autoPlay loop muted playsInline>
                        <source src={videos[activeStep].url}  type="video/mp4" />
                    </video>
                </Grid>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Stepper activeStep={activeStep}>
                        {videos.map((label,index) => {
                            return (
                            <Step key={index}>
                            <StepLabel></StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Stack style={{paddingTop:'5px'}} direction={'row'}>
                        {activeStep != 0 && <Button onClick={handleBack}>Back</Button> }
                        <Button onClick={handleNext}>{activeStep >= videos.length -1 ? 'Finish ' : 'Next'}</Button>
                </Stack>
                </Stack>
            </Stack>
        </Paper>
    )
}