import React, { useState, useContext, useEffect } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { PrimaryButton } from "../studioxr/Button";
import { formatPrice, setProfileFirstAction } from "../libs/util.ts";
import { useLoginState } from "../studioxr/auth/LoginState.ts";
import { ProfileFirstActions, ProfileInterface } from "ts-interfaces";
import { cloudflareCDN } from "ui";
import { useMattoState } from "../MattoState.ts";
import { getPublicURL } from "../libs/firebase.js";
import { AuthContext } from "../auth/Auth";
import { getGoogleAnalyticsProductInfo, stripePitchImage as imageUrl } from "./config.js";


export const GetPro = ({ profilePrices, onSuccess}) => {

    const profile:ProfileInterface | any = useLoginState((state) => state.profile);
    const setOrCreateProfile = useLoginState((state) => state.setOrCreateProfile);
    const [getProButtonnDetails, setGetProButtonnDetails] = useState({label:"Get Mattoboard Pro",disable:true});
    const { currentUser }  = useContext(AuthContext);

    useEffect(() => { setGetProButtonnDetails({label:"Get Mattoboard Pro",disable: profilePrices.productID==null   }); },[profilePrices])

    useEffect(() => {      
        setProfileFirstAction(ProfileFirstActions.OPEN_PAYMENT_MODAL).finally(()=>{});
        try {
            if (profilePrices && window.gtag) 
                window.gtag("event", "view_item", getGoogleAnalyticsProductInfo(profilePrices))
        }
        catch { }
    },[])

    const handleGetProBtnClick = async() => {  
      if(!profile?.id) { 
        if(!currentUser.uid || !currentUser.email) {
          window.alert('Something went wrong! you cannot proceed with the process. Please contact studio@mattoboard.com.') ; 
          return;
        }
        setGetProButtonnDetails({label:'Processing...Please Wait',disable:true});
        const data = {
          user: {
            uid: currentUser?.uid,
            email: currentUser?.email,
          }
        };
        console.log('Get Pro Button clicked');
        await setOrCreateProfile(data, false); //only called if we don't have a profile. 
      }
      setGetProButtonnDetails({label:'Processing...Please Wait',disable:true});
      onSuccess && onSuccess();   
    };

    const edition = profilePrices?.userType == 'student' ? 'Student Edition' : 'Pro';

    const proPopupMessage = () => {
      const showSubscription:any = useMattoState((state) => state.showSubscription);
      let message=''
      if (Array.isArray(showSubscription) && showSubscription.length > 1) { message= showSubscription[1] }      
      if (message?.length > 0)  return (<Typography style={{fontWeight:700,fontSize:'16px',color:'red'}}>{message}</Typography>)
      else return (null)
    }

    return (
      <>
        <Grid sx={{height: '100%', display: 'flex', flexDirection: 'row'}}>
            <Grid sx = {{padding:"15px", justifyContent: "left", overflowY: 'auto', height:'100%', width: '400px', flexGrow: '0'}}>
                <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
                  {/* <img  style={{maxWidth: '200px',paddingLeft:'10px', paddingTop: '5px'}} src='/images/stripe/logo-mattoboard-pro.png' /> */}
                  {/* <Typography className="main-title-des">Design concept boards fast, with professional creativity & flexibility.</Typography>  */}              
                  {proPopupMessage()}
                  <Typography className="main-title2">Get Mattoboard {edition}</Typography> 

                  <Typography className='main-title-des' >Design concept boards fast, with professional creativity & flexibility. </Typography> 
                  <Grid sx={{display: 'flex', flexDirection: 'row', alignItems: 'left'}}>
                    <Grid sx={{padding: '8px'}}>
                     <img  style={{maxWidth: '20px'}} src='/images/stripe/icons-library.png' />
                    </Grid>
                    <Grid>
                      <Typography className="bold-paraHeading" >UNLIMITED PROJECTS AND UPLOADS</Typography> 
                      <Typography className="para-sub" >Unlimited conversion of your personal texture photos into 3D textures. Unlimited upload of reference photos too. </Typography> 
                    </Grid>
                  </Grid>
                  <Grid sx={{display: 'flex', flexDirection: 'row', alignItems: 'left'}}>
                    <Grid sx={{padding: '8px'}}>
                      <img  style={{maxWidth: '20px'}} src='/images/stripe/icons-diamond.png' />
                    </Grid>
                    <Grid>
                      <Typography className="bold-paraHeading" >UNLIMITED PROFESSIONAL RENDERS</Typography> 
                      <Typography className="para-sub" >
                        Use Deep Render to create professional, photorealistic renders of your designs.  
                      </Typography>  
                    </Grid>
                  </Grid>
                  <Grid sx={{display: 'flex', flexDirection: 'row', alignItems: 'left'}}>
                    <Grid sx={{padding: '8px'}}>
                      <img  style={{maxWidth: '20px'}} src='/images/stripe/icons-star.png' />
                    </Grid>
                    <Grid>
                      <Typography className="bold-paraHeading" >RECORD YOUR BOARDS, BACKGROUND REMOVER AND MORE!</Typography>
                      <Typography className="para-sub" >Record your boards for client presentations. Save hours by effortlessly removing the background from furniture and product photos with one click!.</Typography> 
                    </Grid>
                  </Grid>
                  <Grid sx={{display: 'flex', flexDirection: 'row', alignItems: 'left'}}>
                    <Grid sx={{padding: '8px'}}>
                      <img  style={{maxWidth: '20px', visibility: 'hidden'}} src='/images/stripe/icons-star.png' />
                    </Grid>
                    <Grid>
                      {/* <Grid>
                        <Typography className="bold-paraHeading" >BONUS:
                          <span className="para-sub" style={{textShadow: 'none', lineHeight: '1'}}>
                            Become a Verified Founding Pro with access to <u>everything</u> we launch this year, & join our private group to help direct a new future of A&D Virtual Sampling.
                          </span>
                        </Typography>                                            
                      </Grid> */}

                      {profilePrices?.local_price && 
                      <Grid sx={{display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:'flex-start', marginTop: '10px'}}>
                        <Typography className="price-amount" sx={{color: '#D0D0D0', textDecoration: 'line-through'}} >  {formatPrice(profilePrices.local_price * 1.5,profilePrices.currency,false)}</Typography> 
                        <Typography className="price-amount" >{formatPrice(profilePrices.local_price,profilePrices.currency,false)}  </Typography> 
                        <Typography className="priceType-text" >{profilePrices.currency.toUpperCase()} <br/>{profilePrices.planType}*</Typography> 
                      </Grid>                       
                      }

                      {profilePrices.userType == 'adopter' &&
                        <Typography className="para-sub2" >This early adopter offer is limited to<br/>the first 1,000 designers!</Typography> 
                      }
                      {getProButtonnDetails.disable==true&& <CircularProgress sx={{marginRight:'20px'}} size={'1rem'} />  }

                      <PrimaryButton aria-label="GetPro"  variant="contained" sx={{ fontWeight:'700', textTransform:'capitalize', borderRadius: '5px', marginTop: '5px', marginBottom: '5px', padding: getProButtonnDetails.disable ? '6px 16px 6px 16px' : '6px 22px 6px 22px', background:'blue',width: getProButtonnDetails.disable ? '240px' : '280px'}} onClick={handleGetProBtnClick} disabled={getProButtonnDetails.disable}>
                        {getProButtonnDetails.label}
                      </PrimaryButton>
                      {/* <Typography className="asterisk-text" >*Paid annually. Renews automatically,<br/>but we will remind you. Cancel anytime.</Typography>  */}
                      {profilePrices.planType == "yearly" && <Typography className="asterisk-text" >*Paid annually. Renews automatically,<br/>but we will remind you. Cancel Anytime. </Typography>}
                      {profilePrices.planType == "monthly" && <Typography className="asterisk-text" >*Paid monthly. Renews automatically.<br/>Cancel Anytime. </Typography>}
                    </Grid>                
                  </Grid>
                </Grid>    
            </Grid>
            <Grid sx={{display: "flex", width: 'calc(100% - 400px)', height: '100%', overflow: "hidden", borderRadius: "0px 15px 15px 0", flexGrow: '1'}}>
              <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={cloudflareCDN(getPublicURL(imageUrl), 'width=600,format=auto')} loading="lazy" alt=""  />     
            </Grid>
        </Grid>
         
      </>
    );
  };