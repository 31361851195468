import React, { useState, useContext } from "react";
import { Box,FormControl,FormControlLabel,Grid,IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { TrialContainer } from "./styles.ts";
import { PrimaryButton } from "../studioxr/Button.tsx";
import { fetchData } from "../libs/util.ts";
import { useLoginState } from "../studioxr/auth/LoginState.ts";
import { ProfileInterface, UserProfileRole } from "ts-interfaces";
import { cloudflareCDN, firebaseHelper } from "ui";
import { useMattoState } from "../MattoState.ts";
import { cancelStripeSubscriptionAPIUrl } from "./config.js";
import { getPublicURL } from "../libs/firebase.js";
import * as Sentry from "@sentry/react";

const imageUrl = 'stripe_images/expert-pitch-spread--HALF-IMAGE-1.jpg'

const CancelUserSubscription = ({setShowSubscription}) => {

  const profile:ProfileInterface | any = useLoginState((state) => state.profile);
  const setProfile = useLoginState((state) => state.setProfile);
  const [cancelButtonDetails, setCancelButtonDetails] = useState({label:"Continue Cancellation", disable:false});
  const [subscriptionCanceled, setSubscriptionCanceled] = useState(false);


  async function cancelStripeSubscription() {
    const requestCancellation = { customerId: profile?.stripeCustomerId };
    const cancelStripeSubscriptionData = await fetchData(requestCancellation, cancelStripeSubscriptionAPIUrl);
    return cancelStripeSubscriptionData;
  }
  const cancelSubscription = async() => {   
    try {
      Sentry.captureMessage("User attempted to cancel subscription", {
        level: "info",
        user: profile,
      });

      if(profile?.stripeSubscriptionId){ 
        setCancelButtonDetails({label: "Cancelling... Please Wait!", disable: true})
        const cancelStripeSubscriptionData  = await cancelStripeSubscription()
  
        if (
          cancelStripeSubscriptionData?.subscription?.status == "canceled" ||
          cancelStripeSubscriptionData?.notice?.message=='Customer does not have an active subscription') {
            const dataToUpdate = {
              userRole: UserProfileRole.BASIC,
              trialends: null
            }
            const updatedProfile = {...profile, ...dataToUpdate}
            setProfile(updatedProfile);
            setSubscriptionCanceled(true);
        }
        else {
          // const updatedProfile = {...profile}
          // setProfile(updatedProfile);
          setSubscriptionCanceled(true);
        }
      }

    } catch (error) {
      Sentry.captureException(error);
      alert("There is some error in cancelling your subscription. Please contact studio@mattoboard.com for assistance.")
    }
  };

    return (
      <>
        <Grid sx={{height: '100%',display: 'flex', flexDirection: 'row'}}>
          <Grid sx = {{padding:"15px", justifyContent: "left",overflowY: 'auto',height:'100%', width: '400px', flexGrow: '0'}}>
            <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'left', flex: '1'}}>
              
              {!subscriptionCanceled &&
              <>
                <Typography className="main-title" sx={{paddingBottom:'10px'}} >
                  We are sorry to see you go.  Please click below to confirm cancel. </Typography> 
                <PrimaryButton fullWidth variant="contained" onClick={cancelSubscription} disabled={cancelButtonDetails.disable}>
                  {cancelButtonDetails.label}
                </PrimaryButton>
              </>
              }
              {subscriptionCanceled &&
              <>
                <Typography className="main-title" sx={{paddingBottom:'10px'}} >
                  Subscription canceled successfully. You will not be charged for any future billings. </Typography> 
                <PrimaryButton fullWidth variant="contained" onClick={() => { setShowSubscription(null);}}>
                  Continue Designing!
                </PrimaryButton>
              </>
              }
            </Grid>
          </Grid>
          <Grid sx={{display: "flex",width: 'calc(100% - 400px)',height:'100%', overflow: "hidden", borderRadius: "0px 15px 15px 0", flexGrow: '1'}}>
            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={cloudflareCDN(getPublicURL(imageUrl), 'width=600,format=auto')} loading="lazy" alt=""  />     
          </Grid>
        </Grid>  
      </>
    );
  };


export const CancelSubscription = (props) => {
  
  const setShowSubscription = useMattoState((state) => state.setShowSubscription);

    return (
      <TrialContainer>
        <IconButton sx={{zIndex:'100',margin:'-15px'}} onClick={() => setShowSubscription(null)} className="close-btn"> 
        <CloseIcon />
      </IconButton> 
        <Box className="form-root trial">
              <CancelUserSubscription setShowSubscription={setShowSubscription} />
        </Box>

      </TrialContainer>
    );
  };
  