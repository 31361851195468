
export const canvasCss = {marginTop: { xs:'0px',md: '5px'},minHeight:{xs:'500px',md:'500px'},width:{xs:'100%',md: ''},height:{xs:'100%',md: ''}}
export const mainContainerGridCss ={maxHeight:{xs:'100vh',md:'92vh'},overflow:'hidden'}
export const boardGridCss ={height:{xs:'calc(100vh - 30px)',md:'84vh'}}
export const mainContainerCss ={paddingLeft: {xs:'0px',md:'10px'},paddingRight: {xs:'0px',md:'10px'}}


//show mode
export const canvasCssShare = {marginTop: { xs:'0px',md: '0px'},minHeight:{xs:'500px',md:'500px'},width:{xs:'100%',md: '100%'},height:{xs:'100%',md: '100%'}}
export const mainContainerGridCssShare ={maxHeight:{xs:'100vh',md:'100vh'},overflow:'hidden'}
export const boardGridCssShare ={height:{xs:'calc(100vh - 30px)',md:'calc(100vh - 35px)'}}
export const mainContainerCssShare ={paddingLeft: {xs:'0px',md:'0px'},paddingRight: {xs:'0px',md:'0px'}}
export const absoluteButtonsCssShare = {position: 'absolute', top: '49px',left: '40px',zIndex: '1',alignItems: 'center'}