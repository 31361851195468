import React, { useEffect, useRef, useState } from "react";
import { Button, IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import { MyProfilePic } from "../studioxr/auth/MyProfilePic";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';


export const ProfileUpload = ({ onFileChange, img, title='Profile Pic', defaultText='M',disabled=false,maxResizeWidth=200,deleteOption=false,onDelete}) => {
    const fileRef:any = useRef()
    const [image,setImage]=useState<any>(img)
    
    const handleProfilePicChange = (img) => {
        if (img) {
          const ratio = maxResizeWidth / img.width;
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const w = img.width * ratio;
          const h = img.height * ratio;
          canvas.width = w;
          canvas.height = h;
          context?.drawImage(img, 0, 0, w, h);

          setImage(canvas.toDataURL('image/jpeg'))          
          canvas.toBlob(function (blob:any) {
            blob.extension = ".jpg";
            onFileChange?.(blob);
          }, "image/jpeg");
        } else {
            setImage(null);
        }
      };    
    const handleFiles = (e) => {
        if (e.target?.files[0]) {
            const img = new Image();
            img.onload = () => { handleProfilePicChange(img); }
            img.src = URL.createObjectURL(e.target.files[0])
        }
    }
    return (
        <Stack direction='row'  alignItems="center" spacing={2}>
            <Button disabled={disabled} variant='outlined'  component="label" >
                {title}
                <input type="file"  ref={fileRef} onChange={ (e) => { handleFiles(e); } } hidden accept=".webp,.jpg,.png"  />
            </Button>

            <MyProfilePic img={image} defaultText={defaultText} />
            {deleteOption && image &&    
                <IconButton  onClick={ () => { setImage(null); onDelete()} } >
                    <DeleteIcon />
                </IconButton> 
            }
        </Stack>
    )
}

