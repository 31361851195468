import React, { useState, useEffect, useMemo } from "react";
import { TextField, Box, Typography, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useLoginState } from "./LoginState";
import { resetPassword, sendResetPassword } from "../../libs/firebase";
import { PrimaryButton } from "../Button";
import { SigninContainer } from "./styles";

export const Forgot = () => {
  const [emailValue, setEmailValue] = useState("");

  const [error, setError] = useState("");
  const [showFlash, setShowFlash] = useState(false);
  const [flashMessage, setFlashMessage] = useState("");

  const setShowLogin = useLoginState((state) => state.setShowLogin);

  const handleSubmit = (evt: any) => {
    evt.preventDefault();
    if(!emailValue){
      setFlashMessage("Please enter all the required details");
      setShowFlash(true);
      return;
    }
    sendResetPassword(emailValue)
      .then((result) => {
        setFlashMessage(
          "Password reset email sent! Please check your email (if you don't see it, check your spam or junk folder"
        );
        setShowFlash(true);
      })
      .catch((error) => {
        setFlashMessage("This email is not in our records");
        setShowFlash(true);
      });
  };

  const onChangeEmail = (e) => {
    setFlashMessage("");
    setEmailValue(e.target.value.replace(/\s/g, ""));
  };

  return (
    <SigninContainer>
      <Box className="form-root">
        <img src={'/images/mattoboard-logo.jpg'} alt="MattoBoard" className="logo-img" />
        <Typography component="h1" variant="h5" className="login-title">
          Forgot Password?
        </Typography>
        <Box className="signup-link">
          <Typography className="signup-text">New user?</Typography>
          <button className="signup-btn" onClick={() => setShowLogin("signup")} style={{color:'blue',fontWeight:'400'}}>
            Create an account
          </button>
        </Box>
        {showFlash && 
        <Typography component="h6" color='error' align='center' variant="h5">
          {flashMessage}
        </Typography>
        }
        <div className="login-form">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="email"
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={emailValue}
            onChange={onChangeEmail}
            // error={Boolean(error)}
            // helperText={error || flashMessage}
          />

          <PrimaryButton
            fullWidth
            style={{ marginTop: "38px" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Reset Password
          </PrimaryButton>
        </div>
      </Box>
      <IconButton onClick={() => setShowLogin(null)} className="close-btn">
        <CloseIcon />
      </IconButton>
    </SigninContainer>
  );
};
