import { ObjectStatusType, ProductInterface } from "ts-interfaces";


export const filterProductToDraggablePaint = (product: ProductInterface) => {
    if (!product.materialData) return null;
    const filteredData:any = {}   
    filteredData.paintMaterials = [];
    if (product.materialData.materialProps) filteredData.paintMaterials[0] = product.materialData.materialProps;
    filteredData.paintMaterialsData = [];
    filteredData.paintMaterialsData[0]={id:null,name:null,renderedImage:null, userCreated: product.objectStatus==ObjectStatusType.USER_CREATED};
    if (product?.materialData?.id || product?.id) filteredData.paintMaterialsData[0].id = product.materialData.id || product.id ;
    if (product?.name || product?.materialData?.name) filteredData.paintMaterialsData[0].name = product.name || product.materialData.name;
    if (product?.materialData?.renderedImage) filteredData.paintMaterialsData[0].renderedImage  = product.materialData.renderedImage;       
    if (product?.materialData?.preset) filteredData.paintMaterialsData[0].preset = product.materialData.preset; 
    // filteredData.category = ['Paint'];
    if (product.category) filteredData.category = product.category;
    filteredData.type ="paint"           
    return filteredData
}