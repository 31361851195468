import { styled } from '@mui/system';
import {Box, Typography} from '@mui/material'

export const SubheaderTypography = styled(Typography)({
    fontSize: '10px',
    paddingTop: '3px',
    fontWeight: 'bold',
    color:'#8a8a8a',
    width:'100%',
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis"
});
export const SidebarItemBox = styled(Box)(({ theme }) => ({
    display:'flex', 
    flexDirection:'column',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign:'center',
    overflow: 'none',
    color:'#666666',
    paddingTop: '10px',
    paddingBottom: '10px',
    '&:hover, &.selected':{
        cursor:'pointer', 
        backgroundColor: theme.palette.primary.monochrome.medium, 
        color:theme.palette.primary.light,
        '&:before':{   
            background: `radial-gradient(circle closest-side, transparent 0, transparent 50%, ${theme.palette.primary.monochrome.medium} 0) 200% 200%/400% 400%`,
            content: '""',
            position: 'absolute',
            width:'8px',
            height:'8px',
            right: '0',
            overflow: 'hidden',
            top:'-8px'
        },
        '&:first-of-type:before' :{   
            background: '#cccccc'
        },
        '&:after':{   
            background: `radial-gradient(circle closest-side, transparent 0, transparent 50%, ${theme.palette.primary.monochrome.medium} 0) 200% 200%/400% 400%`,
            content: '""',
            position: 'absolute',
            width:'8px',
            height:'8px',
            right: '0',
            overflow: 'hidden',
            bottom:'-8px',
            webkitTransform: 'scaleY(-1)',
            transform: 'scaleY(-1)'
        },
        '&>p': {
            color:theme.palette.primary.light,
        }
    },
    

}));


