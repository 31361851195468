
import React, {useMemo,useRef,useEffect} from 'react'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { styled } from '@mui/system';
import { IconButton, Tooltip, Typography} from '@mui/material'
import { ClearRefinements, useSearchBox } from 'react-instantsearch';
import { useMattoState } from '../MattoState';
import { useClearRefinements } from 'react-instantsearch';

const FilterIconButton = styled(IconButton)(({ theme }) => ({  height:'36px', borderRadius:'10%', float:'right', cssFloat: 'right'}))

export const FilterButton = ({productType=null}) => {
    const { refine, canRefine, createURL } = useClearRefinements();
    const setPaintSearchCode = useMattoState((state) => state.setPaintSearchCode);
    const handleClear = () => { refine();  setPaintSearchCode(null);}

    return (
        <Tooltip title="Clear Filters" arrow>
        <FilterIconButton onClick={handleClear} > 
            <Typography style={{paddingRight:'3px'}} variant='caption'>Clear Filters</Typography>
            <FilterAltOffIcon  color="action"  />
       </FilterIconButton>  
       </Tooltip>
    )
}
