import React, { useMemo,useRef, useState,useEffect, Suspense } from 'react'
import { CanvasObject, PhysicalObjectInterface, SideBarMode } from '../../ts/app_interfaces';
import { invalidate, useThree  } from '@react-three/fiber';
import { DrawLine } from './../../components/DrawLine';
import { lineEndTypes, positionIn3D } from '../../libs/util';
import { produce } from 'immer';
import { usePhysicalObjects } from '../../MattoState';
import { createCanvasTexture, createPlaneGeometry } from './CanvasObject';

export const CANVAS_SCALE=2;  //draw the line 2x bigger than the actual size

//create interface for canvasline object
interface CanvasLineObjectProps {
    physicalObject:PhysicalObjectInterface
    onLoad?:Function
}
export const CanvasLineObject = ({physicalObject,onLoad}:CanvasLineObjectProps) => {
    const planeRef:any = useRef();
    const materialRef:any = useRef();
    const addPhysicalObject = usePhysicalObjects((state)=> state.addPhysicalObject);
    const deleteObject = usePhysicalObjects((state)=> state.deleteObject);
    const canvasObject:CanvasObject = physicalObject.canvasObject as CanvasObject
    const { camera,gl,raycaster } = useThree();
    const [loaded,setLoaded] = useState(false)
    
    const handleLineDrawn = (endPosition, startPosition, copyObject) => {
        if (endPosition==null || startPosition==null) { return; }
        const dist = getDistanceBetweenPoints(startPosition, endPosition)
        if (dist < 1) { return;}
        const startX = startPosition.x + (endPosition.x - startPosition.x) /2;
        const startZ = startPosition.z + (endPosition.z - startPosition.z) /2;
        const y =  getAngleBetweenTwoPoints(startPosition,endPosition) 
        const newPhysicalObject:any = produce(copyObject,draftState => {
            draftState.rotation = [0, y, 0]
            draftState.position = [startX,0,startZ ]
            if (draftState.canvasObject) {
                draftState.canvasObject.lineStartPosition = {x: startPosition.x, y: 0.2, z: startPosition.z}
                draftState.canvasObject.lineEndPosition = {x: endPosition.x, y: 0.2, z: endPosition.z}
            }
        })
        addPhysicalObject(newPhysicalObject,true);
    }
    useEffect(() => {
        if (canvasObject.lineStartPosition!=null) return;      
        const copyPhysicalObject = produce(physicalObject, draftState => { })
        deleteObject(physicalObject.key); //need to actually delete the physical object and then read after drawing the line
        const parentCanvas:any = document.querySelector('canvas'); //threejs canvas
        const lineTypeDot:boolean = canvasObject.lineType=='dot';
        if (!parentCanvas?.offsetParent) return;
        DrawLine({parentCanvas, hasDot: lineTypeDot, callback: handleLineDrawn, positionIn3D, camera, gl, raycaster,copyPhysicalObject});
    }, []);

    const [distance,dynamicTexture,planeGeometry]:any = useMemo(() => {
        if (canvasObject?.lineStartPosition!=null) {     
            const distance = getDistanceBetweenPoints(canvasObject.lineStartPosition, canvasObject.lineEndPosition)
            const canvas = drawLineCanvas(canvasObject);
            if (canvas) {
                const [w,h] = [canvas.width / CANVAS_SCALE  , canvas.height / CANVAS_SCALE]
                return [distance,createCanvasTexture(canvas), createPlaneGeometry(w*0.05,h*0.05)];
            } 
        }
        else { return [0,null,null] }
    },[canvasObject])

    useEffect(() => { 
        if (materialRef.current && loaded==false)  {  setLoaded(true); onLoad?.();  } 
        invalidate()
    }, [planeRef.current])

    useEffect(() => {      
        if (materialRef.current && planeRef.current && dynamicTexture) {
            materialRef.current.map = dynamicTexture;
            materialRef.current.map.flipY = true;
            materialRef.current.alphaTest = 0.1;
            materialRef.current.envMapIntensity = 0.5;
            materialRef.current.metalness =  0.0;
            materialRef.current.transparent = true;
            materialRef.current.shadowSide = 0;
            materialRef.current.map.needsUpdate = true;
            materialRef.current.side = 0;
        }
    }, [materialRef.current, dynamicTexture])

    if (canvasObject.lineEndPosition!=null && canvasObject.lineStartPosition!=null && dynamicTexture != null && distance > 1) {
        return (
            <Suspense fallback={null}>
                <mesh name="line" ref={planeRef} castShadow={false} receiveShadow={false} geometry={planeGeometry} >
                    <meshBasicMaterial attach="material-5"     transparent opacity={0} />
                    <meshBasicMaterial attach="material-0"    transparent  opacity={0} />
                    <meshBasicMaterial attach="material-1"    transparent  opacity={0}/>
                    <meshBasicMaterial attach="material-3"    transparent  opacity={0} />
                    <meshBasicMaterial attach="material-4"   transparent  opacity={0} />
                    <meshStandardMaterial attach="material-2"  ref={materialRef} />                    
                </mesh>
            </Suspense>
        )
    }
    else { return null }
}

//couldn't get the math to work so brute forced it
function getAngleBetweenTwoPoints(p1, p2) {    
    const a =  Math.atan2( Math.abs(p2.z -p1.z), Math.abs(p2.x - p1.x));    
    
    if (p2.x > p1.x && p2.z > p1.z) return -a;
    else if (p1.x > p2.x && p1.z > p2.z) return -a;
    else return a;
}
function getDistanceBetweenPoints(point1, point2)  {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2) + Math.pow(point2.z - point1.z, 2));
}

export function drawLineCanvas(canvasObject:CanvasObject)   {
    if (canvasObject.lineStartPosition==null || canvasObject.lineEndPosition==null) return;
    const dist = getDistanceBetweenPoints(canvasObject.lineStartPosition, canvasObject.lineEndPosition)
    const canvas = document.createElement('canvas');
    canvas.width = dist*20  ;
    canvas.height = 20 ;

    let dotSize = 5;
    let w = dotSize //which direction to draw the dot

    let lineEndType = 'none';
    if(canvasObject.lineEndType) {
        lineEndType = lineEndTypes[canvasObject.lineEndType]
        if(lineEndType == 'dot2' || lineEndType == 'dot2-both-sides') {
            w = 7;
            dotSize = 7;
        }
        if(lineEndType == 'dot3' || lineEndType == 'dot3-both-sides') {
            w = 9;
            dotSize = 9;
        }
    }

    if (canvasObject.lineStartPosition.x > canvasObject.lineEndPosition.x 
        && canvasObject.lineStartPosition.z > canvasObject.lineEndPosition.z) {
        w = canvas.width - dotSize;
    }
    else if (canvasObject.lineStartPosition.x > canvasObject.lineEndPosition.x 
        && canvasObject.lineStartPosition.z < canvasObject.lineEndPosition.z) {
        w = canvas.width - dotSize;
    }
    //w = w * CANVAS_SCALE;

    canvas.width = dist*20*CANVAS_SCALE  ;
    canvas.height = 20*CANVAS_SCALE ;

    const drawDot = (ctx: any, x: number, y: number, radius: number) => {
        ctx.fillStyle = canvasObject.color  || 'black'
        ctx.arc( x, y, radius, 0, Math.PI * 2, true);
        ctx.fill();
    }

    const drawArrow = (ctx, x, y, angle) => {
        ctx.fillStyle = canvasObject.color || 'black';
        ctx.save();
        ctx.translate(x, y);
        ctx.rotate(angle);
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(-8 * CANVAS_SCALE, -8 * CANVAS_SCALE);
        ctx.lineTo(-8 * CANVAS_SCALE, 8 * CANVAS_SCALE);
        ctx.closePath();
        ctx.fill();
        ctx.restore();
    }

    const ctx = canvas.getContext('2d');

    if (ctx) {
        ctx.beginPath();

        switch(lineEndType) {
            case 'dot':
                drawDot(ctx, w* CANVAS_SCALE, 10 * CANVAS_SCALE, 4 * CANVAS_SCALE);
                break;
            case 'dot-both-sides':
                drawDot(ctx, w* CANVAS_SCALE, 10 * CANVAS_SCALE, 4 * CANVAS_SCALE);
                drawDot(ctx, canvas.width - w* CANVAS_SCALE, 10 * CANVAS_SCALE, 4 * CANVAS_SCALE);
                break;
            // set radius to 6 for dot2
            case 'dot2':
                drawDot(ctx, w* CANVAS_SCALE, 10 * CANVAS_SCALE, 6 * CANVAS_SCALE);
                break;
            case 'dot2-both-sides':
                drawDot(ctx, w* CANVAS_SCALE, 10 * CANVAS_SCALE, 6 * CANVAS_SCALE);
                drawDot(ctx, canvas.width - w* CANVAS_SCALE, 10 * CANVAS_SCALE, 6 * CANVAS_SCALE);
                break;
            // set radius to 8 for dot 3
            case 'dot3':
                drawDot(ctx, w* CANVAS_SCALE, 10 * CANVAS_SCALE, 8 * CANVAS_SCALE);
                break;
            case 'dot3-both-sides':
                drawDot(ctx, w* CANVAS_SCALE, 10 * CANVAS_SCALE, 8 * CANVAS_SCALE);
                drawDot(ctx, canvas.width - w* CANVAS_SCALE, 10 * CANVAS_SCALE, 8 * CANVAS_SCALE);
                break;
            case 'arrow':
                drawArrow(ctx,  (dist * 20 - 1) * CANVAS_SCALE, 10 * CANVAS_SCALE, Math.atan2(0, dist * 20 * CANVAS_SCALE));
                break;
            case 'arrow-both-sides':
                drawArrow(ctx, 0, 10 * CANVAS_SCALE, Math.PI);
                drawArrow(ctx, (dist * 20 - 1) * CANVAS_SCALE, 10 * CANVAS_SCALE, Math.atan2(0, dist * 20 * CANVAS_SCALE));
                break;
                
            default: break;
        }
        ctx.strokeStyle = canvasObject.color  || 'black'
        ctx.lineWidth = 1.5 * CANVAS_SCALE;
        ctx.moveTo(0.0, 10 * CANVAS_SCALE);
        ctx.lineTo(dist*20 * CANVAS_SCALE, 10 * CANVAS_SCALE);
        ctx.stroke();
    }
    return canvas;
}


