import { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { PrimaryButton } from "../Button";
import AuthModal from "./AuthModal";
import { ProfileForm } from "./SignUp";
import { SigninContainer } from "./styles";

const SuccessView = ({ onSuccess }) => {
  const complete = () => {
    onSuccess && onSuccess();
  };

  return (
    <>
      <Typography component="h1" variant="h5" className="login-title">
        Changes applied successfully
      </Typography>
      <div className="login-form">
        <PrimaryButton fullWidth variant="contained" onClick={complete} aria-label="back-to-mattoboard-btn">
          back to mattoboard
        </PrimaryButton>
      </div>
    </>
  );
};

export const ProfileModal = ({ open, initialProfile, onClose, onSuccess }) => {
  const [success, setSuccess] = useState(false);  
  
  return (
    <AuthModal open={open} onClose={onClose}>
      <SigninContainer>
        <Box
          className="form-root signup"
          style={success ? { display: "flex" } : {}}
        >
          <div>
            <img src={'/images/mattoboard-logo.jpg'} alt="MattoBoard" className="logo-img" />
            {success ? (
              <SuccessView onSuccess={onClose} />
            ) : (
              <ProfileForm
                initialProfile={initialProfile}
                onSuccess={(data) => {
                  setSuccess(true); onSuccess()
                }}
              />
            )}
          </div>
        </Box>
        <IconButton onClick={onClose} className="close-btn">
          <CloseIcon />
        </IconButton>
      </SigninContainer>
    </AuthModal>
  );
};

export default ProfileModal;
