import React, { useMemo,useRef, useState,useEffect } from 'react'
import { usePhysicalObjects, useProjectData } from '../MattoState'
import { Button, Grid, Hidden, IconButton, Slider, Tooltip, Typography } from '@mui/material'
import { ProductInterface } from '../../../../packages/ts-interfaces';
import { PhysicalObjectInterface } from '../ts/app_interfaces';
import { calculateRepeatModifier, threejs_TextureRepeat } from '../libs/util';
import { produce } from 'immer';
import { invalidate } from '@react-three/fiber';
import { Vector2,Color } from 'three'
import * as Sentry from "@sentry/react";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


const text2={paddingTop:'0px',textAlign:'left',textTransform:'capitalize',overflow:'hidden', textOverflow:'ellipsis'} as any

export const PropertySlider = ({name,materialKey,max,min, defaultValue=1}) => {
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const getCurrentThreeJSObject = usePhysicalObjects( (state) => state.getCurrentThreeJSObject)
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)
    const backgroundTexture:ProductInterface | any = useProjectData(state=>state.backgroundTexture)
    const hasMaterialToModfy = selectedPhysicalObjectKey!=null || backgroundTexture!=null
    const updateCount = usePhysicalObjects(state=>state.updateCount)  //physical object is updated.

    const setProjectData = useProjectData(state=>state.set)	
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)
    const [value,setValue] = useState(0.5)

    if (materialKey=='textureRepeat') max = selectedPhysicalObjectKey==null  ? 20 : 5

    useEffect(()=> {
        handleCopyMaterialData()
        if (materialKey=='textureRepeat') {
            const v = getCurrentMaterialValue('textureRepeat')  
            if (v==null)  setValue(selectedPhysicalObjectKey==null ? 15 : 1)            
            else  setValue(v)
        }
        else {
            if (selectedPhysicalObjectKey==null && backgroundTexture==null) return;
            let val =getCurrentMaterialValue(materialKey)
            val = Array.isArray(val) ? val[0] : val
            if (val==null) val=defaultValue
            setValue(val)
        }
    },[selectedPhysicalObjectKey,backgroundTexture,updateCount])

    const handleSliderChange = (event, newValue) => {
        setValue(newValue)
        if (selectedPhysicalObjectKey==null && backgroundTexture==null) return;
        const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)     
        let v=newValue;
        const threeObj = selectedPhysicalObjectKey!=null ? getCurrentThreeJSObject()
        : window.scene.children.filter(c => c.name=='floor' )[0]
        if (materialKey=='normalScale') { v= new Vector2(newValue,newValue)  }
        if (threeObj.isMesh) { 
            if (materialKey=='textureRepeat') { setRepeatValue(threeObj, selectedPhysicalObjectKey, v); }
            else threeObj.material[materialKey] = v;  
        }
        else {            
            threeObj.traverse(child => {
                if (child.isMesh) { 
                    if (materialKey=='textureRepeat') { setRepeatValue(child, selectedPhysicalObjectKey, v); }
                    else if (selectedPhysicalObject.type=='paint') {
                        if (child.material.name.indexOf('color_material')==0) child.material[materialKey] = v;
                    }
                    else child.material[materialKey] = v; 
                }
            })
        }
        invalidate()
    }
    const handleSliderChangeCommited = (event, newValue) => {
        setValue(newValue)
        if (selectedPhysicalObjectKey==null && backgroundTexture==null) return;
        updateMaterialProp(materialKey,newValue)    
    }
    const handleCopyMaterialData=() => {
		if((!document.getElementById("root")?.classList.contains("dropper-cursor"))|| selectedPhysicalObjectKey!= null)
		return;
		else{
			document.getElementById("root")?.classList.remove("dropper-cursor")
		}
	}
    const getCurrentMaterialValue = (type) => {
        if (selectedPhysicalObjectKey!=null) {
            const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)  
            if (!selectedPhysicalObject) return null
            if (selectedPhysicalObject.type=='paint') {
                //should not really go here, but here we are.
                const t = selectedPhysicalObject.paintMaterials?.[0]?.[type]
                if (!t) return null 
                else return t
            }
            else return selectedPhysicalObject?.materialData?.materialProps?.[type]            
        } 
        else if (backgroundTexture!=null) return backgroundTexture?.materialData?.materialProps?.[type]
        else return null;
    }
    const setRepeatValue2 = (mesh, selectedPhysicalObjectKey, textureRepeat=1) => {
        if (!mesh.material.map) return ;
        let textureRepeatModifier = calculateRepeatModifier(getSelectedPhysicalObject(selectedPhysicalObjectKey))
        textureRepeatModifier = textureRepeatModifier == 0 || isNaN(textureRepeatModifier) ? 1 : textureRepeatModifier	
        const v = textureRepeat * (textureRepeatModifier ?? 1)        
        mesh.material.map.repeat.set(v,v)
    }
    const setRepeatValue = (mesh, selectedPhysicalObjectKey, textureRepeat=1) => {
        try {
            if (!mesh.material?.map) return ;
            const m = getSelectedPhysicalObject(selectedPhysicalObjectKey)
            const textureRepeatModifier = calculateRepeatModifier(m)
            if (m==null) { 
                //is a background obj
                threejs_TextureRepeat(mesh.material.map, 1, false, textureRepeat) 
            }
            else {
                const scaledMesh = !(m.scale?.[0] == m.scale?.[1] && m.scale?.[0] == m.scale?.[2])        
                threejs_TextureRepeat(mesh.material.map, textureRepeatModifier, scaledMesh, textureRepeat)
            }
        }
        catch (e) { 
            Sentry.captureException("Set Repeat Value Error" + e);
            console.log(e)
        }
    }


    const updateMaterialProp = (prop,value) => {
        const selectedPhysicalObject:PhysicalObjectInterface = usePhysicalObjects.getState().getCurrentSelectedPhysicalObject()
        const backgroundTexture:ProductInterface | null = useProjectData.getState().backgroundTexture;

        if (selectedPhysicalObject==null) {
            const newObj = produce(backgroundTexture, (draft:any) => { 
                if (prop=='normalScale') draft.materialData.materialProps[prop]=[value,value]
                else draft.materialData.materialProps[prop]=value           
            })
            setProjectData(state=> { state.backgroundTexture = newObj })
        }
        else if (selectedPhysicalObject.type=='paint') {
            const newObj = produce(selectedPhysicalObject, (draft:any) => { 
                draft.paintMaterials[0][prop]=value 
            })
            updatePhysicalObject(newObj)
        }
        else {
            const newObj = produce(selectedPhysicalObject, (draft:any) => { 
                if (prop=='normalScale') draft.materialData.materialProps[prop]=[value,value]
                else draft.materialData.materialProps[prop]=value 
            })
            updatePhysicalObject(newObj)
        }      
    }       
    const sliderId='propSlider-'+materialKey
    if (hasMaterialToModfy==false && (materialKey=='textureRepeat' || materialKey=='normalScale')) return (null);
    return (
        <>
            <Typography style={text2} variant="caption" >{name}</Typography>  
            {/* <Tooltip placement="top" title="Text" arrow><QuestionMarkIcon style={{fontSize:'12px'}} /></Tooltip> */}
            <Slider id={sliderId} size='small' step={0.01} max={max} min={min} value={value} onChange={handleSliderChange} onChangeCommitted={handleSliderChangeCommited} valueLabelDisplay="auto"   /> 
        </>
    )
}
