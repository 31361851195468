import { useState } from "react";
import {  Grid, Typography, IconButton, Button, styled } from "@mui/material";
import React from "react";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LanguageIcon from '@mui/icons-material/Language';
import { cloudflareCDN } from "../../libs/util";
import { getUrlFromRef } from '../../libs/firebase'
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { gTagBrandClickEvent } from "../../libs/util";

const GetInTouchButton = styled(Button)(({ theme }) => ({
  // [theme.breakpoints.down('lg')]: {
	// 	padding:'0px'
	// },
  	[theme.breakpoints.down('sm')]: {
      display:'none'
    },
  color:'white', background:'darkgrey',marginRight:'0px', maxHeight:'32px', marginTop:'5px', fontSize: '16px', backgroundColor: '#C78CD6', fontWeight: '700', padding: '12px',
  "&:hover": {
    color:'black',
    background: 'lightgrey'
  } 
}));
const GetInTouchIconButton = styled(IconButton)(({ theme }) => ({ 	[theme.breakpoints.down('sm')]: {
  display:'block'
},
display:'none'
}));

const BrandHeader = (props) => {
  const history = useNavigate();
  const brandInfo = props.brandInfo
  const [data, setData] = useState([
    { id: 1, name: 'Website', url: 'URL' },
    { id: 2, name: 'Facebook', url: 'facebook_url' },
    { id: 3, name: 'Instagram',url: 'instagram_url' },
    { id: 4, name: 'Pinterest', url: 'pinterest_url' },
  ]);
    const getIcon = (categoryName) => {
      switch (categoryName) {
        case 'Instagram':  return <InstagramIcon sx={{fontSize: '26px', alignItems: 'baseline' }}/>;
        case 'Facebook': return <FacebookIcon sx={{fontSize: '26px', alignItems: 'baseline'}}/>;
        case 'Pinterest': return <PinterestIcon sx={{fontSize: '26px', alignItems: 'baseline'}}/>;
        case 'Website': return <LanguageIcon sx={{fontSize: '26px', alignItems: 'baseline'}} />;
        default: return null;
      }
    }
  
    const navToBrand = () => history(`/brand?id=${brandInfo?.id}`) 

    const handleClick = (destination, url) => {
      gTagBrandClickEvent(brandInfo?.id, brandInfo?.name, destination, url);
    }


  return (
        <Grid container sx={{paddingTop: '100px', paddingBottom: '20px'}}>
            <Grid item md={6}  xs={12}>
                <Typography className="section-title" onClick={navToBrand} >{brandInfo?.name}</Typography> 
                <div className="social-media-section">
                    {brandInfo?.['getInTouch_url'] && <GetInTouchButton onClick={() => handleClick('Get In Touch', brandInfo?.['getInTouch_url'])} href={brandInfo?.['getInTouch_url']} target="_blank">Get in Touch</GetInTouchButton>}
                    {brandInfo?.['getInTouch_url'] && <GetInTouchIconButton href={brandInfo?.['getInTouch_url']}><ConnectWithoutContactIcon sx={{fontSize: '26px'}}/></GetInTouchIconButton>}
                    {data.map(item => (
                        brandInfo?.[item.url] && <div key={item.id} style={{display: 'flex', paddingLeft: '10px'}} onClick={() => handleClick(item.name, brandInfo?.[item.url])}>
                        <IconButton
                            target="_blank"
                            href={brandInfo?.[item.url] ? brandInfo?.[item.url] : ""}>
                          {getIcon(item.name)}
                        </IconButton>
                        </div>
                    ))}
                </div>
                <Typography className="description-text" variant="body1" dangerouslySetInnerHTML={{__html:  brandInfo?.description}}>     
                </Typography>
            </Grid>
            {( brandInfo?.files?.logo || brandInfo?.logo) &&
              <Grid item md={6}  xs={12} sx={{padding: "20px", display: "flex",cursor: "pointer"}}>
                  <img src={cloudflareCDN(getUrlFromRef(brandInfo?.files?.logo || brandInfo?.logo) ,'height=500,width=500,format=auto')} loading="lazy" alt="" className="brand-logo-img" 
                  onClick={navToBrand} />
              </Grid>
            }
        </Grid>
  );
};

export default BrandHeader;


