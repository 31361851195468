import { useBVH, useGLTF } from '@react-three/drei'
import { invalidate, useLoader  } from '@react-three/fiber';
import React, { useRef,useState,useMemo,Suspense,useEffect } from 'react'
import { CanvasTexture, LinearFilter, BoxGeometry, Box3, MeshDepthMaterial, RGBADepthPacking,SRGBColorSpace, TextureLoader } from 'three';
import { getUrlFromRef } from '../../libs/firebase'
import { GLTFObject } from './GLTFObject';
import { PhysicalMaterial } from '../PhysicalMaterial';
import { DynamicPhysicalObject } from './DynamicPhysicalObject';
import { toneMapDynamicObjectsFlag } from '../../libs/firebase';

export const TextObject = (props) => {
    //generate image now. 
    const image = useImage(props.textParams)
    //<PlaneDynamicObject  dynamicImage={image}  physicalObject={physicalObject} onLoad={onLoad} />

}

export const DynamicObject = (props) => {
    return (
        <Suspense fallback={null}>
            <GLTFDynamicObject physicalObject={props.physicalObject} onLoad={props.onLoad}/>
        </Suspense>
    )
}
export const GLTFDynamicObject = ({physicalObject, onLoad}) => {
    const dynamicImage = useImage(physicalObject.dynamicMaterialProps.files)
    if(physicalObject.mode === "Plane") { 
        return <PlaneDynObject  dynamicImage={dynamicImage}  physicalObject={physicalObject} onLoad={onLoad} /> 
    }
    else if (physicalObject.mode=='material') {                
        return <DynamicPhysicalObject dynamicImage={dynamicImage} physicalObject={physicalObject} onLoad={onLoad} />
    }
    else { return <DynamicObjectImpl dynamicImage={dynamicImage} physicalObject={physicalObject} onLoad={onLoad} /> }
}

export const PlaneDynObject = ({physicalObject,onLoad,loadingText, dynamicImage}) => {     
    const loadingTex = useLoader(TextureLoader, '/images/hourglass.png')
    const materialRef = useRef();
    const planeRef = useRef();
    const planeGeometry = createPlaneGeometry(physicalObject.dynamicMaterialProps.width, physicalObject.dynamicMaterialProps.height);

    const [loaded,setLoaded] = useState(false)
    const dynamicTexture = dynamicImage ? createCanvasTexture(dynamicImage) : loadingTex

    // useEffect(() => {
    //     if(planeRef.current) {
    //         const boundingBox = new Box3().setFromObject(planeRef.current);
    //         boundingBox.applyMatrix4(planeRef.current.parent.matrixWorld);
    //         planeRef.current.parent.boundingBox = boundingBox;
    //     }
    // }, [physicalObject.dynamicMaterialProps.width, physicalObject.dynamicMaterialProps.height])

    useEffect(() => {
        if (materialRef.current && planeRef.current && dynamicTexture) {
            planeRef.current.customDepthMaterial = new MeshDepthMaterial({
                map: dynamicTexture,
                depthPacking: RGBADepthPacking,
                alphaTest: 0.1
            })
            materialRef.current.map = dynamicTexture;
            materialRef.current.map.flipY = true;
            materialRef.current.alphaTest = 0.1;
            materialRef.current.envMapIntensity = 1.0;  // 0.5
            materialRef.current.metalness =  0.0;
            materialRef.current.transparent = true;
            materialRef.current.shadowSide = 0;
            materialRef.current.toneMapped = toneMapDynamicObjectsFlag;
            materialRef.current.map.needsUpdate = true;
        }
    }, [materialRef, dynamicTexture])

    useEffect(() => { 
        if (planeRef.current && loaded==false)  {setLoaded(true); onLoad();  } 
        invalidate()
    }, [planeRef])

    return (
        <mesh ref={planeRef} castShadow receiveShadow geometry={planeGeometry} >
            <meshPhysicalMaterial ref={materialRef} />                    
        </mesh>
    )
}   

  
const DynamicObjectImpl = ({physicalObject,onLoad,dynamicImage}) => {    
    const loadingTex = useLoader(TextureLoader, '/images/hourglass.png')
    const gltf = useGLTF(physicalObject.files.medium)
    const dynamicObjRef = useRef()
    const [mesh,setMesh]=useState()
    const dynamicTexture = dynamicImage ? createCanvasTexture(dynamicImage) : loadingTex
    if (dynamicTexture) positionDynamicImage(physicalObject,dynamicTexture)
    
    useEffect(() => {
        if (!gltf) return;
        const tempMesh = gltf.scene.clone();
        let texture = dynamicTexture
        if (!texture) return;
        tempMesh.traverse((child) => {
            if(child.isMesh) {                
                child.castShadow = true;
                child.receiveShadow = true;
                if(child.name == physicalObject.meshName) {
                    child.userData.key = physicalObject.key;
                    let tempMaterial = child.material.clone();
                    tempMaterial.map = texture;
                    //tempMaterial.emissiveMap = texture;
                    //tempMaterial.emissiveMapIntensity = 0;
                    tempMaterial.map.flipY = physicalObject.flipY || false;
                    tempMaterial.map.needsUpdate = true;
                    tempMaterial.envMapIntensity = physicalObject.envMapIntensity || 1.0;
                  //  tempMaterial.metalness = 0.1;
                    tempMaterial.toneMapped = toneMapDynamicObjectsFlag;
                    child.material = tempMaterial;
                    if (texture) child.material.needsUpdate = true;
                }
            }
        })
        if (texture) texture.needsUpdate = true; 
        setMesh(tempMesh)
    },[gltf,dynamicImage])

    useEffect(() => { 
        if (dynamicObjRef.current) onLoad();
    } ,[dynamicObjRef.current,mesh])

    if (mesh) return <primitive castShadow receiveShadow object={mesh} ref={dynamicObjRef} />;
    else return (null);
}

const createCanvasTexture = (src) => {
    const canvasText = new CanvasTexture(src)
    canvasText.minFilter = LinearFilter;canvasText.maxFilter = LinearFilter;
    //canvasText.encoding = sRGBEncoding; 
    canvasText.colorSpace = SRGBColorSpace;
    canvasText.center.set(0.5, 0.5);    
    return canvasText
}
const createPlaneGeometry = (width, height) => { return new BoxGeometry(width, 0.5, height, 24, 24); }


const useImage = (imageUrl) => {
    if (!imageUrl) return null;
    if (imageUrl instanceof CanvasTexture) return imageUrl
    const [image, setImage]=useState()
    
    useEffect(() => {
        if (imageUrl==null) return;        
        if (imageUrl instanceof HTMLCanvasElement) setImage(imageUrl)
        else {
            const canvasImg = new Image()
            canvasImg.crossOrigin = "anonymous";
            if (imageUrl?.files?.file) { canvasImg.src = getUrlFromRef(imageUrl?.files.file,true) }
            else if (imageUrl?.ctx) { /* empty */ }
            else { canvasImg.src = getUrlFromRef(imageUrl,true) }
            canvasImg.onload = () => { setImage(canvasImg) }     
        }
    },[imageUrl])
    
    return image
}


const positionDynamicImage = (physicalObject, tex) => {
    let imgRatio = physicalObject.dynamicMaterialProps.width / physicalObject.dynamicMaterialProps.height;
    let ratio = 1;

    if(physicalObject.aspect > 0) {
        ratio = physicalObject.aspect / imgRatio;
        if(ratio > 1) {
            tex.repeat.y = 1.0 / ratio;
        } else {
            tex.repeat.x = ratio;
        }
    }
    if(physicalObject.dynamicMaterialProps?.repeat?.x != null) {
        tex.repeat.set(physicalObject.dynamicMaterialProps?.repeat?.x, physicalObject.dynamicMaterialProps?.repeat?.y);
    }
    if(physicalObject.dynamicMaterialProps?.offset?.x != null) {
        tex.offset.x = physicalObject.dynamicMaterialProps?.offset?.x;
        tex.offset.y = physicalObject.dynamicMaterialProps?.offset?.y;
    }
}

export const iphonePhysicalObject = {
	category:'Objects',
	name:'iPhone',
    mode:'iPhone',
    meshName: 'Mesh_1',
	isDynamic:true,
    scale: 2, 
    flipY: false,
    rotation:[0, 0, 0],
    aspect: 7.133/15.386,
    centered: false,
    envMapIntensity: 1.0,
	files: {        
        thumbnail: "/images/dynamic_object_thumbnails/iphone12.jpg", 
        medium:'/files/dynamic_object_gltfs/iphone-blender.glb',
	}
}

export const polaroidPhysicalObject = {
    category: 'Objects',
	name: 'Polaroid',
    mode: 'Polaroid',
    meshName: 'dynamic_image',
	isDynamic: true,
    scale: 2,     
    flipY: false,
    rotation:[0, 0, 0],
    aspect: 49.098/45.878,
    centered: true,
    envMapIntensity: 1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/polaroid.jpg", 
        medium:"/files/dynamic_object_gltfs/polaroid.glb"
    }
}

export const planePhysicalObject = {
    category: 'Objects',
	name: 'Plane',
    mode: 'Plane',
    meshName: 'mesh_dynamic',
	isDynamic: true,
    scale: 0.025,
    envMapIntensity:1.0,
    rotation:[0, 0, 0],
    files: { thumbnail: '/images/dynamic_object_thumbnails/plane.jpg' }
}

export const magazinePhysicalObject = {
    category: 'Objects',
	name: 'Magazine',
    mode: 'Magazine',
    meshName: 'Mesh_1',
	isDynamic: true,
    flipY: false,
    scale: 5, 
    rotation:[0,0, 0],
    aspect: 4.58466 / 3.4988,
    centered: true,
    envMapIntensity: 1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/magazine.jpg", 
        medium:"/files/dynamic_object_gltfs/mag3.glb"
    }
}
export const iwatchPhysicalObject = {
    category: 'Objects',
	name: 'iWatch',
    mode: 'iWatch',
    meshName: 'dynamic_image',
	isDynamic: true,
    scale: 2, 
    flipY: false,
    rotation:[0, 0, 0],
    aspect: 158.362/184.786,
    centered: true,
    envMapIntensity: 1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/iwatch.jpg", 
        medium:"/files/dynamic_object_gltfs/iwatch-blender.glb"
    }
}
export const stickPosterPhysicalObject = {
    category: 'Objects',
	name: 'Taped Poster',
    mode: 'stickPoster',
    meshName: 'dynamic_image',
	isDynamic: true,
    scale: 5.5, 
    flipY: false,
    rotation:[0, 0, 0],
    aspect: 20.995/29.895,
    centered: true,
    envMapIntensity: 1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/tapedPoster.jpg", 
        medium:"/files/dynamic_object_gltfs/poster-with-tape.glb"
    }
}
export const tornPosterPhysicalObject = {
    category: 'Objects',
	name: 'tornPoster',
    mode: 'tornPoster',meshName: 'torn_poster_01',isDynamic: true,scale: 0.7, 
    flipY: false,rotation:[0, 0, 0],aspect: 17.38/24.532,centered: true,
    envMapIntensity: 1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/tornPoster.jpg", 
        medium:"/files/dynamic_object_gltfs/torn-poster.glb"
    }
}


export const BendedPolaroid1 = {
    category: 'Objects', name: 'Bended Polaroid',mode: 'polaroid-type-1',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 150/150,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/bended-polaroid.jpg", 
        medium:"/files/dynamic_object_gltfs/bend_polaroid.glb"
    }
}

export const MagazineFolded = {
    category: 'Objects', name: 'Magazine Folded',mode: 'magazine-folded',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 100/150,scale: 19, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/magazine-folded.jpg", 
        medium:"/files/dynamic_object_gltfs/magazine-folded.glb"
    }
}
export const MagazineFull = {
    category: 'Objects', name: 'Magazine Full',mode: 'magazine-full',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 150/100,scale: 19, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/magazine-full.jpg", 
        medium:"/files/dynamic_object_gltfs/magazine-full.glb"
    }
}
export const Hardcover = {
    category: 'Objects', name: 'Hardcover',mode: 'hardcover',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 150/100,scale: 20, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/hardcover.jpg", 
        medium:"/files/dynamic_object_gltfs/hardcover.glb"
    }
}
export const PinnedRound = {
    category: 'Objects', name: 'Pin Rounded',mode: 'pin-rounded',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 150/100,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/pinned-round.jpg", 
        medium:"/files/dynamic_object_gltfs/pinned-round.glb"
    }
}
export const PinnedSquare = {
    category: 'Objects', name: 'Pin Square',mode: 'pin-square',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 100/100,scale: 27, envMapIntensity:1.2,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/pinned-square.jpg", 
        medium:"/files/dynamic_object_gltfs/pinned-square.glb"
    }
}
export const PinnedVert = {
    category: 'Objects', name: 'Pin Portrait',mode: 'pin-potrait',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 100/150,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/pinned-vert.jpg", 
        medium:"/files/dynamic_object_gltfs/pinned-vert.glb"
    }
}
export const FilmStrip = {
    category: 'Objects', name: 'Film Strip',mode: 'film-strip',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 150/100,scale: 9, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/polaroid-portrait.jpg", 
        medium:"/files/dynamic_object_gltfs/film_strip.glb"
    }
}
export const iPad = {
    category: 'Objects', name: 'Film Strip',mode: 'film-strip',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 100/150,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/polaroid-portrait.jpg", 
        medium:"/files/dynamic_object_gltfs/ipad.glb"
    }
}
export const Bent1 = {
    category: 'Objects', name: 'Bent 1',mode: 'bent1',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 150/113,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/polaroid-portrait.jpg", 
        medium:"/files/dynamic_object_gltfs/bentL.glb"
    }
}
export const BentPotrait = {
    category: 'Objects', name: 'Bent Paper Portrait',mode: 'bent-paper-portrait',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 125/156,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/polaroid-portrait.jpg", 
        medium:"/files/dynamic_object_gltfs/bent-portrait.glb"
    }
}
export const BentSquare = {
    category: 'Objects', name: 'Bent Square',mode: 'bent-square',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 125/125,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/bent-square.jpg", 
        medium:"/files/dynamic_object_gltfs/bent-square.glb"
    }
}

export const TornSide = {
    category: 'Objects', name: 'Torn Side',mode: 'torn-side',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 100/125,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/torn-side.jpg", 
        medium:"/files/dynamic_object_gltfs/torn-side.glb"
    }
}
export const TornLeftSide = {
    category: 'Objects', name: 'Torn Left Side',mode: 'torn-left-side',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 170/96,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/torn-left-side.jpg", 
        medium:"/files/dynamic_object_gltfs/torn-left-side.glb"
    }
}
export const TornSquare = {
    category: 'Objects', name: 'Torn Square',mode: 'torn-square',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect:1,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/torn-square.jpg", 
        medium:"/files/dynamic_object_gltfs/torn-square.glb"
    }
}
export const RoundedSquare = {
    category: 'Objects', name: 'Rounded Square',mode: 'rounded-square',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect:1,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/rounded-square.jpg", 
        medium:"/files/dynamic_object_gltfs/rounded-square.glb"
    }
}
export const PolaroidSquare = {
    category: 'Objects', name: 'Polaroid Square',mode: 'polaroid-square',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 1,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/polaroid-square.jpg", 
        medium:"/files/dynamic_object_gltfs/polaroid-square.glb"
    }
}
export const PinnedLandscape = {
    category: 'Objects', name: 'Pinned Landscape',mode: 'pinned-horizontal',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 150/100,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/pinned-landscape.jpg", 
        medium:"/files/dynamic_object_gltfs/pinned-horizontal.glb"
    }
}
export const BendedLandscape = {
    category: 'Objects', name: 'Bended Landscape',mode: 'bend-landscape',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 150/100,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/bended-landscape.jpg", 
        medium:"/files/dynamic_object_gltfs/bend-landscape.glb"
    }
}
export const BendedPortrait = {
    category: 'Objects', name: 'Bended Portrait',mode: 'bended-potrait',isDynamic: true,
    meshName: 'dynamic_image',rotation:[0, 0, 0],aspect: 125/156,scale: 27, envMapIntensity:1.0,
    files: {
        thumbnail: "/images/dynamic_object_thumbnails/bended-portrait.jpg", 
        medium:"/files/dynamic_object_gltfs/bended-portrait.glb"
    }
}

export const dynamicObjects=[planePhysicalObject,polaroidPhysicalObject,BendedPolaroid1,
    magazinePhysicalObject,MagazineFull,MagazineFolded,Hardcover,iphonePhysicalObject,iwatchPhysicalObject,stickPosterPhysicalObject,BendedPortrait,
    tornPosterPhysicalObject, TornSquare,TornLeftSide,TornSide,PolaroidSquare,RoundedSquare,
    BentSquare,BendedLandscape,PinnedLandscape,PinnedSquare,PinnedRound, PinnedVert
]



