import {Button, IconButton} from '@mui/material'
import { styled } from '@mui/system';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";


export const BarIconButton = styled(IconButton)(({ theme }) => ({ 
    button: { maxHeight:theme?.barButtons?.maxHeight ?? 30 },
}))

export const BarImage = styled('img')({
    borderRadius:'10px',
    height:'50px',
    width:'70px',
    border:'solid 3px lightgray'
});

export const NoMaxWidthTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
  ))({
	[`& .${tooltipClasses.tooltip}`]: {
	  maxWidth: "none"
	}
  });

  export const ActionButton = styled(Button)(({ theme }) => ({ 
    color:'white', 
    width: '100%', 
    background:theme.palette.primary.shades[900],
    marginRight:'7px', 
    maxHeight:theme.barButtons.maxHeight,
    '&:hover': { 
      background:theme.palette.primary.shades[100] 
    },
    '&.Mui-disabled': { 
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    },
  }));

  export const ActionButtonSecondary = styled(Button)(({ theme }) => ({ 
    color:'white', 
    width: '100%', 
    background:theme.palette.primary.tint['t1'],
    marginRight:'7px', 
    maxHeight:'26px',
    fontSize:'0.75rem',
    '&:hover': { 
      background:theme.palette.primary.tint['t2'] 
    },
    '&.Mui-disabled': { 
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    },
  }));


  export const TabButton = styled(({ ...other }) => (
    <Button disableRipple={true} {...other} />
  ))(({ theme }) => ({
      borderRight: '0px !important',
      color: theme.palette.primary.shades[800],
      '&.selected':{
        color: theme.palette.primary.shades[900],
        borderBottom: `4px solid ${theme.palette.primary.shades[200]}`,
        borderRadius: '0px'
      },
      '&:hover': {
        background  :'none'
      }
}));

