import { styled } from "@mui/material/styles";

export const PopoverWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  // top: "72px",
  // right: "20px",
  width: "300px",
  maxHeight: "350px",
  overflowY: "auto",
  background: "white",
  zIndex: 11,
  boxShadow:
    "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  padding: "8px",

  ".title": {
    fontFamily: "Roundo-Regular",
    fontSize: "14px",
    fontWeight: "500",
    margin: "8px 16px",
  },

  ".collection-list": {
    margin: "20px 0",
    height: "180px",
    overflowY: "auto",
    overflowX: 'hidden',

    ".collection-item": {
      borderBottom: "1px solid rgba(0,0,0,0.1)",
      padding: "6px 16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "&:last-child": {
        borderBottom: "none",
      },
      span: {
        fontFamily: "Roundo-Regular",
        fontSize: "16px",
        fontWeight: "500",
      },

      "&.selected": {
        backgroundColor: "#F7F0FA",
      },
    },

    ".no-collections": {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Roundo-Regular",
    },
  },
}));
