import { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import React from "react";
import Header from "../header";
import { ProductsGrid } from "./styles";
import { getDocs, query, orderBy, limit, startAfter,where} from "firebase/firestore";
import { getUrlFromRef, suppliersDB } from "../../libs/firebase";
import BrandHeader from "../Brand/BrandHeader";
import { useRef } from "react";
import { useInView } from "react-intersection-observer";
import { cloudflareCDN } from "ui";
import { useNavigate } from "react-router-dom";

    //const cachedState = sessionStorage.getItem('suppliers');
    //return cachedState ? JSON.parse(cachedState) : [];

const Brands = () => {
  const rootUploadContainer = useRef()
	const { ref, inView, entry } = useInView({root:rootUploadContainer.current,threshold: 0,});
  const [suppliers, setSuppliers] = useState(() => {return []

  });
  const history = useNavigate();

    const loadSuppliers = () => {
      const a = [suppliersDB, where("isActive","==",true),  orderBy("name","asc"),limit(100)]
      if (suppliers.length > 0) {
        a.splice(a.length-1, 0, startAfter(suppliers[suppliers.length-1].name))
      }
      const q = query.apply(this, a)
      getDocs(q).then ( querySnapshot => {
        const prevSuppliers =  suppliers
        const dataTemp:any = []
        querySnapshot.forEach((doc) => {dataTemp.push(doc.data()) })
        const newSuppliersValue = [...prevSuppliers, ...dataTemp]
        if(newSuppliersValue.length > 0){
          //sessionStorage.setItem('suppliers', JSON.stringify(newSuppliersValue));
          setSuppliers(newSuppliersValue)
        }
          console.log("New supplies is ", newSuppliersValue.length);
      })
      .catch((error) => { console.log("Error getting suppliers: ", error); });
    }

	useEffect(() => {
		if (inView==true){
			loadSuppliers()
		}		
	},[inView])

  const navToBrand = (supplier) => { 
   if (supplier?.slug) history(`/brand/${supplier?.slug}`)
   else history(`/brand?id=${supplier?.id}`)  
   // history(`/brand?id=${supplier?.id}`)  
  }

  return (
    <Grid style={{background:'white', overflow: 'hidden'}}>
      <Header/>
      <ProductsGrid>
          <Grid ref={rootUploadContainer} container item xs={12} textAlign={'center'} justifyContent="space-evenly" style={{paddingTop:'150px',maxHeight:'100vh',overflow:'auto'}}>
              {suppliers.map( (supplier, index) => {   
                return(
                  <Grid key={index}  item xs={6} >  

                    <img src={cloudflareCDN(getUrlFromRef(supplier?.files?.logo || supplier?.logo) ,'width=200,format=auto')} 
                    loading="lazy" style={{cursor: "pointer", width: '200px', height: '200px', objectFit: 'contain'}}
                    onClick={ () => navToBrand(supplier) } />         
                  </Grid>)
                })} 
              <Grid key={'placeholder'} ref={ref} item xs={12}>
                <img draggable={false} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==" height={1} alt="Red dot" />
              </Grid>
          </Grid>
      </ProductsGrid>
    </Grid>
  );
};

export default Brands;


