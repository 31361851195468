import React, { useRef,useState,useCallback,useEffect,useContext } from 'react'
import {useMattoState,usePhysicalObjects,useProjectData} from '../MattoState'
import {CameraControls} from './CameraControls'
import {OriginalScene} from './Scenes'
import {PhysicalObject} from './PhysicalObject'
import {TransformManager} from './TransformManager'
import {TransformController} from './TransformController'
import { TextureTransformController } from './TextureTransformController'
import {DropPhysicalObject} from './DropPhysicalObject'
import useEventListener from '../libs/useEventListener'
import { AuthContext } from "../auth/Auth"
import { CopyPasteObject} from './CopyPasteObject'
import { DebuggingModalBox } from "./DebuggingModalBox"
import { IsUrlShow } from '../libs/util'
import { ExportCurrentProject } from '../components/ExportCurrentProject'
import { UVChecker } from '../components/UVChecker'
import { AnimateButton } from '../buttons/AnimateButton'
import { Snackbar } from "@mui/material";
import { PerfHook } from './PerfHook';

function intersectionsFilter(intersections) {
  return intersections?.length ? [intersections[0]] : intersections;
}
export const Board = (props) => {
	let currentUser = null;
	if (AuthContext)currentUser = useContext(AuthContext)?.currentUser;
	const postRef = useRef()
	const setSelectedPhysicalObjectKey = usePhysicalObjects( (state) => state.setSelectedPhysicalObjectKey)
	const [showLockedObjectMessage,setShowLockedObjectMessage] = useState(false)
	const canvasCreated = (canvas) => {
		window.gl = canvas.gl
		//window.gl.useLegacyLights = false
		window.scene = canvas.scene
		window.camera = canvas.camera
		window.raycaster = canvas.raycaster
		window.post = postRef
	}
	return (
		<>
		{!IsUrlShow() && <TransformManager />}
		<StartingCanvas  
		onCreated={(state) => {state.setEvents({ filter: intersectionsFilter});canvasCreated(state)}}
		frameloop={'demand'}  style={ props.style ?? {height:'500px'}}
 		onPointerMissed={ () => { setSelectedPhysicalObjectKey(null); }  } > 
		  <AnimateButton />
		  <ExportCurrentProject />
		  <UVChecker />
		  <PhysicalObjects showLockedObjectMessage={showLockedObjectMessage} setShowLockedObjectMessage={setShowLockedObjectMessage}/>				
		  <CameraControls />
		  {/* <PostProcess /> */}
		  <PerfHook />
		  {!IsUrlShow() && <DropPhysicalObject currentUser={currentUser}/>}
		  {props.children}
		</StartingCanvas>
		  {!IsUrlShow() && <CopyPasteObject/>}
		<DebuggingModalBox />
		<Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center',}} autoHideDuration={2000}  onClose={ ()=> { setShowLockedObjectMessage(false) }} open={showLockedObjectMessage} message={'Object is locked, unlock to move!'} />
    	</>
	)
}


const StartingCanvas = (props) => {
    const scenes=['original']
	const scene = scenes[0]
	if (scene=='original') { return (<OriginalScene {...props} />) }		
	else return null
}

const PhysicalObjects = ({ showLockedObjectMessage, setShowLockedObjectMessage }) => {
	const transformMode = useMattoState( (state) => state.transformMode)
	const physicalObjects = usePhysicalObjects((state) => state.physicalObjects)
	const setSelectedPhysicalObjectKey = usePhysicalObjects( (state) => state.setSelectedPhysicalObjectKey)
	const selectedPhysicalObject = usePhysicalObjects(state=>state.selectedPhysicalObject)
	const isObjectLocked = usePhysicalObjects( (state) => state.isObjectLocked)

	const handleKeyDown = (e) =>  {
		if (IsUrlShow()) return;
		if (e.keyCode==27 && document.getElementById("root")?.classList.contains("dropper-cursor")) { document.getElementById("root")?.classList.remove("dropper-cursor")}
		if (e.target!=document.body) return;
		if (e.keyCode==8 || e.keyCode==46) { usePhysicalObjects.getState().deleteSelectedObject(); }
		else if (e.keyCode==9 && physicalObjects.length > 0) {
		  e.preventDefault()
		  let idx = physicalObjects.indexOf(selectedPhysicalObject) + 1
		  if (idx >= physicalObjects.length) idx = 0;
		  setSelectedPhysicalObjectKey(physicalObjects[idx].key)
		}
	
	}
	const handleMouseUp = (e) =>  {	
		if (selectedPhysicalObject && e.target.nodeName == 'CANVAS' && isObjectLocked(selectedPhysicalObject.key)) {
			if(!showLockedObjectMessage) setShowLockedObjectMessage(true)
		}
	}
   useEventListener('keydown', handleKeyDown);
   useEventListener('mouseup', handleMouseUp);

	return (
		<>
		{!IsUrlShow() && transformMode != null  && transformMode != 'texture' && selectedPhysicalObject && <TransformController />}
		{!IsUrlShow() && transformMode == 'texture' && selectedPhysicalObject && <TextureTransformController />}
		
		{physicalObjects.map((data, index) => (
	        <PhysicalObject physicalObject={data} key={data.key} /> 
	    ))}	
		</>
	)
}
