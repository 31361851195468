import { fetchData  } from "../libs/util.ts";
import { create } from "zustand";
import { getPriceURL, getPricesAndLocationsAPIUrl } from "./config.js";
import * as Sentry from "@sentry/react";

export enum Plans { yearly = 'yearly', monthly = 'monthly' }
export enum UserTypes { student = 'student', pro = 'pro', adopter = 'adopter' }
export enum LookupKey {
    pro_monthly = 'pro_monthly',
    pro_yearly = 'pro_yearly',
    student_monthly = 'student_monthly',
    student_yearly = 'student_yearly'
}
export interface ProfilePrices {
    productID: string | null,
    priceID:string | null,
    planType: Plans,
    userType: UserTypes,
    currency: string,
    lookup_key: LookupKey,
    local_price: number | null,
}
export const useStripeStore = create( (set:any, get:any) => ({
    local_currency:null,
    stripe_prices: null,
    profile_prices:{},
    processing:false,
    retry:false,
  
    setProductAndPrices: async () => {
        let stripe_prices = get().stripe_prices
        let currency = get().local_currency
        set({profile_prices:get().generaeteProfilePrices(stripe_prices,currency ) })

        if (get().processing==true) return  //everything below happens only one time
        set({processing:true})
        const queryParams = new URLSearchParams(window.location.search);
        const forceCurrency = queryParams.get('forcecurrency');
        const resp = await fetchData( {}, getPricesAndLocationsAPIUrl)
        
        if (forceCurrency) { currency = forceCurrency.toLowerCase(); }
        else { currency = resp?.location?.currency?.code?.toLowerCase() || 'usd' }
        set({ local_currency: currency });

        if (!resp?.prices) {           
            if (get().retry==true) {
                console.log("No prices found need to try again!")
                Sentry.captureException("No prices found need to try again!")
                setTimeout(() => { get().setProductAndPrices() }, 1000)
            }
            set({processing:false, retry:true})
            return
        }
        stripe_prices = resp.prices
                
        stripe_prices.data.map( (d) => {
            if (!d.currency_options) d.local_price = d.unit_amount_decimal
            else   {         
                if (d.currency_options[currency]) {
                    d.local_price = d.currency_options[currency]?.unit_amount_decimal   
                    d.local_currency = currency                 
                }       
                else {
                    d.local_price = d.currency_options['usd'].unit_amount_decimal
                    d.local_currency = 'usd'
                }
            }
        })
        if (window?.debug) window.debug.stripe_prices = stripe_prices 
        set({stripe_prices:stripe_prices})
        set({profile_prices:get().generaeteProfilePrices(stripe_prices, currency ) })
    },

    generaeteProfilePrices:(stripe_prices,currency='usd') => {
        const profilePrices={}
        const lookupKeys = Object.values(LookupKey)
        lookupKeys.map( (lookupKey) => {
            const profilePrice = get().createProfilePrices(lookupKey,stripe_prices,currency)
            profilePrices[lookupKey] = profilePrice
        })
        return profilePrices        
    },

    createProfilePrices: (lookupKey:LookupKey,stripe_prices,currency):ProfilePrices => {
        let userType:UserTypes=UserTypes.pro
        let planType:Plans = Plans.monthly
        if (lookupKey==LookupKey.pro_monthly) {userType = UserTypes.pro; planType = Plans.monthly }
        else if (lookupKey==LookupKey.pro_yearly) {
            userType = UserTypes.pro; planType = Plans.yearly
        }
        else if (lookupKey==LookupKey.student_monthly) {
            userType = UserTypes.student; planType = Plans.monthly
        }
        else if (lookupKey==LookupKey.student_yearly) { userType = UserTypes.student; planType = Plans.yearly }        
        const profilePrice:ProfilePrices = {
            productID: null,priceID: null, planType: planType, userType: userType,
            currency: currency, lookup_key: lookupKey,
            local_price: null
        } as ProfilePrices
        if (!stripe_prices) return profilePrice
    
        const price = stripe_prices.data.filter(d => d.lookup_key==lookupKey)?.[0]
        if (!price?.local_price) return profilePrice
        profilePrice.local_price = price.local_price
        profilePrice.currency = price.local_currency
        profilePrice.priceID = price.id
        profilePrice.productID = price.product
        return profilePrice
    }
  }))