import React, { useEffect, useState } from "react";
import {firebaseApp} from "../libs/firebase"
import { getAuth, onAuthStateChanged } from "firebase/auth";

//https://github.com/satansdeer/react-firebase-auth
export const AuthContext = React.createContext();

export const AuthProvider = ({ children, uid }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  useEffect(() => {
    if(!uid) {
      onAuthStateChanged( getAuth(firebaseApp), (user) => {
        //await fetchProfile(user)
        //if (user!=null) return user;
        setCurrentUser(user)
        setPending(false)
      })
    } else {
      const user = {uid: uid}
      setCurrentUser(user)
      setPending(false)      
    }
  }, []);  
  if(pending){
    return <>Loading...</>
  }
  return (
    <AuthContext.Provider
      value={{currentUser}} >
      {children}
    </AuthContext.Provider>   
  );
};