
import React, {useEffect } from 'react'
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree,SAH } from 'three-mesh-bvh'
import { Box3 } from 'three';
import { mergeGeometries } from '../threejs/stdlib/BufferGeometryUtils.js'
import * as Sentry from "@sentry/react";


const targetDepth = 6
export const useBVH = (object3D, options) => {
    const setBVHStuff = (mesh) => {
        mesh.raycast = acceleratedRaycast
        const geometry = mesh.geometry
        if(geometry) {
            geometry.computeBoundsTree = computeBoundsTree
            geometry.disposeBoundsTree = disposeBoundsTree
            geometry.computeBoundsTree(options)
            // let count = 0
            // geometry.boundsTree.traverse( ( depth, isLeaf, boundingData ) => {
            //     const terminate = depth === targetDepth || (isLeaf && depth <= targetDepth)
            //     if ( terminate ) {
            //         count++
            //         const boundingBox = new Box3()
            //         //console.log("Depth is ", depth);
            //         arrayToBox( 0, boundingData, boundingBox );
            //         //console.log(boundingBox);
            //         //boxes.push( boundingBox );
            //         return terminate;
            //     }
            // })
            // console.log("count is ", count);
        }
    }
    useEffect(() => {        
        const geos=[]
        applyTransform(object3D)
        object3D?.current?.traverse( child =>  {if (child.isMesh)  { setBVHStuff(child) }})
        return () => {
            object3D?.current?.traverse(child => {
                if (child.isMesh && child.geometry.boundsTree) {
                    child.geometry.disposeBoundsTree()
                }
            })
        }
    },[object3D,options])
}
export const mergedBVH = (group, depth) => {
    const geos=[]
    const needsMerge = group.children.length > 1 || group.children?.[0].children?.length > 1

    
    group.traverse( c => {
        if ( c.isMesh ) {
            if (!c?.geometry?.clone) {
                const name = group?.name
				Sentry.captureException("Error reading message on ", name)
                return
            }
            const clonedGeometry = c.geometry.clone();
            //clonedGeometry.applyMatrix4( c.matrixWorld );
            for ( const key in clonedGeometry.attributes ) {
                if ( key === 'position' || key === 'normal' ) { continue; }
                clonedGeometry.deleteAttribute( key );
            }
            geos.push( clonedGeometry );
        }
    })    
    const geometry = mergeGeometries(geos)
    geometry.computeBoundsTree = computeBoundsTree
    geometry.disposeBoundsTree = disposeBoundsTree
    geometry.computeBoundsTree({maxDepth:7,verbose:false,})        
    group.geometry = geometry
}
function arrayToBox( nodeIndex32, array, target ) {
	target.min.x = array[ nodeIndex32 ];
	target.min.y = array[ nodeIndex32 + 1 ];
	target.min.z = array[ nodeIndex32 + 2 ];
	target.max.x = array[ nodeIndex32 + 3 ];
	target.max.y = array[ nodeIndex32 + 4 ];
	target.max.z = array[ nodeIndex32 + 5 ];
	return target;
}
//https://stackoverflow.com/questions/58502308/is-it-possible-to-apply-rotation-scale-position-on-three-object3d
function applyTransform(threeJS) {
    // //benchmark
    // const start = performance.now()
    threeJS?.current?.traverse( child => {
      if (child.matrix && child.geometry) {
        child.geometry = child.geometry.clone()
        child.updateMatrix()
        child.geometry.applyMatrix4(child.matrix) //maybe matrixworld?
        child.position.set(0,0,0)
        child.rotation.set(0,0,0)
        child.scale.set(1,1,1)
        child.updateMatrix()
      }  
    })
    //const end = performance.now()
    //console.log("applyTransform took", end-start);
}