import React, { useState, useContext, useEffect, useMemo } from "react";
import { Grid,IconButton,Stack, Typography } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ProfileFirstActions, ProfileInterface } from "ts-interfaces";
import { cloudflareCDN } from "ui";
import { PaymentComponent } from "./PaymentComponent.tsx";
import { getPublicURL } from "../libs/firebase.js";
import { formatPrice, setProfileFirstAction } from "../libs/util.ts";
import { getGoogleAnalyticsProductInfo } from "./config.js";
import { ProfilePrices } from "./useStripeStore.tsx";
import { stripePitchImage as imageUrl } from "./config.js";
import { PromoCodeComponent } from "./PromoCodeComponent.tsx";

interface FinalizePaymentProps {
    clientSecret: any;
    onSuccess: any;
    onBack: any;
    selectedPlan: ProfilePrices;
}
export const FinalizePayment = ({ clientSecret, onSuccess, onBack, selectedPlan }:FinalizePaymentProps) => {
    const [promoCode, setPromoCode] = useState('');
    const [promoId, setPromoId] = useState('');
    const [finalPrice, setFinalPrice] = useState(selectedPlan.local_price);

    useEffect(() => {
      setProfileFirstAction(ProfileFirstActions.FINALIZE_PAYMENT_MODAL).finally(()=>{});
      if (window.gtag && selectedPlan) window.gtag("event", "begin_checkout",  getGoogleAnalyticsProductInfo(selectedPlan))
    },[])

    const onApplyPromoCode = (promo_id: string, discount_percentage: number) => {
      if(selectedPlan.local_price) {
        const discountAmount = (selectedPlan.local_price * discount_percentage) / 100;
        setFinalPrice(selectedPlan.local_price - discountAmount);
        setPromoId(promo_id);  
      }
    }

    const onResetPromoCode = () => {
      setFinalPrice(selectedPlan.local_price);
    }

    return (
      <>
        <Grid sx={{height: '100%',display: 'flex', flexDirection: 'row'}}>
            <Grid  sx = {{padding:"15px", justifyContent: "left",overflowY: 'auto',height:'100%', width: '400px', flexGrow: '0'}}>
              <Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'left', flex: '1'}}>
                <Grid sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Grid>
                    <IconButton onClick={onBack}> 
                        <ChevronLeftIcon />
                    </IconButton>
                  </Grid>
                  <Typography className="main-title" sx={{paddingBottom:'0px'}} >Finalize Payment</Typography> 
                </Grid>
                <Grid item xs={12} sx={{paddingTop:'20px',paddingBottom:'20px'}}>

                  <Stack spacing={1} >
                  <Stack spacing={2} direction={'row'}  justifyContent="space-between" alignItems="center">
                    <Typography sx={{fontSize:'0.9rem'}}>Total Amount:  </Typography>
                    <Typography sx={{fontSize:'0.9rem'}} data-price={finalPrice}> {formatPrice(finalPrice,selectedPlan.currency,true)}  </Typography>
                  </Stack >
                  <Stack spacing={2} sx={{paddingTop: '10px'}}>
                    <PromoCodeComponent promoCode={promoCode} setPromoCode={setPromoCode} onApplyPromoCode={onApplyPromoCode} onResetPromoCode={onResetPromoCode} selectedPlan={selectedPlan} />  
                  </Stack>
                  {/* <Stack sx={{paddingTop:'0px'}} spacing={2} direction={'row'}  justifyContent="space-between" alignItems="center">
                    <Typography sx={{fontWeight:'700',fontSize:'0.9rem'}}>Due Today (30 days free) </Typography>
                    <Typography sx={{fontWeight:'700',fontSize:'0.9rem'}}>$0.00  </Typography>
                  </Stack> */}
                  </Stack>

                </Grid>

                <PaymentComponent clientSecret={clientSecret} onSuccess={onSuccess} selectedPlan={selectedPlan} promoId={promoId} />

              </Grid>            
            </Grid>
            <Grid sx={{display: "flex", width: 'calc(100% - 400px)',height:'100%', overflow: "hidden", borderRadius: "0px 15px 15px 0", flexGrow: '1'}}>
              <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={cloudflareCDN(getPublicURL(imageUrl), 'width=600,format=auto')} loading="lazy" alt=""  />     
            </Grid>
        </Grid>  
         
      </>
    );
  };
