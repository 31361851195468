import { EnvironmentalMap } from '../ts/app_interfaces';

export const getEnvironmentalMap= (preset:string):EnvironmentalMap => {
    return environmentalMaps.filter(env => env.preset === preset)[0];
}

export const environmentalMaps = [
	{name:'Dikhololo Night', preset:'night',
		small_1k:'gs://mattoboard-b8284.appspot.com/envmaps/dikhololo_night_1k.pic',
		large_4k:'gs://mattoboard-b8284.appspot.com/envmaps/dikhololo_night_4k.pic',
		thumbnail:'gs://mattoboard-b8284.appspot.com/envmaps/dikhololo_night.webp',
	} as EnvironmentalMap,
	{name:'Kiara Dawn', preset:'dawn',
		small_1k:'gs://mattoboard-b8284.appspot.com/envmaps/kiara_1_dawn_1k.pic',
		large_4k:'gs://mattoboard-b8284.appspot.com/envmaps/kiara_1_dawn_4k.pic',
		thumbnail:'gs://mattoboard-b8284.appspot.com/envmaps/kiara_1_dawn.webp',
	}  as EnvironmentalMap,
	{name:'St Fagans Interior', preset:'lobby',
		small_1k:'gs://mattoboard-b8284.appspot.com/envmaps/st_fagans_interior_1k.pic',
		large_4k:'gs://mattoboard-b8284.appspot.com/envmaps/st_fagans_interior_4k.pic',
		thumbnail:'gs://mattoboard-b8284.appspot.com/envmaps/st_fagans_interior_1k.webp'		
	}  as EnvironmentalMap,
	{name:"Empty Warehouse", preset:'warehouse', 
		small_1k:'gs://mattoboard-b8284.appspot.com/envmaps/empty_warehouse_01_1k.pic',
		large_4k:'gs://mattoboard-b8284.appspot.com/envmaps/empty_warehouse_01_4k.pic',
		thumbnail:'gs://mattoboard-b8284.appspot.com/envmaps/empty_warehouse.webp'
	}  as EnvironmentalMap,
	{name:'Venice Sunset', preset:'sunset',
		small_1k:'gs://mattoboard-b8284.appspot.com/envmaps/venice_sunset_1k.pic',
		large_4k:'gs://mattoboard-b8284.appspot.com/envmaps/venice_sunset_4k.pic',
		thumbnail:'gs://mattoboard-b8284.appspot.com/envmaps/venice_sunset.webp'		
	} as EnvironmentalMap,
	{name:'Postdamer Platz', preset:'city',
		small_1k:'gs://mattoboard-b8284.appspot.com/envmaps/potsdamer_platz_1k.pic',
		large_4k:'gs://mattoboard-b8284.appspot.com/envmaps/potsdamer_platz_4k.pic',
		thumbnail:'gs://mattoboard-b8284.appspot.com/envmaps/postdamer_platz.webp'		
	}  as EnvironmentalMap,
	{name:'Neon Photostudio', preset:'photostudio',
		small_1k:'gs://mattoboard-b8284.appspot.com/envmaps/neon_photostudio_1k.hdr',
		large_4k:'gs://mattoboard-b8284.appspot.com/envmaps/neon_photostudio_4k.hdr',
		thumbnail:'gs://mattoboard-b8284.appspot.com/envmaps/neon_photostudio.webp'		
	}  as EnvironmentalMap,
	{name:'Studio Small 09', preset:'studio',
		small_1k:'gs://mattoboard-b8284.appspot.com/envmaps/studio_small_09_1k.hdr',
		large_4k:'gs://mattoboard-b8284.appspot.com/envmaps/studio_small_09_4k.hdr',
		thumbnail:'gs://mattoboard-b8284.appspot.com/envmaps/studio_small_09.webp'		
	}  as EnvironmentalMap,
	{name:'Cayley Interior', preset:'interior',
		small_1k:'gs://mattoboard-b8284.appspot.com/envmaps/cayley_interior_1k.hdr',
		large_4k:'gs://mattoboard-b8284.appspot.com/envmaps/cayley_interior_1k.hdr',
		thumbnail:'gs://mattoboard-b8284.appspot.com/envmaps/cayley_interior.webp'		
	}  as EnvironmentalMap,
] as Array<EnvironmentalMap>

