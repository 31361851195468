import React, { useState, useEffect, useContext, useRef } from 'react'
import { Box, Grid, Button, ButtonGroup, Typography} from '@mui/material'
import { AuthContext } from "../auth/Auth.jsx";
import { Stack, styled } from '@mui/system';
import { ScrollableGrid } from './ScrollableGrid.jsx';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import { TypesenseSearch } from '../instasearch/TypesenseSearch.tsx';
import { CollectionHits } from '../instasearch/CollectionHits.tsx';
import { curatedTemplateUserID, getDocByID } from '../libs/firebase.js';
import { CollectionInterface, ProductType } from 'ts-interfaces';
import { getCollections, useLoginState } from '../studioxr/auth/LoginState.ts';
import { useMattoState } from '../MattoState.ts';
import { ArrowBack } from '@mui/icons-material';
import { cloudflareCDN } from 'ui';
import { SideBarMode } from '../ts/app_interfaces.ts';
import { TabButton } from '../buttons/ButtonStyles.jsx';
import { useLoadProjectFromCollection } from './CollectionLoadProject';
import { ActionButton } from '../../../creator/src/buttons/ButtonStyles.jsx';

TimeAgo.addLocale(en)
const getNoOfItems = (coll:CollectionInterface) =>   coll.products.length + (coll.userImages ? coll.userImages.length : 0) + (coll.userMaterials ? coll.userMaterials.length : 0) 
enum CollectionType { MY = 'my', CURATED = 'curated' }

export function CollectionTab () {
    const {currentUser} = useContext(AuthContext);
    const collections = useLoginState( (state) => state.collections)  
    const getMoreCollections = useLoginState( (state) => state.getMoreCollections)
    const [collectionType, setCollectionType]=useState<CollectionType>(CollectionType.CURATED) 
    const [curatedCollections, setCuratedCollections]=useState<CollectionInterface[]>([])
    const [curatedCollectionsLoaded, setCuratedCollectionsLoaded]=useState(false)
    const [selectedCollection, setSelectedCollection]=useState<CollectionInterface | null>(null)
    const loadProjectFromCollection = useLoadProjectFromCollection();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const collectionID = urlParams.get('collectionID')
        if (!collectionID) return
        getDocByID('usersMyCollection', collectionID).then((record) => {
            const collection:CollectionInterface = record.data() as CollectionInterface
            if (collection) {
                setSelectedCollection(collection);
                if(collection.projects && collection.projects.length > 0) loadProjectFromCollection(collection.projects[0], true);
            }
            useMattoState.getState().setSideBarMode(SideBarMode.Collections)
        })
    },[])

    useEffect(() => {
        if (selectedCollection != null && collections?.length > 0) {
            const collection = collections.filter((c:CollectionInterface) => c.id == selectedCollection.id)?.[0]
            if (collection) setSelectedCollection(collection)
        }
    },[collections])

    useEffect(() => { 
        if (collectionType == CollectionType.MY && collections.length == 0) getMoreCollections()
        else if (collectionType==CollectionType.CURATED && curatedCollections.length == 0) getItems()
    },[collectionType])

    const getItems = () => {        
        if (collectionType == CollectionType.MY) return getMoreCollections()
        else if (curatedCollectionsLoaded==false) {
            getCollections(curatedTemplateUserID, curatedCollections, 20).then((newCuratedCollections) => {
                const mergedCollections = [...curatedCollections, ...newCuratedCollections]
                if (newCuratedCollections.length < 20) setCuratedCollectionsLoaded(true)
                setCuratedCollections(mergedCollections as CollectionInterface[])
            });
        }
    }
    const handleMouseOver = (e,id) =>  { { e.preventDefault(); 
        const d = document.getElementById(id+'delIcon')
        if (d) d.style.visibility = 'visible'
        const ee = document.getElementById(id+'editIcon')
        if (ee) ee.style.visibility = 'visible'
    }}
 	const handleMouseOut = (e,id) =>  { { e.preventDefault(); 
        const d = document.getElementById(id+'delIcon')
        if (d) d.style.visibility = 'hidden'    
        const ed = document.getElementById(id+'editIcon')
        if (ed) ed.style.visibility = 'hidden'
    }}
    const deleteIconOnclick = (e, collection) => {
        e.stopPropagation();
        if (window.confirm('Are you sure you wish to delete this collection? This action cannot be undone.')){
            useLoginState.getState().deleteCollection(collection)
        }
    } 
    const editIconClick = (e, collection) => {
        useMattoState.getState().setModal({name:'collection',id:collection.id, product:null, userImage:null, userMaterial: null});    
    }
    
    const handleClick = (collection) =>{ 
        //if (collection?.products?.length <= 0 && collection?.userImages?.length <= 0) return
        setSelectedCollection(collection) 
    }
    const handleCloseSelected = () => setSelectedCollection(null)

    const createNewCollection = () => {
        useMattoState.getState().setModal({name:'collection',id:null});    
    }

    if (!currentUser) return null;  
    else if (selectedCollection) return <CollectionList selectedCollection={selectedCollection} onClose={handleCloseSelected} />
    return (
        <Grid container spacing={1} style={{paddingTop:'17px',paddingLeft:'8px',display:'flex'}} >
        <Grid item xs={12} style={{paddingBottom:'5px',paddingTop:'18px',paddingRight:'15px'}} display='flex' justifyContent="center" >
            <ButtonGroup fullWidth={true} disableElevation variant="text"  >
                <TabButton className={collectionType == CollectionType.CURATED ? 'selected' : ''} onClick ={ () => { setSelectedCollection(null); setCollectionType(CollectionType.CURATED) }}>Curated</TabButton>
                <TabButton className={collectionType == CollectionType.MY ? 'selected' : ''} onClick ={ () => { setSelectedCollection(null); setCollectionType(CollectionType.MY) }}>My Collections</TabButton>
            </ButtonGroup>
        </Grid>
        {collectionType == CollectionType.MY  && <Grid item xs={12}>
            <ActionButton onClick={createNewCollection} variant='contained'>Create New Collection</ActionButton>
        </Grid>}
            <ScrollableGrid
                style={{maxHeight:'85vh', overflow:'auto', paddingTop:'10px', paddingLeft:'4px'}}
                loadItems={getItems}
                onClick={handleClick}
                getNoOfItems={getNoOfItems}
                items={collectionType == CollectionType.MY ? collections : curatedCollections}
                draggable={false}
                editIconOnClick={collectionType == CollectionType.MY ? editIconClick: null} 
                deleteIconOnclick={collectionType == CollectionType.MY ? deleteIconOnclick: null} 
                onMouseOver={collectionType == CollectionType.MY ? handleMouseOver : null} 
                onMouseOut={collectionType == CollectionType.MY ? handleMouseOut : null} 
            type='collections'/>
        </Grid>
    ) 
}

export function CollectionList({selectedCollection, onClose, collectionType}) {
    const [productHitsArray, setProductHitsArray]=useState([])
    const areTwoArrayOfObjectsSame = (first_array_of_objects,second_array_of_objects) => {
        return (
            first_array_of_objects.length === second_array_of_objects.length &&
            first_array_of_objects.every((element_1) =>
                second_array_of_objects.some(
                    (element_2) =>
                        element_1.copyFromID === element_2.copyFromID 
                )
            )
        );
    };
    const getProductsHitsFromTypesenseSearch = (data) =>{
        const productTypeData = data.filter((hit) => hit.productType==ProductType.MATERIAL)
        const sameDataReceived = areTwoArrayOfObjectsSame(productHitsArray,productTypeData)
          if(!sameDataReceived){
            setProductHitsArray(productTypeData)
          }
    }
    //if (selectedCollection?.products?.length <= 0 && selectedCollection?.userImages?.length <=0 ) return null;
    return (
        
        <Stack sx={{width:'100%',paddingTop:'30px',paddingLeft:'0px',display:selectedCollection!=null ?'flex':'none', maxHeight: 'calc(100vh - 100px)'}}>
            <Stack direction={'row'} spacing={1} onClick={onClose} style={{cursor:'pointer'}}>
                <ArrowBack  style={{paddingLeft:'10px',paddingBottom:'10px'}} />
                <Typography>Back</Typography>
            </Stack>

            <DisplayCollectionTitle collection={selectedCollection} onClose={onClose}  />
            <TypesenseSearch 
                indexName='productsV2' 
                productType={ProductType.ANY} 
                hitsHandler={CollectionHits} 
                selectedCollection={selectedCollection}
                getProductsHitsFromTypesenseSearch={getProductsHitsFromTypesenseSearch}
                collectionType={collectionType}
            />

        </Stack>

    )
}
const FineText = styled(Typography)(({ theme } ) => ({
    fontSize:'0.75em', fontWeight:'900', color:'black',textOverflow:'ellipsis'
}))
const VeryFineText = styled(Typography)(({ theme } ) => ({
    fontSize:'0.75em', fontWeight:'100', color:'black',textOverflow:'ellipsis'
}))
export const DisplayCollectionTitle = ({collection, onClose}) => {
    return (
        <Stack sx={{paddingRight:'10px',paddingLeft:'10px'}} >
        <Stack sx={{width:'100%', cursor:'pointer'}}  onClick={onClose} alignContent={'center'}  justifyContent='center' >
            {collection.coverImage?.length > 0 && 
            <Box sx={{
                width: '100%',            // Full width of the container
                paddingTop: '100%',        // 1:1 aspect ratio
                position: 'relative',      // Needed to position the image absolutely
                overflow: 'hidden', 
                borderRadius: '20px'
            }}>
                <img src={cloudflareCDN(collection.coverImage, 'height=400,format=auto')   } style={{
                    // borderRadius:'20px', width:'15vw', height: '15vw', objectFit:'cover', objectPosition: 'center' 
                    position: 'absolute',   // Allows the image to fill the container
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    }} 
                />
            </Box>
            }
            <Stack direction='row' sx={{paddingTop:'5px'}} justifyContent='space-between' alignItems='center' >
                <FineText>{collection.name}</FineText>
                <VeryFineText>{getNoOfItems(collection)} items</VeryFineText>
            </Stack>
            <Stack   >
                <VeryFineText>{collection.description } </VeryFineText>
            </Stack>
        </Stack>    
        </Stack>
    )    
}
