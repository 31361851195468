import React, { forwardRef, useState, useEffect, useRef } from 'react'
import { Button, Checkbox, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { styled } from '@mui/system';
import { Remove, Add } from '@mui/icons-material';

const FilterIconButton = styled(IconButton)(({ theme }) => ({  height:'36px', borderRadius:'10%', float:'right', cssFloat: 'right'}))

export function MyMaterialFilter(props) {

    const handleClear = () => {
        props.setBrandFilters(props.brands.map(brand => { brand.isRefined = false; return brand }))
        props.setCategoryFilters(props.categories.map(category => { category.isRefined = false; return category }))
        props.loadMaterials(true);
    }

    return (
        <>
        <Grid item xs={12} style={{display:'flex', justifyContent:'center', height:'45px',paddingTop:'9px'}}>
            <Tooltip title="Clear Filters" arrow>
                <FilterIconButton onClick={handleClear} > 
                    <Typography style={{paddingRight:'3px'}} variant='caption'>Clear Filters</Typography>
                    <FilterAltOffIcon  color="action"  />
                </FilterIconButton>  
            </Tooltip>
        </Grid>
        <Grid item xs={12}><Divider style={{paddingTop:'9px'}}  /></Grid>
        <MyMaterialDropdown showMore={false} attributeName='Brand' items={props.brands} label="brand" setItems={props.setBrandFilters} filterItems={props.loadMaterials} />
        <MyMaterialDropdown showMore={false} attributeName='Category' items={props.categories} label="category" setItems={props.setCategoryFilters} filterItems={props.loadMaterials} />
        </>
    )
}

function MyMaterialDropdown({attributeName='Brand',showMore,disabled=false, items, label, setItems, filterItems}) {
    const [showAll, setShowAll]=useState(!showMore)
    const [open, setOpen] = useState(showMore);
    const [refinedItems, setRefinedItems] = useState([])
    const [notRefinedItems, setNotRefinedItems] = useState([])

    const handleClick = () => {  setOpen(!open);  }
    useEffect(() => { if(disabled) setOpen(false) }, [disabled])

    const handleFilterClick = (item) => {
        item.isRefined = !item.isRefined;
        setItems(items.map(i => i[label] === item[label] ? item : i))
        filterItems(true);   
    }

    useEffect(() => {
        setRefinedItems(items.filter(item => item.isRefined))
        setNotRefinedItems(items.filter(item => !item.isRefined))
    }, [items])

    return (
        <Grid container alignItems="center"  >    
            <Grid item xs={10}>
                <Typography style={disabled ? {color:'#cecece'}: {color:'black'}}>{attributeName}</Typography>
            </Grid>
            <Grid item xs={2}  >
                <IconButton onClick={handleClick} size="small" disabled={disabled}> {open ? <Remove /> : <Add /> } </IconButton>
            </Grid>
            {open==true &&
                <>
                <Grid>                  
                {refinedItems.map( (item: any,index) =>  {   
                    if (item[label].trim().length == 0) return;
                    return (                
                        <Grid item xs={12} key={index} style={{height:'29px', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                            <Checkbox size='medium' style={{padding: '7px'}} checked={item.isRefined} onChange={() => { handleFilterClick(item) }} />
                            <Typography variant='caption' style={disabled ? {color:'#cecece'}: {color:'black'}}>{item[label]} ({item.count})</Typography>
                        </Grid>
                    )
                }
                )}
                </Grid>
                <Grid item xs={12}>
                    {refinedItems.length > 0 && notRefinedItems.length > 0 && <Divider style={{paddingTop:'9px', borderColor: 'rgba(0, 0, 0, 0.5)'}}  />}
                </Grid>
                </>    
            }

            <Grid item xs={12} sx={!showMore?{maxHeight:'calc(74.5vh - 270px)',  overflowX:'clip', overflowY:'auto'}:{}}>
                {open==true && 
                <>
                    <Grid sx={{marginTop: '0px'}}>
                    {notRefinedItems.map( (item: any,index) =>  {   
                        if (item[label].trim().length == 0) return;
                        return (                
                            <Grid item xs={12} key={index} style={{height:'29px', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                <Checkbox size='medium' style={{padding: '7px'}} checked={item.isRefined} onChange={() => { handleFilterClick(item) }} />
                                <Typography variant='caption' style={disabled ? {color:'#cecece'}: {color:'black'}}>{item[label]} ({item.count})</Typography>
                            </Grid>
                        )
                    }
                    )}
                    </Grid>
                    {showMore==true && items.length > 40 &&
                        <Button style={{paddingTop:'10px'}} size='small' disabled={disabled}  onClick={ () => setShowAll(!showAll)}> Show {showAll==true ? 'Less' : 'More' }</Button>
                    }            
                </>
                }
            </Grid>
            <Grid item xs={12}>
                <Divider style={{paddingTop:'9px'}}  />
            </Grid>
        </Grid>
    )
}
