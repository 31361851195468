import React from "react";
import { Box,Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ProductsGrid = styled(Box)(({ theme }) => ({
  padding: '10px',
  ".section-title": {
    cursor:"pointer",
    textAlign: "left",
    marginBottom: "20px",
    fontSize: "60px",
    fontFamily: "Gambarino",
    lineHeight: "130%",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "40px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "34px",
      textAlign: "left",
      width: "100%",
      marginBottom:"10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
      textAlign: "left",
      width: "100%",
      paddingLeft: "0px",
      marginBottom:"10px",
    },
  },
  ".description-text": {
    textAlign: "left",
    marginBottom: "60px",
    fontSize: "18px",
    fontFamily: "Gambarino",
    lineHeight: "130%",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "40px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      textAlign: "left",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
      textAlign: "left",
      width: "100%",
      paddingLeft: "0px"
    },
    'a':{
      textDecoration: 'none'
    },
    'a:hover':{
      color:'black'
    }
  },
  ".social-media-section": {
    display: 'flex',
    flexDirection: 'row',
    color: '#7c767a',
    cursor: 'pointer',
    marginBottom: "40px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "30px"
      
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px"

    },
  },
  ".brand-logo-img": {
    padding: "20px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    maxHeight: '400px',
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      maxHeight: '220px'
    },
  }
}));

export const BrandGrid = styled(Grid)(({ theme }) => ({
  margin: "60px 0 0px",
  ".MuiGrid-item": {
    padding: 0,
  },
    ".title": {
      textAlign: "left",
      marginBottom: "30px",
      fontSize: "55px",
      fontFamily: "Gambarino",
      lineHeight: "130%",
      [theme.breakpoints.down("lg")]: {
        marginBottom: "40px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "29px",
        textAlign: "left",
        width: "100%",
      },
    },
    ".sort-btn": {
      textTransform: "uppercase",
      fontFamily: "Roundo-Regular",
      fontSize: "15px",
      letterSpacing: "0.15px",
      fontWeight: "500",
      ".down-icon": {
      },
      ".sort-btn-label": {
        marginTop: "4px",
      },
    },
  }
));


export const ProjectsGrid = styled(Box)(({ theme }) => ({
  gridColumnGap: "24px",
  gridRowGap: "24px",
  gridTemplateRows: "auto",
  gridTemplateColumns: "repeat(auto-fit, minmax(310px, 1fr))",
  gridAutoColumns: "1fr",
  gridAutoFlow: "row",
  alignItems: "stretch",
  justifyItems: "stretch",
  display: "grid",
  "a": {
    color: "#121926",
    textDecoration: "none"
  },
  ".project-item": {
    border: "1px solid #f0f0f0",
    backgroundColor: "white",
    borderRadius: "8px",
    flexDirection: "column",
    transition: "opacity .3s cubic-bezier(.165, .84, .44, 1), transform .3s cubic-bezier(.165, .84, .44, 1)",
    display: "flex",
    overflow: "hidden"
  },
  ".project-item:hover": {
    opacity: ".88",
    transform: "translate(0, -4px)"
  },
  ".project-item:active": {
    transform: "scale(.99)translate(0, -4px)"
  },
  ".project-link": {
    width: "100%",
    height: "100%"
  },
  ".w-inline-block": {
    maxWidth: "100%",
    display: "inline-block",
  },
  ".project-item-image-wrapper": {
    borderRadius: "3px",
    alignItems: "flex-start",
    paddingTop: "0%",
    display: "flex",
    position: "relative",
    overflow: "hidden",
  },
  ".project-item-image-wrapper img": {
    minHeight: "100%"
  },
  ".project-item-image": {
    width: "100%",
    height: "240px",
    objectFit: "cover",
    objectPosition: "center",
    borderRadius: "20px",
    marginTop: "0",
    marginBottom: "40px",
    paddingBottom: "0",
    position: "relative",
    top: "0%",
    bottom: "0%",
    left: "0%",
    right: "0%",
    boxShadow: "0 10px 13px 3px rgba(0, 0, 0, .2)"
  },
  ".project-item-content": {
    height: "70px",
    padding: "28px 24px",
    paddingTop: "0"
  },
  "h3": {
    letterSpacing: "-.015em",
    marginTop: "0",
    marginBottom: "0",
    fontFamily: "Gambarino, sans-serif",
    fontSize: "26px",
    fontWeight: "400",
    lineHeight: "1.15",
    display: "block"
  },
  ".w-button": {
    color: "#fff",
    lineHeight: "inherit",
    cursor: "pointer",
    backgroundColor: "#3898ec",
    border: "0",
    borderRadius: "0",
    padding: "9px 15px",
    textDecoration: "none",
    display: "inline-block"
  },
  ".button-5": {
    alignSelf: "flex-start"
  },
  ".button-5.button3d": {
    color: "#121926",
    backgroundColor: "rgba(0, 0, 0, 0)",
    border: ".5px solid #000",
    borderRadius: "20px",
    marginBottom: "20px",
    marginLeft: "22px",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontSize: "14px",
    transform: "translate(0)",
    boxShadow: "0 2px 5px rgba(0, 0, 0, .2)"
  },
  ".button-5.button3d:hover": {
    borderColor: "#b982d0",
    opacity: "1",
    color: "#fff",
    backgroundColor: "#b982d0",
  }

}));