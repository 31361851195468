const behrFlat = {
    materialProps: {"metalness":0.01, "clearcoatRoughness":0.97,"roughness":0.6,"clearcoat":0.04,"reflectivity":0.32,"sheen":0},
    name:'Flat',
    thumbnail: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/system_images/behr/ico-flat.png'
}
const behrMatte = {
    materialProps:{"metalness":0.01, "clearcoatRoughness":0.96,"roughness":0.64,"clearcoat":0.03,"reflectivity":0.25,"sheen":0},
    name:'Matte',
    thumbnail: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/system_images/behr/ico-matte.png'
}
const behrEggshellEnamel = {
    materialProps:{"metalness":0.01, "clearcoatRoughness":0.37,"roughness":0.53,"clearcoat":0.07},
    name:'Eggshell',
    thumbnail: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/system_images/behr/ico-eggshell.png',
}
const behrSatinEnamel = {
    materialProps: {"metalness":0.01, "clearcoatRoughness":0.22,"roughness":0.4,"clearcoat":0.09,"envMapIntensity":1,"reflectivity":0.17},
    name:'Satin',
    thumbnail: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/system_images/behr/ico-satin.png'
}
const behrSemiGlossEnamel = {
    materialProps:{"metalness":0.01, "clearcoatRoughness":0.3,"roughness":0.15,"clearcoat":0.07,"reflectivity":0.24},
    name:'Semi-Gloss',
    thumbnail: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/system_images/behr/ico-semigloss.png'
}
const behrHighGlossEnamel = {
    materialProps:{"metalness":0.01, "clearcoatRoughness":0.53,"roughness":0.09,"clearcoat":0.13,"reflectivity":0.23},
    name:'High-Gloss',
    thumbnail: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/system_images/behr/ico-highgloss.png'
}
export const presetMaps = {
    'behr-flat':behrFlat,
    'behr-matte':behrMatte,
    'behr-eggshell-enamel':behrEggshellEnamel,
    'behr-satin-enamel':behrSatinEnamel,
    'behr-semi-gloss-enamel':behrSemiGlossEnamel,
    'behr-high-gloss-enamel':behrHighGlossEnamel
}
export const getPreset = (presetName) => {
    return presetMaps[presetName]
}