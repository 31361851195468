 import { useState, useEffect } from 'react';
import { CanvasTexture, Texture, TextureLoader,DefaultLoadingManager } from 'three';
import {getUrlFromRef} from '../libs/firebase'

const loader = new TextureLoader();
export default function useTextureLoader(textureSrc) {
	const [texture, setTexture] = useState(null)

	useEffect(() => {		
		if (textureSrc==null) { setTexture(null);	return;	}
		if (textureSrc instanceof Texture || textureSrc instanceof CanvasTexture) setTexture(textureSrc);		
		else  loader.load(getUrlFromRef(textureSrc,true), tex => { setTexture(tex) });
		
	},[textureSrc])
	return texture
}
