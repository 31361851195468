import React from 'react'
import { Grid } from '@mui/material'
import { ImageItemCover } from '../libs/ImageItemCover';
import { cloudflareCDN } from 'ui';
import { getUrlFromRef } from '../libs/firebase';
import { useLoadProjectFromCollection } from './CollectionLoadProject';

export function CollectionUserProjects(props) {
	let currentUserUid = props?.currentUserUid;
    const loadProjectFromCollection = useLoadProjectFromCollection();

    const getImageSrc = (project) => {
    	const url =  getUrlFromRef(project.thumbnail);		
		return cloudflareCDN(url, 'width=400,format=auto');
	}

    if(!props.projects || props.projects.length == 0) return null;

    return (
        <>
        {props.projects.map((item,index) => (
            <Grid key={index} item xs={12} sx={{ marginBottom: '10px' }} >               
                <ImageItemCover 
                    id={item.id} 
                    name={item.name} 
                    src={getImageSrc(item)}
                    draggable={false} 
                    onClick={() => loadProjectFromCollection(item)}
                    currentUserUid={currentUserUid}
                    type='templates'
                    showInfoText={true}
                    infoText='Click to load this template'
                />	
            </Grid>
        ))}
        </>
    );
}
