import React, {useEffect, useState} from "react"
import { Button } from "@mui/material";
import { LightMode as LightModeIcon} from '@mui/icons-material'
import { styled } from '@mui/system';
import {useMattoState,useProjectData} from '../MattoState'
import {isMobile} from 'react-device-detect';

const ToggleLightButton = styled(Button)({backgroundColor:'white', marginRight:'7px', borderRadius:'500px', height:'24px', fontSize:'10px', 
'&:hover':{'backgroundColor':'white' },'&:active':{'backgroundColor':'dimgray' }});


export const MoveLightButton = () => {
	const orbitControls = useMattoState((state) => state.controls)
	const setProjectData = useProjectData(state=>state.set)
    const [moveLightMode, setMoveLightMode]=useState(false)

    let xDown = null;                                                        
    let yDown = null;

    useEffect(() => {
        if(moveLightMode){
            document.addEventListener('touchstart', handleTouchStart, false);  
            document.addEventListener('touchmove', handleTouchMove, false);
            document.addEventListener('mousedown', handleTouchStart, false);        
            document.addEventListener('mousemove', handleTouchMove, false);
        }

        return () => {
            document.removeEventListener('touchstart', handleTouchStart, false);        
            document.removeEventListener('touchmove', handleTouchMove, false);
            document.removeEventListener('mousedown', handleTouchStart, false);        
            document.removeEventListener('mousemove', handleTouchMove, false);
        }
        
    }, [moveLightMode]);

    const toggleMode = () => {
        orbitControls.current.enabled = !orbitControls.current.enabled;
        if(!orbitControls.current.enabled) {
            setMoveLightMode(true)
        }
        else {
            setMoveLightMode(false)
        }
    }
    
    const getTouches = (evt) => {
        return evt.touches ||             // browser API
            evt.originalEvent.touches; // jQuery
    }                                                     
                                                                                
    const handleTouchStart = (evt) => {
        if(!moveLightMode) return;
        const firstTouch = evt.type.includes('mouse') ? evt :getTouches(evt)[0];                                      
        xDown = firstTouch.clientX;                                      
        yDown = firstTouch.clientY;
        evt.stopPropagation();
        evt.preventDefault();
    }                                              
                                                                            
    const  handleTouchMove = (evt) => {
        if(!moveLightMode) return;
        if ( ! xDown || ! yDown ) return;

        let x = evt.type.includes('mouse') ? evt.clientX :evt.touches[0].clientX;                                    
        let y = evt.type.includes('mouse') ? evt.clientY :evt.touches[0].clientY;

        let xDiff = xDown - x;
        let yDiff = yDown - y;                                   
        
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) { /*most significant*/
            setProjectData(state => { state.lighting.directional = -95 + (190 / window.innerWidth * x) })                       
        } 
        xDown = x;
        yDown = y; 
        evt.stopPropagation();
        evt.preventDefault();  
    }  
	return (
        <div> 
            <ToggleLightButton startIcon={<LightModeIcon style={{width: '14px', marginRight: '-4px'}} />} onClick={toggleMode} style={moveLightMode ? {backgroundColor:'#cccccc'}: {backgroundColor:'white'}}>{isMobile ? 'Move light':'Light On/Off'}</ToggleLightButton>
        </div>	
	)
}

