import React, { useRef,useState,useMemo,Suspense,useEffect } from 'react'
import { useTexture } from "@react-three/drei"
import {useMattoState} from '../../MattoState'
import { useLoginState } from '../../studioxr/auth/LoginState';

type GridCMProps = {
    showGrid: boolean;
};

export const GridCM = ({showGrid=false}: GridCMProps) => {
    const [ zeroCM, tenCM, twentyCM, zeroInch, fourInch, eightInch, horizontal, vertical] = useTexture([
        '/images/cm/0cm.png',
        '/images/cm/10cm.png',
        '/images/cm/20cm.png',
        '/images/inches/0in.png',
        '/images/inches/4in.png',
        '/images/inches/8in.png',
        '/images/horizontal.png',
        '/images/vertical.png'
    ])
	const profile:any = useLoginState((state) => state.profile);
    const measuringCategory = profile?.measuring_category  || profile?.measuringCategory  || 'Metric';
    
    
    return (
        <group name={'Grid CM Helper'} visible={showGrid}>
        {measuringCategory == 'Imperial' &&  
        
        <>
        <gridHelper args={[650,140, 0x000000, 0x222222]} position={[0,0.02,0]}/>

        <mesh position={[0.8,0.11,-0.4]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={zeroInch} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,-2.3]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,-6.9]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0.8,0.11,-9.7]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={fourInch} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,-11.5]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,-16.2]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0.8,0.11,-18.9]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={eightInch} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,-20.8]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,2.35]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,7.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0.8,0.11,8.9]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={fourInch} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,11.65]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,16.3]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0.8,0.11,18.1]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={eightInch} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,20.9]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>

        <mesh position={[2.3,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[6.9,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[10.1,0.11,-0.4]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={fourInch} transparent={true}   />
        </mesh>
        <mesh position={[11.6,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[16.2,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[19.2,0.11,-0.4]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={eightInch} transparent={true}   />
        </mesh>
        <mesh position={[20.9,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>

        <mesh position={[-2.4,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[-7.0,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[-8.6,0.11,-0.4]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={fourInch} transparent={true}   />
        </mesh>
        <mesh position={[-11.6,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[-16.3,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[-17.8,0.11, -0.4]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={eightInch} transparent={true}   />
        </mesh>
        <mesh position={[-20.9,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        </>
        }
        {measuringCategory == 'Metric' && 
        
        <> 
        <gridHelper args={[650,140, 0x000000, 0x222222]} position={[0,0.02,0]}/>

        <mesh position={[1.4,0.11,-0.4]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={zeroCM} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,-2.25]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,-6.75]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[1.4,0.11,-9.6]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={tenCM} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,-11.25]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,-15.75]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[1.4,0.11,-18.7]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={twentyCM} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,-20.25]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,2.3]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,6.9]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[1.4,0.11,8.7]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={tenCM} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,11.5]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,16.1]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>
        <mesh position={[1.4,0.11,17.8]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={twentyCM} transparent={true}   />
        </mesh>
        <mesh position={[0,0.11,20.7]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={horizontal} transparent={true}   />
        </mesh>

        <mesh position={[2.25,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[6.8,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[10.4,0.11,-0.4]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={tenCM} transparent={true}   />
        </mesh>
        <mesh position={[11.35,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[15.95,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[19.6,0.11,-0.4]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={twentyCM} transparent={true}   />
        </mesh>
        <mesh position={[20.55,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[-2.3,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[-6.9,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[-7.8,0.11,-0.4]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={tenCM} transparent={true}   />
        </mesh>
        <mesh position={[-11.45,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[-16.025,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        <mesh position={[-16.9,0.11,-0.4]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={twentyCM} transparent={true}   />
        </mesh>
        <mesh position={[-20.575,0.11,-0.0]} rotation={[ -(Math.PI/2),0,0]} >
            <planeGeometry args={[2.5, 2.5]} />
            <meshBasicMaterial map={vertical} transparent={true}   />
        </mesh>
        </>
}
        </group>  

        
    )
}