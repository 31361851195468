import React, { useEffect, useState } from 'react'
import { PhysicalObjectInterface } from '../ts/app_interfaces';
import {usePhysicalObjects, useProjectData} from '../MattoState'
import { getPreset } from 'ui';
import { produce } from 'immer';
import { Avatar, Button, Grid, Typography } from '@mui/material';
import { getProductCache } from '../libs/firebase';
import { gtagViewItemEvent } from '../libs/util';
import { ProductInterface, ObjectStatusType } from 'ts-interfaces';

export const Variants = () => {
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)
    const selectedPhysicalObject:(PhysicalObjectInterface | null | any)  = usePhysicalObjects(state=>state.selectedPhysicalObject)
    const backgroundTexture:ProductInterface | any = useProjectData(state=>state.backgroundTexture)
    const idx = usePhysicalObjects(state=>state.selectedMaterialndex) || 0
    const [hasPreset, setHasPreset] = useState(false)
    const [product, setProduct] = useState<any>(null)
	const projectData = useProjectData()
    const updateProjectData = useProjectData(state=>state.update)


    useEffect(() => {
        fetchProduct()
        async function fetchProduct() {
            const id = selectedPhysicalObject?.paintMaterialsData?.[idx]?.id || backgroundTexture?.materialData?.id 
            let userCreated = false;
            if(selectedPhysicalObject && !!(selectedPhysicalObject?.paintMaterialsData?.[idx]?.userCreated || selectedPhysicalObject?.paintMaterialsData?.[idx]?.objectStatus==ObjectStatusType.USER_CREATED)) userCreated = true;
            if(!selectedPhysicalObject && backgroundTexture?.objectStatus==ObjectStatusType.USER_CREATED) userCreated = true;

            if (!id) {
                setHasPreset(false);return;
            }
            const product:any = await getProductCache(id, userCreated)
            setHasPreset(product?.materialData?.presets?.length > 0)
            setProduct(product)
        }
    },[selectedPhysicalObject,idx])

    const handleNewPresets = (presetKey) => {
        const preset = getPreset(presetKey)
        if (selectedPhysicalObject?.paintMaterialsData?.[idx]?.preset && preset?.materialProps ) {
            const newPhysicalObject =  produce(selectedPhysicalObject, (draft:any) => {
                draft.paintMaterialsData[idx].preset = presetKey
            })       
            updatePhysicalObject(newPhysicalObject)
            gtagViewItemEvent(product,presetKey)
        }
        if( (!selectedPhysicalObject) && backgroundTexture?.materialData?.preset) {
            const presetSettings = getPreset(presetKey);
            const newBackgroundTexture = produce(backgroundTexture, draft => { 
                draft.materialData.files = null;
                draft.materialData.preset = presetKey;                
                draft.materialData.materialProps = {...backgroundTexture.materialData.materialProps, ...presetSettings.materialProps};
            });
            updateProjectData({projectData, ...{backgroundTexture:newBackgroundTexture}})            
        }
    }
    const getPresetKey = () => {
        if(selectedPhysicalObject) return selectedPhysicalObject?.paintMaterialsData?.[idx]?.preset
        else return backgroundTexture?.materialData?.preset
    }
    
    if (hasPreset==false || product==null || product.materialData?.presets==null) { return null }   
    return (
        <Grid container>
            <Grid item xs={12} >                
                <Typography style={{paddingLeft:'4px', marginBottom: '10px',paddingTop:'15px'}}>Finishes</Typography>
            </Grid>
            {product.materialData.presets.map((presetKey) => {
                const preset = getPreset(presetKey)
                if (preset) {
                    const isSelected = getPresetKey() == presetKey;
                    // const variant = getPresetKey() == presetKey ? 'outlined' : 'text';
                    return (
                        <Grid key={presetKey} sx={{paddingBottom: '6px', paddingLeft: '6px'}} item xs={6}>
                            <Button 
                                size='small'
                                startIcon={ <Avatar sx={{width:'25px', height:'20px'}} src={preset.thumbnail} /> }
                                sx={{ 
                                    justifyContent:'left', width:'100%', padding: '3px 6px', fontSize:'0.75rem', borderColor: (theme) => theme.palette.primary.shades[250], 
                                    background: isSelected ? 'black' :'', 
                                    color: isSelected ? 'white' : '',
                                    '&:hover': isSelected ? { background: 'black', color: 'white' } : { background: (theme) => theme.palette.primary.shades[50], borderColor: (theme) => theme.palette.primary.shades[250] }
                                }}  
                                variant='outlined' key={presetKey} 
                                onClick={() => handleNewPresets(presetKey)}>{preset.name}</Button>                    
                        </Grid>
                    )
                }
            })}
        </Grid>
    )
}
