import { useState } from 'react';
import { produce } from 'immer';
import { getDocByID } from '../libs/firebase';
import { useMattoState, useProjectData, usePhysicalObjects } from '../MattoState';

export function useLoadProjectFromCollection() {
    const projectData = useProjectData();
    const [projectTemplateData, setProjectTemplateData] = useState(null);
	const resetProjectData = useProjectData(state => state.reset);
	const updateProjectData = useProjectData(state => state.update);
	const setSelectedPhysicalObjectKey = usePhysicalObjects(state => state.setSelectedPhysicalObjectKey);
	const setStateDirty = useMattoState(state => state.setStateDirty);
    const setPhysicalObjects = usePhysicalObjects(state => state.setPhysicalObjects);

    const loadProjectFromCollection = async (project, hideAlert=false) => {
        const fetchProject = async (project) => {
            if (projectTemplateData && projectTemplateData.id === project.id) return projectTemplateData;
            try {
                const doc = await getDocByID('projects', project.id);
                setProjectTemplateData(doc.data());
                return doc.data();
            } catch (error) {
                console.error('Error fetching project', error);
            }
        };

        const projectTemplate = await fetchProject(project);
        const isStateDirty = useMattoState.getState().isStateDirty;
        if (isStateDirty == true && hideAlert == false) {
            const x = window.confirm("Project unsaved! Are you sure you want to change the template?");
            if (x != true) return;
        }

        const data = JSON.parse(JSON.stringify(projectTemplate));
        const projectId = projectData.projectId ?? null;
        setSelectedPhysicalObjectKey(null);
        setStateDirty(false);
        resetProjectData();
        setPhysicalObjects(JSON.parse(JSON.stringify(projectTemplate.physicalObjects)));

        let backgroundTexture = data.backgroundTexture;
        if (data.backgroundTexture) {
            backgroundTexture = data.backgroundTexture?.materialData?.materialProps
                ? data.backgroundTexture
                : produce(data.backgroundTexture, (draft) => {
                      draft.materialData = {
                          files: data.backgroundTexture.files,
                          materialProps: data.backgroundTexture.materialProps,
                          name: data.backgroundTexture.name,
                          id: data.backgroundTexture.id,
                      };
                  });
        }

        if (data?.id) delete data.id;
        if (data?.projectData?.projectId) delete data.projectData.projectId;
        if (data?.projectData?.userId) delete data.projectData.userId;
        updateProjectData({
            ...data.projectData,
            ...{
                projectId: projectId,
                lighting: data.lighting,
                backgroundTexture: backgroundTexture,
                cameraPosition: data.cameraPosition,
                controlsTarget: data.controlsTarget,
            },
        });
    };

    return loadProjectFromCollection;
}
