import { IconButton, Modal, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PersonIconButton = styled(IconButton)(({ theme }) => ({
  margin: 0,
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
		display:'none'
	},
  width: "60%",
  // maxWidth: "800px",
  height: "calc(100vh - 75px)",
  margin: "auto",
  marginTop: "25px",
  background: "white",
  // padding:"15px",
  borderRadius:'15px',
  zIndex: 10,
  display: "flex",

  ".inner-root": {
    flex: 1,
    // overflow: "auto",
  }
}));

export const TrialContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  // padding: "2px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ".form-root": {
    margin: "auto",

    "&.trial": {
      position: "relative",
      height: "100%",
      width: "100%",

      justifyContent: "center",
      flexDirection: "column",
    },
  },
  ".main-title": {
    paddingTop:"10px",
    fontSize: "25px",
    lineHeight: "115%",
    marginBottom: "5px",
  },
  ".main-title2": {
    fontSize: "20px",
    fontWeight:'700',
    color: 'black',
    paddingTop:"16px",
    paddingBottom:"16px",
    paddingLeft:'10px'

  },
  ".main-title-des": {
    paddingTop:"6px",
    fontSize: "19px",
    lineHeight: "125%",
    marginBottom: "15px",
    color: '#727272',
    fontStyle: 'italic',
    paddingLeft: '10px'
  },
  ".bold-paraHeading": {
    paddingTop:"10px",
    fontSize: "12px",
    fontWeight: "Bold",
    textShadow: "0 0 black"
  },
  ".para-sub": {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: '1.3',
    fontStyle: 'italic'
  },
  ".price-amount":{
    fontSize: "28px",
    fontWeight: "700",
    textShadow: "0 0 black",
    marginRight: '8px'
  },
  ".priceType-text":{
    fontSize: "10px",
    fontWeight: "700",
    textShadow: "0 0 black",
  },
  ".para-sub2": {
    fontSize: "10px",
    fontWeight: "600",
    lineHeight: '1.3',
    fontStyle: 'italic',
  },
  ".asterisk-text": {
    fontSize: "10px",
    fontWeight: "400",
    color: '#161212'
  },
  ".close-btn": {
    position: "absolute",
    right: "-24px",
    top: "20px",
    backgroundColor: "#43494E",
    color: "white",
    padding: "6px"
  },

  ".price-label-text": {
    fontSize: "24px",
    fontWeight: "700",
  },

  ".price-text": {
    fontSize: "14px",
    fontWeight: "500",
    marginLeft: "32px",
    marginTop: "-8px"
  }
}));
