import { useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import {useMattoState, usePhysicalObjects} from '../MattoState'
import { produce } from 'immer'

export const TextureTransformController = () => {

    const { gl, raycaster, scene, camera, invalidate } = useThree();
 
    const orbitControls = useMattoState((state) => state.controls);
    const selectedPhysicalObject = usePhysicalObjects((state) => state.selectedPhysicalObject);
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)

    orbitControls.current.enabled = false;

    let pointerDownFlag = false;
    let mouse = {x:0, y: 0};
    let mouseOriginal = {x:0, y:0};
    let range = {x: 0, y: 0};
    let rangeOriginal = {x: 0, y: 0};
    let scale = {x: 1, y: 1};
    let rangeScaleSetFlag = 0;
    let offsetXValue = null;
    let offsetYValue = null;
    let repeatXValue = null;
    let repeatYValue = null;

    useEffect(() => {

        document.addEventListener("pointerdown", onPointerDown);
        document.addEventListener("pointermove", onPointerMove);
        document.addEventListener("pointerup", onPointerUp);
        document.addEventListener('wheel', onPointerWheel, {passive: false});
        document.addEventListener("DOMMouseScroll", onPointerWheel);

        return () => {
            document.removeEventListener("pointerdown", onPointerDown);
            document.removeEventListener("pointermove", onPointerMove);
            document.removeEventListener("pointerup", onPointerUp);
            document.removeEventListener('wheel', onPointerWheel, {passive: false});
            document.removeEventListener("DOMMouseScroll", onPointerWheel);
            orbitControls.current.enabled = true;
        }
        
    }, []);
 
    const getMouseCoordinates = (event) => {
        let mouse = {x:0, y: 0};
        const rect = gl.domElement.getBoundingClientRect();
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
        return mouse;
        
    }

    const checkIntersection = (event) => {
        mouse = getMouseCoordinates(event);
        raycaster.setFromCamera(mouse, camera);
        const intersectsAll = raycaster.intersectObjects(scene.children);
        const intersects = intersectsAll.filter(intersect => intersect.object.type != 'GridHelper' && intersect.object.parent.name != 'Grid CM Helper');
        let intersectsIndex = 0;
        if(selectedPhysicalObject.mode == 'iPhone') intersectsIndex = 1;

        if(intersects.length > 0 && intersects[intersectsIndex] && intersects[intersectsIndex].object.name == selectedPhysicalObject.meshName && intersects[intersectsIndex].object.userData.key == selectedPhysicalObject.key) return intersects[intersectsIndex];
        return false;
    }

    const onPointerDown = (event) => {
        let intersection = checkIntersection(event);
        if(intersection) {
            setRangeAndScale(intersection);
            mouseOriginal = getMouseCoordinates(event);
            pointerDownFlag = true;
            document.body.style.cursor = "move";
        }        
    }

    const onPointerMove = (event) => {
        event.preventDefault();
        if(pointerDownFlag) {
            document.body.style.cursor = "move";
            let currMouse = getMouseCoordinates(event);
            let deltaX = currMouse.x - mouseOriginal.x;
            let deltaY = -(currMouse.y - mouseOriginal.y);
            let intersection = checkIntersection(event);
            if(intersection) {

                if(intersection.object.material.map.offset.x - deltaX <= range.x && intersection.object.material.map.offset.x - deltaX >= -range.x) {
                    intersection.object.material.map.offset.x -= deltaX;
                }
                if(intersection.object.material.map.offset.y - deltaY <= range.y && intersection.object.material.map.offset.y - deltaY >= -range.y) {
                    intersection.object.material.map.offset.y -= deltaY;
                }
                offsetXValue = intersection.object.material.map.offset.x;
                offsetYValue = intersection.object.material.map.offset.y;
                repeatXValue = intersection.object.material.map.repeat.x;
                repeatYValue = intersection.object.material.map.repeat.y;
                mouseOriginal = currMouse;
                intersection.object.material.map.needsUpdate = true;
                invalidate();
            }
        }
    }

    const onPointerUp = (event) => {
        pointerDownFlag = false;
        updateDynamicMaterialProp('offset', {x:offsetXValue, y:offsetYValue})
        updateDynamicMaterialProp('repeat',{x:repeatXValue, y:repeatYValue})
    }

    const setRangeAndScale = (intersection) => {
        if(!rangeScaleSetFlag) {
            let tex = intersection.object.material.map;
            let imgRatio = tex.image.width / tex.image.height;
            let ratio = selectedPhysicalObject.aspect / imgRatio;
            if(ratio > 1) {
                range.y = 0.5 * (1 - (1.0 / ratio));
                scale.y = 1.0 / ratio;
                rangeOriginal.y = range.y;
            } else {
                range.x = 0.5 * (1 - ratio);
                scale.x = ratio;
                rangeOriginal.x = range.x;
            }
            rangeScaleSetFlag = 1;
        }
    }

    const onPointerWheel = (event) => {
        event.preventDefault();
        let intersection = checkIntersection(event);
        let delta = wheelDistance(event);
        if(intersection) {
            setRangeAndScale(intersection);
            let tex = intersection.object.material.map;
            let repeatX = tex.repeat.x;
            let repeatY = tex.repeat.y;
            
            if(delta) repeatX += delta;
            else repeatX += event.deltaY * 0.003;
            
            repeatY = (scale.y / scale.x) * repeatX;
            if(repeatX >= 0.05 && repeatX <= scale.x && repeatY >= 0.05 && repeatY <= scale.y) {
                let change_x = (scale.x - repeatX);
                let change_y = (scale.y - repeatY);
                range.x = rangeOriginal.x + (0.5 * change_x);
                range.y = rangeOriginal.y + (0.5 * change_y);
                tex.repeat.set(repeatX, repeatY);

                if(tex.offset.x > range.x) {
                    tex.offset.x = range.x;
                } else if(tex.offset.x < -range.x) {
                    tex.offset.x = -range.x;
                }
                
                if(tex.offset.y > range.y) {
                    tex.offset.y = range.y;
                } else if(tex.offset.y < -range.y) {
                    tex.offset.y = -range.y;
                }

                offsetXValue = tex.offset.x;
                offsetYValue = tex.offset.y;
                repeatXValue = repeatX;
                repeatYValue = repeatY;
                tex.needsUpdate = repeatY;

                updateDynamicMaterialProp('repeat',{x:repeatX, y:repeatY})
                updateDynamicMaterialProp('offset',{x:offsetXValue, y:offsetYValue})
        
                invalidate();  
            }
        }
        
    }
    function wheelDistance(e) {
        if (!e) e = window.event;

        let d = e.detail;
        if (d) return -d / 3; // Firefox;

        // IE, Safari, Chrome & other browsers
        return false;
    }

    const updateDynamicMaterialProp = (prop,value) => {
        const selectedPhysicalObject= getSelectedPhysicalObject(selectedPhysicalObjectKey)  
        const newObj = produce(selectedPhysicalObject, draft => { draft.dynamicMaterialProps[prop]=value })
        updatePhysicalObject(newObj)
    }

    return (<></>);

}