import React, { useMemo,useRef, useState,useEffect,useCallback } from 'react'
import {usePhysicalObjects,useProjectData, useMattoState} from '../MattoState'
import { Grid, Typography,Divider } from '@mui/material';
import { invalidate } from '@react-three/fiber';
import { HexColorPicker, HexColorInput} from "react-colorful";
import {useClickOutside} from '../libs/useClickOutside'
import { Color } from 'three';
import { produce } from 'immer'
import { PhysicalObjectInterface } from '../ts/app_interfaces';
import useEventListener from '../libs/useEventListener';

const text2={paddingTop:'0px',textAlign:'center',textTransform:'capitalize',overflow:'hidden', textOverflow:'ellipsis'} as any

export const ColorSelector = ({idx=0,field='color', name='Texture Color', defaultColor='#fff', padding='10px', handleColorChangeOnMouseUpFunc, paintSearchCode}) => {
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const getCurrentThreeJSObject = usePhysicalObjects( (state) => state.getCurrentThreeJSObject)
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)
    const backgroundTexture:any = useProjectData(state=>state.backgroundTexture)
	const setProjectData = useProjectData(state=>state.set)	
    const [isOpen, setOpen] = useState(false);
    const [startingColor,setStartingColor]=useState(defaultColor)
    const popover:any = useRef();
    const copiedMaterialData = useMattoState((state) => state.copiedMaterialData)

    useClickOutside(popover, () => { setOpen(false) })
    useEffect(() => { 
        if(handleColorChangeOnMouseUpFunc) {
            if(paintSearchCode == null) { updateMaterialProp('#fff');}
        } else {
            setOpen(false)
            setStartingColor(getStartingColor())
        }
    },[selectedPhysicalObjectKey,copiedMaterialData,paintSearchCode])

    const getStartingColor = () => {        
        const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey) 
        if (!selectedPhysicalObject) return getHex(backgroundTexture?.materialData?.materialProps?.[field] ?? defaultColor)
        else if (selectedPhysicalObject.type=='paint') {  return getHex(selectedPhysicalObject?.paintMaterials?.[idx]?.[field] ?? defaultColor) }
        else return getHex(selectedPhysicalObject?.materialData?.materialProps?.[field] ?? defaultColor)
    }

    const handleColorChange = (color:string) => {  
            updateMaterialProp(color)
            invalidate()
    }
    const handleMouseUp = () => {
        if(handleColorChangeOnMouseUpFunc) handleColorChangeOnMouseUpFunc(startingColor)
    }
    const getHex = (color) =>  { 
        if (color==0) return '#000000'
        else return isNaN(color) ? color : '#' + Number(color || 16777215).toString(16); 
    }
    const updateMaterialProp = (color:string) => {
        if (color==null) return;
        setStartingColor(color) 
        if(handleColorChangeOnMouseUpFunc) return;
        else {       
            const threeJSColor = new Color(color).toJSON()
            const isPaint = getSelectedPhysicalObject(selectedPhysicalObjectKey)?.type=='paint'
            if (selectedPhysicalObjectKey==null) {
                const newObj = backgroundTexture?.materialData?.materialProps ? produce(backgroundTexture, draft => { draft.materialData.materialProps[field] = threeJSColor }) : {materialData: {materialProps:{[field]:threeJSColor}}}
                setProjectData(state=> { state.backgroundTexture = newObj })
            }
            else if (isPaint) {
                const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)
                if (!selectedPhysicalObject) return;
                const newObj = produce(selectedPhysicalObject, (draft:any)=> {
                    if (!draft.paintMaterials) draft.paintMaterials=[]
                    draft.paintMaterials[idx][field]=threeJSColor
                })
                updatePhysicalObject(newObj)  
            }
            else {
                const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)
                const newObj = produce(selectedPhysicalObject, draft => 
                    {  if (draft?.materialData?.materialProps) draft.materialData.materialProps[field]=threeJSColor; }
                )
                updatePhysicalObject(newObj)  
            }
     }
    }   
    return (                       
        <Grid container item xs={12} style={{maxWidth:'90%',paddingTop:'6px'}} sx={{paddingLeft: {xs:'0px',sm: padding, md:padding,xl:padding}}} >
        <Grid item xs={3} ref={popover}>
            <div className="colorPicker">
                <div className="colorSwatch" style={{ backgroundColor: startingColor }} onClick={() => setOpen(true)} />
                {isOpen && 
                    <div className="colorPopover" >
                        <HexColorPicker color={startingColor} onChange={handleColorChange} onMouseUp={handleMouseUp}/> 
                        <div style={{marginTop:'10px', display: 'flex'}}>
                            <Typography style={{paddingRight:'20px'}} variant="caption"> HEX</Typography> 
                            <HexColorInput style={{textAlign: 'center', width: '100%'}} color={startingColor} onChange={handleColorChange} />
                        </div>
                    </div> 
                }
            </div>
        </Grid>            
        <Grid item xs={9} style={{paddingTop:'5px',paddingLeft:'4px'}}>
        <Typography style={text2} variant="caption" >{name} </Typography>        
        </Grid>
        </Grid>
    )        
}
export const ColorHeader = () => {
    return (
        <Grid container item xs={12} style={{paddingTop:'15px'}} >
            <Grid item xs={12} >
                <Typography style={{paddingLeft:'4px'}}>Color</Typography>
            </Grid>      
        </Grid>
    )
}

