import React from 'react'
import {InputBase} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import {InputAdornment,IconButton} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { Stack } from '@mui/system';

export const SearchBox = ({onChange,onBlur,placeholder='Search'}) => {
	const [values, setValues] = React.useState({input: ""});

	const handleClick = () => {
		setValues({ input: "" });
		onChange('')
	  };
	  const handleMouseDown = event => { event.preventDefault(); };
	  const handleChange = prop => event => {
		setValues({ ...values, [prop]: event.target.value });
		onChange(event.target.value)
	  };
	  const handleBlur = event => { event.preventDefault(); onBlur(event.target.value) };
	const endAdornment = () => {
		if (values.input) {
		  return (
			<InputAdornment position="end">
			  <IconButton
				onClick={handleClick}
				onMouseDown={handleMouseDown}
				color="secondary"
			  >
				<ClearIcon />
			  </IconButton>
			</InputAdornment>
		  );
		}
	
		return "";
	  };
	return (
		<Stack direction="row" spacing={0} alignItems="center" style={{border:'1px solid #c19684',borderRadius:'10px',background:'white'}}>
			<SearchIcon style={{pointerEvents: 'none',paddingLeft:'3px'}} />
			<InputBase 
            onChange={handleChange("input")}
            onBlur={handleBlur}
			type="text"
			value={values.input}
		    style={{paddingLeft:'3px'}}
		    placeholder={placeholder}
		    inputProps={{ 'aria-label': 'search' }}
			endAdornment={endAdornment()}
		  />
			</Stack>

	)
}