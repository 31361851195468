
import React, { useRef, useState, useEffect, useContext,forwardRef } from 'react'
import {Button, Divider, Grid, IconButton, Paper, Popover, Typography, Checkbox, Select, MenuItem} from '@mui/material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import useEventListener from '../libs/useEventListener';
import { PaintMaterialSelect } from './PaintMaterialSelect';
import { useStats } from 'react-instantsearch';


export const SearchFilter = ({side='left', boundingBox={}, typesenseFilter=true, children}) => {
    const [filterOpen, setFilterOpen] = useState(false)
    const filterButtonRef:any = useRef<any>()
    const panelRef:any = useRef<any>()
    const marginTop = side=='left' ? '0px' : '-10px';

    useEventListener('resize', (e) => { if (filterOpen==true) setFilterOpen(false); })
    useEventListener('mousedown', (e) => { 
        if (filterOpen==true 
            && filterButtonRef?.current?.contains(e.target)==false
            && panelRef?.current?.contains(e.target)==false            
            ) {
                setFilterOpen(false); 
        }    
    })

    return (
        <>
        <Grid item xs={12} style={{textAlign:'center', marginTop:marginTop}}> 
            {side=='right' && <PaintMaterialSelect /> }  
            <Button ref={filterButtonRef} onClick={() => void setFilterOpen(!filterOpen)} > 
                <FilterAltOutlinedIcon color="action"  />Filter
            </Button>  
            {typesenseFilter && <ShowMatchesCount />}
        </Grid>
        <Panel boundingBox={boundingBox} ref={panelRef} side={side} open={filterOpen}>
        {children}
        </Panel>                
        </>
    )
}
const ShowMatchesCount = () => {   
    const { nbHits } = useStats();
    return <Typography  variant="body2"  style={{color:'white', 
    padding:'4px', borderRadius:'10px',
    backgroundColor:'hsl(240deg 7% 62%)',display:'inline'}}>{nbHits}</Typography> }
;
const Panel = forwardRef<any,any>(({boundingBox, side='left', children, open=false}, ref) => {
    if (!boundingBox.current) return(null);
    const b = boundingBox?.current?.getBoundingClientRect() || 0
    const left = side=='left' ? b?.right + 10 : b?.left - 250 || 0
    const top = side=='left' ? b?.top + 87 : b?.top - 6 || 0 

    return (
        <Paper ref={ref}  elevation={0} style={{position:'absolute',overflowY:'scroll',overflowX:'clip',
        borderRadius: '5px',zIndex: 10,height: '74.5vh', width:'220px', padding:'15px', visibility: open ? 'visible' : 'hidden',
        top: top, left: left
        }}>
            {children}
        </Paper>
    )
})