import { useState, useEffect } from "react";
import { Box, Container, Grid } from "@mui/material";
import { TypesenseSearch } from "../../instasearch/TypesenseSearch";
import { ProductType } from 'ts-interfaces'
import { ProductsGrid} from "./styles";
import React from "react";
import { getDocByID, suppliersDB} from "../../libs/firebase";
import Header from "../header";
import { BrandProjectList } from "./BrandProjectList";
import BrandHeader from "./BrandHeader";
import { BrandProductHits2 } from "../search/BrandProductHits2";
import { useParams } from "react-router-dom";
import { getDocs, query, where } from "firebase/firestore";


const Brand = () => {
  const {slug} = useParams()
  const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());
  const [brandInfo, setBrandInfo] = useState<any>(null);
  const [brandID, setBrandID] = useState<any>(params.id);
  const [projectIdArray, setProjectIdArray] = useState([]);
  const [projectEditMap, setProjectEditMap] = useState({});

	useEffect(() => {
    if (!params.id) return;
    getDocByID('suppliers',params.id).then( (doc) => {
      if (doc.exists()) {
        const brandData = doc.data();
        setBrandInfo(brandData);
      }
      else { setBrandID(null) }       
    })		
	},[params.id])


  useEffect(() => {
    async function getSlug() {
      const q = query(suppliersDB as any, where("slug", "==", slug?.toLowerCase()))
      const docs = await getDocs(q)
      if (docs.docs.length > 0) { 
        const brandData:any = docs.docs[0].data();
        setBrandInfo(brandData);
        setBrandID(brandData.id)
      }
      else { setBrandID(params.id ?? null) }
    }
    if (slug) getSlug()
  },[slug])

  useEffect(() => {
    if(brandInfo?.projects?.length > 0) {
      const ids = brandInfo.projects.map(project => project.id);

      const canEditMap = brandInfo.projects.reduce((acc, project) => {
        if(project.canEdit == false) {
          acc[project.id] = true;
        } else {
          acc[project.id] = false;
        }
        return acc;
      }, {});
      setProjectEditMap(canEditMap);
      setProjectIdArray(ids)
    }
  },[brandInfo])

  
  return (
    <Grid sx={{background:'white'}}>
        <Header />
        <ProductsGrid>
          <Container>
            <BrandHeader brandInfo={brandInfo}/>
              {projectIdArray.length > 0 &&
                <Grid mb="40px">
                  <BrandProjectList projectIdArray={projectIdArray} projectEditMap={projectEditMap} />
                </Grid>
              } 
          </Container>

          {brandID && 
            <TypesenseSearch
            indexName="productsV2"
            productType={ProductType.ANY}
            hitsHandler={BrandProductHits2}
            supplierID={brandID} />
          }

      </ProductsGrid>
    </Grid>
  );
};

export default Brand;


