import React, { useRef, useEffect, useState, useContext } from "react";
import { Box, Container, Typography, Grid, Stack, IconButton} from "@mui/material";
import { unflattenObject,getResizedImage } from "../helper";
import { ProductInterface } from "ts-interfaces";
import { FilterWrapper, FilberBtn,DesktopChips,ProductItem, ProductImg, ResultWrapper } from "./BrandProductHits.styles";
import { MyStats } from "./MyStats";
import { AuthContext } from "../../auth/Auth";
import { useLoginState } from "../auth/LoginState";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import CollectionPopover from "../components/CollectionPopover";
import { getProductDisplayName } from "../../libs/util";
import { useInfiniteHits } from "react-instantsearch";
import { MyRefinementChips } from "../../instasearch/CustomRefinementChipList";
import { useInView } from "react-intersection-observer";

export const BrandProductHits2 = ({productType}) => {
  const [filterOpened, setFilterOpened] = useState(false);
  const [stateHits, setStateHits] = useState<ProductInterface[]>()

  const thumbnailsMaterialContainer = useRef<any>();
  const { hits,currentPageHits,results,isFirstPage,isLastPage,showPrevious,showMore,sendEvent} = useInfiniteHits();

	const { ref, inView, entry } = useInView({root:thumbnailsMaterialContainer.current,threshold: 0,});
    useEffect(() => {  
        if (inView==true  && isLastPage==false ) {  
            if (entry && entry?.intersectionRect?.top > 200) {
                showMore()
            }
        } 
    },[inView])

  const handleFilterOpen = () => { setFilterOpened(!filterOpened); };
  useEffect(() => { setStateHits(hits as any)}, [hits]);

  return (
    <Grid sx={{position: 'relative'}}>
      <Container>
        <FilterWrapper>
          <Stack spacing={2}>
          <FilberBtn onClick={handleFilterOpen}>
              <img src={'/images/discover/filter.png'} alt="filter" style={{ width: 24, height: 24 }}/>
              <Typography className="filter-title">Filters</Typography>
            </FilberBtn>
            <DesktopChips>            
              <MyRefinementChips limit={40} attribute='color' attributeName='Color' />
            </DesktopChips>
          </Stack>
        </FilterWrapper>
        <ResultWrapper><MyStats /> results</ResultWrapper>  

      <Grid container ref={thumbnailsMaterialContainer} item xs={12} justifyContent="space-evenly"  style={{height: "70vh",overflowY: "scroll",padding: '0 8px' }} sx={{ "& > *": { minWidth: "150px" },'&::-webkit-scrollbar':{width: 0,height: 0 }}}> 
      {stateHits && stateHits.map((product: ProductInterface, index: number) => {
         return (
            <Grid key={index} item xs={4} md={3} lg={2.4} xl={2} 
            ref={ index === stateHits.length-1 ? ref : undefined }
            sx={{ padding: 0 , display: 'flex', justifyContent:'center'}}> 
              <Product prod={unflattenObject(product)} />
            </Grid>
          );
        })}
      </Grid>  
      </Container>
    </Grid>
  );
};

const imgHeight=150;
  const imgCss:any = { objectFit:'cover',borderRadius:'25px', width:'10vw',height:'10vw', cursor:'pointer', minWidth:'150px', minHeight:'150px' }
  const maxGridWith=imgHeight+'px'
  const tcss = {fontFamily:'Roundo-Regular',fontSize:'10px',color:'gray', textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}
  const tcss2 = {fontFamily:'Roundo-Regular',fontWeight:'900', fontSize:'0.9rem',color:'black',textOverflow:'ellipsis', overflow:'hidden'}
  
const Product = ({prod}) => {
  const { currentUser } = useContext(AuthContext);
  const setShowLogin = useLoginState((state) => state.setShowLogin);
  const [popoverShown, setPopoverShown] = useState(false);
  const thumbnail = getResizedImage(prod, imgHeight);
  const popoverRef = useRef();

  const addCollection = () => {
      if (!currentUser?.uid) { setShowLogin('signup'); return; }
      setPopoverShown(true)
  }
  
  return (
      <>
      <Stack direction="column" spacing={0}>
      <img style={{...imgCss}} src={thumbnail} />
      <Grid container sx={{paddingTop:'8px', paddingBottom: '10px',maxWidth:maxGridWith}}>
          <Grid item xs={9}>
              <Stack>
              <Typography sx={tcss2}>{getProductDisplayName(prod)  }</Typography>
              {/* <Typography sx={tcss} >{prod.supplier || "Generic"}</Typography> */}
              </Stack>
          </Grid>
          <Grid item xs={3} container  justifyContent="center" alignItems="flex-start">                 
              {/* <IconButton onClick={addCollection} size='small' sx={{fontSize:'1rem',backgroundColor:'#e6e6e6'}}><PlaylistAddIcon style={{fontSize:'1rem'}} /></IconButton> */}
          </Grid>
      </Grid>
      </Stack>   
      {/* {popoverShown && 
          <CollectionPopover 
              currentUserUid={currentUser.uid}
              popoverRef={popoverRef}
              onClose={() => setPopoverShown(false)}
              product={prod} 
              /> 
      } */}
      </> 
  )
}
