import React, { useEffect,useState,useRef,useContext, useMemo } from "react"
import { Button,Typography,AppBar,Menu,MenuItem,IconButton,Toolbar, Stack } from "@mui/material";
import { Close, Home, Share, Menu as MenuIcon, FitnessCenter as FitnessCenterIcon } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./Auth";
import {useMattoState} from '../MattoState'
import { Box, styled } from '@mui/system';
import { BarIconButton } from '../buttons/ButtonStyles'
import HelpIcon from '@mui/icons-material/Help';
import AuthButton from "../studioxr/auth/AuthButton";
import { useLoginState } from "../studioxr/auth/LoginState";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UserSubscriptionModal from '../stripe/UserSubscriptionModal';
import { UserProfileRole } from "ts-interfaces";
import { ModalMode } from "../ts/app_interfaces";
import AddIcon from '@mui/icons-material/Add';
import Notifications from './../components/Notifications';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import BoltIcon from '@mui/icons-material/Bolt';
import EmailIcon from '@mui/icons-material/Email';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { getContactForm,getFeedbackForm } from "../libs/util";
import { DeepRenderResults } from "../deep_render/DeepRenderResults";
// import {SubscriptionStatus} from '../stripe/UserSubscriptionModal';


const MyButton2 = styled(Button)(({ theme }) => ({
	[theme.breakpoints.down('lg')]: {
		display:'none'
	},
	color:'white', background:'darkgrey',marginRight:'7px', maxHeight:theme.barButtons.maxHeight }));
	
const MyButton = styled(Button)(({ theme }) => ({ 
	[theme.breakpoints.down('md')]: {
		display:'none'
	},
	color:'white', background:'darkgrey',marginRight:'7px', maxHeight:theme.barButtons.maxHeight }));
const BarToolbar  = styled(Toolbar)(({ theme }) => ({
	paddingLeft:'0px', paddingRight:'0px', minHeight:'50px',backgroundColor:theme.bar.backgroundColor
}));
const StudioXRButton = styled(Button)(({ theme }) => ({ color:'black', background:'white',marginRight:'7px', maxHeight:theme.barButtons.maxHeight }));
const BarAppBar = styled(AppBar)(({ theme }) => ({
	boxShadow: "none",flexGrow:1,height:theme.bar.height,borderRadius:'10px 10px 0px 0px',
}));
const BetaTypography = styled(Typography)({ color: '#737373', paddingLeft:'10px', fontStyle:'italic' })
const HomeButton = styled(Button)({ flexGrow:1, display:'flex', justifyContent:'left',marginLeft:'80px' })
const HoverDiv = styled('div')({ lineHeight:'20px', '&:hover':{'cursor':'pointer' }})
let helpUrl = 'https://mattoboard.helpjuice.com/';


export const Bar = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useNavigate();
	const {currentUser} = useContext(AuthContext);
	const profile = useLoginState((state) => state.profile);
	const setShowLogin = useLoginState((state) => state.setShowLogin);
	const setShowSubscription = useMattoState((state) => state.setShowSubscription);

	const confirmIfDirty = () => {
		const dirty = useMattoState.getState().isStateDirty
		if (dirty==true) {
			const x = window.confirm("Project unsaved! Are you sure you want to leave?")
			if (x!=true) return false;
		}
		return true;	
	}
	const toolbarStyle = useMemo(() => {
		const t = {minHeight:'50px',paddingLeft:'0px',paddingRight:'0px'}
		if (props.homePage==true) t.backgroundImage='url(/images/index-header.jpg)';
		return t
	},[props.homePage])

	const handleProjectClick = () => {
		if (!currentUser?.uid) { setShowLogin('signup'); return; }		
		else if (confirmIfDirty()==false) return;
		else { history('/projects') }
	}
	const handleHelpClick = () => {
		window.open(helpUrl, '_blank').focus();
	}
	const handleTryMattoboardPro = () => {
		setShowSubscription('startTrial')
	}
	const handleHomePageClick = () => {
		if (confirmIfDirty()==false) return;
		history('/')
	}
	const handleDiscoverClick = () => {
		if (confirmIfDirty()==false) return;
		history('/boards')
	}
	const askAQuestion = () => {
		window.href = 'https://mattoboard.helpjuice.com/contact-us'
	}
	const startProject = () => {
		//if (confirmIfDirty()==false) return;
		if (!currentUser?.uid) { setShowLogin('signup'); return; }
		else { window.location.href = '/project/new' }
	}
	const goToAccounts = () => {   setShowSubscription('accounts') }

	return (
		<>
		<BarAppBar position="static"  >
		   <BarToolbar style={toolbarStyle}>
		        {/* <Button style={{maxHeight:'30px'}} startIcon={<MenuIcon />} /> */}
		        <HoverDiv >
		   		<img onClick={handleHomePageClick} style={{maxWidth: '160px', marginLeft: '24px'}} src='/images/mattoboard-logo.png' />
		   		</HoverDiv>
		   		{/* <BetaTypography >Beta</BetaTypography> */}
				{/* <HomeButton onClick={handleProjectClick} startIcon={<Home />}>Start New Project</HomeButton> */}

				{/* <HomeButton onClick={handleProjectClick} startIcon={<Home />}>Projects</HomeButton> */}
				{/* <Stack style={{flexGrow:1, display:'flex'}} direction="row" spacing={1}>
				<HomeButton>Your Projects</HomeButton>
				<HomeButton>Start New Projects</HomeButton>
				</Stack> */}
		
				<Button startIcon={<AddIcon />} onClick={startProject} 
					sx={{paddingLeft:'50px',display: {xs:'none',sm: 'none', md:'flex'}, justifyContent:'left'}}>Start A New Project</Button>
				<Button startIcon={<Home />} onClick={handleProjectClick} sx={{display:'flex', flexGrow:'1', justifyContent:'left'}}>Your Projects</Button>


				{/* <ProjectsMenu /> */}
				{currentUser && profile && profile.userRole !== UserProfileRole.PRO &&
					<MyButton  sx={{ xs:'none',sm: 'none', md:'inline' }} id="mattoboardProBtn" onClick={handleTryMattoboardPro} startIcon={<CheckCircleOutlineIcon style={{ color: 'purple' }} />}>Upgrade to Pro</MyButton>
				}
				{currentUser && profile?.userRole === UserProfileRole.PRO &&
				
					<Box component="div" sx={{ display:{xs:'none',sm: 'inline'} }} >
					<a    href='https://blog.mattoboard.com/pro' target='_'><img src='/images/icons/proFinalIcon.png' style={{ height:'25px',paddingRight:'10px', marginLeft: '24px'}} /></a>
					</Box>
				}
				{/* <SubscriptionStatus/> */}
				
				<HelpButton />
				<DeepRenderResults />
				{/* <MyButton target="_blank" href="https://docs.google.com/forms/u/3/d/e/1FAIpQLSfdCdqyteX7Kq83BO0Tf7DSISjycswfvEHw_2Pyu1tU6mwmJQ/viewform?usp=send_form" startIcon={<CheckCircleOutlineIcon style={{ color: 'purple' }} />}>Try Mattoboard Pro</MyButton> */}

				{/* <MyButton onClick={handleDiscoverClick} startIcon={<ColorLensIcon style={{ color: 'gold' }} />}>Discover Boards</MyButton> */}
				{/* <MyButton target="_blank" href="https://mattoboard.helpjuice.com/contact-us" startIcon={<ColorLensIcon style={{ color: 'gold' }} />}>Ask a Question</MyButton> */}
				 {/* <MyButton2 target="_blank" href="https://zoom.us/webinar/register/WN_Ew7rdjBnTwu5XdhjfVvwaw#/registration" startIcon={<ColorLensIcon style={{ color: 'gold' }} />}>Sign Up For April's Webinar</MyButton2>  */}
				{currentUser && profile?.uid && <Notifications />}
				<AuthButton />
		   </BarToolbar>
		  <UserSubscriptionModal/>
		 </BarAppBar>

		 </>
	)
}

const ProjectsMenu = () => {	
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const history = useNavigate();
	const {currentUser} = useContext(AuthContext);
	const setShowLogin = useLoginState((state) => state.setShowLogin);

	const handleClick = (event) => {
	  setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
	  setAnchorEl(null);
	};

	const confirmIfDirty = () => {
		const dirty = useMattoState.getState().isStateDirty
		if (dirty==true) {
			const x = window.confirm("Project unsaved! Are you sure you want to leave?")
			if (x!=true) return false;
		}
		return true;	
	}

	const startProject = () => {
		if (confirmIfDirty()==false) return;
		if (!currentUser?.uid) { setShowLogin('signup'); return; }
		else { window.location.href = '/project/new' }
	}
	const projects = () => {
		if (confirmIfDirty()==false) return;
		if (!currentUser?.uid) { setShowLogin('signup'); return; }
		else { history('/projects') }
	}
	return (
		<>
		<HomeButton  startIcon={<Home />}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      	>
        Project
      	</HomeButton>
		<Menu
        	id="basic-menu"
	        anchorEl={anchorEl}
    	    open={open}
        	onClose={handleClose}
        	MenuListProps={{'aria-labelledby': 'basic-button' }} >
        		<MenuItem  onClick={startProject}>Start a New Project</MenuItem>
		        <MenuItem onClick={projects}>Show Your Projects</MenuItem>
      	</Menu>
		</>
	)
}

const HelpButtonOld= () => {
	const setModal = useMattoState((state) => state.setModal);
	
	const handleDrawerToggle = (event) => { 
		if (window.gtag) { gtag('event', 'open_help_menu')}
		setModal(ModalMode.HelpCenter)
	}
	return (
		<BarIconButton onClick={handleDrawerToggle} edge='start' size="large">
			<HelpIcon  style={{color:'black', fontSize:'30px'}}/>
		</BarIconButton>
	)
}
const getNavItems = (profile) => {
	const items = [
		{ icon:BoltIcon, secondary:'Quick Tutorials 15 sec', label: "Get Started 7 Tips", link: "welcome",},
		{ icon:YouTubeIcon, secondary:'Full Tutorial Videos', label: "YouTube Tutorials", link: "https://www.youtube.com/@mattoboard586",},
		{ icon:EmailIcon, secondary:'Send Us a Messaage', label: "Contact Us",link: getContactForm(profile),},
		{ icon:ModeCommentIcon, secondary:'Looking for a new feature or brand?', label: "Request Brands/Feedback",link: getFeedbackForm(profile),}

	];
	return items;
}

const HelpButton = () => {
	const profile = useLoginState((state) => state.profile);


	const setModal = useMattoState((state) => state.setModal);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleDrawerToggle = (event) => { 
		if (window.gtag && open==true) { gtag('event', 'open_help_menu')}
		setAnchorEl(event.currentTarget);  
	};
	const handleClose = () => { setAnchorEl(null);  };
	const handleLink = (link) => {
		if (link=='welcome') {  setModal(ModalMode.Welcome); handleClose(); return; }	
		if (window.gtag) { gtag('event', 'help_menu_link', { 'event_category': 'help_menu', 'event_label': link})}
		window.open(link, '_blank')?.focus();
		handleClose();
	};
	return (
		< >


		<BarIconButton   style={{paddingLeft:'25px'}} onClick={handleDrawerToggle} edge='start' size="large">
			<HelpIcon  style={{color:'black', fontSize:'30px'}}/>
		</BarIconButton>
		<Menu  anchorEl={anchorEl} open={open} onClose={handleClose} >
			{getNavItems(profile).map(  (item, index)  => (
				<MenuItem dense={false} key={index}>
				<ListItemIcon sx={{marginLeft:'-5px'}}> 
					<item.icon style={{color:'black'}} />
				</ListItemIcon>
				<ListItemText primary={item.label}  secondaryTypographyProps={{fontSize:'0.78rem', color:'#203ed3'}} secondary={item.secondary} onClick={ () => handleLink(item.link)} />	
				</MenuItem>
			))}
      	</Menu>
	</>
	)
}

//				<MenuItem key={item.label} onClick={ () => handleLink(item.link)}>{item.label}</MenuItem>
