import React, { useRef,useState,useEffect } from 'react'
import GridOnIcon from '@mui/icons-material/GridOn';
import Tooltip from '@mui/material/Tooltip';
import {useMattoState} from '../MattoState'
import useEventListener from '../libs/useEventListener';
import {BarIconButton} from './ButtonStyles'

export function GridButton(props) {
	const toggleGrid = useMattoState((state) => state.toggleGrid)
	useEventListener('keydown', (e) => { 	
		if (e.key=='g' && e.target==document.body) toggleGrid(); 	
	}, document.body);
	
	return (
        <Tooltip title="Grid" arrow>
		<BarIconButton
            onClick={ () => toggleGrid()}
            aria-label="grid"
            edge='start'
            size="large">
		<GridOnIcon style={{color:'black'}} />
		</BarIconButton>
		</Tooltip>
    );

}