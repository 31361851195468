import React, { useEffect, useState, useContext } from "react";
import Header  from './header';
import { AuthContext } from "../auth/Auth";
import { useLoginState } from "./auth/LoginState";

export const StudioXRHome = (props) => {
    const {currentUser} = useContext(AuthContext);
    const setShowLogin = useLoginState((state) => state.setShowLogin);

    let url = 'https://blog.mattoboard.com/signup'
    if (window?.location?.pathname) {
        if (window.location.pathname=='/boards') {
            url='https://blog.mattoboard.com/boards'
        }
    }

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (params.oobCode) {
			setShowLogin('reset')
		}
        if ( (!currentUser) && props.startDesigning==true) {
            sessionStorage?.setItem("redirectTo", '/project/new_from?id=sbsdwnpss9&welcome=true');            
            setShowLogin('signup')
        }
    }, [])

    return (
        <>
        <Header/>
        <iframe src={url} style={{position:'fixed', top:'0px',
                    border:'0px', left:'0px',height:'100%',width:'100%'}}>
                        Your browser doesn't support iframes
                    </iframe>
        </>
      )
}


