import React, { useEffect, useState,useRef } from 'react'
import {useMattoState} from '../MattoState'
import { invalidate } from '@react-three/fiber'

let lightCount = -150

export const AnimateButton = () => {
    const doAnimate = useMattoState((state) => state.doAnimate);
	const setDoAnimate = useMattoState((state) => state.setDoAnimate);
	const animateRef:any = useRef()
	useEffect(() => {
		if (!window.debug.animate) {
			window.debug.animate = () => {  setDoAnimate(!doAnimate) }
		} 
	},[])
	const animate = time => { 
		if (lightCount>450)  {
			cancelAnimationFrame(animateRef.current);
			//animation done. 
			setDoAnimate(false);
			return;
		}
		lightCount +=1;
		const newValue = Math.sin(lightCount/100) * 95
        const hack = window.scene.children.filter(c => c.name=="directional light")?.[0]
        if (hack) {
            hack.position.x = newValue;
            invalidate()
        }
		//setProjectData(state => { state.lighting.directional = newValue })
		animateRef.current = requestAnimationFrame(animate); 
	}
	useEffect(() => {
		lightCount=-150
		if (doAnimate==false) {
			cancelAnimationFrame(animateRef.current);
		}
		else {  animateRef.current = requestAnimationFrame(animate);}
		return () => cancelAnimationFrame(animateRef.current);
	},[doAnimate])

    return (null)
}