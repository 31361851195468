import React, { useState, useContext } from "react";
import Avatar from "@mui/material/Avatar";
import { getUrlFromRef, } from "../../libs/firebase";
import { styled } from '@mui/system';

const MyAvatar = styled(Avatar)({
    color: "#ffffff",
    backgroundColor: "gray",
    height: "30px",
    width: "30px",
  });
export const MyProfilePic = ({img, defaultText='M'}) => {
   if (img) return <MyAvatar src={getUrlFromRef(img, true)} />;
   else return <MyAvatar>{defaultText}</MyAvatar>;
}