import React, { useEffect, useState,useRef } from 'react'
import {Tooltip,Typography,Popover,Slider,Grid} from '@mui/material';
import FlareIcon from '@mui/icons-material/Flare';
import { useProjectData} from '../MattoState'
import {BarIconButton} from './ButtonStyles'
import { invalidate } from '@react-three/fiber';
import { setProfileFirstAction } from '../libs/util';
import { ProfileFirstActions } from '../../../../packages/ts-interfaces/index.ts';

export function LightingButton(props) {
	const [anchorEl, setAnchorEl] = useState(null)
	const [open, setOpen] = useState(false);
	const projectData = useProjectData()
	const setProjectData = useProjectData(state=>state.set)
	const [directionalValue, setDirectionalValue] = useState(0)

	useEffect(() => {
		setDirectionalValue(projectData.lighting.directional)
	}, [projectData.lighting.directional])

	const handleDirectionalChange = (event,newValue) => {	
		const hack = window.scene?.children?.filter(c => c.name=="directional light")?.[0]
		if (hack?.position?.x) {
			hack.position.x = newValue;
			invalidate()
		}
		else { 
			setProjectData(state => { state.lighting.directional = newValue })
		}
		setDirectionalValue(newValue)
	}
	const handleDirectionalCommit = (event,newValue) => {
		setProjectData(state => { state.lighting.directional = newValue })
	}
	const handleDirectionalLightChange = (event,newValue)=> {
		setProjectData(state => { state.lighting.directionalIntensity  = newValue })
	}
	const handleColorTempChange = (event,newValue) => {
		setProjectData(state=> {state.lighting.colorTemperature = newValue})
	}
	
	const handleClick = () => (event) => {
		setProfileFirstAction(ProfileFirstActions.OPEN_LIGHTING).finally(() => {})
		setAnchorEl(event.currentTarget);
		setOpen(!open)
	};
	const handleClose = () => {  setOpen(false) };
	return (
        <React.Fragment>
		<Tooltip title="Lighting" arrow>
		<BarIconButton
			sx={{paddingTop: { xs:'0px',sm: '0px', md: '12px' }, paddingBottom: { xs:'0px',sm: '0px', md: '12px' }}}
            onClick={handleClick()}
            aria-label="download"
            edge='start'
            size="large">
		<FlareIcon style={{color:'black'}}  />
		</BarIconButton>
		</Tooltip>
		<Popover
		  open={open}
		  anchorEl={anchorEl}
		  onClose={handleClose}
		  anchorOrigin={{ vertical: 'bottom',horizontal: 'center',}}
		  transformOrigin={{vertical: 'top',horizontal: 'left',}}
		>
		  <Grid container alignItems="center" style={{overflow:'hidden',minWidth:'265px', background:'#ebebeb', padding:'4px 12px', borderRadius:'6px'}}>
			<LightingSlider name="Color Temperature" max={17500} min={2000} step={50} value={projectData.lighting.colorTemperature} onChange={handleColorTempChange} />
			<LightingSlider name="Light Intensity" max={12} min={0} step={0.01} value={projectData.lighting.directionalIntensity} onChange={handleDirectionalLightChange} />
			<LightingSlider name="Light Position" max={95} min={-95} step={0.01} value={directionalValue} onChangeCommitted={handleDirectionalCommit} onChange={handleDirectionalChange} />
		  </Grid>
		</Popover>
	  </React.Fragment>
    );
}
const typographyStyle = {color:'#737373',fontSize:'14px'}
const gridStyle={paddingLeft:'10px', paddingTop:'1px', display:'flex'}
function LightingSlider({name,max,min,step,value,onChange,onChangeCommitted}) {
	return (
		<React.Fragment>
		<Grid item xs={5}><Typography style={typographyStyle} >{name}:</Typography></Grid>
		<Grid item xs={7} style={gridStyle} >
		<Slider size="small" style={{color:'#000'}} max={max} min={min} step={step} value={value} 
			onChangeCommitted={onChangeCommitted} onChange={onChange} aria-labelledby="continuous-slider" />
		</Grid>
		</React.Fragment>
	)
}