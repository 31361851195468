import React, { useMemo,useRef, useState,useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { useMattoState } from '../MattoState';
import { SideBarMode } from '../ts/app_interfaces';
import { EnvironmentTab } from './EnvironmentTab';
import { CollectionTab } from './CollectionTab';
import { TemplateTab } from './TemplateTab';
import { BackgroundTab } from './BackgroundTab';
import { UploadsTab } from './UploadsTab';
import { TypesenseSearch } from '../instasearch/TypesenseSearch';
import { ProductHits } from '../instasearch/ProductHits';
import { ProductType } from '../../../../packages/ts-interfaces';
import { CreateMaterialTab } from './CreateMaterialTab';
import { TextTab } from './TextTab';


export const SideBarCategory = () => {
    const sideBarMode:any = useMattoState((state) => state.sideBarMode);
    // const sideBarPaints = useMemo(() => {
    //     if (sideBarPaints==SideBarMode.Paints) return true

    // },[sideBarMode])
    return (
        <Grid container spacing={1}>           
            <div  style={{ display: sideBarMode==SideBarMode.Objects ? "block" : "none", width:'100%'}} >
                <TypesenseSearch indexName='productsV2'   productType={ProductType.NOT_STATIC} hitsHandler={ProductHits} /> 
            </div>
            {/* <div  style={{ display: sideBarMode==SideBarMode.Products ? "block" : "none", width:'100%'}} >
                <TypsenseSearch indexName='productsV2' defaultQuery={staticObjectQuery} hitsHandler={ProductHits}   /></div> */}
            {/* <div  style={{ display: sideBarMode==SideBarMode.Environment ? "block" : "none" }} > <EnvironmentTab  /></div> */}
            <div style={{ display: sideBarMode==SideBarMode.YourMaterials ? "flex" : "none", width: '100%' }} > <CreateMaterialTab  /></div>
            <div style={{ display: sideBarMode==SideBarMode.Collections ? "flex" : "none", width: '100%' }} > <CollectionTab /></div>
            <div  style={{ display: sideBarMode==SideBarMode.Templates ? "block" : "none" }} > <TemplateTab  /></div>
            {sideBarMode==SideBarMode.Text && <TextTab  /> }
            
            {/* <div  style={{ display: sideBarMode==SideBarMode.Background ? "block" : "none" }} > <BackgroundTab  /></div> */}
            <div  style={{ display: sideBarMode==SideBarMode.Accents ? "block" : "none", width:'100%'}} > 
                <TypesenseSearch  doQuery={sideBarMode==SideBarMode.Accents}  indexName='productsV2' productType={ProductType.STATIC}  hitsHandler={ProductHits} /> 
            </div>
            <div  style={{ display: sideBarMode==SideBarMode.Paints ? "block" : "none", width:'100%'}} > 
                 <TypesenseSearch  doQuery={sideBarMode==SideBarMode.Paints} indexName='productsV2' productType={ProductType.PAINTOBJECT}  hitsHandler={ProductHits} /> 
            </div>
            <div  style={{ display: sideBarMode==SideBarMode.Uploads ? "flex" : "none", flexGrow: 1 }} > <UploadsTab  /></div>
        </Grid>
    )
}