import React from 'react'
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { BarIconButton, NoMaxWidthTooltip } from './ButtonStyles';
import { useMattoState } from '../MattoState';

const TooltipText = "Reset Camera\nNote: To rotate camera angle, right click & drag mouse/touchpad on background"

export function ResetCamera(props) {

	const controls = useMattoState((state) => state.controls)
	const handleClick = () => (event) => {
		resetCameraToOriginalPosition(controls)
	};

	return (
		<React.Fragment>
			<NoMaxWidthTooltip title={<div style={{textAlign:'center', whiteSpace: 'pre-line'}}>{TooltipText}</div>} arrow>
				<BarIconButton
					sx={{paddingTop: { xs:'0px',sm: '0px', md: '12px' }, paddingBottom: { xs:'0px',sm: '0px', md: '12px' }}}
					onClick={handleClick()}
					edge='start'
					size="large">
					<CameraswitchIcon style={{ color: 'black' }} />
				</BarIconButton>
			</NoMaxWidthTooltip>
		</React.Fragment>
	);
}
export const resetCameraToOriginalPosition = (controls) => {
	if (!controls?.current?.target) return
	const startingCameraPosition2 = [1, 0, 0, 0, 0, 0.0000010001554604510685, -0.9999999999994995, 0, 0, 0.9999999999994995, 0.0000010001554604510685, 0, 0, 49.367843386243472, 0.000015370232479014257, 1]
	window.camera.matrix.fromArray(startingCameraPosition2)
	window.camera.matrix.decompose(window.camera.position, window.camera.quaternion, window.camera.scale);
	controls.current.target.set(0, 0, 0);
	window.camera.updateProjectionMatrix();
	controls.current.update();
	window.gl.shadowMap.autoUpdate=true
	window.gl.shadowMap.needsUpdate=true
}