import React, { useEffect } from 'react'
import useEventListener from '../libs/useEventListener';
import { useProjectData,usePhysicalObjects } from '../MattoState'
import { getEnvironmentalMap } from '../libs/environment';
import { getProductCache, getUrlFromRef } from '../libs/firebase';
import { useThree } from '@react-three/fiber'
import { createCanvasWithText } from '../threejs/objects/CanvasObject';
import { CANVAS_SCALE, drawLineCanvas } from '../threejs/objects/CanvasLineObject';
import { getPreset } from 'ui';
import * as Sentry from "@sentry/react";

const mesh_keys_to_keep=["meshType","isStaticObject", "full_sized_glb","web_sized_glb","meshTextureRepeat","files"]

export const ExportCurrentProject =  () => {
    const [doExport,setDoExport]=React.useState(false)

    const handleExport = (json) => {
        setDoExport(false)
        const blob = new Blob([json],{type: 'text/json' })
        blob.extension='.json'
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = URL.createObjectURL( blob );
        link.download = 'scene.json';
        link.click();
    }

    const handleKeyDown = (e) =>  {  
        if ( (e.key=='O' || e.key=='o' ||  e.code=="KeyO") && e.shiftKey==true && (e.ctrlKey==true || e.metaKey==true || e.altKey==true)) { 
           setDoExport(true)
        }
    }
    useEventListener('keydown', handleKeyDown);
    return (<ExportCurrentScene doExport={doExport} onExport={handleExport}/>)
}




export const ExportCurrentScene = ({doExport,onExport}) => {
    const camera = window.camera
    const scene = window.scene
    const updateAllObjectStates = usePhysicalObjects((state) => state.updateAllObjectStates)
    const getThreeJSObject = usePhysicalObjects( (state) => state.getThreeJSObject);
    
    useEffect(  () => {
        try {
            if (doExport==true) onExport?.(exportProject())  
        }
        catch (e) {            
            Sentry.captureException(e)
            console.log(e)
        }
    },[doExport])

    const exportProject =  () => {
        updateAllObjectStates()
        const physicalObjects = usePhysicalObjects.getState().physicalObjects
        const projectData = useProjectData.getState()
        const project={}
        
        const light = scene.children.filter(t => t.type=='DirectionalLight')[0]
        project.light = light.toJSON()
        project.light.scale = light.scale
        project.light.position = light.position
        project.light.rotation = light.rotation
                
        const canvas = document.getElementsByTagName('canvas')[0]
        project.canvas= {width:canvas.width, height:canvas.height}

        project.camera = camera.toJSON()        
        project.camera.scale = camera.scale
        project.camera.rotation = camera.rotation
        project.camera.quaternion = camera.quaternion
        project.camera.position = camera.position
        if (localStorage.user_session) project.session=localStorage.user_session

        project.environment = getUrlFromRef(getEnvironmentalMap(projectData.envmap).small_1k)
        project.floor = JSON.parse(JSON.stringify(projectData.backgroundTexture))
        if (project.floor==null) {
            const p = window.scene.children.filter(c => c.name=='floor')[0].toJSON().materials?.[0]
            project.floor={materialProps:p }
        }
        const objects=[];

        ['tags','original_name','objectID','visible','updatedAt','sourceURL','materialCategory','hash','supplier','text_match','materialTextures','materialColor','description','objectStatus'].forEach(k => {
            delete project?.floor?.[k]
        })

        Object.keys(project?.floor?.files ?? {} ).forEach(key => {
            const url = getUrlFromRef(project?.floor?.files[key])
            project.floor.files[key]=url
        })
    
        physicalObjects.forEach(object => {            
            const o = JSON.parse(JSON.stringify(object));
            
            delete o.category; delete o.meshId; delete o.refId;
            delete o.originalScale; delete o.objectStatus; delete o.meshTextureRepeat;
            delete o.description; delete o.supplier; delete o.visible; 
            delete o.isMaterialChangeable;  delete o.materialCategory; delete o.active;
            delete o.textureRepeat; delete o.dimensionData;
            if (o.mesh) {
                delete o.mesh.full_file_size;
                delete o.mesh.full_sized_vertices;
                delete o.mesh.thumbnail;
                delete o.mesh.web_file_size;
                delete o.mesh.web_sized_vertices;
                delete o.mesh.rendered_image
            }
            if (o.mesh && o.materialData?.materialProps) {
                const obj = scene.children.filter(f => f?.userData?.key == o.key )?.[0]                
                if (obj?.children?.[0]?.material?.map?.repeat) {                    
                    o.materialData.materialProps['three_repeat'] = obj.children[0].material.map.repeat
                    //o.mesh.three_repeat = obj.children[0].material.map.repeat
                }
            }
            // Object.keys(o).map( key => {
            //     if (!mesh_keys_to_keep.includes(key)) delete o[key]                
            // })

            const threeJS = getThreeJSObject(object.key)
            // console.log("look ", threeJS);

            Object.keys(o?.dynamicMaterialProps?.files ?? {}).forEach(f => {                
                const url = getUrlFromRef(o?.dynamicMaterialProps?.files)
                o.dynamicMaterialProps.files=url
            })
            Object.keys(o?.materialData?.files ?? {}).forEach(f => {
                const url = getUrlFromRef(o?.materialData?.files[f])
                o.materialData.files[f]=url
            })

            if (o?.files) {
                Object.keys(o?.files).forEach(f => {
                    if (o.type=='dynamic') {
                        const url = 'https://' + window.location.host + '/' + o?.files[f]
                        o.files[f]=url
                    }
                    else {
                        const url = getUrlFromRef(o?.files[f])
                        o.files[f]=url
                    }
                })  
            }
            if (object.materialData.files?.color_original) {
                try {
                    o.materialData.materialProps.textureRepeat = threeJS.children[0].material.map.repeat.x                
                }
                catch (e) { console.log(e) }
            }          
            if (o.type=='canvas') {    
                if (o.type=='canvas' && o.canvasObject?.text?.length < 1) return
                      
                const c = o.canvasObject.type=='line' ? drawLineCanvas(o.canvasObject) : createCanvasWithText(o.canvasObject)
                o.dynamicMaterialProps= { files:c.toDataURL(),height:c.height,width:c.width }
                
                if (o.canvasObject.type=='line') {
                    //kinda a hack here.
                    o.scale = o.scale.map(f => f/CANVAS_SCALE)
                }                
                delete o.canvasObject
            }
            if (o.type=='paint') {
                if(!o.paintMaterialsData) return;
                for (let [index, value] of o.paintMaterialsData.entries()) {
                    if (value.preset==null) continue
                    const p = getPreset(value.preset)
                    if (p?.materialProps==null) continue                    
                    o.paintMaterials[index] = {...o.paintMaterials[index], ...p.materialProps }                     
                }
            }
            objects.push(o)
        })
        
        project.objects = objects;
        project['title'] = webify(projectData.title);
        console.log(project);
        return JSON.stringify(project)
    }
    return (null)
}

function webify(str) { return str.replace(/[^a-zA-Z0-9]/g,'_') }