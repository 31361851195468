import React, { useRef, useState,useEffect,useContext } from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { ProIconButton } from '../buttons/ProIconButton';
import { ObjectStatusType } from 'ts-interfaces';
import { BookmarkButton } from '../buttons/BookmarkButton';
import { getResizedImage } from '../studioxr/helper';
import { useDoubleClick } from './useDoubleClick';

const imgCss = { borderRadius:'13px', width:'7vw',height:'7vw', cursor:'grab',backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat'}
const textCss = { textAlign:'center', textTransform:'capitalize', overflow:'hidden', textOverflow:'ellipsis'}

export const ImageItem = (props) => {
	let currentUserUid = props?.currentUserUid
	const imageUrl = escapeSpecialCharacters(props.src)
	const [showBookmarkIcon, setShowBookmarkIcon] = useState(false);
	//const myDoubleClickCallback = useDoubleClick(props.onClick, props.onClick);

	const handleMouseOver = (e) => {
		props.onMouseOver?.(e)
		setShowBookmarkIcon(true)
	}
	const handleMouseOut = (e) => {
		props.onMouseOut?.(e)
		setShowBookmarkIcon(false)
	}
	const product = {
		id: props.id,
		name: props.name,
		src: props.src,
		type: props.type,
		objectStatus: props.objectStatus
	}
	return (
		<Grid container spacing={0}  >
		   <Grid item xs={12} style={{display:'flex', justifyContent:'center'}} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
			   <div style = {{position: 'relative'}}>
					{/* <img  onDragStart={props.onDragStart} id={props.id} draggable={props.draggable}  src={props.src} 
						style={{...imgCss}} onClick={props.onClick} /> */}
						 <div className="image-item" id={props.id} style={{...imgCss,backgroundImage:'url("'+imageUrl+'")'}} 
							onClick={props.onClick}  onDragStart={props.onDragStart} draggable={props.draggable}></div> 

					{props.onDelete && <CloseIcon /> }
					{/* {(props.type == 'collection' || props.type == 'material' || props.type == 'brandedAccessories' || props.type =='selectedMaterial') && 
						<InfoButton id={props.id} visible={props?.visible ? props.visible :false}/>
					} */}

					{(props.type != 'shapeshift' && !props.hideBookmarkIcon) && <BookmarkButton product={product} showBookmarkIcon={showBookmarkIcon} currentUserUid={currentUserUid} />}

					{/* {props.type == 'material' && currentUserUid &&
						<FavoriteButton id={props.id} currentUserUid={currentUserUid}/>
					} */}
					{props.objectStatus ==  ObjectStatusType.APPROVED_PRO && 
						<ProIconButton id={props.id} />
					}
				 </div>  
		   </Grid>
		 	<Grid container item xs={12} justifyContent="center" >     		 
			 <Typography variant="caption" style={{...textCss}}>{props.name}</Typography> 
		 	</Grid>
		</Grid>
	)
}

const escapeSpecialCharacters = (url) => {
	url = url.replace(/'/g, '%27');
  
	url = url.replace(/ /g, '%20');
  
	return url;
  }
