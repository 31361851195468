import React, {useMemo,useRef,useEffect,useState} from 'react'
import { Select,MenuItem } from '@mui/material'
import { styled } from '@mui/system';
import { usePhysicalObjects } from '../MattoState'

const SelectMultiColor = styled(Select)(({ theme }) => ({  fontWeight:'500',fontSize:'0.875rem' }));

export const PaintMaterialSelect = () => {
    const getCurrentThreeJSObject = usePhysicalObjects( (state) => state.getCurrentThreeJSObject)
    const selectedPhysicalObject:any = usePhysicalObjects(state=>state.selectedPhysicalObject)
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const setSelectedMaterialndex = usePhysicalObjects(state=>state.setSelectedMaterialndex)

    useEffect(() => { setSelectedMaterialndex(0) }, [selectedPhysicalObjectKey])
    const colorMaterialArray = useMemo(() => {        
        const a:string[]= []
        if (selectedPhysicalObject?.type!='paint') return []    
        const threeObj = getCurrentThreeJSObject()
        threeObj?.traverse( (child:any) => {
            if(child.isMesh && child.material?.name?.includes('color_material')){
                a.push(child.material.name)
            }
        })
        return a.sort();
    }, [selectedPhysicalObject])
    const handleMaterialNameChange = (event) => {
        const idx = parseInt(event.target.value.slice(-1))-1;
        setSelectedMaterialndex(idx)
    }
    if (colorMaterialArray.length < 2) return (null)
    return (
        <SelectMultiColor  sx={{ boxShadow: 'none', '.MuiSelect-select':{padding:'2px 4px', background: 'white', border: '1px solid #666' },'&.Mui-focused .MuiOutlinedInput-notchedOutline':{border:0},'.MuiOutlinedInput-notchedOutline': { border: 0 }}} id="materialName" name="materialName"  defaultValue={colorMaterialArray[0]} onChange={handleMaterialNameChange}>
        {colorMaterialArray.map((materialName:any) => (
            <MenuItem key={materialName} value={materialName}>
              {materialName?.charAt(0).toUpperCase() + materialName?.slice(1).replace('_material',' ')}
              </MenuItem>
        ))}
        </SelectMultiColor>        
    )
}