import React, { useRef,useState,useMemo,useEffect } from 'react'
import { SphereGeometry,BoxGeometry ,CylinderGeometry,Color } from 'three';
import {PhysicalMaterial} from '../PhysicalMaterial'
import { PhysicalObjectInterface } from '../../ts/app_interfaces';
import { useBVH } from '../../components/useBVH';

export const Shape = (props) => {
	const physicalObject:PhysicalObjectInterface = props.physicalObject
	const ref:any = useRef()
	useBVH(ref)
	useEffect(() => { if (props.onLoad) props.onLoad() },[])


	const geometry = useMemo(() => {
		switch (physicalObject.name) {
	        case 'Cube': return new BoxGeometry(5,5,6,64,64) as any;
	        case 'Sphere': return new SphereGeometry( 4.5, 64, 64 );
	        case 'Plane': return new BoxGeometry(3,4,0.1,30,30);
	        case 'Cylinder': return new CylinderGeometry(4,4,12,32,32);
			case 'Wall': return new BoxGeometry(3,4,0.1,30,30);
	        default: return null;
		}
	}, [physicalObject.name])

	if (physicalObject.materialData==null) return (null);
	return (
	    <mesh ref={ref} castShadow receiveShadow  geometry={geometry} >
	       <PhysicalMaterial  material={physicalObject.materialData}   />
	    </mesh>
	)
}
