import React, { useEffect, useState } from 'react'
import { PhysicalObjectInterface } from '../ts/app_interfaces';
import { useMattoState, usePhysicalObjects } from '../MattoState'
import { useLoginState } from '../studioxr/auth/LoginState';
import { ProfileInterface, UserProfileRole } from 'ts-interfaces';
import { produce } from 'immer';
import { getPublicURL, updateRecord, useStaging } from '../libs/firebase';
import { dynamicObjects } from '../threejs/objects/DynamicObject';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { generateID } from '../libs/util';
import { ActionButton } from './ButtonStyles';

export const BackgroundRemovalButton = () => {
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)
    const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)
    const profile: ProfileInterface | any = useLoginState((state) => state.profile);
    const setShowLogin = useLoginState((state) => state.setShowLogin);
    const [backgroundRemoved, setBackgroundRemoved] = useState(false);
	const setShowSubscription = useMattoState((state) => state.setShowSubscription);
	const setTransformMode = useMattoState((state) => state.setTransformMode);
	const addPhysicalObject = usePhysicalObjects((state) => state.addPhysicalObject)
    const updateObjectState = usePhysicalObjects(state=>state.updateObjectState)
	const isUploading = useMattoState((state)=>state.isUploading)
    const backgroundRemovalProcessing = useMattoState((state) => state.backgroundRemovalProcessing);
    const setBackgroundRemovalProcessing = useMattoState((state) => state.setBackgroundRemovalProcessing);
	const setSnackbarMessage = useMattoState((state) => state.setSnackbarMessage);

    useEffect(() => {
        if(selectedPhysicalObject?.dynamicMaterialProps?.backgroundRemoved) {
            setBackgroundRemoved(true);
        } else {
            setBackgroundRemoved(false);
        }
    }, [selectedPhysicalObject]);

    const breakString = (inputString: string) => {
        const regex = /^(.*\/)(\d+_.*)(\.[^.]+)$/;
        const match = inputString.match(regex);
      
        if (match) {
          const [, prefix, middle, suffix] = match;
          return [prefix, middle, suffix];
        } else {
          // Handle the case where the input string doesn't match the expected pattern
          console.error("Invalid input string format");
          return null;
        }
    }

    const isObjectDynamic = () => {
        if(selectedPhysicalObject && selectedPhysicalObject?.isDynamic && selectedPhysicalObject?.dynamicMaterialProps?.metadata?.type !== 'logo') return true;
        else return false;
    }

    const changeMeshToPlane = (backgroundRemovedFile) => {
        const dynamicObject = dynamicObjects[0];
        const originalFile = selectedPhysicalObject.dynamicMaterialProps?.files; 
        const newObject:any = produce(selectedPhysicalObject, draft=> {
            draft.name = dynamicObject.name;
            draft.mode = dynamicObject.mode;
            draft.dimensionData = [];
            draft.isStaticObject=true;
            draft.meshName = dynamicObject.meshName;
            draft.flipY = dynamicObject.flipY;
            draft.scale = [dynamicObject.scale,dynamicObject.scale,dynamicObject.scale];
            if (dynamicObject.aspect) draft.aspect = dynamicObject.aspect;
            draft.rotation = dynamicObject.rotation;
            if (dynamicObject.centered) draft.centered = dynamicObject.centered;
            draft.envMapIntensity = dynamicObject.envMapIntensity;
            if (draft.dynamicMaterialProps) draft.dynamicMaterialProps.repeat = {x:null, y:null};
            if (draft.dynamicMaterialProps) draft.dynamicMaterialProps.offset = {x:null, y:null};
            if (dynamicObject.meshTextureRepeat) draft.meshTextureRepeat = dynamicObject.meshTextureRepeat;
            if (dynamicObject.dynamicScale) draft.dynamicScale = dynamicObject.dynamicScale;
            if (dynamicObject.files) draft.files = dynamicObject.files; 
            if (draft.dynamicMaterialProps) {
                draft.dynamicMaterialProps.files = backgroundRemovedFile;
                draft.dynamicMaterialProps.backgroundRemoved = true;
                draft.dynamicMaterialProps.originalFile = originalFile;
                draft.dynamicMaterialProps.backgroundRemovedFile = backgroundRemovedFile;
            }
        })
        usePhysicalObjects.getState().deleteSelectedObject();
        addPhysicalObject(newObject, true);
        setTransformMode(null);
    }

    const getImageDimensions = async (imageUrl) => {
        try {
          const img: any = new Image();
          img.src = getPublicURL(imageUrl);
          await new Promise((resolve, reject) => {
            img.onload = () => resolve();
            img.onerror = (error) => reject(error);
          });
          const width = img.width;
          const height = img.height;
          return { width, height };
        } catch (error) {
          console.error('Error getting image dimensions:', error);
          return { width: 0, height: 0 };
        }
    };

    const removeBackground = async () => {
		if(profile?.userRole !== UserProfileRole.PRO) {  
            if(!profile?.uid) setShowLogin('signup'); 
            else setShowSubscription(['startTrial', 'Sign up to Pro for unlimited background removals']); return; 
        }

        if (!selectedPhysicalObject) return;
        
        if(!selectedPhysicalObject.dynamicMaterialProps?.backgroundRemoved) {
            let newObj;
            setBackgroundRemovalProcessing(true);
            
            if(selectedPhysicalObject?.dynamicMaterialProps?.backgroundRemovedFile) {
                if(selectedPhysicalObject.name !== "Plane") {
                    changeMeshToPlane(selectedPhysicalObject?.dynamicMaterialProps?.backgroundRemovedFile);        
                } else {
                    const bgRemovedFile = selectedPhysicalObject?.dynamicMaterialProps?.backgroundRemovedFile;
                    const { width, height } = await getImageDimensions(bgRemovedFile);
                    newObj = produce(selectedPhysicalObject, draft => {                 
                        if (draft.dynamicMaterialProps?.files) {
                            draft.dynamicMaterialProps.files = bgRemovedFile;
                            draft.dynamicMaterialProps.width = width;
                            draft.dynamicMaterialProps.height = height;
                            draft.dynamicMaterialProps.backgroundRemoved = true;
                            
                        }
                    });
                    updateObjectState(newObj);
                }
                setBackgroundRemoved(true);
                setBackgroundRemovalProcessing(false);
            } else {
                setSnackbarMessage('Processing Removing Background', false);
                const uploadedImage = selectedPhysicalObject?.dynamicMaterialProps?.files;
                const uploadedImagePublicURL = getPublicURL(uploadedImage);
                const uploadedImageArray = breakString(uploadedImage);
                const [filePath, fileName, extension] = uploadedImageArray
                const updatedFileName = fileName + '_bg_removed.png';
                const uploadedImageName = fileName + extension;
                const removeBackgroundRequest = {
                    image: uploadedImagePublicURL,
                    filePath: filePath,
                    fileName: updatedFileName,
                    useStaging: useStaging,
                };

                const requestURL = useStaging ? 'https://us-central1-mattoboard-staging.cloudfunctions.net/removeBackgroundFromImageAndTrim' : 'https://us-central1-mattoboard-b8284.cloudfunctions.net/removeBackgroundFromImageAndTrim';
                try {
                    const resp = await fetch(requestURL, {
                        method: 'POST',
                        headers: {'Accept': 'application/json','Content-Type': 'application/json'},
                        body: JSON.stringify(removeBackgroundRequest)
                    });
        
                    if (!resp.ok) {
                        throw new Error(`HTTP error! status: ${resp.status}`);
                    }                
    
                    const data = await resp.json();
                    const { width, height } = await getImageDimensions(data.url);
    
                    if(selectedPhysicalObject.name !== "Plane") {
                        changeMeshToPlane(data.url);
                    } else {
                        const originalFile = selectedPhysicalObject.dynamicMaterialProps?.files; 
                        newObj = produce(selectedPhysicalObject, draft => {                 
                            if (draft.dynamicMaterialProps?.files) {
                                draft.dynamicMaterialProps.files = data.url;
                                draft.dynamicMaterialProps.width = width;
                                draft.dynamicMaterialProps.height = height;
                                draft.dynamicMaterialProps.backgroundRemoved = true;
                                draft.dynamicMaterialProps.originalFile = originalFile;
                                draft.dynamicMaterialProps.backgroundRemovedFile = data.url;
                            }
                            draft.key = Math.random();
                        });  
                        // updateObjectState(newObj);
                        usePhysicalObjects.getState().deleteSelectedObject();
                        addPhysicalObject(newObj, true);
                        setTransformMode(null);
                    }
                    const fileArray = filePath.split('/');
                    const userId = fileArray[1];
                    const currentUserUid = profile?.uid;
    
                    useMattoState.setState({ isUploading:true })
                    let recordId = fileArray[2];
                    let recordData = {};
    
                    if(userId == currentUserUid) {
                        recordData = {
                            files: {
                                bg_removed_file: data.url
                            }
                        };
                    } else {
                        recordId = generateID();
                        recordData = {
                            id: recordId,
                            updatedAt: Date.now(),
                            uid: currentUserUid,
                            name: selectedPhysicalObject.dynamicMaterialProps?.metadata?.name || uploadedImageName,
                            files: {
                                file: selectedPhysicalObject.dynamicMaterialProps?.files,
                                bg_removed_file: filePath + updatedFileName
                            }
                        }
                    }
    
                    updateRecord(recordId, 'userImages', recordData)
                        .then(result => {
                            useMattoState.setState({ isUploading:false })
                            setSnackbarMessage('Background Removing Done');
                        })
                    setBackgroundRemoved(true);
                } catch (error) {
                    console.error('Error removing background:', error);
                    setBackgroundRemoved(false);
                    setSnackbarMessage('Some error removing background');
                } finally {
                    setBackgroundRemovalProcessing(false);
                }
            }
        }
    }

    const addBackground = async () => {
		if(profile?.userRole !== UserProfileRole.PRO) {  if(!profile?.uid) setShowLogin('signup'); else setShowSubscription('startTrial'); return; }

        if(selectedPhysicalObject.dynamicMaterialProps?.backgroundRemoved) {
            const originalFile = selectedPhysicalObject.dynamicMaterialProps?.originalFile;
            const { width, height } = await getImageDimensions(originalFile);
            const newObj = produce(selectedPhysicalObject, draft => {                 
                if (draft.dynamicMaterialProps?.files) {
                    draft.dynamicMaterialProps.files = originalFile;
                    draft.dynamicMaterialProps.width = width;
                    draft.dynamicMaterialProps.height = height;
                    draft.dynamicMaterialProps.backgroundRemoved = false;
                }
            })            
            updateObjectState(newObj);
            setBackgroundRemoved(false);
        }
    }

    return (
        <>
            {isObjectDynamic() && !backgroundRemoved &&
                <ActionButton disabled={backgroundRemovalProcessing || isUploading} onClick={(e) => removeBackground()}>{backgroundRemovalProcessing ? 'Processing...' : 'Remove Background'}</ActionButton>
            }
            {isObjectDynamic() && backgroundRemoved &&
                <ActionButton onClick={(e) => addBackground()}>Add Background</ActionButton>
            }
        </>
    )

}