import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            iphone: 200,
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        }
    },
    typography: {
        button: {textTransform: 'none'},
        fontFamily: ['Montserrat','Roboto','"Helvetica Neue"','Nunito','Arial','sans-serif'].join(',')
    },
    bar: { height:'50px', backgroundColor:'#cccccc' },
    barButtons: {maxHeight:'30px' },
    subBarButtons: {maxHeight:'27px' },
    secondBar: {height:'40px' },
    iconColor: { color:'black' },
    container: {paddingLeft:'10px',paddingRight:'10px'},
      
    palette: {
        background: { default: '#ebebeb'},
        primary: {
            light: '#FFFFFF',main: '#000000',dark: '#002884',contrastText: '#FFFFFF', hero: '#c58ed4',
            shades: {
                50: '#d9d9d9',
                100: '#c58ed4',
                200: '#a251b9',
                250: '#9b9b9b',
                300: '#6d337f',
                600: '#37193f',
                800: '#6c6c6c',
                900: '#000000'
            },
            monochrome: {
                primary: '#c58ed4',
                secondary: '#6d337f',
                light: '#eaeaea',
                medium: '#3d3d3d',
                dark: '#18191b',
            },
            tint: {
                t1: '#c58ed4',
                t2: '#d2abdd',
                t3: '#e2c7e8',
                t4: '#f0e3f4',
                t5: '#ffffff',
            }
        },
        secondary: { 
            light: '#ff7961',main: '#cccccc',dark: '#ba000d',contrastText: '#000',
        }
    },
});

export default theme;
