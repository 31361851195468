import React, {useRef, useEffect, useMemo, useState} from 'react'
import { useMattoState, usePhysicalObjects} from "../MattoState";
import LoopIcon from '@mui/icons-material/Loop';
import {IconButton,Button,Slider} from '@mui/material'
import {Box3, Vector3,Vector2,Matrix4} from "three";
import {collisions } from "../libs/collision-rules";
import { invalidate }  from '@react-three/fiber'
import { styled } from '@mui/system';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { InspectorMode } from '../ts/app_interfaces';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {  NoMaxWidthTooltip } from '../buttons/ButtonStyles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

export const TransformButton = styled(Button)(({ theme }) => ({ 
	margin: theme.spacing(1),
	pointerEvents: 'auto',
	position: 'absolute',
	top: '-9px',
	left: '65px',
	backgroundColor:'white'
}))

const buttonSwapUpCss = {backgroundColor:'white',pointerEvents: 'auto',position: 'absolute',top: 135,padding: 6,
               			width: 32,minWidth: 32,paddingLeft: 18,paddingRight: 7,color: 'black'}
const buttonSwapUpCss2 = {backgroundColor:'white',pointerEvents: 'auto',position: 'absolute',top: 165,padding: 6,
               			width: 32,minWidth: 32,paddingLeft: 18,paddingRight: 7,color: 'black'}

const buttonInfoCss = {backgroundColor:'white',pointerEvents: 'auto',position: 'absolute',top: 168,padding: 6,
               			width: 32,minWidth: 32,paddingLeft: 18,paddingRight: 7,color: 'black'}
const buttonInfoCss2 = {backgroundColor:'white',pointerEvents: 'auto',position: 'absolute',top: 198,padding: 6,
               			width: 32,minWidth: 32,paddingLeft: 18,paddingRight: 7,color: 'black'}
const buttonLockCss = {backgroundColor:'white',pointerEvents: 'auto',position: 'absolute',top: 102,padding: 6,
                        width: 32,minWidth: 32,paddingLeft: 18,paddingRight: 7,color: 'black'}
const buttonLockCss2 = {backgroundColor:'white',pointerEvents: 'auto',position: 'absolute',top: 132,padding: 6,
						width: 32,minWidth: 32,paddingLeft: 18,paddingRight: 7,color: 'black'}


function calcSidePosition(object, side='left',camera=window.camera,gl=window.gl) {
	const v1 = new Vector3()
	const size = new Vector2()
	gl.getSize(size)
	object?.updateMatrixWorld();
    v1.setFromMatrixPosition(object?.matrixWorld);
	const box = new Box3().setFromObject(object)

	let boxVector1 = new Vector3(box.min.x, box.max.y, box.min.z);
	let boxVector2 = new Vector3(box.min.x, box.max.y, box.max.z);
	let boxVector3 = new Vector3(box.max.x, box.max.y, box.min.z);
	let boxVector4 = new Vector3(box.max.x, box.max.y, box.max.z);
	
	boxVector1.project(camera);
	boxVector2.project(camera);
	boxVector3.project(camera);
	boxVector4.project(camera);

	// box.getCenter(v1)
	// v1.x = box.min.x
	v1.project(camera);
	v1.x = Math.min(boxVector1.x, boxVector2.x, boxVector3.x, boxVector4.x);

	v1.x = ( v1.x + 1) * size.width / 2;
	v1.y = - ( v1.y - 1) * size.height / 2;
	v1.z = 0;
	return v1
}

export const TransformManager = props => {
	const managerRef = useRef();

	const [prevSliderVal, setPrevSliderVal] = useState(0);
	const [sliderChanging, setSliderChanging] = useState(false);

	const selectedObjectIsChanging = useMattoState((state) => state.selectedObjectIsChanging);
	const transformMode = useMattoState((state) => state.transformMode);
	const setTransformMode = useMattoState((state) => state.setTransformMode);
	const updateAllObjectStates = usePhysicalObjects((state) => state.updateAllObjectStates)
	const selectedPhysicalObject = usePhysicalObjects((state) => state.selectedPhysicalObject);	
	const [box3SelectedObject,setBox3SelectedObject] = useState(null)
	const [showSwapUp, setShowSwapUp]=useState(false)
    const setInspectorMode = useMattoState((state) => state.setInspectorMode);
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
	const lockedObjects = usePhysicalObjects( (state) => state.lockedObjects)
	const isObjectLocked = usePhysicalObjects( (state) => state.isObjectLocked)

	const calcObjectManagePosition = () => {
		if (!managerRef?.current || !box3SelectedObject ) return;
		//managerRef.current.style.visibility = selectedObjectIsChanging==true ? 'hidden' : 'visible'
		const canvasRect = window.gl.domElement.getBoundingClientRect();
		const managerRect = managerRef.current.getBoundingClientRect()
		const selectedObject = usePhysicalObjects.getState().getCurrentThreeJSObject()
		if (!selectedObject) return;
		const pos = calcSidePosition(selectedObject)
		pos.x = pos.x + canvasRect.x - (managerRect.width - 130)
		if ( pos.x  < canvasRect.x) pos.x = canvasRect.x;
		if ( pos.x  > canvasRect.right) pos.x = (canvasRect.right - canvasRect.y);
		if (pos.y < canvasRect.y) pos.y = canvasRect.y;
		if (pos.y >  ( canvasRect.height - canvasRect.y)) pos.y = (canvasRect.height - canvasRect.y)
		managerRef.current.style.transform = `translate3d(${pos.x}px,${pos.y}px,0)`
	}
	// const swapUp = () => {
	// 	const selectedObject = usePhysicalObjects.getState().getCurrentThreeJSObject() 
	// 	const meshes = window.scene.children.filter(s => s.userData.physicalMesh==true)
	// 	const results = aabb.swapUp(selectedObject,meshes)		
	// 	if (results && results.length > 0) {
	// 		updateAllObjectStates()
	// 		incrementUserHasFinished()
	// 		invalidate()
	// 	}
	// }
	
	useEffect(() => { setTransformMode(null); }, [selectedPhysicalObject?.key])
	useEffect(() => { 		
		const selectedObject = usePhysicalObjects.getState().getCurrentThreeJSObject()
		if (selectedObject==null) {
			managerRef.current.style.visibility = 'hidden';
			setBox3SelectedObject(null)
			return;
		}
		managerRef.current.style.visibility = selectedObjectIsChanging==true ? 'hidden' : 'visible'
		if(showSwapUp) setShowSwapUp(false)
		//setShowSwapUp(aabb.checkSwipeUp(selectedObject, window.scene?.children?.filter(s => s.userData.physicalMesh==true)))
		setBox3SelectedObject(new Box3().setFromObject(selectedObject));

	},[selectedPhysicalObject,selectedObjectIsChanging])

	useEffect(() => {
		if (box3SelectedObject==null) return;
		if (!sliderChanging) calcObjectManagePosition(managerRef.current, box3SelectedObject);
	}, [box3SelectedObject]);

	useEffect(()=> {
	},[lockedObjects])
	  
	return (
		<div ref={managerRef} className="wrap-object-manage" style={{visibility: 'hidden'}}>
			<div className={`Panel toolbar-transform ${transformMode==null ? 'translate' : transformMode}`}>

				<Button
					variant="contained"
					size="medium"
					style={selectedPhysicalObject && selectedPhysicalObject.type === "dynamic" ? buttonSwapUpCss2 : buttonSwapUpCss}
					onClick={() => usePhysicalObjects.getState().deleteSelectedObject()}
					startIcon={<DeleteOutlineOutlinedIcon/>}
				/>
				<Button
					variant="contained"
					size="medium"
					style={selectedPhysicalObject && selectedPhysicalObject.type === "dynamic" ? buttonLockCss2 : buttonLockCss}
					onClick={() => {isObjectLocked(selectedPhysicalObjectKey) ? usePhysicalObjects.getState().unLockSelectedObject() : usePhysicalObjects.getState().lockSelectedObject()}}
					startIcon={isObjectLocked(selectedPhysicalObjectKey) ? <LockOutlinedIcon/> : <LockOpenOutlinedIcon/>}
				/>
				<button onClick={() => setTransformMode(null)} className="transform-button translate-btn" data-type-transform="translate">
					<img title="Translate" src="https://threejs.org/editor/images/translate.svg" alt="Trans" />
				</button>
				<button onClick={() => {setTransformMode('rotate');setInspectorMode(InspectorMode.Properties)}} className="transform-button rotate-btn" data-type-transform="rotate">
					<img title="Rotate" src="https://threejs.org/editor/images/rotate.svg" alt="Rotat" />
				</button>
				<button onClick={() =>{ setTransformMode('scale');setInspectorMode(InspectorMode.Properties)}} className="transform-button scale-btn" data-type-transform="scale">
					<img title="Scale" src="https://threejs.org/editor/images/scale.svg" alt="Scale" />
				</button>
				{selectedPhysicalObject && selectedPhysicalObject.type === "dynamic" && selectedPhysicalObject.mode !='material' &&
				<button onClick={() => setTransformMode('texture')} className="transform-button texture-btn">
					<img title="Texture" src="../../images/grab-icon.svg" alt="Texture" />
				</button>
				}
			</div>
			{
				showSwapUp &&
				<Button
					variant="contained"
					size="medium"
					style={selectedPhysicalObject && selectedPhysicalObject.type === "dynamic" ? buttonSwapUpCss2 : buttonSwapUpCss}
					startIcon={<LoopIcon/>}
					onClick={() => {swapUp();}}
				/>
			}

			{
				(transformMode == null) &&
				<ElevationSlider />			
			}
			{   (transformMode == null) && 
				<NoMaxWidthTooltip title="Hold 'Shift' key when moving an item to keep it at same height" placement="left-start">
					<Button
					variant="contained"
					size="medium"
					style={selectedPhysicalObject && selectedPhysicalObject.type === "dynamic" ? buttonInfoCss2 : buttonInfoCss}
					startIcon={<InfoOutlinedIcon/>}/>
				</NoMaxWidthTooltip>
		    }
		</div>
	)
}


export const ElevationSlider = () => {
	const updateObjectState = usePhysicalObjects((state) => state.updateObjectState);
	const selectedPhysicalObjectKey = usePhysicalObjects((state) => state.selectedPhysicalObjectKey);	
	const getSelectedPhysicalObject = usePhysicalObjects((state) => state.getSelectedPhysicalObject);	
	const selectedPhysicalObject = getSelectedPhysicalObject(selectedPhysicalObjectKey)


	const selectedObject = useMemo(() => {
		return usePhysicalObjects.getState().getCurrentThreeJSObject()
	},[selectedPhysicalObject])
	
	const floor = useMemo(() => { 
		if (selectedObject==null) return 0
		// console.log("Floor ", selectedObject, aabb.floorY(selectedObject));
		// let  f = aabb.floorY(selectedObject)
		// if (isNaN(f)) f = 0
		// return f;
		return collisions.floorY(selectedObject)
	},[selectedPhysicalObject])

	const changeFunction = (newValue) => {
		if (selectedObject?.position?.y) {
			selectedObject.position.y=newValue
			invalidate()
		}
	}
	const changeFinished = () => {
		updateObjectState(selectedPhysicalObject)
		invalidate()
	}
	if (selectedPhysicalObject==null) return (null);	
	return (
		<PosYSlider changeFinished={changeFinished} step={0.1} changeFunction={changeFunction}
		min={floor}  
		value={selectedObject?.position?.y || 0}
		max={10}/>
	)
}

export const PosYSlider = (props) => {
	const [v,setV] = useState(0);
	useEffect(() => { setV(props.value) }, [props.value])

	const handleChange = (event,newValue) => {
		setV(newValue);
		if (props.changeFunction) props.changeFunction(newValue);
	}
	return (
		<>
		<CustomizedSlider
			className={'no-pseudo'}
			orientation="vertical"
			aria-labelledby="vertical-slider"
			defaultValue={0}
			size='small'
			step={props.step} max={props.max} min={props.min}
			value={v|| 0}
			onChange={handleChange}
			onChangeCommitted={() => {
				props.changeFinished();
			}}
		/>
		</>
	)
};

const CustomizedSlider = styled(Slider)`
	left:35px;
	top:0px;
	color #52af77;
	height:8;
	position:absolute;
	width:220;
	pointer-events:auto;

  & .MuiSlider-thumb {
    height:24px;
	width:24px;
	background-color: #fff;
	border: 2px solid lightgray;
	marginBottom: -10px !important;
	marginLeft: -8px !important;
	&:focus,; &:hover,; &$active: boxShadow	
  }
  & .MuiSlider-valueLabel {
	left: calc(-50% + 4px);
  }
  & .MuiSlider-track {
	height: 8px;
	borderRadius: 4px;
	background: transparent;
  }
  & .MuiSlider-rail {
	width: 7px !important;
	height: 8;
	borderRadius: 4px;
	background: #24a256;
	opacity: 1;
  }
`;
