import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, redirect } from "react-router-dom";
import {Box,Divider,IconButton,List,ListItem,ListItemButton,ListItemText,Container, Menu} from "@mui/material";
import { Menu as MenuIcon, Person as PersonIcon,  } from "@mui/icons-material";
import AuthButton from "./auth/AuthButton";
import { HeaderContainer,StyledAppBar,LogoContainer,StyledToolBar,MenuButton,
  NavContainer,StyledDrawer,StyledLogo,NavLink,BoardButton,PersonButton, StyledMenuItem
} from "./styles";
import { AuthContext } from "../auth/Auth.jsx";
import { useLoginState } from "./auth/LoginState";
import React from "react";
import { useMattoState } from "../MattoState";
import { userCollectionsDB } from "../libs/firebase";
import { query, where,getDocs,orderBy } from "firebase/firestore";
import UserSubscriptionModal from "../stripe/UserSubscriptionModal.js";

export const MemoizedAuthButton = React.memo(AuthButton);


const navItems = [
    { label: "Brands", link: "/brands",},
    { label: "Boards", link: "/boards",},
    { label: "Trends", link: "https://blog.mattoboard.com/",},
    { label: "For Suppliers",link: "https://blog.mattoboard.com/brands",}
    // { label: "My Collections",link: "/my-collections", },
];


export default function Header(props) {
  const { wind, customClass } = props;
  const location = useLocation();

  if (location.pathname === "/for_suppliers") {
    return null;
  }
  const [mobileOpen, setMobileOpen] = useState(false);

  const navigate = useNavigate();
  const { currentUser }  = useContext(AuthContext);
  const setShowLogin = useLoginState((state) => state.setShowLogin);
	const setUserCollectionData = useMattoState((state) => state.setUserCollectionData);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const mobileHamburgerOpen = Boolean(anchorEl);
  const handleDrawerToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  }

  useEffect(() => {
		if (currentUser) {
			const q = query(userCollectionsDB as any, where("uid", "==", currentUser.uid),orderBy("updatedAt","desc"))
			getDocs(q).then (querySnapshot => {
                        if(querySnapshot.size > 0) {	
                          const collectionsTemp:any = []
                          querySnapshot.forEach((doc:any) => {collectionsTemp.push(doc.data()) })
                          setUserCollectionData(collectionsTemp)
                        }
                      else { setUserCollectionData({}) }
			})
		.catch((error) => { console.log("Error getting user collection data: ", error); });
		}
		else { 
			setUserCollectionData({})
		}
	},[currentUser])

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img width={100} src={'/images/mattoboard-logo.jpg'} alt="MattoBoard" />
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const handleStartDesigning = () => {
    const redirectTo = sessionStorage?.getItem("redirectTo");
    if (currentUser && redirectTo) { sessionStorage?.clear(); window.open(redirectTo, '_blank');  } 
    else if (currentUser) { window.location.href='/project/new'; }
    else { setShowLogin("signup"); }
  }
  const handleClick = (href) => {    
    if (href.startsWith('http')) { window.location.href = href; return;}
    else navigate(href); 
  }

  const container = wind !== undefined ? () => wind().document.body : undefined;

   const ConditionalLink = ({item, index}) => {
      const userNotLoggedIn = item.link == '/my-collections' && !currentUser
      return !userNotLoggedIn  
      ? 
      <NavLink  onClick = { () => handleClick(item.link) }  key={index}>{item.label}</NavLink>
      : 
      <NavLink key={index} onClick={() => { setShowLogin("signup") }}>{item.label}</NavLink>
    }
  return (
    <HeaderContainer className={customClass}>
      <StyledAppBar>
        <Container style={{maxWidth: "1488px"}}>
          <StyledToolBar>
            <LogoContainer>
              <StyledLogo  width={195} src={'/images/mattoboard-logo.jpg'} onClick= { () => handleClick('/') }  alt="MattoBoard" />
            </LogoContainer>
            <NavContainer>
              {navItems.map((item, index) => (
                <ConditionalLink key={index} item={item} index={index}/>
              ))}
              <PersonButton>
                <MemoizedAuthButton />
              </PersonButton>
              <BoardButton onClick={ () => handleStartDesigning() } variant="outlined">
                <span style={{fontWeight:'bold'}}>Start Designing</span>
              </BoardButton>
              <MenuButton
                id="basic-button"
                aria-controls={mobileHamburgerOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={mobileHamburgerOpen ? 'true' : undefined}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </MenuButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={mobileHamburgerOpen}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
              }}>
                {navItems.slice(0, 4).map((item, index) => (
                   <StyledMenuItem  onClick = { () => handleClick(item.link) }  key={index}>{item.label}</StyledMenuItem>
                ))}
              </Menu>         
            </NavContainer>
          </StyledToolBar>
        </Container>
      </StyledAppBar>
      <Box component="nav">
        <StyledDrawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </StyledDrawer>
      </Box>
      <UserSubscriptionModal/>
    </HeaderContainer>
  );
}