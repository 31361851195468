import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { ImageItemCover } from '../libs/ImageItemCover';
import { ProductType } from 'ts-interfaces';
import { filterProductToDraggablePaint } from '../libs/product_helpers';
import structuredClone from '@ungap/structured-clone';
import { getDocByID } from '../libs/firebase';
import { usePhysicalObjects, useProjectData } from '../MattoState';
import { handleMeshChange } from '../libs/util';


export function CollectionUserMaterials(props) {
	let currentUserUid = props?.currentUserUid
    const getCurrentSelectedPhysicalObject = usePhysicalObjects( (state) => state.getCurrentSelectedPhysicalObject)
	const setProjectData = useProjectData(state=>state.set)	    
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)
    const getCurrentThreeJSObject = usePhysicalObjects( (state) => state.getCurrentThreeJSObject)
    const [preloadedProducts, setPreloadedProducts] = useState({});

    useEffect(() => {
        async function preloadProducts() {
            const products = {};
            for (const item of props.userMaterials) {
                const product = await fetchProductById(item.id);
                products[item.id] = product;
            }
            setPreloadedProducts(products);
        }
        preloadProducts();
    }, [props.userMaterials]);


	const onClick = async (e,item) => { 
        const productId = item.id;
        const selectedPhysicalObject = getCurrentSelectedPhysicalObject()
        const product = await fetchProductById(productId);
        const idx = selectedPhysicalObject?.type=='paint' ? usePhysicalObjects.getState().selectedMaterialndex : 0 
	    handleMeshChange(product,getCurrentThreeJSObject,getCurrentSelectedPhysicalObject,setProjectData,updatePhysicalObject,idx)
	}

    const onDrag = (e,item) => { 
        const productId = item.id;
        const product = preloadedProducts[productId];
        if (product.productType==ProductType.PAINT) e.dataTransfer.setData("paint", JSON.stringify(filterProductToDraggablePaint(product)))
        else e.dataTransfer.setData("material", JSON.stringify(structuredClone(product)));
	}

    async function fetchProductById(productId) {
        const product = await getDocByID('userProducts', productId);
        return product.data();
    }

    return (
        <>
        {props.userMaterials.map((item,index) => (
            <Grid key={index}  item xs={6} >               
                <ImageItemCover 
                    id={item.id} 
                    name={item.name} 
                    src={item.src}
                    draggable={true} 
                    onClick={(e) => onClick(e,item)}
                    onDragStart={(e) => onDrag(e,item)}
                    currentUserUid={currentUserUid}
                    type='collectionUserMaterials'
                />	
            </Grid>
        ))}
        </>
    );
}