import React, { useState, useContext,useRef, useEffect } from 'react'
import { Fab } from "@mui/material"
import { setProfileFirstAction } from '../libs/util'

export const FabHelp = (props) => {
    const [show, setShow] = useState(false)
    const fabKey = props.fabkey || 'helper_fab'

    useEffect( () => {
        go()
        async function go() {
            try {
                if (props.firstAction) {
                    const result = await setProfileFirstAction(props.firstAction)
                    if (result!=true) { return; }
                }
                if (  (!localStorage) || localStorage.getItem(fabKey)) { 
                    //already shown return; 
                    return;
                }
                else {
                    localStorage.setItem(fabKey,'true')
                    const test = localStorage.getItem(fabKey)
                    if (test!='true')  {
                        //can't set localstorage, return
                        return;
                    }
                    setShow(true) 
                    if (window.gtag)  window.gtag('event','helper_shown_' + fabKey)
                    setTimeout(() => { setShow(false) }, 5000);   
                }
            }
            catch (error) {
                console.log("oops!!",error);
            }
        }
    }, [])

    const handleFabClick = () => {
        try {
            localStorage.setItem(fabKey,'false')
            if (window.gtag)  window.gtag('event','helper_clicked_' + fabKey) 
        }
        finally {
            setShow(false)
            if (props.url) window.open(props.url, '_blank')
        }
    }
    if (show==false) return (null)
    return (
        <Fab onClick={handleFabClick} {...props}>
            {props.children }
        </Fab>
    )
}

