import React, { useRef,useEffect,useState } from 'react'
import { useFormik } from "formik";
import { ProductInterface, MaterialData, TopSuppliers, validateProduct } from 'ts-interfaces';
import { create } from 'zustand';
import { Avatar, Button, Chip, DialogTitle, Grid, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { computeMaps, convertImageToBlob, deepClone, materialDefaults2, ComputeMapsInput } from '../libs/util';
import { cloudflareCDN, generateID } from 'ui';
import AuthModal  from '../studioxr/auth/AuthModal';
import { uploadAndSave, uploadFileToGCloud } from '../libs/firebase';
import { produce } from 'immer';
import { MaterialCategory } from '../../../../packages/ts-interfaces';
import * as Sentry from "@sentry/react";
import Autocomplete from '@mui/material/Autocomplete';
import { ActionButton } from '../buttons/ButtonStyles';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';


export const materialCreator = create( ( set:any,get:any) => ({
    modalState:null,
    saving:false,
    setModalState: (modalState)=> set({modalState}),
    setSaving: (saving)=> set({saving}),
}))
const textFieldProps:any = { variant: "outlined", margin: "normal", size: 'small', fullWidth: true, type: "text", sx: { paddingRight: '10px', width:'98%', marginTop: '8px' }}

const materialCategorySubset = [MaterialCategory.CARPET, MaterialCategory.CONCRETE,  MaterialCategory.FABRIC, 
    MaterialCategory.FLOORING, MaterialCategory.MARBLE, MaterialCategory.LEATHER, MaterialCategory.TERRAZZO, MaterialCategory.TILE, MaterialCategory.STONE, 
    MaterialCategory.WOOD, MaterialCategory.ORGANIC, MaterialCategory.METAL,MaterialCategory.OTHER,  MaterialCategory.WALLPAPER, MaterialCategory.WALLPAPER_MATTE].sort()

export const MaterialCreatorMetadataModal = ({incomingProduct, onSavedProduct}) => {
    const modalState = materialCreator(state=>state.modalState)
    const setModalState = materialCreator(state=>state.setModalState)
    const saving = materialCreator(state=>state.saving)
    const setSaving = materialCreator(state=>state.setSaving)
    const [errorMessage,setErrorMessage] = useState(false)
    const [flash,setFlash] = useState<any>(null)
    const [product,setProduct] = useState<ProductInterface>()
    const [isHovered, setIsHovered] = useState(false);
    const  showModal = modalState=='metadata'
    const formik = useFormik({
        initialValues: {name:  'Material', url: '', brand: '', category: MaterialCategory.TILE},
        onSubmit: values => {  },
    });

    const handleBrandChange = (_, value) => {
        formik.setFieldValue('brand', value);
      };

    useEffect( () => {
        if (saving==true || (!incomingProduct) || incomingProduct?.uid==null) return;             
        else { 
            formik.initialValues.name = incomingProduct.name || 'Material'
            if (incomingProduct.category?.[0]) formik.initialValues.category = incomingProduct.category?.[0]
            formik.initialValues.brand = incomingProduct.metadata?.brand || ''
            formik.initialValues.url = incomingProduct.metadata?.url || ''            
            setProduct(incomingProduct) 
        }
    },[incomingProduct])

    const validateForm = () => {
        if (formik.values.brand?.length < 1) {
            setFlash("Please enter a brand name or put 'None' ")
            return false
        } 
        else return true 
    }

    async function handleSubmit() {
        setSaving(true)
        try {
            const isNewProduct = product?.materialData?.files?.color_original==null ||  product.materialData.files.color_original instanceof HTMLCanvasElement            
            const newProduct = isNewProduct==false ? product as ProductInterface : await saveMaterialToUserProduct(product) as ProductInterface
                
            const newProduct2:ProductInterface = produce(newProduct, draft => {
                draft.name = formik.values.name;                                
                if (draft.materialData) draft.materialData.materialProps = deepClone(materialDefaults2)
                if (!draft.metadata) draft.metadata={};
                draft.metadata.brand = formik.values.brand;
                draft.metadata.url = formik.values.url;
                draft.category = [formik.values.category];
            })
            validateProduct(newProduct2)
            setProduct(newProduct2)
            const result = await uploadAndSave(newProduct.id, 'userProducts', newProduct2.uid, newProduct2)
            if (window.gtag && isNewProduct==true) window.gtag("event", "custom_material_created");
            console.log("saved! ", result);
            onSavedProduct(newProduct2)
        }
        catch (error) {
            console.log("oops!!",error);
            Sentry.captureException(error);
            setErrorMessage(true)
        }
        finally {
            setSaving(false)
            setModalState(null)
        }
    }
    const handleClose = () => { setModalState(null) }
    const getImage = () => {                
        const i = product?.materialData?.files?.color_original
        return (i instanceof HTMLCanvasElement) ? i.toDataURL() : cloudflareCDN(i,'height=120,format=auto')
    }

    const makeCategoryPretty = (category) => {
        if (category==MaterialCategory.WALLPAPER_MATTE) return 'Wallpaper Matte'
        else return category.charAt(0).toUpperCase() + category.slice(1)
    }

    if (!showModal) return (null)
    else {
        return (
        <>
        <AuthModal open={showModal} onClose={handleClose}>
            <Stack sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
                    <DialogTitle sx={{ paddingLeft: '0px', fontWeight: 'bold' }}>Material Details</DialogTitle>
                    <a 
                        href="https://www.youtube.com/watch?v=DCQKcJE6WdQ" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ textDecoration:'none' }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <Grid sx={{ 
                            display: 'flex', flexDirection: 'row', 
                            background: (theme) => isHovered ? theme.palette.primary.shades[200] : theme.palette.primary.shades[100],
                            color: 'white', padding: '2px 8px', borderRadius: '4px'
                        }}>
                            <PlayCircleIcon fontSize="small" />
                            <Typography sx={{ fontSize: '14px', paddingLeft: '4px' }}>Tutorial</Typography>
                        </Grid>
                    </a>
                </Grid>
                {flash && <Typography sx={{color:'red'}}>{flash}</Typography> }

                

                <Stack direction="column" spacing={1} style={{ paddingTop:'8px' }} alignItems="center" justifyContent="center">
                    <img width={130} src={getImage()} style={{ borderRadius:'10px', marginBottom: '20px' }} />
                </Stack>

                <TextField  {...textFieldProps} id="name"  label="Material Name" name="name" value={formik.values.name} onChange={formik.handleChange} />

                <Autocomplete
                    id="brand"
                    freeSolo
                    value={formik.values.brand}
                    onChange={handleBrandChange}
                    options={Object.values(TopSuppliers)}
                    renderInput={(params) => <TextField {...params} {...textFieldProps} label="Brand Name" name="brand" value={formik.values.brand} onChange={formik.handleChange} placeholder="Brand Name (Optional)" />}
                />

                <Select  sx={{ paddingRight: '10px',width:'98%', marginTop: '8px', marginBottom: '8px' }} size='small' id="category"  label="Category" name="category" value={formik.values.category} onChange={formik.handleChange} >
                { materialCategorySubset.map((value) => (
                    <MenuItem key={value} value={value}>{makeCategoryPretty(value)} </MenuItem>
                ))}
                </Select>
                <TextField  {...textFieldProps} id="url"  label="URL" name="url" value={formik.values.url} onChange={formik.handleChange} placeholder="URL (Optional)" />

                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', padding:'10px', marginTop: '20px', marginBottom: "20px" }}>
                    <ActionButton disabled={saving} style={{ width: '150px' }} variant="contained" onClick={handleSubmit}>
                    {saving ? "Saving..." : "Save Material"}
                    </ActionButton>
                    <Button 
                        onClick={handleClose} 
                        variant="outlined"
                        sx={{
                            width: '100px',
                            height: '30px',
                            background: 'white',
                            borderColor: (theme) => theme.palette.primary.shades[250],
                            '&:hover': { background: (theme) => theme.palette.primary.shades[100], borderColor: (theme) => theme.palette.primary.shades[250], color: (theme) => theme.palette.primary.light }
                        }}
                    >
                        Close
                    </Button>
                </Grid>

            </Stack>
        </AuthModal>
        <Snackbar open={saving} message="Uploading and processing material, this can take a minute." />
        <Snackbar autoHideDuration={7000} onClose={() => setErrorMessage(false) }  open={errorMessage} message="Sorry we are having trouble uploading this.  Please let us know at support. " />

        </>
        )        
    }
}
async function saveMaterialToUserProduct(product) {
    return new Promise( async (resolve,reject) => {
        const product1:any = await uploadColorOriginal(product)
        const product2 = await generateMaps(product1)    
        resolve(product2)
    })
}
async function uploadColorOriginal(product:ProductInterface) {
    const i = product?.materialData?.files?.color_original
    return new Promise( async (resolve,reject) => {
        if (i==null || i==undefined || product.uid==null) reject('No user id or image');
        if ( i instanceof HTMLCanvasElement) {
            const blob = await convertImageToBlob(i,'test.jpg')
            const savedImage = await uploadFileToGCloud('userImages/' + product.uid, product.id,blob)
            const newProduct = produce(product, draft => { 
                if (draft.materialData?.files) draft.materialData.files.color_original = savedImage; 
                if (draft.materialData) draft.materialData.aspectRatio = i.width / i.height;
            })
            resolve(newProduct)
        }    
        else { resolve(product)}
    })
}
async function generateMaps(product:ProductInterface) {
    return new Promise( async (resolve,reject) => {
        if (!product.materialData?.files?.normal_original && product.uid) {
            const maps:ComputeMapsInput = await computeMaps(product.materialData?.files?.color_original, product.id, product.uid)
            const newProduct = produce(product, draft => {
                if (draft.materialData?.files) {
                    draft.materialData.files.normal_original = maps.normalMap; 
                    draft.materialData.files.albedo_original = maps.albedoMap
                }
            })
            resolve(newProduct)
        }
        else { resolve(product);  }        
    })
}


