import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPrimaryBtn = styled(Button)(({ theme }) => ({
  fontFamily: "Roundo-Regular",
  fontSize: "15px",
  color: "white",
  //background: theme.palette.customColor.purple,
  borderRadius: "84px",
  paddingLeft: "22px",
  paddingRight: "22px",
  textTransform: "uppercase",
  "> span": {
    marginTop: "4px",
  },
  "&:hover": {
    //background: theme.palette.customColor.purpleHover,
  },
}));

const StyledOutlinedBtn = styled(Button)(({ theme }) => ({
  fontFamily: "Roundo-Regular",
  fontSize: "15px",
//  color: theme.palette.customColor.main,
  textTransform: "uppercase",
  border: "1px solid #9D989B",
  borderRadius: "74px",
  paddingLeft: "22px",
  paddingRight: "22px",
  "span": {
    marginTop: "4px",
  },
}));

export const PrimaryButton = ({children, ...rest}) => (
  <StyledPrimaryBtn {...rest}>
    <span>{children}</span>
  </StyledPrimaryBtn>
)

export const OutlineButton = ({children, ...rest}) => (
  <StyledOutlinedBtn {...rest}>
    <span>{children}</span>
  </StyledOutlinedBtn>
)

export const OutlineButtonWithIcon = styled(Button)(({ theme }) => ({ 
  marginRight:'7px',
  fontFamily: "Roundo-Regular",
  fontSize: "15px",
  textTransform: "uppercase",
  border: "1px solid #9D989B",
  borderRadius: "74px",
  paddingLeft: "22px",
  paddingRight: "22px",
  paddingTop: "10px",
  "span": {
    marginTop: "-4px",
  },
}));

