import React from "react"
import { Button, Grid } from "@mui/material";
import { styled } from '@mui/system';


const CreateABoardButton = styled(Button)({backgroundColor:'orangered', color:'white', marginRight:'20px', borderRadius:'500px', height:'34px', 
    padding:'6px 16px', fontSize: '12px', boxShadow: '1px 4px 12px #cc0000', fontWeight:'bold',
    '&:hover':{'backgroundColor':'red'},'&:active':{'backgroundColor':'dimgray'}});


export const CreateBoardButton = () => {

    const cannotEdit = window.location.href.includes('ee=true');

    const onClick = () => {
        if (window.gtag) { gtag('event', 'edit_board')}
        const x = window.location.href.replace('show','project');
        window.location.replace(x)
    }
    if (cannotEdit) { return (null) }
	return (
        <Grid sx={{display: {xs:'none',sm: 'none', md:'block'}}} > 
            <CreateABoardButton onClick={onClick}>Edit This Board</CreateABoardButton>
        </Grid>	
	)
}

