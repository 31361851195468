import React, { useState } from 'react'
import { usePhysicalObjects } from '../MattoState'
import useEventListener from '../libs/useEventListener'
import { produce } from 'immer'
import { positionIn3D } from '../libs/util'
import { Snackbar} from '@mui/material';

export const CopyPasteObject = (props) => {
	const selectedPhysicalObject = usePhysicalObjects(state=>state.selectedPhysicalObject)
	const addPhysicalObject = usePhysicalObjects((state) => state.addPhysicalObject)
	const [copiedPhysicalObject, setCopiedPhysicalObject] =useState(null)
	const [openSnack, setOpenSnack]=useState(false)
	const [message,setMessage]=useState("")

	const handleKeyDown = (e) =>  {
		// if (e.target!=document.body) return;
	   if(e.target.type == 'email' || e.target.type == 'password' || e.target.type == 'text' ||  window.getSelection().toString().length > 0) return;
	   else if (e.key=='c' && (e.metaKey==true || e.ctrlKey==true)){
			copySelectedObject(selectedPhysicalObject);e.preventDefault()
	    }
	   else if (e.key=='v' && (e.metaKey==true || e.ctrlKey==true)){
			pasteSelectedObject(e);e.preventDefault()
	    }
	}

	const copySelectedObject = (selectedPhysicalObject) => {
		if(!selectedPhysicalObject) return;
	    setCopiedPhysicalObject(selectedPhysicalObject)
		setOpenSnack(true) 
		setMessage('Object Copied')
    }
	const pasteSelectedObject = (event) => {
		if(!copiedPhysicalObject) return;

		// const intersects = positionIn3D(gl,raycaster,camera,event)
		let scale  = copiedPhysicalObject.scale ?? [1,1,1]
		if (Array.isArray(scale)===false ) scale = [scale,scale,scale]

		const copiedTempPhysicalObject = produce(copiedPhysicalObject, draft=> {
			draft.key = Math.random()
			draft.scale = scale.map(x => x * 1)	
			// draft.position = [intersects.x,intersects.y,intersects.z]	
			draft.rotation =  copiedPhysicalObject.rotation  ?? [0,0,0]	
		})
		addPhysicalObject(copiedTempPhysicalObject,true)
		setOpenSnack(true) 
		setMessage('Object Pasted')
	}
	useEventListener('keydown', handleKeyDown);
	return (
		<>
		<Snackbar
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
			open={openSnack}
			onClose={ () => { setOpenSnack(false)}}
			autoHideDuration={4000}
			message={message}
		/>
    	</>
	)
}
