import React, { useEffect,useRef } from 'react'
import { Grid} from '@mui/material'
import { ImageItemCover } from '../libs/ImageItemCover';
import { CollectionItemCover } from '../libs/CollectionItemCover.jsx';
import { useInView } from 'react-intersection-observer';

export function ScrollableGrid(props) {
	const rootUploadContainer = useRef()
	const { ref, inView, entry } = useInView({root:rootUploadContainer.current,threshold: 0,});

	useEffect(() => { if (inView==true)props.loadItems() },[inView])

	return (
			<Grid ref={rootUploadContainer} container item xs={12} id={props.id ? props.id :"scrollableDiv"} justifyContent="space-evenly" sx={{paddingRight:{md:'10px',xl:'0px'}}} 
			style={props.style}>
				{(props.type == 'collections' || props.type == 'curatedImages') && props.items.map( (item ,index)  => (
					<Grid key={index}  item xs={6} >               
						<CollectionItemCover 
							id={item.id} 
							name={item.name} 
							src1={item?.thumbnails?.[0] ? item.thumbnails[0].src : "/images/CollectionDefaultThumbnail1.png"}
							src2={item?.thumbnails?.[1] ? item.thumbnails[1].src : "/images/CollectionDefaultThumbnail2.png"}
							src3={item?.thumbnails?.[2] ? item.thumbnails[2].src : "/images/CollectionDefaultThumbnail3.png"}
							coverImage={item.coverImage}
							onClick={(e) => props?.onClick && props.onClick(item)}
							draggable={props.draggable} 
						    type={props.type}
							getNoOfItems={() => props.getNoOfItems(item)}
							lastUpdatedAt={item.updatedAt}
							editIconOnClick={(e) => props?.editIconOnClick?.(e,item)} 
							deleteIconOnclick={(e) => props?.deleteIconOnclick && props.deleteIconOnclick(e,item)} 
							onMouseOver={(e) =>  props?.onMouseOver && props.onMouseOver(e,item.id)} 
							onMouseOut={(e) => props?.onMouseOut && props.onMouseOut(e,item.id)} 
						/>	
					</Grid>
				))}
				{props.type != 'collections' && props.type != 'curatedImages' && props.items.map((item,index) => (
					<Grid key={index} item xs={props.type === 'templates'?12:6} >               
						<ImageItemCover 
							id={item.id} 
							getName={(e) => props.getName(item)} 
							getSrc={(e) => props.getSrc(item)} 
							onClick={(e) => props?.onClick && props.onClick(item)}
							draggable={props.draggable} 
							onDragStart={(e) => props?.onDragStart && props.onDragStart(e,item)}
							deleteIconOnclick={(e) => props?.deleteIconOnclick && props.deleteIconOnclick(e,item)} 
							onMouseOver={(e) =>  props?.onMouseOver && props.onMouseOver(e,item.id)} 
							onMouseOut={(e) => props?.onMouseOut && props.onMouseOut(e,item.id)} 
							onInfoClick={(e) => props?.onInfoClick && props.onInfoClick(e,item.id)}
							showInfoIcon={props.showInfoIcon || false}
						    type={props.type}
							isPro={item?.isPro || props?.proTemplateIDs?.includes(item.id) ? true : false}
							imageStyle={props?.imageStyle}
							getIsDisabled={(e) => props?.getIsDisabled && props.getIsDisabled(item)}
							currentUserUid={props?.currentUserUid}
							getImageFiles={(e) => props?.getImageFiles && props.getImageFiles(item)}
						/>	
					</Grid>
				))}
				<Grid key={'placeholder'} ref={ref} item xs={6}>
					<img draggable={false} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==" height={1} alt="Red dot" />
				</Grid>
			</Grid>
    );
}