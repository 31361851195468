import React, { useMemo,useRef, useState,useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { PropertyControllerContainer } from './PropertyControllerContainer';
import { DynamicObjectSelector } from './DynamicObjectSelector';
import { MaterialHeader } from './MaterialHeader';
import { useMattoState } from '../MattoState';
import { InspectorMode } from '../ts/app_interfaces';
import { MaterialSearch } from './MaterialSearch';
import { CanvasObjectInspector } from './CanvasObjectInspector';

export const Inspector2 = () => {
    const inspectorMode:any = useMattoState((state) => state.inspectorMode);

    return (        
        <Grid container spacing={1} style={{paddingTop:'4px' }}>   
            <MaterialHeader />
            {inspectorMode==InspectorMode.Properties && <PropertyControllerContainer />}
            {inspectorMode==InspectorMode.Objects &&  <DynamicObjectSelector  /> }
            {inspectorMode==InspectorMode.CanvasObject &&  <CanvasObjectInspector  /> }
            <MaterialSearch hide={inspectorMode!=InspectorMode.Materials} />
        </Grid>
    )
}

