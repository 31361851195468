import React, { useRef,useState,useEffect } from 'react'
import {useMattoState} from '../MattoState'
import {Dialog,DialogContent,DialogTitle,DialogActions,TextField,Button} from '@mui/material'

export const DialogBox = () => {

	const denyUpload = useMattoState((state) => state.denyUpload);
  const [open, setOpen] = useState(false);
  const handleClose = (e,f) => {setOpen(false); useMattoState.setState({ denyUpload:false})};

  useEffect(() => {
    if (denyUpload) {
      setOpen(true)
    }
  }, [denyUpload]);

  return (
  <>
    {denyUpload &&
      <Dialog
        onClose={handleClose}
        disableEscapeKeyDown={true}
        aria-labelledby="simple-dialog-title"
        open={open}>
        <DialogTitle id="simple-dialog-title">You must log in to upload images</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button color="success" onClick={handleClose} variant="contained">Close</Button>
        </DialogActions>
      </Dialog>}   
  </>
  )
}