import { Chip } from '@mui/material';
import React from 'react'
import { useRefinementList } from 'react-instantsearch';


export const MyRefinementChips = ({attribute, limit, showMore,attributeName }:any) => {
  const { items,refine,canRefine} = 
    useRefinementList({ showMoreLimit:100, attribute: attribute,limit: limit,showMore: showMore,sortBy: ['name:asc'] });

  const handleClick = (item) => { if (canRefine) refine(item.value); };
  const getChipStyle = (item) => item.isRefined ? "filled" : "outlined";
  return (
      <>
      {items.length > 0 && items.map( (item,index) => 
        {
        if (item.label.trim().length == 0) return;
        return (           
              <Chip
                key={`${item.label}-${index}`}
                label={`${item.label} (${item.count || 0})`}
                variant={getChipStyle(item)}
                onClick={() => handleClick(item)}
                // onDelete={() => removeFilter(item)}
              />
        )
      }
      )}
      </>
  )
};
const MyEmptyRefinementList = (props) => { return (null); }

