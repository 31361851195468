import { Box, Drawer,MenuList,} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Clear } from "@mui/icons-material";

const drawerWidth = 440;

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: drawerWidth,
    "@media (max-width: 600px)": {
      width: "100vw",
    },
  },
}));

export const FilterWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "10px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "15px",
  },
  fontFamily: "Roundo-Regular",

  ".MuiChip-root": {
    marginRight: "4px",
    marginBottom:"4px",
    ".MuiChip-label": {
      fontFamily: "Roundo-Regular",
      marginTop: "4px",
    },
  },

  ".filter": {
    display: "flex",
    alignItems: "center",
  },

  ".sort-btn": {
    textTransform: "uppercase",
    fontFamily: "Roundo-Regular",
    fontSize: "15px",
    letterSpacing: "0.15px",
    fontWeight: "500",
    ".down-icon": {
    },
    ".sort-btn-label": {
      marginTop: "4px",
    },
  },
}));

export const FilberBtn = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: "15px",
  cursor: "pointer",

  img: {
    marginRight: "8px",
  },

  ".filter-title": {
    fontFamily: "Roundo-Regular",
    fontSize: "20px",
    letterSpacing: "0.15px",
    fontWeight: "500",
    marginTop: "4px",
  },
}));

export const ClearButton = styled("button")(({ theme }) => ({
  border: "none",
  fontFamily: "Roundo-Regular",
  fontSize: "15px",
  letterSpacing: "0.15px",
  fontWeight: "500",
  textDecoration: "underline",
  backgroundColor: "transparent",
  cursor: "pointer",
  marginTop: "4px",
  marginLeft: "20px",
  textTransform: "uppercase",
}));

export const SortMenu = styled(MenuList)(({ theme }) => ({
  minWidth: "196px",

  ".MuiMenuItem-root": {
    fontFamily: "Roundo-Regular",
    fontSize: "16px",
    letterSpacing: "0.15px",
    fontWeight: "500",
  },
}));

export const FilterPanel = styled(Box)(({ theme }) => ({
  padding: "32px",

  ".filter-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "24px",
    marginBottom: "4px",
  },
}));

export const CloseWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

export const CloseIcon = styled(Clear)(({ theme }) => ({
  cursor: "pointer",
  width: "40px",
  height: "40px",
}));

export const FilterOptionsWrapper = styled(Box)(({ theme }) => ({
  marginTop: "24px",

  ".MuiAccordion-root": {
    boxShadow: "none",

    "&.Mui-expanded": {
      "&::before": {
        opacity: 1,
      },
    },

    ".MuiAccordionSummary-content": {
      ".MuiTypography-root": {
        fontFamily: "Roundo-Regular",
        fontSize: "14px",
        letterSpacing: "0.1px",
        fontWeight: "500",
        lineHeight: "48px",
        marginTop: "4px",
      },
    },
  },

  ".form-group": {
    ".MuiFormControlLabel-label": {
      fontFamily: "Roundo-Regular",
      fontSize: "16px",
      letterSpacing: "0.15px",
      fontWeight: "300",
      lineHeight: "150%",
      marginTop: "4px",
    },
  },
}));

export const ResultWrapper = styled(Box)(({ theme }) => ({
  fontFamily: "Roundo-Regular",
  fontSize: "12px",
  fontWeight: "300",
  lineHeight: "15px",
  marginTop: "4px",
  marginBottom: "24px",
  marginLeft:"10px",
  color:"#7a7a7a"
}));

export const DesktopChips = styled(Box)(({ theme }) => ({

}));

export const MobileChips = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: "40px",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
  ".MuiChip-root": {
    marginRight: "4px",
    marginBottom: "4px",
    ".MuiChip-label": {
      fontFamily: "Roundo-Regular",
      marginTop: "4px",
    },
  },
}));

export const SortItem = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const ProductItem = styled("div")(({ theme }) => ({
  width: "100%",
  height: 0,
  paddingBottom: "100%",
  position: "relative",

  ".popover-trigger": {
    position: "absolute",
    top: "20px",
    right: "20px",
  },
}));

export const ProductImg = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,

  ".prod-img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  ".popover": {
    position: "absolute",
    top: "72px",
    right: "20px",
    width: "300px",
    maxHeight: "350px",
    overflowY: "auto",
    background: "white",
    zIndex: 11,
    boxShadow:
      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    padding: "8px",

    ".title": {
      fontFamily: "Roundo-Regular",
      fontSize: "14px",
      fontWeight: "500",
      margin: "8px 16px",
    },

    ".collection-list": {
      margin: "20px 0",
      height: "180px",
      overflowY: "auto",

      ".collection-item": {
        borderBottom: "1px solid rgba(0,0,0,0.1)",
        padding: "6px 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "&:last-child": {
          borderBottom: "none",
        },
        span: {
          fontFamily: "Roundo-Regular",
          fontSize: "16px",
          fontWeight: "500",
        },

        "&.selected": {
          backgroundColor: "#F7F0FA",
        },
      },

      ".no-collections": {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Roundo-Regular",
      },
    },
  },

  ".actions": {
    display: "none",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    padding: "20px",
    boxSizing: "border-box",
    flexDirection: "column",
    justifyContent: "space-between",

    ".top": {
      display: "flex",
      justifyContent: "space-between",

      ".info": {
        width: "42px",
        height: "42px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "white",
        borderRadius: "6px",

        img: {
          width: "20px",
          height: "20px",
        },
      },

      ".collection-btn": {
        height: "42px",
        border: "1px solid #FFFFFF",
        borderRadius: "25px",
        padding: "0 12px",
        display: "flex",
        alignItems: "center",
        ".bookmark": {
          width: "24px",
          height: "24px",
        },

        ".divider": {
          width: "2px",
          height: "24px",
          margin: "0 8px",
          background: "#DBA9ED",
        },

        ".arrow": {
          width: "24px",
          height: "24px",
        },
      },
    },

    ".bottom": {
      padding: "8px",
      background: "white",
      borderRadius: "6px",

      ".product": {
        fontFamily: "Roundo-Regular",
        fontSize: "18px",
        fontWeight: "500",
      },
      ".brand": {
        fontFamily: "Roundo-Regular",
        fontSize: "16px",
        fontWeight: "300",
      },
    },
  },

  ".actions.hovered": {
    cursor: "pointer",
    display: "flex",
  },
}));

export const NewCollectionContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  padding: "20px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",

  ".form-root": {
    maxWidth: "480px",
    margin: "auto",
  },

  ".logo-img": {
    width: "130px",
    marginBottom: "10px",
  },

  ".modal-title": {
    fontSize: "50px",
    fontFamily: "Gambarino",
    lineHeight: "115%",
    marginBottom: "15px",
  },

  ".modal-form": {
    marginTop: "40px",
  },

  ".close-btn": {
    position: "absolute",
    right: "20px",
    top: "20px",
    svg: {
      width: "30px",
      height: "30px",
    },
  },
}));
