import React, { useEffect,useMemo,useRef } from "react";
import { useState } from "react";
import { Button, FormControl, IconButton, Input, InputAdornment, TextField, Typography, useFormControl } from "@mui/material";
import { Stack } from "@mui/system";
import { cloudflareCDN } from "ui";
import { ActionButton } from "../../../buttons/ButtonStyles";

const CollectionBookmarkGrid = ({product,userImage,userMaterial,collection,toggleCollection}) => {
  const [showSave, setShowSave] = useState(false);
  const backgroundColor = showSave ? '#f1f1f1' : 'white';

  const alreadyHasProduct = useMemo(() => {
    return collection.products && product?.id && collection.products.includes(product.id)
      || collection.userImages && userImage?.id && collection.userImages.map((u) => u.id).includes(userImage.id) 
      || collection.userMaterials && userMaterial?.id && collection.userMaterials.map((u) => u.id).includes(userMaterial.id)
  },[product, collection])

  const handleClick = () => { 
    if (toggleCollection) toggleCollection(collection.id,product,userImage,userMaterial) 
  }

  const getCoverImage = () => collection.coverImage ? cloudflareCDN(collection.coverImage, 'height=400,format=auto') 
    : collection.thumbnail?.[0] ||  '/images/CollectionDefaultThumbnailStudioXR1.png'

  return (
        <Stack     
          key={collection.id}   
          sx={{background:backgroundColor, borderRadius:'10px', paddingLeft:'10px', minHeight:'50px', cursor:'pointer'}}
          onMouseEnter={ ()=> setShowSave(true)}
          onMouseLeave={ ()=> setShowSave(false)}
          onClick={handleClick}   direction="row" spacing={2}  justifyContent="space-between"  alignItems="center" >
          
          <Stack direction='row' spacing={1} alignItems="center">
            <img src ={getCoverImage()} 
                  height='40px' width='40px' style={{objectFit:'cover',borderRadius:'10px'}} />
            <Typography id={'bookmarktab_' + collection.id}  style={{fontFamily: "Roundo-Regular", fontWeight:'600', fontSize:'15px' }}>{collection.name}</Typography>
          </Stack>

          {showSave && 
            <ActionButton variant="contained" style={{ width: '80px', marginRight: '6px' }}>
              {alreadyHasProduct ? 'Remove' : 'Save' }
            </ActionButton>
          }
        </Stack>
    )
}
export default CollectionBookmarkGrid;


