import React, { useRef,useState,useEffect,Suspense,useMemo } from 'react'
import {usePhysicalObjects} from '../MattoState'
import useEventListener from '../libs/useEventListener';
import { produce } from 'immer'

export const UVChecker = () => {
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)

    const uvImage='https://firebasestorage.googleapis.com/v0/b/mattoboard-staging.appspot.com/o/uv_check%2FUVChecker.jpg?alt=media'

    const uvCheck = () => {
        if (!selectedPhysicalObjectKey) return;
        const files={ large_color:uvImage, small_color:uvImage, medium_color:uvImage, color_original:uvImage  }
        const physicalObject = getSelectedPhysicalObject(selectedPhysicalObjectKey)
        if (physicalObject?.materialData?.files) {
            const updatedObject = produce(physicalObject, draft=> {
                draft.materialData.files=files
            })
            updatePhysicalObject(updatedObject)
        }
    }

    const handleKeyDown = (e) =>  {  
        if ( (e.key=='9' || e.key=='9' ||  e.code=="Key9" || e.key=='(') && e.shiftKey==true && (e.ctrlKey==true || e.metaKey==true || e.altKey==true)) { 
            uvCheck()
        }
    }
    useEventListener('keydown', handleKeyDown);
    return (null)

}