import React, { useRef, useState,useEffect,useLayoutEffect,Suspense,useMemo } from "react"
import { useThree,useFrame } from '@react-three/fiber'
import { Vector3, Matrix4, Plane} from 'three';
import {useMattoState,usePhysicalObjects, useProjectData} from '../MattoState'
import { useGesture } from "@use-gesture/react"
import {Shape} from './objects/Shape'
import {GLTFObject,AsyncGLTFObject} from './objects/GLTFObject'
import {positionIn3D} from '../libs/util'
import useEventListener from '../libs/useEventListener'
import { collisions } from '../libs/collision-rules'
import { DynamicObject } from "./objects/DynamicObject"
import { PhysicalObjectInterface } from '../ts/app_interfaces';
import { mergedBVH } from "../components/useBVH";
import { IsUrlShow, handleMeshChange } from '../libs/util'
import { PaintGLTFObject } from "./objects/PaintGLTFObject";
import { produce } from 'immer'
import { CanvasGLTFObject } from "./objects/CanvasObject";
import { ProductType } from "ts-interfaces";

export const PhysicalObject = (props) => {
   const physicalObject = props.physicalObject as PhysicalObjectInterface
   const { camera,gl,raycaster,scene,invalidate } = useThree();
   const ref:any = useRef();
   const transformMode = useMattoState((state) => state.transformMode)
   const controls:any = useMattoState((state) => state.controls)
   const setSelectedPhysicalObjectKey = usePhysicalObjects( (state) => state.setSelectedPhysicalObjectKey)
   const isObjectDraggedIn = usePhysicalObjects( (state) => state.isObjectDraggedIn)
   const setSelectedObjectIsChanging = useMattoState((state) => state.setSelectedObjectIsChanging)
   const updateObjectState = usePhysicalObjects((state)=> state.updateObjectState)
   const getCurrentThreeJSObject = usePhysicalObjects( (state) => state.getCurrentThreeJSObject)
   const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)
   const setProjectData = useProjectData(state=>state.set)	    
   const getCurrentSelectedPhysicalObject = usePhysicalObjects( (state) => state.getCurrentSelectedPhysicalObject)
   const copiedMaterialData = useMattoState((state) => state.copiedMaterialData)
   const setCopiedMaterialData = useMattoState((state)=>state.setCopiedMaterialData)
   const isObjectLocked = usePhysicalObjects( (state) => state.isObjectLocked)
   const lockSelectedObject = usePhysicalObjects( (state) => state.lockSelectedObject)

   useLayoutEffect(() => {
		if ( (!ref.current?.userData) || ref.current.userData.key) return;
		ref.current.userData={physicalMesh:true, key:String(physicalObject.key), meshId:physicalObject.meshId, dragOffset: new Vector3(), inverseMatrix: new Matrix4() }
		ref.current.name = physicalObject.name
		invalidate()
   },[])
   //useEffect(() => { console.log("visit here?");updateObjectState(physicalObject) } ,[])

   const calculateDragOffsetAndInvMatrix = (domEvent) => {
   		const object = ref.current;
		const intersects = positionIn3D(gl,raycaster,camera,domEvent);
		const dragOffset = new Vector3();
		const worldPos = new Vector3();
		const inverseMatrix = new Matrix4();
		inverseMatrix.copy( object.parent.matrixWorld );
		inverseMatrix.invert();
		dragOffset.copy( intersects ).sub( worldPos.setFromMatrixPosition( object.matrixWorld ) );
		object.userData.dragOffset.copy( dragOffset );
		object.userData.inverseMatrix.copy ( inverseMatrix);
	}

    const dragStart = (state) => {
		if (IsUrlShow()) return;       
        if (state.altKey==true) return;
		if (isObjectLocked(physicalObject.key)) return;
        if (controls?.current) controls.current.enabled=false;
        if (transformMode == null) {
			toggleShadowMaps(false)
			setSelectedObjectIsChanging(true)
	        setSelectedPhysicalObjectKey(physicalObject.key)
			calculateDragOffsetAndInvMatrix(state.event);
	        document.body.style.cursor='grabbing';
        }
    }
    const dragStop = (state) => {
		if (IsUrlShow()) return; 
		if (isObjectLocked(physicalObject.key)) return;
		if (controls?.current) controls.current.enabled=true;
		setSelectedObjectIsChanging(false)
		document.body.style.cursor='auto';
		toggleShadowMaps(true)
		updateObjectState(physicalObject)
    } 
    const onDrag = (state) => {
		if (IsUrlShow()) return; 
        if (state.altKey==true || transformMode != null) return;
		if (isObjectLocked(physicalObject.key)) return;
		if (state.first===true)  {  const g = gl as any; g.shadowMap.needsUpdate=true; }
		if ( (Math.abs(state.movement[0]) + Math.abs(state.movement[1]))  < 0.5 ) return; 
	    positionEvent(state.event)
	}
	const toggleShadowMaps = (shadowMapOn=true) => {
		if (shadowMapOn==true && physicalObject.type==ProductType.CANVAS) return;
		const g = gl as any  //typescript bug?
		if (shadowMapOn===true) {
			g.shadowMap.autoUpdate=true
			g.shadowMap.needsUpdate = true
		}
		else { g.shadowMap.autoUpdate=false }		
		ref.current.traverse( (child) => { if (child.isMesh==true) {			
			const insideChildMesh = child.name.includes('inside') ? true : false
			child.receiveShadow = insideChildMesh ? false : shadowMapOn; 
			child.castShadow =  insideChildMesh ? false :shadowMapOn
		}})
	}
	const handleMeshLoad = () => {
		ref.current.position.x = physicalObject.position ? physicalObject.position[0] : 0
		ref.current.position.y = physicalObject.position ? physicalObject.position[1] : 0
		ref.current.updateMatrixWorld(true,true)				
		mergedBVH(ref.current)		
		if (isObjectDraggedIn(physicalObject)  || props.droppedIn===true) {
			ref.current.position.y= collisions.updatePhysics(ref.current,collisions.getPhysicalMeshes(),true) || 0.1
		}
		if (props.onMeshLoad) props.onMeshLoad(ref.current)

		const newPhysicalObject:any = produce(physicalObject,draftState => {
			draftState.originalScale = !physicalObject.originalScale ? physicalObject.scale[0] : physicalObject.originalScale
		})
		//invalidate();
		updateObjectState(newPhysicalObject,window.scene,isObjectDraggedIn(physicalObject));
	}
    const handleCopyMaterialData=() => {
		if(!document.getElementById("root")?.classList.contains("dropper-cursor"))
		return;
		else{
		const isMaterialNonEditable = physicalObject.type == 'dynamic' || (physicalObject.isStaticObject)// && physicalObject.type != 'paint')
		if(!isMaterialNonEditable)	
		handleMeshChange(copiedMaterialData,getCurrentThreeJSObject,getCurrentSelectedPhysicalObject,setProjectData,updatePhysicalObject)
			document.getElementById("root")?.classList.remove("dropper-cursor")
			setCopiedMaterialData({})
		}
	}
	
	const positionEvent = (domEvent) => {
		if (domEvent==null) return;
		const intersects = positionIn3D(gl,raycaster,camera,domEvent);
		//Negate the offset to avoid the centering while dragging.
		intersects.sub( ref.current.userData.dragOffset ).applyMatrix4( ref.current.userData.inverseMatrix );

		ref.current.position.x = intersects.x
		ref.current.position.z = intersects.z
		if (domEvent.shiftKey!=true) 
			ref.current.position.y= collisions.updatePhysics(ref.current,collisions.getPhysicalMeshes()) || 0.1					
    	invalidate()
	}
    const toggleHover = (hovered) => { document.body.style.cursor = hovered ? 'grab' : 'auto'  }
    const bind:any = useGesture({onDrag: state => onDrag(state),
	        onDragStart: state => dragStart(state),
	        onDragEnd: state => dragStop(state) }, {})
    const bindings = {
		onPointerOver:(e) => { e.stopPropagation(); toggleHover(transformMode==null) },
		onPointerOut:(e) => { e.stopPropagation(); toggleHover(false) },
		onClick: () => {setSelectedPhysicalObjectKey(physicalObject.key);handleCopyMaterialData() },
		...bind()
    }
    //if (useMattoState.getState().meshBounds==true) bindings.raycast=meshBounds;
	const handleKeyDown = (e) =>  {
	    if (e.target!=document.body) return;
	    const o:any = usePhysicalObjects.getState().selectedPhysicalObject
	    if (o == null || o.key != physicalObject.key) return;
	    if (controls?.current.enableDamping==true) return;
	    if (e.keyCode==38) {
            ref.current.position.z = ref.current.position.z - 0.3;//up
			updateObjectState(physicalObject);e.preventDefault();
	    }
	    else if (e.keyCode==40) {
            ref.current.position.z = ref.current.position.z + 0.3;//down
			updateObjectState(physicalObject);e.preventDefault()
	    }
	    else if (e.keyCode==37) {
            ref.current.position.x = ref.current.position.x - 0.3;//left
			updateObjectState(physicalObject);e.preventDefault()
	    }
	    else if (e.keyCode==39) {
            ref.current.position.x = ref.current.position.x + 0.3; //right
			updateObjectState(physicalObject);e.preventDefault()
	    }
	    else if (e.keyCode==85) {
            ref.current.position.y = ref.current.position.y + 0.3; //up
			updateObjectState(physicalObject);e.preventDefault()
	    }
	    else if (e.keyCode==68) {
	        ref.current.position.y = ref.current.position.y - 0.3; //down
			updateObjectState(physicalObject);e.preventDefault()
	    }
		else if (e.key=='L' && (e.metaKey==true || e.ctrlKey==true)){
			lockSelectedObject();e.preventDefault()
	    }
	}
	useEventListener('keydown', handleKeyDown);
	const renderObject = getRenderObject(physicalObject,handleMeshLoad)

	return (
		<group ref={ref}  {...bindings} name={physicalObject?.name} 
		scale={[physicalObject.scale[0], physicalObject.scale[1] || physicalObject.scale[0], physicalObject.scale[2]] || [1,1,1]} 
		position={physicalObject.position || [0,0,0]}
		rotation={physicalObject.rotation || [0,0,0]} 
		>
		{renderObject}
		</group>
	)
}
const getRenderObject = (physicalObject, handleMeshLoad) => {
	const objType = resolveType(physicalObject)	
	switch (objType) {
		case 'shape':  return <Shape physicalObject={physicalObject} onLoad={handleMeshLoad} />;
		case 'gltf': return <GLTFObject physicalObject={physicalObject}  onLoad={handleMeshLoad} size={gltfSize()} />;
		case 'not_static': return <GLTFObject physicalObject={physicalObject}  onLoad={handleMeshLoad} size={gltfSize()} />;
		case 'dynamic': return <DynamicObject key={physicalObject.key} physicalObject={physicalObject} onLoad={handleMeshLoad} />;
		case 'paint': return <PaintGLTFObject physicalObject={physicalObject}  onLoad={handleMeshLoad} size={gltfSize()} />;
		case 'canvas': return <CanvasGLTFObject key={physicalObject.key} physicalObject={physicalObject}  onLoad={handleMeshLoad} />;
		default: return null;
	}
}
const resolveType = (physicalObject) => {	
	if (physicalObject.category=='Freeform models' && physicalObject.mesh==null) return 'shape'
	const t = physicalObject.type?.toLowerCase()
	if (t) return t;
	else {
		if (physicalObject.files?.draco_normal || physicalObject.files?.small) return 'gltf'
		else if (physicalObject.text) return 'text'
		else if (physicalObject.productType) return physicalObject.productType 
	}
}
const gltfSize = () =>  { 
	return window.location?.search.toLowerCase().includes('meshhighquality') ? 'original' : 'small' 
}

