import React, { useRef,useState,useEffect } from 'react'
import { useProgress} from '@react-three/drei'
import {Typography, CircularProgress} from '@mui/material';
import { invalidate }  from '@react-three/fiber'
import {useMattoState} from '../MattoState'

export const LoadingMessage = () => {
  const loaderRef = useRef()
  const { active,total } = useProgress()
  const isUploading = useMattoState((state) => state.isUploading);

  useEffect(() => {
    if (active && loaderRef.current && window.gl) {
      const rect = window.gl.domElement.getBoundingClientRect()      
      loaderRef.current.style.top= (rect.bottom-30) + 'px'
      loaderRef.current.style.left= (rect.x + 20) + 'px'      
    }
    if (!window.debug) window.debug={}
    if (active==true) { window.debug.loading = true; window.debug.totalItemsToLoad = total }
    if (active==false) {
      window.debug.loading = false; window.debug.totalItemsToLoad = total; window.debug.lastUpdate=Date.now()
      invalidate()
    }
  },[active,loaderRef.current])

  useEffect(() => {
    if (isUploading && loaderRef.current && window.gl) {
      const rect = window.gl.domElement.getBoundingClientRect()      
      loaderRef.current.style.top = (rect.bottom-30) + 'px'
      loaderRef.current.style.left = (rect.x + 20) + 'px'      
    }
    if (!isUploading) {
      invalidate()
    }
  }, [isUploading]);
  return (
    <>
    {active && !isUploading &&
    <div ref={loaderRef} style={{position:'absolute'}} >
    <Typography display="inline"  >Loading Materials </Typography> <CircularProgress size={15} />
    </div>    
    }
    {isUploading &&
      <div ref={loaderRef} style={{position:'absolute', top:'0px', left:'0px'}} >
      <Typography display="inline">Uploading Image </Typography> <CircularProgress size={15} />
      </div>    
    }
    </>
  )
}

//if i havebnt been active for 100ms then console.log?